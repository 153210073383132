import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import "../../styles/Team1OT.css";
import {saveOccupational } from "../../firebase";
function Section1() {
  const [answer, setAnswer] = useState("");
  const [open, setOpen] = useState(false);
  
  
  const saveData =() => {

const alldata=  {
    housebound: sessionStorage.getItem("a"),
    feedingP: sessionStorage.getItem("a1"),
    toiletP: sessionStorage.getItem("a2"),
    bathP: sessionStorage.getItem("a3"),
    groomP: sessionStorage.getItem("a4"),
    dressP: sessionStorage.getItem("a5"),
       feedingC: sessionStorage.getItem("b1"),
    toiletC: sessionStorage.getItem("b2"),
    bathC: sessionStorage.getItem("b3"),
    groomC: sessionStorage.getItem("b4"),
    dressC: sessionStorage.getItem("b5"),
    feedingCom: sessionStorage.getItem("c1"),
    toiletCom: sessionStorage.getItem("c2"),
    bathCom: sessionStorage.getItem("c3"),
    groomCom: sessionStorage.getItem("c4"),
    dressCom: sessionStorage.getItem("c5"),
    telephone: sessionStorage.getItem("a11"),
    shopping: sessionStorage.getItem("a12"),
    food: sessionStorage.getItem("a13"),
    housekeeping: sessionStorage.getItem("a14"),
    laundry: sessionStorage.getItem("a15"),
    transportation: sessionStorage.getItem("a16"),
    medication: sessionStorage.getItem("a17"),
    finances: sessionStorage.getItem("a18"),
    changesinADL: sessionStorage.getItem("a19"),
    changesinADLcomm: sessionStorage.getItem("b19"),
    //To Check (26.08.22)
    addInfo: sessionStorage.getItem("OTAdditionalInfo"),

	bowelComment: (sessionStorage.getItem("cc1")||""),
	bowelCurrent:(sessionStorage.getItem("bc1")||""),
	bowelPrevious:(sessionStorage.getItem("ac1")||""),
	
	bladderComment:(sessionStorage.getItem("cc2")||""),
	bladderCurrent:(sessionStorage.getItem("bc2")||""),
	bladderPrevious:(sessionStorage.getItem("ac2")||""),
	
	  driving:(sessionStorage.getItem("a21")||""),
    employment:(sessionStorage.getItem("a20")||""),
    leisure:(sessionStorage.getItem("a21l")||""),

	draft: true
  };
  	  saveOccupational(alldata, false)

  }

  const handleClose = () => {
    setOpen(false);
  };

  sessionStorage.setItem("a", answer);

  const navigate = useNavigate();

  var typography = "Select the appropriate radio button";

  function validation() {
	  saveData()
    if (answer === "") {
      setOpen(true);
    } else {
      navigate("/Section2OT");
    }
  }

  return (
    <div className="screen">
      <Header
        typography={typography}
        history={"/PatientIDOT"}
        name={"Occupational Therapy"}
      />
      <br />
      <Card sx={{ maxWidth: "80%", borderRadius: "20px", textAlign: "center" }}>
        <CardContent>
          <FormControl>
            <FormLabel
              sx={{ fontSize: 24, color: "black" }}
              id="demo-controlled-radio-buttons-group"
            >
              Is the patient housebound?{" "}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => setAnswer(e.target.value)}
            >
              <FormControlLabel
                sx={{ fontSize: 20 }}
                value="yes"
                control={<Radio />}
                label={<Typography sx={{ fontSize: 20 }}>yes</Typography>}
              />
              <FormControlLabel
                sx={{ fontSize: 20 }}
                value="no"
                control={<Radio />}
                label={<Typography sx={{ fontSize: 20 }}>no</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
      <button className="next-button" onClick={validation}>
        Next
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            Please select an option
          </DialogTitle>
        </DialogContent>
        <Button onClick={handleClose}>close</Button>
      </Dialog>
    </div>
  );
}
export default Section1;
