import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { saveNurse } from "../firebase";
import Header from "../header";
import BottomNav from "../bottomnavg";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import CsvDownloadButton from 'react-json-to-csv'
import JsPDF from 'jspdf';

export default function SummaryPageNurse() {
  const navigate = useNavigate();
  const location = useLocation();
  // Displays entries in "Other Profession Referred"
  const [SLPdisplay, setSLP] = useState(false);
  const [Poddisplay, setPod] = useState(false);
  const [nutritiondisplay, setNutritiondisplay] = useState(false);
  const [Psycholdisplay, setPsychol] = useState(false);
  const [Psydisplay, setPsy] = useState(false);
  const [Otherdisplay, setOther] = useState(false);
  const [WeightLossdisplay, setWeightLoss] = useState(false);

  const [open, setOpen] = useState(false);
  
    const generatePDF = () => {

    const report = new JsPDF('landscape','pt','a4');
    report.html(document.querySelector('#report')).then(() => {
        report.save('NurseSummary.pdf');
    });
  }


  const alldata = {
    addmission: sessionStorage.getItem("BarthelIndexAdmission"),
    discharge: sessionStorage.getItem("BarthelIndexDischarge"),
    BarthelDifference: sessionStorage.getItem("BarthelIndex"),
    CalculatedHeight: sessionStorage.getItem("Patient-CalculatedHeight"),
    Weight: sessionStorage.getItem("Patient-Weight"),
    ExerciseWeightLoss: sessionStorage.getItem("Due to exercise?"), //yes or no,
    WeightLoss: sessionStorage.getItem("Weight Lost?"), //yes or no
    OtherProfessionReferredSLP: sessionStorage.getItem("Nurse-SLP"),
    OtherProfessionReferredPodiatry: sessionStorage.getItem("Nurse-Podiatry"),
    OtherProfessionReferredNaD: sessionStorage.getItem("Nurse-NaD"),
    OtherProfessionReferredPsychology:
      sessionStorage.getItem("Nurse-Psychology"),
    OtherProfessionReferredPsychiatry:
      sessionStorage.getItem("Nurse-Psychiatry"),
    OtherProfessionReferredOther: sessionStorage.getItem("Nurse-Other"),
    AddInfo: sessionStorage.getItem("NurseAdditionalInfo"),
	Appetite: sessionStorage.getItem("Appetite3"),
	FeedingRoute: sessionStorage.getItem("Feeding-Route3"),
	AssistiveDevices: sessionStorage.getItem("assistive-devices-required"),
	WoundPresent: sessionStorage.getItem("wound-present"),
	SuturesClipsPresent: sessionStorage.getItem("Sutures-Clips-Present")
    

  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    saveNurse(alldata);
    navigate("/PatientView");
  };

  const refresh = () => {
    setOpen(true);
    console.log(open);
  };

  useEffect(() => {
    if (sessionStorage.getItem("Weight Lost?") == "Yes") {
      setWeightLoss(true);
    } else {
      setWeightLoss(false);
    }

    if (
      !sessionStorage.getItem("Nurse-SLP") ||
      sessionStorage.getItem("Nurse-SLP") == "null"
    ) {
      setSLP(false);
    } else {
      setSLP(true);
    }

    if (
      !sessionStorage.getItem("Nurse-Podiatry") ||
      sessionStorage.getItem("Nurse-Podiatry") == "null"
    ) {
      setPod(false);
    } else {
      setPod(true);
    }

    if (
      !sessionStorage.getItem("Nurse-NaD") ||
      sessionStorage.getItem("Nurse-NaD") == "null"
    ) {
      setNutritiondisplay(false);
    } else {
      setNutritiondisplay(true);
    }

    if (
      !sessionStorage.getItem("Nurse-Psychology") ||
      sessionStorage.getItem("Nurse-Psychology") == "null"
    ) {
      setPsychol(false);
    } else {
      setPsychol(true);
    }

    if (
      !sessionStorage.getItem("Nurse-Psychiatry") ||
      sessionStorage.getItem("Nurse-Psychiatry") == "null"
    ) {
      setPsy(false);
    } else {
      setPsy(true);
    }

    if (
      !sessionStorage.getItem("Nurse-Other") ||
      sessionStorage.getItem("Nurse-Other") == "null"
    ) {
      setOther(false);
    } else {
      setOther(true);
    }
  });
  var typography =
    "Confirm your assessment findings. \nEdit from side edit buttons as necessary. \nConfirm and submit your assessment before exiting. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          history={"/AdditionalinfoNurse"}
          name={"Nurse Summary"}
          typography={typography}
        />
        <h1>Summary</h1>
        {/* Barthel Index box */}
						<div id="report" style={{width:"70%"}}>

        <div className="summary-container" style={{ width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Barthel Index</h3>
              </td>
              {console.log(location)}
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/BarthelIndexPage", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">Admission: </td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("BarthelIndexAdmission")}
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Referral:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("BarthelIndexDischarge")}
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Difference:</td>
              <td td className="summary-rightcolumn">
                {sessionStorage.getItem("BarthelIndex")}
              </td>
            </tr>
          </table>
        </div>
        {/* Measurements box */}
        <div className="summary-container" style={{ width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Measurements</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/Measurements", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">Height:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Patient-CalculatedHeight")} cm
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Weight:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Patient-Weight")} kg
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Weight Loss:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Weight Lost?")}
              </td>
            </tr>
            <tr style={{ display: WeightLossdisplay ? null : "none" }}>
              <td className="summary-leftcolumn">
                Weight Lost due to Exercise / Diet:
              </td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Due to exercise?")}
              </td>
            </tr>
			  <tr>
              <td className="summary-leftcolumn">BMI:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("bmi")} ({(parseFloat(sessionStorage.getItem("Patient-Weight"))/((parseFloat(sessionStorage.getItem("Patient-CalculatedHeight"))/100)*(parseFloat(sessionStorage.getItem("Patient-CalculatedHeight"))/100))).toFixed(2)})
              </td>
            </tr>
            {/* {fragment} */}
            {/* <tr>
              <td className="summary-leftcolumn">Weight lost due to exercise:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Weight Lost?")}
              </td>
            </tr> */}
          </table>
        </div>
		
		   {/* Others box */}
        <div className="summary-container" style={{ width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Others</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/Others", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">Appetite:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Appetite3")} 
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Feeding route:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Feeding-Route3")} 
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Any assistive devices required?:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("assistive-devices-required")}
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">
                Skin condition: Wound present:
              </td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("wound-present")}
              </td>
            </tr>
			<tr>
              <td className="summary-leftcolumn">
                Skin condition: Sutures / Clips Present:
              </td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Sutures-Clips-Present")}
              </td>
            </tr>
            {/* {fragment} */}
            {/* <tr>
              <td className="summary-leftcolumn">Weight lost due to exercise:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Weight Lost?")}
              </td>
            </tr> */}
          </table>
        </div>
        {/* Other Profession Referred box */}
        <div className="summary-container" style={{ width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>
                  Other Profession Referred
                </h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/OtherProfRef", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr style={{ display: SLPdisplay ? null : "none" }}>
              <td className="summary-leftcolumn">
                Speech and Language Pathologist:{" "}
              </td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Nurse-SLP")}
              </td>
            </tr>
            <tr style={{ display: Poddisplay ? null : "none" }}>
              <td className="summary-leftcolumn">Podiatry:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Nurse-Podiatry")}
              </td>
            </tr>
            <tr style={{ display: nutritiondisplay ? null : "none" }}>
              {/* <tr value={nutritiondisplay}> */}
              <td className="summary-leftcolumn">Nutrition and Dietetics:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Nurse-NaD")}
              </td>
            </tr>
            <tr style={{ display: Psycholdisplay ? null : "none" }}>
              <td className="summary-leftcolumn">Psychology:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Nurse-Psychology")}
              </td>
            </tr>
            <tr style={{ display: Psydisplay ? null : "none" }}>
              <td className="summary-leftcolumn">Psychiatry:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Nurse-Psychiatry")}
              </td>
            </tr>
            <tr style={{ display: Otherdisplay ? null : "none" }}>
              <td className="summary-leftcolumn">Other:</td>
              <td className="summary-rightcolumn">
                {sessionStorage.getItem("Nurse-Other")}
              </td>
            </tr>
          </table>
        </div>
        {/* Additional Info box */}
        <div className="summary-container" style={{ width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Additional Info</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/AdditionalinfoNurse", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
          </table>
          <div className="summary-additional-info">
            {sessionStorage.getItem("NurseAdditionalInfo")}
          </div>
        </div>


        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: "1em", borderRadius: "25px" }}
        >
          <h5
            style={{
              marginTop: "1em",
              marginRight: "1em",
              marginLeft: "1em",
              marginBottom: "0.5em",
            }}
          >
            Submit?
          </h5>
          <DialogContentText
            style={{
              marginRight: "1.3em",
              marginLeft: "1.3em",
            }}
          >
            This will save the data and end the assessment.
          </DialogContentText>
          <div
            style={{
              padding: "1em",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleClose()}
              variant="contained"
            >
              No
            </Button>

            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleOpen()}
              variant="contained"
            >
              Yes
            </Button>
          </div>
        </Dialog>
      </div>
	  	  		  <button onClick={generatePDF} style={{borderColor:"#015893", backgroundColor:"rgb(50, 201, 83)", borderRadius: 10, fontSize: "large"}} type="button">Export PDF</button>

      </div>
      <BottomNav
        prevNavigation={() => navigate("/AdditionalinfoNurse")}
        submitButton={() => refresh()}
        currentPage={6}
        numPages={6}
      />
    </div>
  );
}
