import React, { useEffect } from "react";
import Header from "../header";
import { Grid, List, ListItem } from "@mui/material";
import { useState } from "react";
import "../styles/HomeContent.css";
import "../styles/Summary.css";
import { useNavigate } from "react-router-dom";
import { getPatients2 } from "../firebase";
import "../styles/SocialWorkerSum.css";
import BottomNav from "../bottomnavg";
import { getPatientSectionDetails } from "../firebase";

export default function PatientViewDetails() {
  const [patients, setPatients] = useState([]);
  const [currentPatient, setCurrentPatient] = useState({});

  const navigation = useNavigate();

  useEffect(() => {
    getPatients2().then((result) => {
      setPatients(result);
    });
  }, []);

  useEffect(() => {
    setPatient();
  }, [patients]);

  const setNurse = (data) => {
    sessionStorage.setItem("BarthelIndexAdmission", data.Admission);
    sessionStorage.setItem("BarthelIndexDischarge", data.Discharge);
    sessionStorage.setItem("BarthelIndex", data.BarthelDifference);
    sessionStorage.setItem("BarthelIndexAdmissionArray", data.AdmissionA);
    sessionStorage.setItem("BarthelIndexDischargeArray", data.DischargeA);
    sessionStorage.setItem("BarthelIndexDifference", data.BarthelDifferenceA);
    sessionStorage.setItem("Patient-CalculatedHeight", data.CalculatedHeight);
    sessionStorage.setItem("Patient-Height", data.Height);
    sessionStorage.setItem("Patient-Weight", data.Weight);
    sessionStorage.setItem("Weight Lost?", data.WeightLoss);
    sessionStorage.setItem("Due to exercise?", data.ExerciseWeightLoss);
    sessionStorage.setItem("bmi", data.BMI);
    sessionStorage.setItem(
      "Nurse-SLP",
      data.OtherProfessionReferredSLP || null
    );
    sessionStorage.setItem(
      "Nurse-Podiatry",
      data.OtherProfessionReferredPodiatry || null
    );
    sessionStorage.setItem(
      "Nurse-NaD",
      data.OtherProfessionReferredNaD || null
    );
    sessionStorage.setItem(
      "Nurse-Psychology",
      data.OtherProfessionReferredPsychology || null
    );
    sessionStorage.setItem(
      "Nurse-Psychiatry",
      data.OtherProfessionReferredPsychiatry || null
    );
    sessionStorage.setItem(
      "Nurse-Other",
      data.OtherProfessionReferredOther || null
    );
    sessionStorage.setItem("NurseAdditionalInfo", data.AddInfo);

    sessionStorage.setItem("Appetite3", data.Appetite);
    sessionStorage.setItem("Feeding-Route3", data.FeedingRoute);
    sessionStorage.setItem("assistive-devices-required", data.AssistiveDevices);
    sessionStorage.setItem("wound-present", data.WoundPresent);
    sessionStorage.setItem("Sutures-Clips-Present", data.SuturesClipsPresent);
  };

  const setPhysio = (data) => {
    console.log(data);
    sessionStorage.setItem(
      "TUGQuestion1",
      data.TUGTestResults.LevelsOfMobility.PreviousLevelofMobility ||
        data.TUGTestResults.LevelsOfMobility.IPreviousLevelofMobility
    );
    sessionStorage.setItem("q5", data.smoking);
    sessionStorage.setItem("q1", data.diagnosis);
    sessionStorage.setItem(
      "TUGQuestion2",
      data.TUGTestResults.LevelsOfMobility.CurrentLevelofMobility ||
        data.TUGTestResults.LevelsOfMobility.ICurrentLevelofMobility
    );
    sessionStorage.setItem(
      "mobilityQuestion11",
      data.TUGTestResults.LevelsOfMobility.DPreviousLevelofMobility
    );
    sessionStorage.setItem(
      "mobilityQuestion22",
      data.TUGTestResults.LevelsOfMobility.DCurrentLevelofMobility
    );
    sessionStorage.setItem(
      "TUGTestCarriedOut",
      data.TUGTestResults.TUGTestCarriedOut
    );
    sessionStorage.setItem(
      "TUGTimer",
      data.TUGTestResults.RiskOfFallStatus.TimeTakenInSeconds
    );
    sessionStorage.setItem(
      "TUGStatus",
      data.TUGTestResults.RiskOfFallStatus.Status
    );
    sessionStorage.setItem("question1", data.GripStrengthResults.Question1);
    sessionStorage.setItem("question4", data.GripStrengthResults.Question4);
    //LeftResultCHECK
    sessionStorage.setItem(
      "MaxLeftHandResult",
      JSON.stringify(data.GripStrengthResults.MaxLeftHandResult)
    );
    const gender = sessionStorage.getItem("Patientgender");
    let left = data.GripStrengthResults.MaxLeftHandResult.Risk;
    if (typeof data.GripStrengthResults.MaxLeftHandResult.Risk == "object") {
      if (gender == "Female" || gender == "female")
        left = data.GripStrengthResults.MaxLeftHandResult.Risk.FResult;
      else left = data.GripStrengthResults.MaxLeftHandResult.Risk.MResult;
    }

    let right = data.GripStrengthResults.MaxRightHandResult.Risk;
    if (typeof data.GripStrengthResults.MaxLeftHandResult.Risk == "object") {
      if (gender == "Female" || gender == "female")
        right = data.GripStrengthResults.MaxRightHandResult.Risk.FResult;
      else right = data.GripStrengthResults.MaxRightHandResult.Risk.MResult;
    }
    sessionStorage.setItem("LeftHandResultStrength", left);
    sessionStorage.setItem("question5", data.GripStrengthResults.Question5);
    //Right result CHECK
    sessionStorage.setItem(
      "MaxRightHandResult",
      JSON.stringify(data.GripStrengthResults.MaxRightHandResult)
    );
    sessionStorage.setItem("RightHandResultStrength", right);
    sessionStorage.setItem("PhysioAdditionalInfo", data.AddInfo);

    //current mobility
    sessionStorage.setItem("ulRight", data.CurrentMobility?.ul?.right || "");
    sessionStorage.setItem("ulLeft", data.CurrentMobility?.ul?.left);

    sessionStorage.setItem("llRight", data.CurrentMobility?.ll?.right);
    sessionStorage.setItem("llLeft", data.CurrentMobility?.ll?.left);

    sessionStorage.setItem("bedmobility", data.CurrentMobility?.bedMobility);
    sessionStorage.setItem("transfers", data.CurrentMobility?.transfers);
    sessionStorage.setItem("freeText", data.CurrentMobility?.otherComment);
    sessionStorage.setItem("gripStrength1", data.HandGripStrengthTest.first);
    sessionStorage.setItem("gripStrength2", data.HandGripStrengthTest.second);
    sessionStorage.setItem("gripStrength3", data.HandGripStrengthTest.third);
    sessionStorage.setItem("leftInput1temp", data.HandGripStrengthTest.left1);
    sessionStorage.setItem("leftInput2temp", data.HandGripStrengthTest.left2);
    sessionStorage.setItem("rightInput1temp", data.HandGripStrengthTest.right1);
    sessionStorage.setItem("rightInput2temp", data.HandGripStrengthTest.right2);
  };

  const handleClick = () => {
    getPatientSectionDetails(
      sessionStorage.getItem("patientId"),
      "SectionE"
    ).then((data) => {
      if (data && data.draft) {
        setNurse(data);
      } else {
        setNurse(data);
      }
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionB"
      ).then((data) => {
        if (!data) navigation("/v2/diagnosis");
        else if (data && data.draft) {
          setPhysio(data);
          navigation("/v2/diagnosis");
        } else {
          setPhysio(data);
          navigation("/v2/review", { state: { summary: true } });
        }
      });
    });
    navigation(
      window.location.href.includes("v2") ? "/v2/diagnosis" : "/Patient-Menu"
    );
  };

  var typography =
    "Confirm patient details are correct. \nProceed to assessment or go back to choose another patient.";

  // sessionStorage.setItem("id","45");
  var id = sessionStorage.getItem("id");

  const setPatient = () => {
    patients.forEach((patient) => {
      if (patient.id === id) {
        setCurrentPatient(patient);
        console.log(currentPatient);
      }
    });
    console.log(patients);
  };

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Grid
          justifyContent={"center"}
          container
          rowGap={4}
          columns={{ xs: 2, sm: 2, md: 6 }}
        >
          <Header
            history={
              window.location.href.includes("v2")
                ? "/v2/PatientView"
                : "/PatientView"
            }
            typography={typography}
            name={"Patient Details"}
          ></Header>
          <h1 style={{ paddingTop: "0.4em", paddingBottom: "0.1em" }}>
            Patient Details
          </h1>
          <List>
            <ListItem>
              <table
                className="search-container"
                style={{ borderSpacing: "0.7em" }}
              >
                {/* <Button onClick={() => this.forceUpdate()}  > Hello</Button> */}
                <tr className="grid-data">
                  <td>
                    <h5>Name :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>{currentPatient.name}</td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>ID :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>{currentPatient.id}</td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Age :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>{currentPatient.age}</td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Gender :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.gender}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Address :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {[
                      currentPatient.houseNum,
                      currentPatient.houseName,
                      currentPatient.streetname,
                      currentPatient.locality,
                    ]
                      .join(", ")
                      .replace(",", "")
                      .replace(", ,", ",")}
                  </td>
                </tr>
              </table>
            </ListItem>
            <ListItem>
              <table
                className="search-container"
                style={{ borderSpacing: "0.7em" }}
              >
                <tr className="grid-data">
                  <td>
                    <h5>Next of Kin Name :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.kinName}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Relationship :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.kinRelation}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Contact Number :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.KinNumber}
                  </td>
                </tr>
              </table>
            </ListItem>
            <ListItem>
              <table
                className="search-container"
                style={{ borderSpacing: "0.7em" }}
              >
                {/* <td className="Table-Names"><h3>Support</h3></td><br /> */}
                {/* <h5 style={{paddingBottom:"3em"}}>jifdsojois</h5>  */}
                <tr className="grid-data">
                  <td>
                    <h5>Admitting Consultant :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.AdmitConsultant}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Admitted Ward :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.AdmitWard}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5> No. of Admissions in Past Year : </h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.AdmitLastYear}
                  </td>
                </tr>
              </table>
            </ListItem>
          </List>
        </Grid>
      </div>
      <BottomNav
        nextNavigation={() => handleClick()}
        prevNavigation={() =>
          navigation(
            window.location.href.includes("v2")
              ? "/v2/PatientView"
              : "/PatientView"
          )
        }

        // buttonDisabled={next}
      />
      {/* <ListItem>
            <Button onClick={handleClick} variant="contained" fullWidth={true}>
              Next
            </Button>
          </ListItem> */}
    </div>
  );
}
