import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import { TableCell, TableRow } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import BottomNav from "../bottomnavg";
import { saveDataSectionA as saveData } from "../firebase";

function Section7() {
  const navigate = useNavigate();

  const [trail1, setTrial1] = useState([]);
  const [trail2, setTrial2] = useState([]);
  var typography =
    "Memory\n \nRead a list of 5 words at a rate of one per second, giving the following    instructions: “This is a memory test. I am going to read a list of words that you will have to remember now and later on. Listen carefully. When I am through, tell me as many words as you can remember. It doesn’t matter in what order you say them”. \n\nMark a check in the allocated space for each word the patient produces on this first trial. \n\nWhen the patient indicates that they finished (recalled all words), or can recall no more words, read the list a second time with the following         instructions: “I am going to read the same list for a second time. Try to     remember and tell me as many words as you can, including words you said the first time.” \n\nPut a check in the allocated space for each word the patient recalls after the second trial. \nAt the end of the second trial, inform the patient that they will be asked to recall these words again by saying, “I will ask you to recall those words again at the end of the test.” \n\nScoring:\nNo points are given for Trials One and Two.";

  useEffect(() => {
    if (
      sessionStorage.getItem("trail1") &&
      sessionStorage.getItem("trail1") != "null"
    )
      setTrial1(sessionStorage.getItem("trail1").split(",").map(Number));
    if (
      sessionStorage.getItem("trail2") &&
      sessionStorage.getItem("trail2") != "null"
    )
      setTrial2(sessionStorage.getItem("trail2").split(",").map(Number));
  }, []);

  const onClick = (index, trail, checked) => {
    if (trail == 1) {
      if (!checked) {
        let temp = [...trail1];
        if (temp) {
          temp = temp.filter((i) => i != index && i != `${index}`);
        } else temp = [];
        setTrial1(temp);
      } else {
        let temp = [...trail1];
        temp.push(index);
        setTrial1(temp);
      }
    } else {
      if (!checked) {
        let temp = [...trail2];
        if (temp) temp = temp.filter((i) => i != index);
        else temp = [];
        setTrial2(temp);
      } else {
        let temp = [...trail2];
        temp.push(index);
        setTrial2(temp);
      }
    }

    sessionStorage.setItem("trail1", trail1);
    sessionStorage.setItem("trail2", trail2);
    console.log(sessionStorage.getItem("trail1"));
    console.log(sessionStorage.getItem("trail2"));
  };
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec6"} name={"Memory"} />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          <div>
            <h5 style={{ marginBottom: "1.7em" }}>Memory</h5>
            <p style={{ marginBottom: "1.5em", textAlign: "center" }}>
              Read the list of words and ask the patient to repeat them twice.
            </p>
            <br />
            {/* Table */}
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Face</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Velvet</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Church</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Daisy</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Red</b>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>1st Trial</TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(1, 1, e.target.checked)}
                      checked={
                        (trail1 &&
                          trail1.length > 0 &&
                          typeof trail1 == "object" &&
                          trail1.find((i) => i == 1) &&
                          trail1.find((i) => i == 1) > 0) ||
                        false
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(2, 1, e.target.checked)}
                      checked={
                        (trail1 &&
                          trail1.length > 0 &&
                          typeof trail1 == "object" &&
                          trail1.find((i) => i == 2) &&
                          trail1.find((i) => i == 2) > 0) ||
                        false
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(3, 1, e.target.checked)}
                      checked={
                        trail1 &&
                        trail1.length > 0 &&
                        typeof trail1 == "object" &&
                        trail1.find((i) => i == 3) &&
                        trail1.find((i) => i == 3) > 0
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(4, 1, e.target.checked)}
                      checked={
                        trail1 &&
                        trail1.length > 0 &&
                        typeof trail1 == "object" &&
                        trail1.find((i) => i == 4) &&
                        trail1.find((i) => i == 4) > 0
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(5, 1, e.target.checked)}
                      checked={
                        trail1 &&
                        trail1.length > 0 &&
                        typeof trail1 == "object" &&
                        trail1.find((i) => i == 5) &&
                        trail1.find((i) => i == 5) > 0
                      }
                    />
                  }
                />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>2nd Trial</TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(1, 2, e.target.checked)}
                      checked={
                        trail2 &&
                        trail2.length > 0 &&
                        typeof trail2 == "object" &&
                        trail2.find((i) => i == 1) &&
                        trail2.find((i) => i == 1) > 0
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(2, 2, e.target.checked)}
                      checked={
                        trail2 &&
                        trail2.length > 0 &&
                        typeof trail2 == "object" &&
                        trail2.find((i) => i == 2) &&
                        trail2.find((i) => i == 2) > 0
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(3, 2, e.target.checked)}
                      checked={
                        trail2 &&
                        trail2.length > 0 &&
                        typeof trail2 == "object" &&
                        trail2.find((i) => i == 3) &&
                        trail2.find((i) => i == 3) > 0
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(4, 2, e.target.checked)}
                      checked={
                        trail2 &&
                        trail2.length > 0 &&
                        typeof trail2 == "object" &&
                        trail2.find((i) => i == 4) &&
                        trail2.find((i) => i == 4) > 0
                      }
                    />
                  }
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(e) => onClick(5, 2, e.target.checked)}
                      checked={
                        trail2 &&
                        trail2.length > 0 &&
                        typeof trail2 == "object" &&
                        trail2.find((i) => i == 5) &&
                        trail2.find((i) => i == 5) > 0
                      }
                    />
                  }
                />
              </TableCell>
            </TableRow>
            <br />
          </div>
        </div>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "left" }}>Score: No points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => {
          sessionStorage.setItem("trail1", trail1);
          sessionStorage.setItem("trail2", trail2);
          saveData();
          navigate("/v2/sec8");
        }}
        prevNavigation={() => {
          saveData();
          navigate("/v2/sec6");
        }}
        currentPage={9}
        numPages={19}
      />
    </div>
  );
}
export default Section7;
