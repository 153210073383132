import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { CardContent } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section15() {
  const navigate = useNavigate();

  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }
  const [score, setScore] = useState(0);
  // Handles confirm dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

const [checkedv15, setcheckedv15] = useState([])
  useEffect(() => {
    console.log("edasd");
    console.log({ score });
  });

  function navToNextPage() {
    sessionStorage.setItem("checkedv15", checkedv15);
	saveData()
	    navigate("/results");

//    sessionStorage.setItem("month", "0");
 //   sessionStorage.setItem("year", "0");
  //  sessionStorage.setItem("day", "0");
 //   sessionStorage.setItem("city", "0");
 //   sessionStorage.setItem("place", "0");
  }
  function getScore() {
    console.log("edasasdd");
    console.log({ score });
    var score =
      parseInt(sessionStorage.getItem("date")) +
      parseInt(sessionStorage.getItem("month")) +
      parseInt(sessionStorage.getItem("year")) +
      parseInt(sessionStorage.getItem("day")) +
      parseInt(sessionStorage.getItem("place")) +
      parseInt(sessionStorage.getItem("city"));
    setScore(score);
    sessionStorage.setItem("orientation", score.toString());
  }

  const onChangeDateCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("date", "1");
    else sessionStorage.setItem("date", "0");
	if(value)setcheckedv15([...checkedv15, 1])
		else setcheckedv15([...checkedv15.filter(i=>i!=1)])
    getScore();
  };

  const onChangeMonthCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("month", "1");
    else sessionStorage.setItem("month", "0");
	if(value)setcheckedv15([...checkedv15, 2])
		else setcheckedv15([...checkedv15.filter(i=>i!=2)])
    getScore();
  };

  const onChangeYearCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("year", "1");
    else sessionStorage.setItem("year", "0");
	if(value)setcheckedv15([...checkedv15, 3])
		else setcheckedv15([...checkedv15.filter(i=>i!=3)])
    getScore();
  };

  const onChangeDayCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("day", "1");
    else sessionStorage.setItem("day", "0");
	if(value)setcheckedv15([...checkedv15, 4])
		else setcheckedv15([...checkedv15.filter(i=>i!=4)])
    getScore();
  };

  const onChangePlaceCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("place", "1");
    else sessionStorage.setItem("place", "0");
	if(value)setcheckedv15([...checkedv15, 5])
		else setcheckedv15([...checkedv15.filter(i=>i!=5)])
    getScore();
  };

  const onChangeCityCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("city", "1");
    else sessionStorage.setItem("city", "0");
	if(value)setcheckedv15([...checkedv15, 6])
		else setcheckedv15([...checkedv15.filter(i=>i!=6)])
    getScore();
  };

  var typography = "Orientation\n\nInstruct the patient:\n“Tell me the date today”. \nIf the patient does not give a complete answer, then prompt accordingly by saying: “Tell me the [year, month, exact date, and day of the week].” \nThen say: “Now, tell me the name of this place, and which city it is in.” \n\nScoring:\nGive one point for each item correctly answered. \nThe subject must tell the exact date and the exact place (name of \nhospital). \nNo points are allocated if subject makes an error of one day for the day and date.\n\nClick whether you would like to allocate the points or not.";

  useEffect(()=>{
	  if(sessionStorage.getItem("checkedv15") &&sessionStorage.getItem("checkedv15")!="null"){
		  setcheckedv15(sessionStorage.getItem("checkedv15").split(",").map(Number))
		  let temp =sessionStorage.getItem("checkedv15").split(",").map(Number)
		  console.log(temp)
		  if(temp)
		  {
			  if(temp.includes(1))
				  sessionStorage.setItem("date", "1");
			  if(temp.includes(2))
				  sessionStorage.setItem("month", "1");
			  if(temp.includes(3))
				  sessionStorage.setItem("year", "1");
			  if(temp.includes(4))
				  sessionStorage.setItem("day", "1");
			  if(temp.includes(5))
				  sessionStorage.setItem("place", "1");
			  if(temp.includes(6))
				  sessionStorage.setItem("city", "1");
			  console.log(temp.includes(1))
		  }
	  }
	  
	  		  getScore();

  }, [])
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/sec14"}
          name={"Orientation"}
        />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          {/* Checkboxes */}
          <h5 style={{ marginBottom: "0.3em", alignSelf: "flex-start" }}>
            Orientation
          </h5>
          <TableContainer
            style={{
              paddingTop: "1em",
              paddingBottom: "1em",
              marginLeft: "1em",
              marginRight: "1em",
              width: "90%",
              alignSelf: "center",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: "0.8em",
                      textAlign: "center",
                      padding: "7px",
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8em",
                      textAlign: "center",
                      padding: "7px",
                    }}
                  >
                    Month
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8em",
                      textAlign: "center",
                      padding: "7px",
                    }}
                  >
                    Year
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8em",
                      textAlign: "center",
                      padding: "7px",
                    }}
                  >
                    Day
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8em",
                      textAlign: "center",
                      padding: "7px",
                    }}
                  >
                    Place
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.8em",
                      textAlign: "center",
                      padding: "7px",
                    }}
                  >
                    City
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableCell style={{ padding: "7px" }}>
                  <FormControlLabel
                    style={{
                      display: "block",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    control={<Checkbox checked={checkedv15 && checkedv15.length>0 && typeof checkedv15 == "object" && checkedv15.find(i=> i==1) && checkedv15.find(i=> i==1)>0}/>}
                    id="date"
                    onClick={onChangeDateCheck}
                  />
                </TableCell>
                <TableCell style={{ padding: "7px" }}>
                  <FormControlLabel
                    style={{
                      display: "block",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    control={<Checkbox checked={checkedv15 && checkedv15.length>0 && typeof checkedv15 == "object" && checkedv15.find(i=> i==2) && checkedv15.find(i=> i==2)>0}/>}
                    id="month"
                    onClick={onChangeMonthCheck}
                  />
                </TableCell>
                <TableCell style={{ padding: "7px" }}>
                  <FormControlLabel
                    style={{
                      display: "block",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    control={<Checkbox checked={checkedv15 && checkedv15.length>0 && typeof checkedv15 == "object" && checkedv15.find(i=> i==3) && checkedv15.find(i=> i==3)>0}/>}
                    id="year"
                    onClick={onChangeYearCheck}
                  />
                </TableCell>
                <TableCell style={{ padding: "7px" }}>
                  <FormControlLabel
                    style={{
                      display: "block",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    control={<Checkbox checked={checkedv15 && checkedv15.length>0 && typeof checkedv15 == "object" && checkedv15.find(i=> i==4) && checkedv15.find(i=> i==4)>0}/>}
                    id="day"
                    onClick={onChangeDayCheck}
                  />
                </TableCell>
                <TableCell style={{ padding: "7px" }}>
                  <FormControlLabel
                    style={{
                      display: "block",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    control={<Checkbox checked={checkedv15 && checkedv15.length>0 && typeof checkedv15 == "object" && checkedv15.find(i=> i==5) && checkedv15.find(i=> i==5)>0} />}
                    id="place"
                    onClick={onChangePlaceCheck}
                  />
                </TableCell>
                <TableCell style={{ padding: "7px" }}>
                  <FormControlLabel
                    style={{
                      display: "block",
                      textAlign: "center",
                      margin: "auto",
                    }}
                    control={<Checkbox checked={checkedv15 && checkedv15.length>0 && typeof checkedv15 == "object" && checkedv15.find(i=> i==6) && checkedv15.find(i=> i==6)>0}/>}
                    id="city"
                    onClick={onChangeCityCheck}
                  />
                </TableCell>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/* Score box */}
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
        prevNavigation={() => {saveData(); navigate("/sec14")}}
        currentPage={18}
        numPages={21}
      />
     
    </div>
  );
}
export default Section15;
