import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Header from "../header";
import Card from "@mui/material/Card";
import BottomNav from "../bottomnavg";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import ScrollBtn from "../ScrollBtn";
import { saveTeam3 } from "../firebase";

function Section2() {
  const diseasesArray = [
    { id: 1, condition: "Myocardial Infarction", score: 1 },
    { id: 2, condition: "Congestive Heart Failure", score: 1 },
    { id: 3, condition: "Peripheral Vascular Disease", score: 1 },
    { id: 4, condition: "Cerebrovascular Disease", score: 1 },
    { id: 5, condition: "Dementia", score: 1 },
    { id: 6, condition: "Chronic Pulmonary Disease", score: 1 },
    { id: 7, condition: "Rheumatic Disease", score: 1 },
    { id: 8, condition: "Peptic Ulcer Disease", score: 1 },
    { id: 9, condition: "Liver Disease, mild", score: 1 },
    { id: 10, condition: "Diabetes without chronic complications", score: 1 },
    { id: 11, condition: "Renal Disease, mild to moderate", score: 1 },
    { id: 12, condition: "Diabetes with chronic complications", score: 2 },
    { id: 13, condition: "Hemiplegia or Paraplegia", score: 2 },
    { id: 14, condition: "Any Malignancy", score: 2 },
    { id: 15, condition: "Liver Disease, moderate to severe", score: 3 },
    { id: 16, condition: "Renal Disease, severe", score: 3 },
    { id: 17, condition: "HIV Infection, no AIDS", score: 3 },
    { id: 18, condition: "Metastatic Solid Tumor", score: 6 },
    { id: 19, condition: "AIDS", score: 6 },
  ];
  
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }


  const [checkedState, setCheckedState] = useState(
    new Array(diseasesArray.length).fill(false)
  );

  const [totalScore, setTotal] = useState(0);
  const [open, setOpen] = useState(false);

  function navToNextPage() {
	  saveData()
    //sessionStorage.setItem("MOCASec3Comment", comment);
    navigate("/moca");
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    if( sessionStorage.getItem("mobility-index"))
      setTotal(parseInt(sessionStorage.getItem("mobility-index")))
    if( sessionStorage.getItem("mobility-index-checked"))
      setCheckedState(sessionStorage.getItem("mobility-index-checked").split(",").map(v => v !== "false"))
  }, [])

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);
    sessionStorage.setItem("mobility-index-checked", updatedCheckedState)
    const totalScore = updatedCheckedState.reduce(
      (sum, currentState, index) => {
        if (currentState === true) {
          return sum + diseasesArray[index].score;
        }
        return sum;
      },
      0
    );

    setTotal(totalScore);
    sessionStorage.setItem("mobility-index", totalScore.toString());
  };

  function checkMutuallyInclusive() {
    if (document.getElementById("mal2").checked === true) {
      document.getElementById("mal1").checked = true;
      document.getElementById("mal1").disabled = true;
    } else {
      document.getElementById("mal1").disabled = false;
      document.getElementById("mal1").disabled = false;
    }
  }

  function LiverCheck() {
    if (document.getElementById("liver1").checked === true) {
      document.getElementById("liver2").disabled = true;
    } else {
      document.getElementById("liver2").disabled = false;
    }
  }

  function LiverCheck2() {
    if (document.getElementById("liver2").checked === true) {
      document.getElementById("liver1").disabled = true;
    } else {
      document.getElementById("liver1").disabled = false;
    }
  }

  function DiabetesCheck() {
    if (document.getElementById("diabetes1").checked === true) {
      document.getElementById("diabetes2").disabled = true;
    } else {
      document.getElementById("diabetes2").disabled = false;
    }
  }

  function DiabetesCheck2() {
    if (document.getElementById("diabetes2").checked === true) {
      document.getElementById("diabetes1").disabled = true;
    } else {
      document.getElementById("diabetes1").disabled = false;
    }
  }

  function RenalCheck() {
    if (document.getElementById("renal1").checked === true) {
      document.getElementById("renal2").disabled = true;
    } else {
      document.getElementById("renal2").disabled = false;
    }
  }

  function RenalCheck2() {
    if (document.getElementById("renal2").checked === true) {
      document.getElementById("renal1").disabled = true;
    } else {
      document.getElementById("renal1").disabled = false;
    }
  }

  function HivCheck() {
    if (document.getElementById("hiv1").checked === true) {
      document.getElementById("hiv2").disabled = true;
    } else {
      document.getElementById("hiv2").disabled = false;
    }
  }

  function HivCheck2() {
    if (document.getElementById("hiv2").checked === true) {
      document.getElementById("hiv1").disabled = true;
    } else {
      document.getElementById("hiv1").disabled = false;
    }
  }

  const navigate = useNavigate();

  var typography =
    "Check the appropriate check boxes.\n \nThe final CCI score is shown at the bottom of the page. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/diagnosis"}
          name={"Charlson Co-Morbidity Index"}
        />
        <h1>Charlson CoMorbidity Index</h1>
        <div
          className="gc-container"
          style={{ fontSize: "20px", fontFamily: "Roboto" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[0]}
              onChange={() => handleOnChange(0)}
            />
            <label className="check-label">Myocardial Infarction</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[1]}
              onChange={() => handleOnChange(1)}
            />
            <label className="check-label">Congestive Heart Failure</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[2]}
              onChange={() => handleOnChange(2)}
            />
            <label className="check-label">Peripheral Vascular Disease</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[3]}
              onChange={() => handleOnChange(3)}
            />
            <label className="check-label">Cerebrovascular Disease</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[4]}
              onChange={() => handleOnChange(4)}
            />
            <label className="check-label">Dementia</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[5]}
              onChange={() => handleOnChange(5)}
            />
            <label className="check-label">Chronic Pulmonary Disease</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[6]}
              onChange={() => handleOnChange(6)}
            />
            <label className="check-label">Rheumatic Disease</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[7]}
              onChange={() => handleOnChange(7)}
            />
            <label className="check-label">Peptic Ulcer Disease</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="liver1"
              checked={checkedState && checkedState[8]}
              onChange={() => {
                handleOnChange(8);
                LiverCheck();
              }}
            />
            <label className="check-label">Liver Disease, mild</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="diabetes1"
              checked={checkedState && checkedState[9]}
              onChange={() => {
                handleOnChange(9);
                DiabetesCheck();
              }}
            />
            <label className="check-label">
              Diabetes without chronic complications
            </label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="renal1"
              checked={checkedState && checkedState[10]}
              onChange={() => {
                handleOnChange(10);
                RenalCheck();
              }}
            />
            <label className="check-label">
              Renal Disease, mild to moderate
            </label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="diabetes2"
              checked={checkedState && checkedState[11]}
              onChange={() => {
                handleOnChange(11);
                DiabetesCheck2();
              }}
            />
            <label className="check-label">
              Diabetes with chronic complications
            </label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[12]}
              onChange={() => handleOnChange(12)}
            />
            <label className="check-label">Hemiplegia or Paraplegia</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[13]}
              id="mal1"
              onChange={() => {
                handleOnChange(13);
              }}
            />
            <label className="check-label">Any Malignancy</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="liver2"
              checked={checkedState && checkedState[14]}
              onChange={() => {
                handleOnChange(14);
                LiverCheck2();
              }}
            />
            <label className="check-label">
              Liver Disease, moderate to severe
            </label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="renal2"
              checked={checkedState && checkedState[15]}
              onChange={() => {
                handleOnChange(15);
                RenalCheck2();
              }}
            />
            <label className="check-label">Renal Disease, severe</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="hiv1"
              checked={checkedState && checkedState[16]}
              onChange={() => {
                handleOnChange(16);
                HivCheck();
              }}
            />
            <label className="check-label">HIV Infection, no AIDS</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="mal2"
              checked={checkedState && checkedState[17]}
              onChange={() => {
                handleOnChange(17);
                checkMutuallyInclusive();
              }}
            />
            <label className="check-label">Metastatic Solid Tumor</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              checked={checkedState && checkedState[18]}
              id="hiv2"
              onChange={() => {
                handleOnChange(18);
                HivCheck2();
              }}
            />
            <label className="check-label">AIDS</label>
          </div>
          <h5
            style={{ marginTop: "1em", marginLeft: "0.5em", fontSize: "1.2em" }}
          >
            {" "}
            Score: {totalScore}{" "}
          </h5>
        </div>

        {/* Confirm dialog  */}
        <Dialog
          open={open}
          onClose={handleClose}
          style={{ padding: "1em", borderRadius: "25px" }}
        >
          <h5
            style={{
              marginTop: "1em",
              marginRight: "1em",
              marginLeft: "1em",
              marginBottom: "0.5em",
            }}
          >
            Continue?
          </h5>
          <DialogContentText
            style={{
              marginRight: "1.3em",
              marginLeft: "1.3em",
            }}
          >
            Are you sure you want to continue?
          </DialogContentText>
          <div
            style={{
              padding: "1em",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* Closes dialog */}
            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleClose()}
              variant="contained"
            >
              No
            </Button>

            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => navToNextPage()}
              variant="contained"
            >
              Yes
            </Button>
          </div>
        </Dialog>
        <ScrollBtn />
        <BottomNav
          prevNavigation={() => {saveData(); navigate("/socialhabits")}}
          nextNavigation={() => setOpen(true)}
          currentPage={4}
          numPages={21}
        />
      </div>
    </div>
  );
}
export default Section2;
