import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveDataSectionA as saveData } from "../firebase";

function Section13() {
  const [score, setScore] = useState(0);

  function navToNextPage() {
    saveData();
    navigate("/v2/sec14");
  }
  function getScore() {
    var score =
      parseInt(sessionStorage.getItem("trans")) +
      parseInt(sessionStorage.getItem("meas"));
    setScore(score);
    sessionStorage.setItem("abstraction", score.toString());
  }

  function onChangetransCheck() {
    if (document.getElementById("trans").checked == true)
      sessionStorage.setItem("trans", "1");
    else sessionStorage.setItem("trans", "0");

    getScore();
  }

  function onChangemeasCheck() {
    if (document.getElementById("meas").checked == true)
      sessionStorage.setItem("meas", "1");
    else sessionStorage.setItem("meas", "0");

    getScore();
  }

  useEffect(() => {
    if (
      sessionStorage.getItem("meas") &&
      sessionStorage.getItem("meas") == "1"
    ) {
      document.getElementById("meas").checked = true;
      getScore();
    }
    if (
      sessionStorage.getItem("trans") &&
      sessionStorage.getItem("trans") == "1"
    ) {
      document.getElementById("trans").checked = true;
      getScore();
    }
  }, []);
  const navigate = useNavigate();

  var typography =
    "Abstraction\n\nInstruct the patient:\n“Tell me how an orange and a banana are alike”.\nIf the patient answers in a concrete manner, then say only one additional time: “Tell me another way in which those items are alike”. If the subject does not give the appropriate response (fruit), say, “Yes, and they are also both fruit.” \nDo not give any additional instructions or clarification. \nAfter the practice trial, say: “Now, tell me how a train and a bicycle are alike”. \nFollowing the response, administer the second trial, saying: “Now tell me how a ruler and a watch are alike”. \nDo not give any additional instructions or prompts.\n\nScoring: \nOnly the last two item pairs are scored. Give 1 point to each item pair correctly answered. \nThe following responses are acceptable: \nTrain-bicycle = means of transportation, means of travelling, you take trips in both; \nRuler-watch = measuring instruments, used to measure. \n\nThe following responses are not acceptable: Train-bicycle = they have wheels; Ruler-watch = they have numbers.\n\nClick whether you would like to allocate the points or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/sec12"}
          name={"Abstraction"}
        />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          <h5
            style={{
              alignSelf: "flex-start",
              marginBottom: "1.5em",
              textAlign: "left",
            }}
          >
            Abstraction
          </h5>
          <p
            style={{
              marginBottom: "1.5em",
              textAlign: "center",
              maxWidth: "100%",
            }}
          >
            Name similarities between the objects.
            <br />
            Example: <b>orange - banana = fruit</b>
          </p>
          <br />

          <div>
            <input
              className="check"
              type="checkbox"
              id="trans"
              onClick={onChangetransCheck}
            />
            <label className="check-label">Train - Bicycle</label>
          </div>
          <br />

          <div>
            <input
              className="check"
              type="checkbox"
              id="meas"
              onClick={onChangemeasCheck}
            />
            <label className="check-label">Watch - Ruler</label>
          </div>
        </div>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
        prevNavigation={() => {
          saveData();
          navigate("/v2/sec12");
        }}
        currentPage={15}
        numPages={19}
      />
    </div>
  );
}
export default Section13;
