import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section6() {
  const [score, setScore] = useState(0);
  // Handles confirm dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  function getScore() {
    var score =
      parseInt(sessionStorage.getItem("lion")) +
      parseInt(sessionStorage.getItem("rhino")) +
      parseInt(sessionStorage.getItem("camel"));
    setScore(score);
    sessionStorage.setItem("naming", score.toString());
  }
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }
  
  useEffect(()=>{
	   if(sessionStorage.getItem("lion") && sessionStorage.getItem("lion")=="1"){
	  document.getElementById("lion").checked = true;
	  getScore();
  }
   if(sessionStorage.getItem("rhino") && sessionStorage.getItem("rhino")=="1"){
	  document.getElementById("rhino").checked = true;
	  getScore();
  }
   if(sessionStorage.getItem("camel") && sessionStorage.getItem("camel")=="1"){
	  document.getElementById("camel").checked = true;
	  getScore();
  }
  }, [])

  function onChangeCamelCheck() {
    if (document.getElementById("camel").checked == true)
      sessionStorage.setItem("camel", "1");
    else sessionStorage.setItem("camel", "0");

    getScore();
  }

  function onChangeLionCheck() {
    if (document.getElementById("lion").checked == true)
      sessionStorage.setItem("lion", "1");
    else sessionStorage.setItem("lion", "0");

    getScore();
  }

  function onChangeRhinoCheck() {
    if (document.getElementById("rhino").checked == true)
      sessionStorage.setItem("rhino", "1");
    else sessionStorage.setItem("rhino", "0");

    getScore();
  }

  function navToNextPage() {
	  saveData()
    navigate("/sec7");
  // sessionStorage.setItem("lion", "0");
  //  sessionStorage.setItem("camel", "0");
 //   sessionStorage.setItem("rhino", "0");
  }

  const navigate = useNavigate();

  var typography = "Naming \n\nBeginning on the left, point to each figure and say: “Tell me the name of this animal”.\n \nScoring:\nOne point each is given for the following responses: \n(1)	lion \n(2)	rhinoceros or rhino\n(3)	camel or dromedary\n\nClick whether you would like to allocate the point or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec5"} name={"Naming"} />
        <h1>MoCA Test</h1>

        <div
          className="gc-container"
          style={{ paddingTop: "1.2em", paddingBottom: 0 }}
        >
          <h5 style={{ marginBottom: "0em" }}>Naming</h5>

          <div
            className="moca-picture-container"
            style={{ alignItems: "center", MaxWidth: "100%" }}
          >
            <div>
              <input
                className="check"
                type="checkbox"
                id="lion"
                onChange={onChangeLionCheck}
              ></input>
              <img src="lion.png" style={{ width: "90%" }}></img>
            </div>

            <div>
              <input
                className="check"
                type="checkbox"
                id="rhino"
                onChange={onChangeRhinoCheck}
              ></input>
              <img src="rhino.png" style={{ width: "90%" }}></img>
            </div>

            <div>
              <input
                className="check"
                type="checkbox"
                id="camel"
                onChange={onChangeCamelCheck}
              ></input>
              <img src="camel.png" style={{ width: "90%" }}></img>
              <label></label>
            </div>

            {/* <label>[{score} points]</label> */}
            {/* <span style={{textAlign: "left"}}>
              Score: {" "}
               {score} points
            </span> */}
          </div>
        </div>
        <div
          className="gc-container"
          style={{ marginTop: "1em", paddingTop: "1em", paddingBottom: "1em" }}
        >
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
		nextNavigation={() => navToNextPage()}
        prevNavigation={() => {saveData(); navigate("/sec5")}}
        currentPage={9}
        numPages={21}
      />
  
    </div>
  );
}
export default Section6;
