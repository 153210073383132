import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Header from "../header";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { typography } from "@mui/system";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";


function Section1() {
  
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  const [question1, setAnswerQuestion1] = useState("");
  const [errormsg, setErrorMsg] = useState(false);
  const [errormsg2, setErrorMsg2] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    //setRadio(sessionStorage.getItem("Lives Alone?"));
    setAnswerQuestion1(sessionStorage.getItem("q1"));
  }, []);

  const handleChange = (event) => {
    var value = event.target.value;
    setAnswerQuestion1(value);
  };

  function Validation() {
    console.log(question1)
	saveData()
    if (question1 !== "" && question1 && question1 != "null") {
		if(question1.includes("Other:") && question1.split("Other:")[1]&& question1.split("Other:")[1].trim().length>0){
		  sessionStorage.setItem("q1", question1);
		  	saveData()

		  navigate("/referraldetails");
		  return true;
		}else if(question1.includes("Other:")){
			setErrorMsg2(true);
		}else{
			  sessionStorage.setItem("q1", question1);
			  	saveData()

		  navigate("/referraldetails");
		  return true;
		}
    } else {
      setErrorMsg(true);
    }
	return "false";
  }

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setErrorMsg(false);
  };
  
    const handleClose2 = () => {
    setErrorMsg2(false);
  };

  var typography =
    "Click on the correct diagnosis / reason for admission of the patient.";
console.log(question1, question1 && question1.split("Other:")[1]);
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/"}
          name={"Geriatric Consultant"}
        />
        <h1>Reason for Admission to MDH</h1>
        <div className="gc-container">
          <FormControl>
            <RadioGroup
              onChange={handleChange}
              value={question1} 
              //value={radio}
              // onChange={(e) => setAnswerQuestion1(e.target.value)}
              // onChange={(e) => setAnswerQuestion1(e.target.value)}
            >
              <FormControlLabel
                value={"Rehab Post-Lower Limb Fracture"}
                control={<Radio />}
                label={"Rehab Post-Lower Limb Fracture"}
                style={{ paddingBottom: "0.3em" }}
              />

              <FormControlLabel
                value={"Deterioration in General Condition"}
                control={<Radio />}
                label={"Deterioration in General Condition"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Falls"}
                control={<Radio />}
                label={"Falls"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Respiratory"}
                control={<Radio />}
                label={"Respiratory"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Neurology"}
                control={<Radio />}
                label={"Neurology"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Rehab Post-Lower Limb Amputation"}
                control={<Radio />}
                label={"Rehab Post-Lower Limb Amputation"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Rehab Post-General Surgery"}
                control={<Radio />}
                label={"Rehab Post-General Surgery"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Rehab Post-Upper Limb Fracture"}
                control={<Radio />}
                label={"Rehab Post-Upper Limb Fracture"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Cognitive Decline / Dementia Care"}
                control={<Radio />}
                label={"Cognitive Decline / Dementia Care"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Other"}
                control={<Radio />}
                label={"Other"}
                style={{ paddingBottom: "0.3em" }}
				checked={question1 && question1.includes("Other")}
              />
            </RadioGroup>
			 <TextField
            style={{ marginBottom: "1em" }}
            placeholder=""
            id="count"
			disabled={!question1 || (question1 && !question1.includes("Other"))}
			//faultValue={question1}
			value={question1 && question1.split("Other:")[1]&& question1.split("Other:")[1].trim()}
            variant="outlined"
            onChange={(e) => setAnswerQuestion1("Other: "+ e.target.value)}
          />
		  <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg2 ? "block" : "none" }}
          >
            Please input details
          </h4>
          </FormControl>

          <br />
          {console.log(errormsg)}
          <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg ? "block" : "none" }}
          >
            Please select an option
          </h4>
          {/* <FormControl>
            <InputLabel id="demo-simple-select-label">
              Choose an Option
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Choose an option"
              onChange={(e) => setAnswerQuestion1(e.target.value)}
            >
              <MenuItem value="Post-LL Fracture Rehab">
                Post-LL Fracture Rehab
              </MenuItem>
              <MenuItem value="Deterioration in General Condition">
                Deterioration in General Condition
              </MenuItem>
              <MenuItem value="Falls">Falls</MenuItem>
              <MenuItem value="Respiratory">Respiratory</MenuItem>
              <MenuItem value="Neurology">Neurology</MenuItem>
              <MenuItem value="Rehab Post-LL Amputation">
                Rehab Post-LL Amputation
              </MenuItem>
              <MenuItem value="Rehab Post-General Surgery">
                Rehab Post-General Surgery
              </MenuItem>
              <MenuItem value="Post-UL Fracture Rehab">
                Post-UL Fracture Rehab
              </MenuItem>
              <MenuItem value="Cognitive Decline / Dementia Care">
                Cognitive Decline / Dementia Care
              </MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </Select>
          </FormControl> */}
        </div>
      </div>
      <BottomNav
        nextNavigation={() => Validation()}
        currentPage={1}
        numPages={21}
      ></BottomNav>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: "1em", borderRadius: "25px" }}
      >
        <h5
          style={{
            marginTop: "1em",
            marginRight: "1em",
            marginLeft: "1em",
            marginBottom: "0.5em",
          }}
        >
          Input missing
        </h5>
        <DialogContentText
          style={{
            marginRight: "1.3em",
            marginLeft: "1.3em",
          }}
        >
          Please fill in all the fields.
        </DialogContentText>
        <div
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{
              paddingLeft: "2em",
              paddingRight: "2em",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "25px",
              fontFamily: "Fenix",
              textTransform: "none",
              fontSize: "16px",
            }}
            onClick={() => handleClose()}
            variant="contained"
          >
            Ok
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
export default Section1;
