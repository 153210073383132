import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import "../../styles/HomeContent.css";
import { List, ListItem, useScrollTrigger } from "@mui/material";
import { Button, FormControlLabel, Grid } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormControl } from "@mui/material";
import "../../styles/header.css";
import { saveSocialWorker } from "../../firebase";

export default function CommunityApps() {
  const [fragments, setFragments] = useState(<></>);
  const [result, setResult] = useState("");
  const [next, setNext] = useState(true);
  const navigation = useNavigate();
  
  const saveData = () =>{
	  saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
		LivesAlone: sessionStorage.getItem("Lives Alone?"),
		AnySupport: sessionStorage.getItem("Any Support?"),
	  },
      {
		Stairs: sessionStorage.getItem("Stairs In Home:"),
		Amenities: sessionStorage.getItem("Amenities"),
		AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
		OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
		Amendments: sessionStorage.getItem("Amendments Required:"),
		OtherAmendments: sessionStorage.getItem("Required Amendments"),
	  },
      {
		Patient: sessionStorage.getItem("PatientPlans"),
		Relative: sessionStorage.getItem("RelativePlans"),
	  },
      sessionStorage.getItem("Array"),
      {
		Apps: sessionStorage.getItem("CommunityApps:"),
		TimeSpan: sessionStorage.getItem("TimeSpan"),
	  },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient"),
	  true,
	  false
    );
  }

  useEffect(() => {
    sessionStorage.setItem("TimeSpan", "");
  }, []);

  const handleChange = (event) => {
    var value = event.target.value;

    sessionStorage.setItem("CommunityApps:", value);
    var testing = [];

    if (value === "Yes") {
      testing.push(
        <React.Fragment>
          <div className="search-container">
            <RadioGroup onChange={readNewForm}>
              <h6> TimeSpan </h6>
              <FormControlLabel
                value={"<6 Months"}
                control={<Radio />}
                label="<6 Months"
              />
              <FormControlLabel
                value={"6 - 12 Months"}
                control={<Radio />}
                label="6 - 12 Months"
              />
              <FormControlLabel
                value={"1-2 Years"}
                control={<Radio />}
                label="1-2 Years"
              />
              <FormControlLabel
                value={"2-3 Years"}
                control={<Radio />}
                label="2-3 Years"
              />
              <FormControlLabel
                value={">3 Years"}
                control={<Radio />}
                label=">3 Years"
              />
            </RadioGroup>
          </div>
        </React.Fragment>
      );
    }
    if (value === "No") {
      setNext(false);
    }
    setFragments(testing);
  };

  const readNewForm = (event) => {
    var value = event.target.value;
    setResult(value);
    sessionStorage.setItem("TimeSpan", value);
    setNext(false);
  };


  
  var typography = "Ask the patient the relevant question";
  return (
    <div>
      <Grid
        justifyContent={"center"}
        container
        rowGap={4}
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <Header
          history={"/Social-Services"}
          typography={typography}
          name={"Community Apps"}
        ></Header>

        <List>
          <ListItem>
            <FormControl>
              <RadioGroup className="search-container" onChange={handleChange}>
                <FormControlLabel
                  value={"Yes"}
                  control={<Radio />}
                  label="Yes"
                />
                {fragments}
                <FormControlLabel value={"No"} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </ListItem>


          <ListItem>
            <Button
              onClick={() => {saveData(); navigation("/Summary-Social-Worker")}}
              disabled={next}
              variant="contained"
              fullWidth={true}
            >
              Next
            </Button>
          </ListItem>
        </List>
      </Grid>
    </div>
  );
}
