import React, { useEffect, useState, Component } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../header";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {
  Button,
  Popover,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import BottomNav from "../bottomnavg";
import ConfirmDialog from "../ConfirmDialog";
import { saveNurse } from "../firebase";

const tablerows = [
  {
    activity: "Mobility",
    admission: [0, 1, 2, 3],
    discharge: [0, 1, 2, 3],
    desc: "0: Immobile including being wheeled by another \n1: wheelchair independent, including corners \n2: walks with help of one person (verbal or physical) including help up into walking frame or other help standing \n3: independent (but may use any aid except rolator; for example, stick or frame) > 50 m / about house",
  },
  {
    activity: "Grooming",
    admission: [0, 1],
    discharge: [0, 1],
    desc: "0: needs help with personal care \n1: independent (must be able to do all personal activities E.g. washing hands and face/ combing hair/ cleaning teeth/shaving, implements provided)",
  },
  {
    activity: "Dressing",
    admission: [0, 1, 2],
    discharge: [0, 1, 2],
    desc: "0: dependent (needs help) \n1: needs help but can do about half unaided \n2: independent (including buttons, zips, laces, etc.)",
  },
  {
    activity: "Bathing",
    admission: [0, 1],
    discharge: [0, 1],
    desc: "0: dependent (needs help) \n1: independent (able to wash self all over, may be in shower. A full bath or standing and sponging all over. Includes getting into and out of bath or shower)",
  },
  {
    activity: "Stairs",
    admission: [0, 1, 2],
    discharge: [0, 1, 2],
    desc: "0: Unable (needs lift or cannot negotiate stairs) \n1: Needs help (verbal or physical supervision, carrying aid) \n2: Independent (must carry walking aid if used)",
  },
  {
    activity: "Bowels",
    admission: [0, 1, 2],
    discharge: [0, 1, 2],
    desc: "0: incontinent (or needs to be given enemas / suppositories) \n1: occasional accident (rare; < once a week) \n2: continent (if needs enema/supp. must manage himself)",
  },
  {
    activity: "Transfers",
    admission: [0, 1, 2, 3],
    discharge: [0, 1, 2, 3],
    desc: "0: unable, no sitting balance, needs hoist or complete lift by 2 persons \n1: major help (one or two people, physical), can sit \n2: minor help (verbal or minor physical E.g. Help from spouse) \n3: independent (no help; includes locking of wheelchair if necessary)",
  },
  {
    activity: "Bladder",
    admission: [0, 1, 2],
    discharge: [0, 1, 2],
    desc: "0: incontinent, or catheterised and unable to manage alone \n1: occasional accident (max. once in 24 hrs. needs help with device) \n2: continent (able to use any device e.g. catheter if necessary). Continent for more than 7 days",
  },
  {
    activity: "Feeding",
    admission: [0, 1, 2],
    discharge: [0, 1, 2],
    desc: "0: unable (needs to be fed) \n1: Needs help cutting, spreading butter, but can feed self \n2: Independent (able to use any neccesary device in a reasonable time, able to cut up food, use condiments, spread butter etc. on his own, food may be placed within reach)",
  },
  {
    activity: "Toilet Use",
    admission: [0, 1, 2],
    discharge: [0, 1, 2],
    desc: "0: dependent (unable to manage without major assistance) \n1: Needs some help (able to manage with minor help balancing, handling clothes or toilet paper. Still able to use toilet) \n2: Independent (can get on and off alone, able to handle clothes, wipe self, flush toilet, empty commode completely unaided)",
  },
];

export default function BarthelIndexPage() {
  const navigate = useNavigate();
  const location = useLocation()
  const [admissionvalue, setAdmission] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [dischargevalue, setDischarge] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [differencevalue, setDifference] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [totalvalue, setTotal] = useState(0);
  const [totaladmissionvalue, setTotalAdmission] = useState(0);
  const [totaldischargevalue, setTotalDischarge] = useState(0);
  const [infoanchor, setInfoanchor] = useState(null);
  const [infotext, setInfotext] = useState("");

  //Handles popover of info box
  const openinfo = Boolean(infoanchor);
  const infoid = openinfo ? "info-popover" : undefined;
  const handleClickInfo = (event, infoinput) => {
    setInfoanchor(event.currentTarget);
    setInfotext(infoinput);
  };
  const handleCloseInfo = () => {
    setInfoanchor(null);
  };

  // Handles confirmation dialog
  const [opendialog, setOpendialog] = useState(false);
  const handleDialogCancel = () => {
    setOpendialog(false);
  };

  useEffect(() => {
    const bi = sessionStorage.getItem("BarthelIndex");
    const ai = sessionStorage.getItem("BarthelIndexAdmission");
    const di = sessionStorage.getItem("BarthelIndexDischarge");
    const a = sessionStorage.getItem("BarthelIndexAdmissionArray");
    const d = sessionStorage.getItem("BarthelIndexDischargeArray");
    const diff = sessionStorage.getItem("BarthelIndexDifference");
    console.log(a, typeof a);
    if (isNaN(bi)) {
      setTotal(0);
    } else {
      setTotal(bi);
    }

    if (isNaN(ai)) {
      setTotalAdmission(0);
    } else {
      setTotalAdmission(ai);
    }

    if (isNaN(di)) {
      setTotalDischarge(0);
    } else {
      setTotalDischarge(di);
    }

    if (!a || a === "null") {
      setAdmission([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    } else {
      setAdmission(a.split(",").map((v) => parseInt(v)));
    }

    if (!d || d === "null") {
      setDischarge([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    } else {
      setDischarge(d.split(",").map((v) => parseInt(v)));
    }

    if (!diff || diff === "null") {
      setDifference([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
    } else {
      setDifference(diff.split(",").map((v) => parseInt(v)));
    }
    //setTotal(sessionStorage.getItem("BarthelIndex") || 0);
    // setTotalAdmission(sessionStorage.getItem("BarthelIndexAdmission") || 0);
    // setTotalDischarge(sessionStorage.getItem("BarthelIndexDischarge") || 0);
    // setAdmission(sessionStorage.getItem("BarthelIndexAdmissionArray"));
    // setDischarge(sessionStorage.getItem("BarthelIndexDischargeArray"));
  }, []);

  // Updates value in admission dropdown list element
  // i is index of admission array in "admission: [0, 1, 2]" or "admission: [0, 1]" or "admission: [0, 1, 2]" etc.
  const handleChangeAdmission = (e, i) => {
    const admv = admissionvalue;
    let adm = [...admv];
    adm[i] = e.target.value;
    setAdmission(adm);
    handleChangeDifference(i, adm[i], dischargevalue[i]);

    // Removes "undefined" and "NaN" from admission array
    let cleanadm = adm.filter((element) => {
      return !Number.isNaN(element) && element !== undefined;
    });
    console.log(adm);
    // Sums up values in admission array
    setTotalAdmission(
      cleanadm.reduce(
        (totaladd, currentValue) => (totaladd = totaladd + currentValue)
      )
    );
  };

  // Updates value in discharge dropdown list element
  const handleChangeDischarge = (e, i) => {
    let adm = [...dischargevalue];
    adm[i] = e.target.value;
    setDischarge(adm);
    handleChangeDifference(i, admissionvalue[i], adm[i]);

    // Removes "undefined" and "NaN" from discharge array
    let cleanadm = adm.filter((element) => {
      return !Number.isNaN(element) && element !== undefined;
    });
    // Sums up values in discharge array
    setTotalDischarge(
      cleanadm.reduce(
        (totaladd, currentValue) => (totaladd = totaladd + currentValue)
      )
    );
  };

  // Calculates the difference between discharge and admission
  // dis is discharge array, adm is admission array
  const handleChangeDifference = (i, adm, dis) => {
    let diff = [...differencevalue];
    let diffval = dis - adm;
    diff[i] = diffval;
    setDifference(diff);
    // Removes "undefined" and "NaN" from difference array
    let cleandiff = diff.filter((element) => {
      return !Number.isNaN(element) && element !== undefined;
    });
    console.log(diff, "fkdmk");
    // Calculates total in difference array
    if (cleandiff && cleandiff.length > 0)
      setTotal(
        cleandiff.reduce(
          (total, currentValue) => (total = total + currentValue)
        )
      );
  };
  
  const saveData =() => {

const alldata=  {
    addmission: sessionStorage.getItem("BarthelIndexAdmission"),
    discharge: sessionStorage.getItem("BarthelIndexDischarge"),
    BarthelDifference: sessionStorage.getItem("BarthelIndex"),
    CalculatedHeight: sessionStorage.getItem("Patient-CalculatedHeight"),
    Weight: sessionStorage.getItem("Patient-Weight"),
    ExerciseWeightLoss: sessionStorage.getItem("Due to exercise?"), //yes or no,
    WeightLoss: sessionStorage.getItem("Weight Lost?"), //yes or no
    OtherProfessionReferredSLP: sessionStorage.getItem("Nurse-SLP"),
    OtherProfessionReferredPodiatry: sessionStorage.getItem("Nurse-Podiatry"),
    OtherProfessionReferredNaD: sessionStorage.getItem("Nurse-NaD"),
    OtherProfessionReferredPsychology:
      sessionStorage.getItem("Nurse-Psychology"),
    OtherProfessionReferredPsychiatry:
      sessionStorage.getItem("Nurse-Psychiatry"),
    OtherProfessionReferredOther: sessionStorage.getItem("Nurse-Other"),
    AddInfo: sessionStorage.getItem("NurseAdditionalInfo"),
	Appetite: sessionStorage.getItem("Appetite3"),
	FeedingRoute: sessionStorage.getItem("Feeding-Route3"),
	AssistiveDevices: sessionStorage.getItem("assistive-devices-required"),
	WoundPresent: sessionStorage.getItem("wound-present"),
	SuturesClipsPresent: sessionStorage.getItem("Sutures-Clips-Present"),
	draft: true
  };
  	  saveNurse(alldata, false)

  }

  function navToNextPage() {
	    saveData()
	  console.log(location, sessionStorage.getItem("BarthelIndexAdmission"))
	  if(location?.state?.from=="Summary") navigate("/SummaryPageNurse");
    else navigate("/Measurements");
  }

  //   Stores totalvalue and moves to next page
  function storeTotalValue() {
    sessionStorage.setItem("BarthelIndex", totalvalue);
    sessionStorage.setItem("BarthelIndexAdmission", totaladmissionvalue);
    sessionStorage.setItem("BarthelIndexDischarge", totaldischargevalue);
    sessionStorage.setItem("BarthelIndexDifference", differencevalue);
    sessionStorage.setItem("BarthelIndexAdmissionArray", admissionvalue);
    sessionStorage.setItem("BarthelIndexDischargeArray", dischargevalue);
    navToNextPage();
  }

  useEffect(() => {
    // console.log(admissionvalue);
    // console.log(dischargevalue);
    // console.log("Total Admission:");
    // console.log(totaladmissionvalue);
    // console.log("Total Discharge:");
    // console.log(totaldischargevalue);
  });
  
  

  
  var typography =
    "Click on the drop down menu and choose appropriate score, for both   admission and referral BI level.\nThe i button indicates the score rating for each domain.\nThe differences in the scores and the total scores are shown on the screen.  ";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          history={"/"}
          name={"Nurse Assessment"}
          typography={typography}
        />

        <div display="flex" justifyContent="center">
          <h1>Barthel Index</h1>
        </div>
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"space-around"}
          rowGap={0}
        >
          <div item className="nurse-container" style={{ width: "80%" }}>
            <TableContainer>
              <Table>
                {/* Table Header */}
                <TableHead>
                  <TableRow>
                    <TableCell style={{ width: "25%" }}>
                      <h5 className="BITA">Activity</h5>
                    </TableCell>
                    <TableCell style={{ width: "25%" }}>
                      <h5 className="BIT">Admission</h5>
                    </TableCell>
                    <TableCell style={{ width: "25%" }}>
                      <h5 className="BIT">Referral</h5>
                    </TableCell>
                    <TableCell style={{ width: "15%" }}>
                      <h5 className="BIT">+/-</h5>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* First map: for each Activity create two <Select> cells (for "Admission" and "Discharge" column) and one <TableCell> for "+/-"" column */}
                  {tablerows.map((row, index) => (
                    <TableRow key={row.activity}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontSize: "0.8em",
                          paddingTop: "0.9em",
                          paddingBottom: "0.9em",
                        }}
                      >
                        <div className="nurse-activity-box">
                          {/* Gets description from tablerows array to display in Popover*/}
                          <InfoOutlinedIcon
                            onClick={(e) => handleClickInfo(e, row.desc)}
                          />
                          &nbsp;
                          {row.activity}
                          <MuiThemeProvider>
                            <Popover
                              key={row.desc}
                              id={infoid}
                              open={openinfo}
                              anchorEl={infoanchor}
                              onClose={handleCloseInfo}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                            >
                              {/* Displays infotext */}
                              <div
                                style={{
                                  fontSize: "1.1rem",
                                  padding: "1.3em",
                                  wordBreak: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {infotext}
                              </div>
                            </Popover>
                          </MuiThemeProvider>
                        </div>
                      </TableCell>
                      <TableCell
                        align="right"
                        style={{ paddingTop: "0.9em", paddingBottom: "0.9em" }}
                      >
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            // defaultValue={0}
                            value={admissionvalue && admissionvalue[index]}
                            onChange={(e) => handleChangeAdmission(e, index)}
                          >
                            {/* Second map: creates values for "Admission" dropdownlist and handles changes*/}
                            {row.admission.map((admissionvalue) => (
                              <MenuItem value={admissionvalue}>
                                {admissionvalue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      {/* Third map: creates values for "Discharge" dropdownlist and handles changes*/}
                      <TableCell
                        align="right"
                        style={{ paddingTop: "0.9em", paddingBottom: "0.9em" }}
                      >
                        <FormControl fullWidth>
                          <Select
                            size="small"
                            // defaultValue={0}
                            value={dischargevalue && dischargevalue[index]}
                            onChange={(e) => handleChangeDischarge(e, index)}
                          >
                            {row.admission.map((dischargevalue) => (
                              <MenuItem value={dischargevalue}>
                                {dischargevalue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      {/* Creates cell for "+/-" column. Displays nothing when difference is not a number */}
                      {isNaN(differencevalue[index]) ? (
                        <TableCell align="center"></TableCell>
                      ) : (
                        <TableCell align="center" style={{ fontSize: "0.7em" }}>
                          {differencevalue[index]}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: "none",
                        paddingBottom: "0.5em",
                      }}
                    >
                      <h4 style={{ fontSize: "1.5em" }}>Total:</h4>
                    </TableCell>
                    {/* Two empty cells for Admission and Discharge column */}
                    <TableCell
                      style={{
                        fontSize: "0.8em",
                        textAlign: "center",
                        borderBottom: "none",
                        paddingBottom: "0.5em",
                      }}
                    >
                      {totaladmissionvalue}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.8em",
                        textAlign: "center",
                        borderBottom: "none",
                        paddingBottom: "0.5em",
                      }}
                    >
                      {totaldischargevalue}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "0.8em",
                        textAlign: "center",
                        borderBottom: "none",
                        paddingBottom: "0.5em",
                      }}
                    >
                      {totalvalue}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <ConfirmDialog
            showDialog={opendialog}
            cancel={() => handleDialogCancel()}
            confirmButtonText="Yes"
            cancelButtonText="No"
            confirm={() => storeTotalValue()}
            dialogTitle="Continue?"
            dialogText="Are you sure you want to move to the next page?"
          />
        </Grid>
      </div>
      <BottomNav
        nextNavigation={() => setOpendialog(true)}
        currentPage={1}
        numPages={6}
        goToSummary={() => storeTotalValue()}
      ></BottomNav>
    </div>
  );
}
