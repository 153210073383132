import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import { TableCell, TableRow } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section14() {
  const navigate = useNavigate();

  const [score, setScore] = useState(0);
  // Handles confirm dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }
  function navToNextPage() {
	  saveData()
    navigate("/sec15");
  //  sessionStorage.setItem("red", "0");
   // sessionStorage.setItem("velvet", "0");
  //  sessionStorage.setItem("church", "0");
  //  sessionStorage.setItem("face", "0");
  //  sessionStorage.setItem("daisy", "0");
  }
  function getScore() {
    var score =
      parseInt(sessionStorage.getItem("velvet")) +
      parseInt(sessionStorage.getItem("daisy")) +
      parseInt(sessionStorage.getItem("face")) +
      parseInt(sessionStorage.getItem("red")) +
      parseInt(sessionStorage.getItem("church"));
    setScore(score);
    console.log({ score });
    sessionStorage.setItem("delayedrecall", score.toString());
  }

  const onChangefaceCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("face", "1");
    else sessionStorage.setItem("face", "0");
    getScore();
  };

  const onChangevelvetCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("velvet", "1");
    else sessionStorage.setItem("velvet", "0");
    getScore();
  };

  const onChangechurchCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("church", "1");
    else sessionStorage.setItem("church", "0");
    getScore();
  };

  const onChangedaisyCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("daisy", "1");
    else sessionStorage.setItem("daisy", "0");
    getScore();
  };

  const onChangeredCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("red", "1");
    else sessionStorage.setItem("red", "0");
    getScore();
  };

  // function onChangeredCheck() {
  //   if (document.getElementById("red").checked == true)
  //     sessionStorage.setItem("red", "1");
  //   else sessionStorage.setItem("red", "0");

  //   getScore();
  // }

  // function onChangevelvetCheck() {
  //   if (document.getElementById("velvet").checked == true)
  //     sessionStorage.setItem("velvet", "1");
  //   else sessionStorage.setItem("velvet", "0");

  //   getScore();
  // }

  // function onChangechurchCheck() {
  //   if (document.getElementById("church").checked == true)
  //     sessionStorage.setItem("church", "1");
  //   else sessionStorage.setItem("church", "0");

  //   getScore();
  // }

  // function onChangefaceCheck() {
  //   if (document.getElementById("face").checked == true)
  //     sessionStorage.setItem("face", "1");
  //   else sessionStorage.setItem("face", "0");

  //   getScore();
  // }

  // function onChangedaisyCheck() {
  //   if (document.getElementById("daisy").checked == true)
  //     sessionStorage.setItem("daisy", "1");
  //   else sessionStorage.setItem("daisy", "0");

  //   getScore();
  // }
  
    const [trail21, setTrial1] = useState([]);
  const [trail22, setTrial2] = useState([])
  const [trail23, setTrial3] = useState([])

useEffect(()=>{
	if(sessionStorage.getItem("trail21") && sessionStorage.getItem("trail21")!="null")
	setTrial1(sessionStorage.getItem("trail21").split(',').map(Number))
if(sessionStorage.getItem("trail22") && sessionStorage.getItem("trail22")!="null")
	setTrial2(sessionStorage.getItem("trail22").split(',').map(Number))
if(sessionStorage.getItem("trail23") && sessionStorage.getItem("trail23")!="null")
	setTrial3(sessionStorage.getItem("trail23").split(',').map(Number))
console.log(trail21, trail22,trail21 && trail21.length>0 && typeof trail21 == "object" && trail21.find(i=> i==1)>0)
	console.log(sessionStorage.getItem("trail22"))
	if(trail21){
		if(trail21.includes(1))
			sessionStorage.setItem("face", "1")
		if(trail21.includes(2))
			sessionStorage.setItem("velvet", "1")
		if(trail21.includes(3))
			sessionStorage.setItem("church", "1")
		if(trail21.includes(4))
			sessionStorage.setItem("daisy", "1")
		if(trail21.includes(5))
			sessionStorage.setItem("red", "1")
	}
	getScore()
}, [])

useEffect(()=>{
	if(trail21){
		if(trail21.includes(1))
			sessionStorage.setItem("face", "1")
		if(trail21.includes(2))
			sessionStorage.setItem("velvet", "1")
		if(trail21.includes(3))
			sessionStorage.setItem("church", "1")
		if(trail21.includes(4))
			sessionStorage.setItem("daisy", "1")
		if(trail21.includes(5))
			sessionStorage.setItem("red", "1")
	}
	getScore();
}, [trail21])

useEffect(()=>{
	getScore();
}, [trail22])

useEffect(()=>{
	getScore();
}, [trail23])

const onClick = (index, trail, checked) =>{
	console.log(checked)
	if(trail==1){
		if(!checked){
			let temp = [...trail21]
			if(temp){
							console.log(temp, index, typeof index)

			temp= temp.filter(i => i!=index && i!=`${index}`)			
			console.log(temp)
}
			
			else temp =[]
			console.log(temp)
			setTrial1(temp)
		}else{
			let temp = [...trail21]
				temp.push(index)
				setTrial1(temp)
		}
	
	}else if(trail==2){
		if(!checked){
			let temp = [...trail22]
if(temp)
			temp=temp.filter(i => i!=index)
		else temp =[]
		setTrial2(temp)
		}else{
			let temp = [...trail22]
				temp.push(index)
				setTrial2(temp)
		}
		}else {
		if(!checked){
			let temp = [...trail23]
if(temp)
			temp=temp.filter(i => i!=index)
		else temp =[]
		setTrial3(temp)
		}else{
			let temp = [...trail23]
				temp.push(index)
				setTrial3(temp)
		}
	}
	
	sessionStorage.setItem("trail211", trail21)
	sessionStorage.setItem("trail222", trail22)
	sessionStorage.setItem("trail23", trail23)
	console.log(sessionStorage.getItem("trail211"))
	console.log(sessionStorage.getItem("trail222"))
	
}

  var typography = "Delayed recall\n\nInstruct the patient:\n“I read some words to you earlier, which I asked you to remember. Tell me as many of those words as you can remember.”\nTick the appropriate checkbox for each of the words correctly recalled without any cues. \n\nScoring: \nAllocate 1 point for each word recalled freely without any cues. \n\nClick whether you would like to allocate the points or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/sec13"}
          name={"Delayed Recall"}
        />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          <div>
            <h5 style={{ marginBottom: "1.5em" }}>Delayed Recall</h5>
            <p style={{ textAlign: "center" }}>
              Patient has to recall the 5 words.
              <br /> Points are given for words WITH NO CUE.
            </p>
            <br />
            {/* Table */}
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Face</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Velvet</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Church</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Daisy</b>
              </TableCell>
              <TableCell style={{ fontSize: "1em" }}>
                <b>Red</b>
              </TableCell>
            </TableRow>

            {/* No Cue row */}
            <TableRow>
              <TableCell style={{ fontSize: "0.7em", textAlign: "center" }}>
                No Cue
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
                  control={<Checkbox	onChange={(e)=> onClick(1, 1, e.target.checked)}
checked={trail21 && trail21.length>0 && typeof trail21 == "object" && trail21.find(i=> i==1) && trail21.find(i=> i==1)>0}/>} 
                  onClick={onChangefaceCheck}
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
 control={<Checkbox	onChange={(e)=> onClick(2, 1, e.target.checked)}
checked={trail21 && trail21.length>0 && typeof trail21 == "object" && trail21.find(i=> i==2) && trail21.find(i=> i==2)>0}/>}                   onClick={onChangevelvetCheck}
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
 control={<Checkbox	onChange={(e)=> onClick(3, 1, e.target.checked)}
checked={trail21 && trail21.length>0 && typeof trail21 == "object" && trail21.find(i=> i==3) && trail21.find(i=> i==3)>0}/>}                   onClick={onChangechurchCheck}
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
 control={<Checkbox	onChange={(e)=> onClick(4, 1, e.target.checked)}
checked={trail21 && trail21.length>0 && typeof trail21 == "object" && trail21.find(i=> i==4) && trail21.find(i=> i==4)>0}/>}                   onClick={onChangedaisyCheck}
                />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel
				 control={<Checkbox	id="redBox" onChange={(e)=> onClick(5, 1, e.target.checked)}
checked={trail21 && trail21.length>0 && typeof trail21 == "object" && trail21.find(i=> i==5) && trail21.find(i=> i==5)>0}/>} 
                  onClick={onChangeredCheck}
                />
              </TableCell>
            </TableRow>

            {/* Category Cue row */}
            <TableRow>
              <TableCell style={{ fontSize: "0.7em", textAlign: "center" }}>
                Category Cue
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
			  <FormControlLabel
 control={<Checkbox	onChange={(e)=> onClick(1, 2, e.target.checked)}
checked={trail22 && trail22.length>0 && typeof trail22 == "object" && trail22.find(i=> i==1) && trail22.find(i=> i==1)>0}/>} />              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel  control={<Checkbox	onChange={(e)=> onClick(2, 2, e.target.checked)}
checked={trail22 && trail22.length>0 && typeof trail22 == "object" && trail22.find(i=> i==2) && trail22.find(i=> i==2)>0}/>}  />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel  control={<Checkbox	onChange={(e)=> onClick(3, 2, e.target.checked)}
checked={trail22 && trail22.length>0 && typeof trail22 == "object" && trail22.find(i=> i==3) && trail22.find(i=> i==3)>0}/>}  />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel  control={<Checkbox	onChange={(e)=> onClick(4, 2, e.target.checked)}
checked={trail22 && trail22.length>0 && typeof trail22 == "object" && trail22.find(i=> i==4) && trail22.find(i=> i==4)>0}/>}  />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel  control={<Checkbox	onChange={(e)=> onClick(5, 2, e.target.checked)}
checked={trail22 && trail22.length>0 && typeof trail22 == "object" && trail22.find(i=> i==5) && trail22.find(i=> i==5)>0}/>}  />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell style={{ fontSize: "0.7em", textAlign: "center" }}>
                Multiple Choice Cue
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel  control={<Checkbox	onChange={(e)=> onClick(1, 3, e.target.checked)}
checked={trail23 && trail23.length>0 && typeof trail23== "object" && trail23.find(i=> i==1) && trail23.find(i=> i==1)>0}/>} />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel control={<Checkbox	onChange={(e)=> onClick(2, 3, e.target.checked)}
checked={trail23 && trail23.length>0 && typeof trail23== "object" && trail23.find(i=> i==2) && trail23.find(i=> i==2)>0}/>}/>
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel control={<Checkbox	onChange={(e)=> onClick(3, 3, e.target.checked)}
checked={trail23 && trail23.length>0 && typeof trail23== "object" && trail23.find(i=> i==3) && trail23.find(i=> i==3)>0}/>} />
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel control={<Checkbox	onChange={(e)=> onClick(4, 3, e.target.checked)}
checked={trail23 && trail23.length>0 && typeof trail23== "object" && trail23.find(i=> i==4) && trail23.find(i=> i==4)>0}/>}/>
              </TableCell>
              <TableCell style={{ alignItems: "center", paddingLeft: "25px" }}>
                <FormControlLabel control={<Checkbox	onChange={(e)=> onClick(5, 3, e.target.checked)}
checked={trail23 && trail23.length>0 && typeof trail23== "object" && trail23.find(i=> i==5) && trail23.find(i=> i==5)>0}/>}/>
              </TableCell>
            </TableRow>
          </div>
        </div>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() =>{sessionStorage.setItem("trail21", trail21);
	sessionStorage.setItem("trail22", trail22);
		sessionStorage.setItem("trail23", trail23); navToNextPage()}}
        prevNavigation={() => {saveData(); navigate("/sec13")}}
        currentPage={17}
        numPages={21}
      />

    
    </div>
  );
}
export default Section14;
