import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import Alert from "@mui/material/Alert";
import {
  Popover,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import "../../styles/Team1OT.css";
import BottomNav from "../../bottomnavg";
import { saveOccupational } from "../../firebase";

function Section2() {
  const [feedingprevious, setFeedingPrevious] = React.useState("");
  const [feedingcurrent, setFeedingCurrent] = React.useState("");
  const [feedingcomment, setFeedingComment] = React.useState("");
  const [feedingdisplay, setFeedingDisplay] = useState(false);

  const [toiletingprevious, setToiletingPrevious] = React.useState("");
  const [toiletingcurrent, setToiletingCurrent] = React.useState("");
  const [toiletingcomment, setToiletingComment] = React.useState("");
  const [toiletingdisplay, setToiletingDisplay] = useState(false);

  const [bathingprevious, setBathingPrevious] = React.useState("");
  const [bathingcurrent, setBathingCurrent] = React.useState("");
  const [bathingcomment, setBathingComment] = React.useState("");
  const [bathingdisplay, setBathingDisplay] = useState(false);

  const [groomingprevious, setGroomingPrevious] = React.useState("");
  const [groomingcurrent, setGroomingCurrent] = React.useState("");
  const [groomingcomment, setGroomingComment] = React.useState("");
  const [groomingdisplay, setGroomingDisplay] = useState(false);

  const [dressingprevious, setDressingPrevious] = React.useState("");
  const [dressingcurrent, setDressingCurrent] = React.useState("");
  const [dressingcomment, setDressingComment] = React.useState("");
  const [dressingdisplay, setDressingDisplay] = useState(false);

  const [bowelsPrevious, setBowelsPrevious] = React.useState("");
  const [bowelscurrent, setBowelsCurrent] = React.useState("");
  const [bowelscomment, setBowelsComment] = React.useState("");
  const [bowelsdisplay, setBowelsDisplay] = useState(false);
  
  const [bladderprevious, setBladderPrevious] = React.useState("");
  const [bladdercurrent, setBladderCurrent] = React.useState("");
  const [bladdercomment, setBladderComment] = React.useState("");
  const [bladderdisplay, setBladderDisplay] = useState(false);

  const saveData =() => {

const alldata=  {
    housebound: sessionStorage.getItem("a"),
    feedingP: sessionStorage.getItem("a1"),
    toiletP: sessionStorage.getItem("a2"),
    bathP: sessionStorage.getItem("a3"),
    groomP: sessionStorage.getItem("a4"),
    dressP: sessionStorage.getItem("a5"),
    feedingC: sessionStorage.getItem("b1"),
    toiletC: sessionStorage.getItem("b2"),
    bathC: sessionStorage.getItem("b3"),
    groomC: sessionStorage.getItem("b4"),
    dressC: sessionStorage.getItem("b5"),
    feedingCom: sessionStorage.getItem("c1"),
    toiletCom: sessionStorage.getItem("c2"),
    bathCom: sessionStorage.getItem("c3"),
    groomCom: sessionStorage.getItem("c4"),
    dressCom: sessionStorage.getItem("c5"),
    telephone: sessionStorage.getItem("a11"),
    shopping: sessionStorage.getItem("a12"),
    food: sessionStorage.getItem("a13"),
    housekeeping: sessionStorage.getItem("a14"),
    laundry: sessionStorage.getItem("a15"),
    transportation: sessionStorage.getItem("a16"),
    medication: sessionStorage.getItem("a17"),
    finances: sessionStorage.getItem("a18"),
    changesinADL: sessionStorage.getItem("a19"),
    changesinADLcomm: sessionStorage.getItem("b19"),
    //To Check (26.08.22)
    addInfo: sessionStorage.getItem("OTAdditionalInfo"),

	bowelComment: (sessionStorage.getItem("cc1")||""),
	bowelCurrent:(sessionStorage.getItem("bc1")||""),
	bowelPrevious:(sessionStorage.getItem("ac1")||""),
	
	bladderComment:(sessionStorage.getItem("cc2")||""),
	bladderCurrent:(sessionStorage.getItem("bc2")||""),
	bladderPrevious:(sessionStorage.getItem("ac2")||""),
	
	  driving:(sessionStorage.getItem("a21")||""),
    employment:(sessionStorage.getItem("a20")||""),
    leisure:(sessionStorage.getItem("a21l")||""),

	draft: true
  };
  	  saveOccupational(alldata, false)

  }
  
  useEffect(() => {
      setFeedingPrevious(sessionStorage.getItem("a1") || "")
      setToiletingPrevious(sessionStorage.getItem("a2") || "")
      setBathingPrevious(sessionStorage.getItem("a3") || "")
      setGroomingPrevious(sessionStorage.getItem("a4") || "")
      setDressingPrevious(sessionStorage.getItem("a5") || "")
      setFeedingCurrent(sessionStorage.getItem("b1") || "")
      setToiletingCurrent(sessionStorage.getItem("b2") || "")
      setBathingCurrent(sessionStorage.getItem("b3") || "")
      setGroomingCurrent(sessionStorage.getItem("b4") || "")
      setDressingCurrent(sessionStorage.getItem("b5") || "")

    setFeedingComment(sessionStorage.getItem("c1") || "")
    setToiletingComment(sessionStorage.getItem("c2") || "")
    setBathingComment(sessionStorage.getItem("c3") || "")
    setGroomingComment(sessionStorage.getItem("c4") || "")
    setDressingComment(sessionStorage.getItem("c5") || "")
	
	
	setBowelsComment(sessionStorage.getItem("cc1")||"")
	setBowelsCurrent(sessionStorage.getItem("bc1")||"")
	setBowelsPrevious(sessionStorage.getItem("ac1")||"")
	
	setBladderComment(sessionStorage.getItem("cc2")||"")
	setBladderCurrent(sessionStorage.getItem("bc2")||"")
	setBladderPrevious(sessionStorage.getItem("ac2")||"")
    // setFeedingDisplay(sessionStorage.getItem("d1") || false)
    // setToiletingDisplay(sessionStorage.getItem("d2") || false)
    // setBathingDisplay(sessionStorage.getItem("d3") || false)
    // setGroomingDisplay(sessionStorage.getItem("d4") || false)
    // setDressingDisplay(sessionStorage.getItem("d5") || false)
  }, []);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  var typography = "Click on the type of dependence, for both previous (past 6 months) and current level. \n\nAdd additional comments as necessary.";

  // Checks if all dropdowns were selected
  function validation() {
	    //Stores values for "Previous" column
      sessionStorage.setItem("a1", feedingprevious);
      sessionStorage.setItem("a2", toiletingprevious);
      sessionStorage.setItem("a3", bathingprevious);
      sessionStorage.setItem("a4", groomingprevious);
      sessionStorage.setItem("a5", dressingprevious);

      // Stores values for "Current" column
      sessionStorage.setItem("b1", feedingcurrent);
      sessionStorage.setItem("b2", toiletingcurrent);
      sessionStorage.setItem("b3", bathingcurrent);
      sessionStorage.setItem("b4", groomingcurrent);
      sessionStorage.setItem("b5", dressingcurrent);

      // Stores values for "Comments" column
      sessionStorage.setItem("c1", feedingcomment);
      sessionStorage.setItem("c2", toiletingcomment);
      sessionStorage.setItem("c3", bathingcomment);
      sessionStorage.setItem("c4", groomingcomment);
      sessionStorage.setItem("c5", dressingcomment);
	  
	  
	 sessionStorage.setItem("cc1", bowelscomment)
	sessionStorage.setItem("bc1", bowelscurrent)
	sessionStorage.setItem("ac1", bowelsPrevious)
	
	sessionStorage.setItem("cc2", bladdercomment)
	sessionStorage.setItem("bc2", bladdercurrent)
	sessionStorage.setItem("ac2",bladderprevious)
    if (feedingprevious === "" || feedingcurrent === ""  ) {
      setFeedingDisplay(true);
    } else {
      setFeedingDisplay(false);
     // navigate("/Section4OT");
    }

    if (toiletingprevious === "" || toiletingcurrent === ""  ) {
      setToiletingDisplay(true);
    } else {
      setToiletingDisplay(false);
    }
    if (bathingprevious === "" || bathingcurrent === ""  ) {
      setBathingDisplay(true);
    } else {
      setBathingDisplay(false);
    }
    if (groomingprevious === "" || groomingcurrent === ""  ) {
      setGroomingDisplay(true);
    } else {
      setGroomingDisplay(false);
    }
    if (dressingprevious === "" || dressingcurrent === ""  ) {
      setDressingDisplay(true);
    } else {
      setDressingDisplay(false);
    }
	
	if (bowelsPrevious === "" || bowelscurrent === ""  ) {
      setBowelsDisplay(true);
    } else {
      setBowelsDisplay(false);
    }
	
	if (bladdercurrent === "" || bladderprevious === ""  ) {
      setBladderDisplay(true);
    } else {
      setBladderDisplay(false);
    }
		saveData()

    if (
      feedingprevious === "" ||
      toiletingprevious === "" ||
      bathingprevious === "" ||
      groomingprevious === "" ||
      dressingprevious === "" ||
      feedingcurrent === "" ||
      toiletingcurrent === "" ||
      bathingcurrent === "" ||
      groomingcurrent === "" ||
      dressingcurrent === "" ||
	  bladdercurrent === "" || bladderprevious === ""  ||
	  bowelsPrevious === "" || bowelscurrent === ""
    ) {
     // setOpen(true);
    }else {

    
	saveData()

      navigate("/Section4OT");
    }
  }

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/Section1OT"}
          name={"Occupational Therapy"}
        />
        <h1>Activities of Daily Living</h1>
        <div item className="occupational-container">
          <table className="occupational-table">
            {/* Table Header */}
            <TableRow>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Activity
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Previous
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Current
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Comments
                </h5>
              </TableCell>
            </TableRow>
            <TableRow>
              {/* Feeding Row */}
              <TableCell style={{ fontSize: "0.7em" }}>Feeding</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={feedingprevious}
                    onChange={(e) => setFeedingPrevious(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={feedingcurrent}
                    onChange={(e) => setFeedingCurrent(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={feedingcomment}
                  value={feedingcomment}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setFeedingComment(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>

            <TableRow style={{ display: feedingdisplay ? null : "none"}}>
              <TableCell colSpan={4} align="center">
                <h4 className="alert" id="question1-alert" style={{margin:"0", fontSize: "1.3em"}}>
                  Please select an option
                </h4>
              </TableCell>
            </TableRow> 

            {/* Toileting row */}
            <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>Toileting</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={toiletingprevious}
                    onChange={(e) => setToiletingPrevious(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={toiletingcurrent}
                    onChange={(e) => setToiletingCurrent(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  defaultValue={toiletingcomment}
                  value={toiletingcomment}
                  inputProps={{ maxLength: 500 }}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setToiletingComment(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>

            <TableRow style={{ display: toiletingdisplay ? null : "none"}}>
              <TableCell colSpan={4} align="center">
                <h4 className="alert" id="question1-alert" style={{margin:"0", fontSize: "1.3em"}}>
                  Please select an option
                </h4>
              </TableCell>
            </TableRow>

            {/* Bathing row */}
            <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>Bathing</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={bathingprevious}
                    onChange={(e) => setBathingPrevious(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={bathingcurrent}
                    onChange={(e) => setBathingCurrent(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                  
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={bathingcomment}
                  value={bathingcomment}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setBathingComment(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>
            <TableRow style={{ display: bathingdisplay ? null : "none"}}>
              <TableCell colSpan={4} align="center">
                <h4 className="alert" id="question1-alert" style={{margin:"0", fontSize: "1.3em"}}>
                  Please select an option
                </h4>
              </TableCell>
            </TableRow>
            {/* Grooming row */}
            <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>Grooming</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={groomingprevious}
                    onChange={(e) => setGroomingPrevious(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                  
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={groomingcurrent}
                    onChange={(e) => setGroomingCurrent(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  defaultValue={groomingcomment}
                  value={groomingcomment}
                  inputProps={{ maxLength: 500 }}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setGroomingComment(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>
            <TableRow style={{ display: groomingdisplay ? null : "none"}}>
              <TableCell colSpan={4} align="center">
                <h4 className="alert" id="question1-alert" style={{margin:"0", fontSize: "1.3em"}}>
                  Please select an option
                </h4>
              </TableCell>
            </TableRow>
            {/* Dressing row */}
            <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>Dressing</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={dressingprevious}
                    onChange={(e) => setDressingPrevious(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
               
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={dressingcurrent}
                    onChange={(e) => setDressingCurrent(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={dressingcomment}
                  value={dressingcomment}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setDressingComment(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>
            <TableRow style={{ display: dressingdisplay ? null : "none"}}>
              <TableCell colSpan={4} align="center">
                <h4 className="alert" id="question1-alert" style={{margin:"0", fontSize: "1.3em"}}>
                  Please select an option
                </h4>
              </TableCell>
            </TableRow>
          </table>
		
		  <table className="occupational-table">
            {/* Table Header */}
            <TableRow>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Continence level
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Previous
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Current
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Comments
                </h5>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>Bowels</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={bowelsPrevious}
                    onChange={(e) => setBowelsPrevious(e.target.value)}
                  >
                     <MenuItem style={{ fontSize: "14px" }} value={"C"}>
                      Continent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"OA"}>
                      Occasional Accident
                    </MenuItem>
					<MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Incontinent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={bowelscurrent}
                    onChange={(e) => setBowelsCurrent(e.target.value)}
                  >
  <MenuItem style={{ fontSize: "14px" }} value={"C"}>
                      Continent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"OA"}>
                      Occasional Accident
                    </MenuItem>
					<MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Incontinent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  defaultValue={bowelscomment}
                  value={bowelscomment}
                  inputProps={{ maxLength: 500 }}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setBowelsComment(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>

            <TableRow style={{ display: bowelsdisplay ? null : "none"}}>
              <TableCell colSpan={4} align="center">
                <h4 className="alert" id="question1-alert" style={{margin:"0", fontSize: "1.3em"}}>
                  Please select an option
                </h4>
              </TableCell>
            </TableRow>
   <TableRow>
              <TableCell style={{ fontSize: "0.7em" }}>Bladder</TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={bladderprevious}
                    onChange={(e) => setBladderPrevious(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"C"}>
                      Continent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"OA"}>
                      Occasional Accident
                    </MenuItem>
					<MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Incontinent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={bladdercurrent}
                    onChange={(e) => setBladderCurrent(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"C"}>
                      Continent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"OA"}>
                      Occasional Accident
                    </MenuItem>
					<MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Incontinent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  defaultValue={bladdercomment}
                  value={bladdercomment}
                  inputProps={{ maxLength: 500 }}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setBladderComment(e.target.value)}
                ></TextField>
              </TableCell>
            </TableRow>

            <TableRow style={{ display: bladderdisplay ? null : "none"}}>
              <TableCell colSpan={4} align="center">
                <h4 className="alert" id="question1-alert" style={{margin:"0", fontSize: "1.3em"}}>
                  Please select an option
                </h4>
              </TableCell>
            </TableRow>
		  </table>
        </div>
        {/* <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogTitle id="alert-dialog-title">
              Please answer all the questions
            </DialogTitle>
          </DialogContent>
          <Button onClick={handleClose}>close</Button>
        </Dialog> */}

        {/* Input missing dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          style={{ padding: "1em", borderRadius: "25px" }}
        >
          <h5
            style={{
              marginTop: "1em",
              marginRight: "1em",
              marginLeft: "1em",
              marginBottom: "0.5em",
            }}
          >
            Input missing
          </h5>
          <DialogContentText
            style={{
              marginRight: "1.3em",
              marginLeft: "1.3em",
            }}
          >
            Please answer all the questions.
          </DialogContentText>
          <div
            style={{
              padding: "1em",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* Closes dialog */}
            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleClose()}
              variant="contained"
            >
              Ok
            </Button>
          </div>
        </Dialog>
      </div>
      <BottomNav
        nextNavigation={() => validation()}
        currentPage={1}
        numPages={5}
      />
    </div>
  );
}
export default Section2;
