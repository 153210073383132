// page 3 nurse
import React, { useContext, useEffect, useState } from "react";
import { Text } from "react";
import Header from "../header";
import TextField from "@mui/material/TextField";
import { width } from "@mui/system";
import { useNavigate , useLocation} from "react-router-dom";
import {
  Button,
  InputLabel,
  ListItem,
  List,
  Typography,
  Box,
  Grid,
  RadioGroup,
  Radio,
  Popover,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import BottomNav from "../bottomnavg";
// import Input from "./Input";
import { saveNurse } from "../firebase";

export default function Others(props) {
  const navigate = useNavigate();
  const [appetite, setAppettite] = useState("")
  const [feedingroute, setFeedingRoute] = useState("");
  const [assistive, setAssistive] = useState()
  const [woundPresent, setWoundPresent] = useState();
  const [suturesPresent, setSuturesPresent] = useState()
  const location = useLocation();
 const [appetiteerror, setAppettiteError] = useState(false)
 const [feedingRouteError, setFeedingRouteError] = useState(false)
 const [assistiveError, setAssistiveError] = useState(false)
  const [woundPresentError, setWoundPresentError] = useState(false);
  const [suturesPresentError, setSuturesPresentError] = useState(false) 

  function validation() {
	(sessionStorage.setItem("Sutures-Clips-Present", suturesPresent));
    (sessionStorage.setItem("wound-present", woundPresent));
    (sessionStorage.setItem("assistive-devices-required", assistive));
    (sessionStorage.setItem("Feeding-Route3", feedingroute));
	console.log(appetite);
    (sessionStorage.setItem("Appetite3", appetite));
    console.log(" asdsd " + sessionStorage.getItem("Weight Lost?"), appetite, feedingroute, assistive, woundPresent, suturesPresent, !appetite);
	if(!appetite) setAppettiteError(true);  else setAppettiteError(false) 
	if(!feedingroute) setFeedingRouteError(true);  else setFeedingRouteError(false) 
	if(!assistive) setAssistiveError(true); else setAssistiveError(false) 
	if(!woundPresent) setWoundPresentError(true); else {
		setWoundPresentError(false) }
	if(!suturesPresent) setSuturesPresentError(true); else setSuturesPresentError(false) 
		console.log(appetite && feedingroute && assistive && woundPresent && suturesPresent)
	if((appetite && feedingroute && assistive && woundPresent && suturesPresent)) {
				saveData()

		 if(location?.state?.from=="Summary") navigate("/SummaryPageNurse");
    else {
		navigate("/OtherProfRef")}
	}


  }
  
   const saveData =() => {

const alldata=  {
    addmission: sessionStorage.getItem("BarthelIndexAdmission"),
    discharge: sessionStorage.getItem("BarthelIndexDischarge"),
    BarthelDifference: sessionStorage.getItem("BarthelIndex"),
    CalculatedHeight: sessionStorage.getItem("Patient-CalculatedHeight"),
    Weight: sessionStorage.getItem("Patient-Weight"),
    ExerciseWeightLoss: sessionStorage.getItem("Due to exercise?"), //yes or no,
    WeightLoss: sessionStorage.getItem("Weight Lost?"), //yes or no
    OtherProfessionReferredSLP: sessionStorage.getItem("Nurse-SLP"),
    OtherProfessionReferredPodiatry: sessionStorage.getItem("Nurse-Podiatry"),
    OtherProfessionReferredNaD: sessionStorage.getItem("Nurse-NaD"),
    OtherProfessionReferredPsychology:
      sessionStorage.getItem("Nurse-Psychology"),
    OtherProfessionReferredPsychiatry:
      sessionStorage.getItem("Nurse-Psychiatry"),
    OtherProfessionReferredOther: sessionStorage.getItem("Nurse-Other"),
    AddInfo: sessionStorage.getItem("NurseAdditionalInfo"),
	Appetite: sessionStorage.getItem("Appetite3"),
	FeedingRoute: sessionStorage.getItem("Feeding-Route3"),
	AssistiveDevices: sessionStorage.getItem("assistive-devices-required"),
	WoundPresent: sessionStorage.getItem("wound-present"),
	SuturesClipsPresent: sessionStorage.getItem("Sutures-Clips-Present"),
	draft: true
  };
  	  saveNurse(alldata, false)

  }
  
  
  const bad = ["null", "undefined"]

  useEffect(() => {
	  if(bad.indexOf(sessionStorage.getItem("Sutures-Clips-Present"))<0)
    setSuturesPresent(sessionStorage.getItem("Sutures-Clips-Present"));
	  if(bad.indexOf(sessionStorage.getItem("wound-present"))<0)

    setWoundPresent(sessionStorage.getItem("wound-present"));
		  if(bad.indexOf(sessionStorage.getItem("assistive-devices-required"))<0)

    setAssistive(sessionStorage.getItem("assistive-devices-required"));
		  if(bad.indexOf(sessionStorage.getItem("Feeding-Route3"))<0)

    setFeedingRoute(sessionStorage.getItem("Feeding-Route3"));
		  if(bad.indexOf(sessionStorage.getItem("Appetite3"))<0)

    setAppettite(sessionStorage.getItem("Appetite3"));
    
  }, []);


  const apettiteOptions = ["Normal", "Average", "Poor", "Enteral or Parenteral" ,"Fluids only of NO nutritional value"]
	const feedingRouteOptions = ["Oral", "NG", "PEG", "RIG"]
	
  var typography =
    "Q1. Input demispan height, in centimetres. \nMeasure the distance from the middle of the sternal notch to the tip of the middle finger. \nCheck that patient’s arm is horizontal and in line with shoulder. \n\nQ2. Input patient weight, in kilograms. \nClick on the appropriate answers for the questions to follow. ";
	console.log(apettiteOptions.indexOf(appetite), appetite);
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          history={"/Measurements"}
          name={"Nurse Assessment"}
          typography={typography}
        />
        <h1>Other</h1>
        <div className="nurse-container">
		 <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            // paddingTop={"1em"}
            padding={"1em"}
            //   columns={{ xs: 2, sm: 2, md: 3 }}
          >
            {/* ////// */}

              {/* //value={q3} onChange={handleAmendments} */}
              <Typography
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingBottom={"0.5em"}
              >
               Appetite
              </Typography>

  <FormControl fullWidth>
                          <Select
                            size="small"
                            defaultValue={appetite}
                            value={(appetite)}
                            onChange={(e) => {console.log(e.target.value);setAppettite(e.target.value);}}
                          >
                            {/* Second map: creates values for "Admission" dropdownlist and handles changes*/}
                            {apettiteOptions.map((admissionvalue) => (
                              <MenuItem value={admissionvalue}>
                                {admissionvalue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
            {/* ///// */}
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: appetiteerror ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please select option
            </h5>
          </Grid>
		 <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            // paddingTop={"1em"}
            padding={"1em"}
            //   columns={{ xs: 2, sm: 2, md: 3 }}
          >
            {/* ////// */}

              {/* //value={q3} onChange={handleAmendments} */}
              <Typography
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingTop={"0.5em"}
                paddingBottom={"0.5em"}
              >
               Feeding route
              </Typography>

  <FormControl fullWidth>
                          <Select
                            size="small"
                             defaultValue={feedingroute}
                            value={feedingroute}
                            onChange={(e) => setFeedingRoute(e.target.value)}
                          >
                            {/* Second map: creates values for "Admission" dropdownlist and handles changes*/}
                            {feedingRouteOptions.map((admissionvalue) => (
                              <MenuItem value={admissionvalue}>
                                {admissionvalue}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
            {/* ///// */}
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: feedingRouteError ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please select option
            </h5>
          </Grid>
		  	
 <Typography
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingTop={"0.5em"}
                paddingBottom={"0.5em"}
				paddingLeft={"0.5em"}
				fontSize={"1em"}
              >Skin condition</Typography>
		 <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            // paddingTop={"1em"}
            padding={"1em"}
            //   columns={{ xs: 2, sm: 2, md: 3 }}
          >
		    
            {/* ////// */}

            <RadioGroup value={assistive} onChange={(e)=> setAssistive(e.target.value)}>
              {/* //value={q3} onChange={handleAmendments} */}
              <Typography
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingBottom={"0.5em"}
              >
               Any assistive devices required?
              </Typography>

              <FormControlLabel
                className="FormControlLabel"
                value={"Yes"}
                control={<Radio />}
				checked={assistive=="Yes"}
                label="Yes"
              />

              <FormControlLabel
                className="FormControlLabel"
                value={"No"}
                control={<Radio />}
								checked={assistive=="No"}

                label="No"
              />
            </RadioGroup>
			
            {/* ///// */}
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: assistiveError ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please select option
            </h5>
          </Grid>
           <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            // paddingTop={"1em"}
            padding={"1em"}
            //   columns={{ xs: 2, sm: 2, md: 3 }}
          >
            {/* ////// */}
   <Typography
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingBottom={"0.5em"}
              >Wound Present </Typography>

			 <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  defaultValue={woundPresent}
                  value={woundPresent}
                  inputProps={{ maxLength: 500 }}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setWoundPresent(e.target.value)}
                ></TextField>
            {/* ///// */}
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: woundPresentError ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please input field
            </h5>
          </Grid>

          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            // paddingTop={"1em"}
            padding={"1em"}
            //   columns={{ xs: 2, sm: 2, md: 3 }}
          >
            {/* ////// */}

   <Typography
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingBottom={"0.5em"}
              > Sutures/clips Present </Typography>
			 <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  defaultValue={suturesPresent }
                  value={suturesPresent}
                  inputProps={{ maxLength: 500 }}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setSuturesPresent(e.target.value)}
                ></TextField>
            {/* ///// */}
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: suturesPresentError ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please input field
            </h5>
          </Grid>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => navigate("/Measurements")}
        // nextNavigation={true}
        nextNavigation={() => validation()}
        currentPage={3}
        numPages={6}
      ></BottomNav>
    </div>
  );
}
