import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import { Checkbox } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import Typography from "@mui/material/Typography";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section11() {
  const [score, setScore] = useState(0);
  // Handles confirm dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  function navToNextPage() {
	  saveData()
    navigate("/sec12");
//sessionStorage.setItem("rep1", "0");
 //   sessionStorage.setItem("rep2", "0");
  }
  
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  function getScore() {
    var r1 = parseInt(sessionStorage.getItem("rep1"));
    var r2 = parseInt(sessionStorage.getItem("rep2"));

    var temp = 0;

    var rep = r1 + r2;
    if (rep === 2) {
      temp = 2;
    } else if (rep === 1) {
      temp = 1;
    } else if (rep === 0) {
      temp = 0;
    }

    setScore(temp);

    sessionStorage.setItem("lang", temp.toString());

    var scoretemp = temp + parseInt(sessionStorage.getItem("sec12"));

    sessionStorage.setItem("language", scoretemp.toString());
  }

  function onChangerep1Check() {
    if (document.getElementById("rep1").checked == true)
      sessionStorage.setItem("rep1", "1");
    else sessionStorage.setItem("rep1", "0");

    getScore();
  }

  function onChangerep2Check() {
    if (document.getElementById("rep2").checked == true)
      sessionStorage.setItem("rep2", "1");
    else sessionStorage.setItem("rep2", "0");

    getScore();
  }
  
    useEffect(()=>{
	  if(sessionStorage.getItem("rep1") &&sessionStorage.getItem("rep1")=="1"){
		  document.getElementById("rep1").checked = true;
		  getScore();
	  }
	  	  if(sessionStorage.getItem("rep2") &&sessionStorage.getItem("rep2")=="1"){
		  document.getElementById("rep2").checked = true;
		  getScore();
	  }
  }, [])

  const navigate = useNavigate();

  var typography = "Sentence repetition\n\nInstruct the patient: \n“I am going to read you a sentence. Repeat it after me, exactly as I say it [pause]: I only know that John is the one to help today.” \n\nFollowing the response, say: “Now I am going to read you another      sentence. Repeat it after me, exactly as I say it [pause]: The cat always hid under the couch when dogs were in the room.” \n\nScoring:\nAllocate 1 point for each sentence correctly repeated. \nRepetition must be exact. Be alert for errors that are omissions (e.g., omitting “only”, “always”) and substitutions/additions (e.g., “John is the one who helped today;” substituting “hides” for “hid”, altering plurals, etc.). \n\nClick whether you would like to allocate the points or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec10"} name={"Language"} />
        <h1>MoCA Test</h1>
        <div
          className="moca-container"
          // sx={{ maxWidth: "80%", borderRadius: "20px", textAlign: "left" }}
        >
          {/* <CardContent> */}
          <h5 style={{ alignSelf: "flex-start", marginBottom: "1.7em" }}>
            Language
          </h5>
          <p style={{ marginBottom: "1.5em", textAlign: "center" }}>
            Patient needs to repeat the exact sentence.
          </p>
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "flex-start",
            }}
          >
            <input
              className="check"
              type="checkbox"
              id="rep1"
              onClick={onChangerep1Check}
            />
            <label>I only know that John is the one to help today</label>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <input
              style={{
                marginLeft: "1em",
                marginTop: "0.4em",
                marginBottom: 0,
                marginRight: "1em",
              }}
              className="check"
              type="checkbox"
              id="rep2"
              onClick={onChangerep2Check}
            />
            <div style={{ maxWidth: "490px" }}>
              <label>
                The cat always hid under the couch when dogs were in the room
              </label>
            </div>
          </div>

          {/* MUI Checkbox Implementation */}
          {/* <FormControlLabel
              style={{
                alignItems: "flex-start",
              }}
              control={
                <Checkbox
                  onClick={onChangerep2Check}
                  id="rep2"
                  style={{
                    paddingLeft: "0.5em",
                    paddingRight: "0.5em",
                    paddingTop: "3px",
                  }}
                />
              }
              label={
                <Typography sx={{ fontSize: "20px", float: "top" }}>
                  The cat always hid under the couch when dogs were in the room
                </Typography>
              }
            /> */}
          {/* </CardContent> */}
        </div>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
		prevNavigation={()=> {saveData(); navigate("/sec10")}}
        currentPage={14}
        numPages={21}
      />
    </div>
  );
}
export default Section11;
