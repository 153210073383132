// Only being used when clicking the back button from /LevelsofMobility page

import React, { useEffect, useState } from "react";
import Header from "../header";
import { Grid, TextField } from "@mui/material";
import { Box, fontSize } from "@mui/system";
import "../styles/HomeContent.css";
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
// import Summary from '../Summary';
import patients from "../assets/patients.json";
import "../styles/header.css";
import { getPatients } from "../firebase";
import "../firebase";
import { useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

export default function PatientID() {
  const [currentPatient, setCurrentPatient] = useState({
    id: 0,
    name: "",
    gender: "",
    surname: "",
  });
  const [fragments, setFragments] = useState(<></>);
  const [patientID, setPatientId] = useState("");
  const [patients, setPatients] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    getPatients().then((results) => {
      setPatients(results);
    });
  });

  function SetCurrentPatient(patient) {
    setCurrentPatient(patient);
    sessionStorage.setItem("PatientData", patient.id);
    sessionStorage.setItem("patientGender", patient.gender);
  }

  const DisplayPatients = (event) => {
    var value = event.target.value.toUpperCase();
    var testing = [];

    if (value !== "") {
      //console.log(getPatients());
      patients.map((patient, index) => {
        var tempID = patient.id.toUpperCase().substr(0, value.length);
        let regex = new RegExp(tempID, "g");

        if (regex.test(value)) {
          testing.push(
            <React.Fragment key={index}>
              <ListItemButton onClick={() => SetCurrentPatient(patient)}>
                <ListItemText primary={patient.name} secondary={patient.id} />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        }
      });

      setFragments(testing);
    } else {
      setFragments(<></>);
    }
  };

  // const [error, setError] = useState("");
  sessionStorage.setItem("PatientData", currentPatient.id);
  var typography =
    "Input a Patient ID to be directed to his Barthel & Measurements Summary Page";
  const json = JSON.stringify(patients);
  const obj = JSON.parse(json);

  if (currentPatient.id !== 0) {
    navigation("/LevelsOfMobility");
  }

  return (
    <Grid
      justifyContent={"center"}
      rowGap={4}
      container
      columns={{ xs: 2, sm: 2, md: 6 }}
    >
      <Header
        typography={typography}
        history={"/"}
        item
        name={"Patient Page"}
      />

      <Grid justifyContent={"center"} container item>
        <div className="search-container">
          <TextField
            label={"Patient ID"}
            onChange={DisplayPatients}
          ></TextField>
          <List>
            <Divider />
            {fragments}
          </List>
        </div>
      </Grid>
      <Grid container item></Grid>
    </Grid>
  );
}
