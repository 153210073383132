import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import { typography } from "@mui/system";
import TextField from "@mui/material/TextField";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section12() {
  const [score, setScore] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  
  
  const saveData = () =>{

	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  // Calculates the running time
  const [lapse, setLapse] = useState(0);
  //Running state
  const [stopped, setStopped] = useState(true);
  //Checks if timer runs out
  const [timeover, setTimeover] = useState(false);
  const intervalRef = useRef(null);
  //Color of Start/Stop button
  const [buttoncolor, setButtonColor] = useState("#32c953");

  // var typography = "A point will be given if the patient says 11 words or more";

  function getScore() {
    var score = parseInt(sessionStorage.getItem("sec12"));
    setScore(score);
    var scoretemp = parseInt(sessionStorage.getItem("lang")) + score;
    sessionStorage.setItem("language", scoretemp.toString());
  }

  //Handles Start/Stop button
  function handleStartStopTimer() {
    // Change button color to red
    setButtonColor("#D2192F");
    // If "stop" is clicked
    if (stopped) {
      const startTime = Date.now() - lapse;
      intervalRef.current = setInterval(() => {
        // Stop Timer if it reaches 60 seconds
        if (Date.now() - startTime > 60000) {
          setTimeover(true);
          setStopped(false);
          setLapse(60000);
          setButtonColor("grey");
        } else {
          //Sets lapse to currently displayed time
          setLapse(Date.now() - startTime);
		  console.log(lapse, "test")
          sessionStorage.setItem("TUGTimer", lapse / 1000);
          setStopped(false);
        }
      }, 0);
    } else {
      // Displays current running time
      clearInterval(intervalRef.current);
    }
    setStopped(false);
  }

  //Handles reset of timer
  function handleResetTimer() {
    setLapse(0);
    clearInterval(intervalRef.current);
    setButtonColor("#32c953");
    setStopped(true);
    setTimeover(false);
  }

  function onChangeSec12countCheck() {
    if (document.getElementById("count").value > 10) {
      sessionStorage.setItem("sec12", "1");
      setScore(1);
    } else {
      sessionStorage.setItem("sec12", "0");
      setScore(0);
    }
	sessionStorage.setItem("counter", document.getElementById("count").value)
    getScore();
  }

  function Validation() {
	  saveData()
    if (
      document.getElementById("count").value < 0 ||
      document.getElementById("count").value == null ||
      document.getElementById("count").value == ""
    ) {
      setOpen(true);
    } else {
      navigate("/sec13");
    }
  }
  
  useEffect(()=>{
	  if( sessionStorage.getItem("TUGTimer") && sessionStorage.getItem("TUGTimer") !="null")
		  setLapse(parseFloat(sessionStorage.getItem("TUGTimer"))*1000)
	  console.log(sessionStorage.getItem("TUGTimer") )
	  if(sessionStorage.getItem("counter") && sessionStorage.getItem("counter")!=
	  "null"){document.getElementById("count").value = sessionStorage.getItem("counter");onChangeSec12countCheck()}
  },[])

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  var typography = "Verbal fluency\n\nInstruct the patient:\n“Tell me as many words as you can think of that begin with a certain letter of the alphabet that I will tell you in a moment. You can say any kind of word you want, except for proper nouns (like Bob or Boston), numbers, or words that begin with the same sound but have a different suffix, for example, love, lover, loving. I will tell you to stop after one minute. Are you ready? [Pause] \n\nNow, tell me as many words as you can think of that begin with the letter F. [time for 60 sec]. Stop.” \n\nScoring:\nAllocate one point if the patient generates 11 words or more in 60 sec. \nRecord the patient’s response in the allocated box.\n\nClick whether you would like to allocate the point or not. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec11"} name={"Language"} />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          <h5 style={{ marginBottom: "1.5em", alignSelf: "flex-start" }}>
            Language
          </h5>
          <p style={{ marginBottom: "1.5em", textAlign: "center" }}>
            Fluency: Name maximum number of words in one minute that begin with
            letter <b>F</b>
          </p>
          <div className="moca-timer-container">
            {/* Blue circle timer*/}
            <div className="moca-timer">
              {/* Displays 0.000s if timer hasn't started */}
              {lapse == 0 ? (
                <span style={{ fontFamily: "Fenix" }}>0.000 s</span>
              ) : (
                [
                  // Displays 20.000 s if time runs out, else current running time
                  timeover ? (
                    <span style={{ fontFamily: "Fenix" }}>60.000 s</span>
                  ) : (
                    <span style={{ fontFamily: "Fenix" }}>
                      {lapse / 1000} s
                    </span>
                  ),
                ]
              )}
            </div>
            {/* Start/Stop button, gets disabled once time runs out */}
            <div className="moca-timer-buttons-section">
              <Button
                variant={"contained"}
                onClick={handleStartStopTimer}
                disabled={timeover}
                size="large"
                style={{
                  borderRadius: "25px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  fontFamily: "Fenix",
                  textTransform: "capitalize",
                  backgroundColor: buttoncolor,
                }}
              >
                {/* If timer hasnt been clicked yet display "Start" */}
                {stopped ? "Start" : "Stop"}
              </Button>

              {/* Reset button */}
              <Button
                style={{
                  borderRadius: "25px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  fontFamily: "Fenix",
                  textTransform: "capitalize",
                }}
                variant={"contained"}
                onClick={handleResetTimer}
              >
                Reset
              </Button>
            </div>
          </div>
        </div>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <p style={{ marginBottom: "0.5em", textAlign: "left" }}>
            Enter Number of Words:
          </p>
          <TextField
            style={{ marginBottom: "1em" }}
            placeholder="Number of words"
            type="number"
            id="count"
            variant="outlined"
            onChange={onChangeSec12countCheck}
          />
          Score: {score} points
        </div>
      </div>
      <BottomNav
        nextNavigation={() => Validation()}
		prevNavigation ={()=>{saveData(); navigate("/sec11")}}
        currentPage={15}
        numPages={21}
      />

      {/* Confirm dialog  */}
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "1em", borderRadius: "25px" }}
      >
        <h5
          style={{
            marginTop: "1em",
            marginRight: "1em",
            marginLeft: "1em",
            marginBottom: "0.5em",
          }}
        >
          Input missing
        </h5>
        <DialogContentText
          style={{
            marginRight: "1.3em",
            marginLeft: "1.3em",
          }}
        >
          Input a number before continuing.
        </DialogContentText>
        <div
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          {/* Closes dialog */}
          <Button
            style={{
              paddingLeft: "2em",
              paddingRight: "2em",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "25px",
              fontFamily: "Fenix",
              textTransform: "none",
              fontSize: "16px",
            }}
            onClick={() => handleClose()}
            variant="contained"
          >
            Ok
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
export default Section12;
