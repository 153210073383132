import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Header from "../header";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { typography } from "@mui/system";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

export default function Section1b() {
  const [question5, setAnswerQuestion5] = useState("");
  const [question6, setAnswerQuestion6] = useState("");
  const [question7, setAnswerQuestion7] = useState("");
  const [errormsg, setErrorMsg] = useState(false);
  const [errormsg1, setErrorMsg1] = useState(false);
  const [errormsg2, setErrorMsg2] = useState(false);

  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  //sessionStorage.setItem("q5", question5);
  //sessionStorage.setItem("q6", question6);
 // sessionStorage.setItem("q7", question7);

  const navigate = useNavigate();

  useEffect(() => {
      if(sessionStorage.getItem("q5"))
    setAnswerQuestion5(sessionStorage.getItem("q5"));
      if(sessionStorage.getItem("q6"))
    setAnswerQuestion6(sessionStorage.getItem("q6"));
      if(sessionStorage.getItem("q7"))
    setAnswerQuestion7(sessionStorage.getItem("q7"));

    console.log(sessionStorage.getItem("q5")+ " q5");
  }, []);

  const handleChange = (event) => {
    var value = event.target.value;
    setAnswerQuestion5(value);
    sessionStorage.setItem("q5", value);
  };

  const handleChange1 = (event) => {
    var value = event.target.value;
    setAnswerQuestion6(value);
    sessionStorage.setItem("q6", value);
  };

  const handleChange2 = (event) => {
    var value = event.target.value;
    setAnswerQuestion7(value);
    sessionStorage.setItem("q7", value);
  };

  function Validation() {

    if (question5 === "") {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }

    if (question6 === "") {
      setErrorMsg1(true);
    } else {
      setErrorMsg1(false);
    }

    if (question7 === "") {
      setErrorMsg2(true);
    } else {
      setErrorMsg2(false);
    }

saveData()

    if (question5 !== "" && question6 !== "" && question7 !== "") {
      navigate("/mobility");
    } else {
     // setOpen(true);
        setErrorMsg(true)
        setErrorMsg1(true)
        setErrorMsg2(true)
    }
  }
  function navToPreviousPage() {
	  saveData()
    navigate("/referraldetails");
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  var typography = "Click on the correct option for all the questions listed. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/referraldetails"}
          name={"Geriatric Consultant"}
        />
        <h1>Social Habits</h1>
        <div className="gc-container">
          <h5 style={{ paddingBottom: "0.5em" }}>Smoking</h5>
          <FormControl>
            {/* <FormLabel
              sx={{ fontSize: 16, color: "black" }}
              id="demo-controlled-radio-buttons-group"
            >
              Smoking
            </FormLabel> */}
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={question5}
              onChange={handleChange}
              //onChange={(e) => setAnswerQuestion5(e.target.value)}
              row
              style={{ paddingLeft: "3em" }}
              // row
            >
              <FormControlLabel
                className="FormControlLabel"
                // sx={{ fontSize: 20 }}
                //style={{paddingRight: "3em"}}
                value="Yes"
                control={<Radio />}
                label="Yes"
                // label={<Typography sx={{ fontSize: 20 }}>yes</Typography>}
              />
              <FormControlLabel
                className="FormControlLabel"
                //sx={{ fontSize: 20 }}
                style={{ paddingLeft: "3.5em" }}
                value="No"
                control={<Radio />}
                label="No"
                //label={<Typography sx={{ fontSize: 20 }}>No</Typography>}
              />
            </RadioGroup>
<br/>
            <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg ? null : "none" }}
          >
           Please select an option
          </h4>
          </FormControl>
          <br />
          <FormControl>
            {/* <FormLabel
              sx={{ fontSize: 24, color: "black" }}
              id="demo-controlled-radio-buttons-group"
            >
              Drinking
            </FormLabel> */}
            <h5 style={{ paddingBottom: "0.5em" }}>Alcohol</h5>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={handleChange1}
             // onChange={(e) => setAnswerQuestion6(e.target.value)}
              row
              style={{ paddingLeft: "3em" }}
              value={question6}
              // row
            >
              <FormControlLabel
                //fontSize="200px"
                className="FormControlLabel"
                value="Yes"
                control={<Radio />}
                label="Yes"
                //label={<Typography sx={{ fontSize: 20 }}>Yes</Typography>}
              />
              <FormControlLabel
                // sx={{ fontSize: 20 }}
                className="FormControlLabel"
                value="No"
                control={<Radio />}
                label="No"
                style={{ paddingLeft: "3.5em" }}
                //label={<Typography sx={{ fontSize: 20 }}>No</Typography>}
              />
            </RadioGroup>
<br/>
            <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg1 ? null : "none" }}
          >
            Please select an option
          </h4>
          </FormControl>
        </div>
        <h1>Level of Education</h1>
        <div className="gc-container">
          <FormControl sx={{ minWidth: 280 }}>
            <RadioGroup
            onChange={handleChange2}
              //onChange={(e) => setAnswerQuestion7(e.target.value)}
              value={question7}
              row
              style={{ paddingLeft: "3.5em" }}
            >
              <FormControlLabel
                value={"Primary"}
                control={<Radio />}
                label={"Primary"}
                style={{ paddingRight: "3em" }}
              />
              <FormControlLabel
                value={"Secondary"}
                control={<Radio />}
                label={"Secondary"}
                style={{ paddingLeft: "2.5em" }}
              />
              <FormControlLabel
                value={"Post-Secondary"}
                control={<Radio />}
                label={"Post-Secondary"}
              />
              <FormControlLabel
                value={"Tertiary"}
                control={<Radio />}
                label={"Tertiary"}
                style={{ paddingLeft: "2.9em" }}
              />
            </RadioGroup>
<br/>
            <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg2 ? null : "none" }}
          >
            Please select an option
          </h4>
            {/* <InputLabel id="demo-simple-select-label">
              Choose an Option
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Choose an option"
              onChange={(e) => setAnswerQuestion7(e.target.value)}
            >
              <MenuItem value="Primary">Primary</MenuItem>
              <MenuItem value="Secondary">Secondary</MenuItem>
              <MenuItem value="Post-Secondary">Post-Secondary</MenuItem>
              <MenuItem value="Tertiary">Tertiary</MenuItem>
            </Select> */}
          </FormControl>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => Validation()}
        currentPage={3}
        numPages={21}
      ></BottomNav>

      {/* Confirm dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "1em", borderRadius: "25px" }}
      >
        <h5
          style={{
            marginTop: "1em",
            marginRight: "1em",
            marginLeft: "1em",
            marginBottom: "0.5em",
          }}
        >
          Input missing
        </h5>
        <DialogContentText
          style={{
            marginRight: "1.3em",
            marginLeft: "1.3em",
          }}
        >
          Please fill in all the fields.
        </DialogContentText>
        <div
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{
              paddingLeft: "2em",
              paddingRight: "2em",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "25px",
              fontFamily: "Fenix",
              textTransform: "none",
              fontSize: "16px",
            }}
            onClick={() => handleClose()}
            variant="contained"
          >
            Ok
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
