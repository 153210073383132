import React, { useState, useEffect } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section3() {
  const [score, setScore] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const [comment, setComment] = useState("");

  function navToNextPage() {
    sessionStorage.setItem("MOCASec3Comment", comment);
	saveData()
    navigate("/sec4");
  }
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }


  function getScore() {
    var scoretemp =
      score +
      parseInt(sessionStorage.getItem("clock1")) +
      parseInt(sessionStorage.getItem("clock2")) +
      parseInt(sessionStorage.getItem("clock3")) +
      parseInt(sessionStorage.getItem("cube"));
    sessionStorage.setItem("visuo", scoretemp.toString());
    var score = parseInt(sessionStorage.getItem("pattern"));
    setScore(score);
  }

  function onChangePatternCheck() {
    if (document.getElementById("pattern").checked == true) {
      sessionStorage.setItem("pattern", "1");
    } else sessionStorage.setItem("pattern", "0");

    getScore();
  }

  useEffect(() => {
	  if(sessionStorage.getItem("MOCASec3Comment") && sessionStorage.getItem("MOCASec3Comment") !="null")
    setComment(sessionStorage.getItem("MOCASec3Comment"));
  if(sessionStorage.getItem("pattern") && sessionStorage.getItem("pattern")=="1"){
	  document.getElementById("pattern").checked=true;
	      getScore();

  }
  }, []);

  var typography = "Instruct the patient: \nPlease draw a line, going from a number to a letter in ascending order. Begin here [point to (1)] and draw a line from 1 then to A then to 2 and so on. End here [point to (E)]. \n\nScoring: \nAllocate one point if the patient successfully draws the following pattern: 1 −A- 2- B- 3- C- 4- D- 5- E, without drawing any lines that cross.\nAny error that is not immediately self-corrected earns a score of 0.\n\nComment on the picture drawn. \nClick whether you would like to allocate the point or not.";
  var typoTitle = "Alternating Trail Making\n\n"
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typoTitle + typography}
          history={"/moca"}
          name={"Visuospatial/Executive"}
        />
        <h1>MoCA Test</h1>
        <div className="moca-container" style={{ paddingBottom: "1em" }}>
          <h5 style={{ marginBottom: "1em", alignSelf: "flex-start" }}>
            Visuospatial / Executive
          </h5>

          {/* <div className="moca-cube-container"> */}
          <img
            // style={{ marginTop: "0.5em", width: "auto" }}
            style={{ width: "45%" }}
            src="pattern.png"
          ></img>
          {/* </div> */}
        </div>

        {/* Comment box */}
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <div style={{ float: "left" }}>
            <h5 style={{ marginBottom: "1em", float: "left" }}>Comments</h5>
          </div>
          <TextField
            multiline="true"
            rows="4"
            style={{ width: "100%" }}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter additional comments (optional)"
            value={comment}
            defaultValue={comment}
          ></TextField>
        </div>
        {/* Score box */}
        <div
          className="gc-container"
          style={{ paddingTop: "1em", paddingBottom: "1em", marginTop: "1em" }}
        >
          <div style={{ paddingBottom: "0.5em" }}>
            <input
              className="check"
              type="checkbox"
              id="pattern"
              onChange={onChangePatternCheck}
              style={{ marginLeft: "0" }}
            />
            <label>Correct </label>
          </div>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
        currentPage={6}
		prevNavigation={() => {saveData(); navigate("/moca")}}

        numPages={21}
      />

    </div>
  );
}

export default Section3;
