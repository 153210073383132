import React, { useEffect, useState } from "react";
import { FormControl, Grid, ListItem } from "@mui/material";
import Header from "../../header";
import "../../styles/HomeContent.css";
import "../../styles/header.css";
import { useNavigate } from "react-router-dom";
import { RadioGroup, Radio } from "@mui/material";
import { List } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Button } from "@mui/material";
import { saveSocialWorker } from "../../firebase";

export default function PatientPlans() {
  const [next, setNext] = useState(next);

  const navigation = useNavigate();

  const handleChange = (event) => {
    var value = event.target.value;
    // console.log(value);
    sessionStorage.setItem("PatientPlans", value);
    setNext(false);
  };
  
  const saveData = () =>{
	  saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
		LivesAlone: sessionStorage.getItem("Lives Alone?"),
		AnySupport: sessionStorage.getItem("Any Support?"),
	  },
      {
		Stairs: sessionStorage.getItem("Stairs In Home:"),
		Amenities: sessionStorage.getItem("Amenities"),
		AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
		OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
		Amendments: sessionStorage.getItem("Amendments Required:"),
		OtherAmendments: sessionStorage.getItem("Required Amendments"),
	  },
      {
		Patient: sessionStorage.getItem("PatientPlans"),
		Relative: sessionStorage.getItem("RelativePlans"),
	  },
      sessionStorage.getItem("Array"),
      {
		Apps: sessionStorage.getItem("CommunityApps:"),
		TimeSpan: sessionStorage.getItem("TimeSpan"),
	  },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient"),
	  true,
	  false
    );
  }

  var typography = "Ask the the Patient the following questions";

  return (
    <div>
      <Grid
        justifyContent={"center"}
        container
        rowGap={4}
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <Header
          typography={typography}
          name={"Patient Expectations and Plans"}
          history={"/SH-Home-Env"}
        ></Header>
        <List>
          <ListItem>
            <FormControl>
              <RadioGroup className="search-container" onChange={handleChange}>
                <FormControlLabel
                  value={"Return Home"}
                  control={<Radio />}
                  label="Return Home"
                />

                <FormControlLabel
                  value={"Re-Allocation of Own Home"}
                  control={<Radio />}
                  label="Re-Allocation of Own Home"
                />
                <FormControlLabel
                  value={"Residential Care Home"}
                  control={<Radio />}
                  label="Residential Care Home"
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <Button
              onClick={() => navigation("/SH-Relative-Plans")}
              disabled={next}
              variant="contained"
              fullWidth={true}
            >
              Next
            </Button>
          </ListItem>
        </List>
      </Grid>
    </div>
  );
}
