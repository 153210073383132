import "../styles/CommonStyle.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";

import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveDataSectionB as saveData } from "../firebase";
function LevelsOfMobilityV2() {
  var typography =
    "Click on the type of mobility level, for both previous (past 6 months) and current level.";

  const [question11, setAnswerQuestion11] = useState("");
  const [question1WalkingAid, setQuestion1WalkingAid] = useState("");
  const [question1WalkingAidOther, setQuestion1WalkingAidOther] = useState("");
  const [transferQuestion, setTransferQuestion] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("mobilityQuestion11"))
      setAnswerQuestion11(sessionStorage.getItem("mobilityQuestion11"));
    if (sessionStorage.getItem("mobilityQuestion1WalkingAid"))
      setQuestion1WalkingAid(
        sessionStorage.getItem("mobilityQuestion1WalkingAid")
      );
    if (sessionStorage.getItem("mobilityQuestion1WalkingAidOther"))
      setQuestion1WalkingAidOther(
        sessionStorage.getItem("mobilityQuestion1WalkingAidOther")
      );
    if (sessionStorage.getItem("transferQuestion"))
      setTransferQuestion(sessionStorage.getItem("transferQuestion"));
    setQuestion1WalkingAid(
      sessionStorage.getItem("TUGQuestion1")?.split(":")[0]?.trim()
    );
    console.log(question1WalkingAid);
    setQuestion1WalkingAidOther(
      sessionStorage.getItem("TUGQuestion1")?.split(":")[1]?.trim()
    );
  }, []);

  useEffect(() => {
    console.log(question11);
    sessionStorage.setItem("mobilityQuestion11", question11);
  }, [question11]);

  useEffect(() => {
    sessionStorage.setItem("mobilityQuestion1WalkingAid", question1WalkingAid);
  }, [question1WalkingAid]);

  useEffect(() => {
    sessionStorage.setItem("transferQuestion", transferQuestion);
  }, [transferQuestion]);

  useEffect(() => {
    sessionStorage.setItem(
      "mobilityQuestion1WalkingAidOther",
      question1WalkingAidOther
    );
  }, [question1WalkingAidOther]);

  //Check if there is an option selected and if textbox is filled
  function validateForm() {
    let previousMobilitySuccess = false;

    if (question1WalkingAid == "" || !question1WalkingAid) {
      document.getElementById("question1-other-alert").innerHTML = "";
      document.getElementById("question1-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question1Form").scrollIntoView();
      previousMobilitySuccess = false;
    } else if (
      (question1WalkingAid == "other" ||
        (question1WalkingAid && question1WalkingAid.includes("other"))) &&
      (!question1WalkingAidOther ||
        (question1WalkingAidOther && question1WalkingAidOther == ""))
    ) {
      document.getElementById("question1-alert").innerHTML = "";
      document.getElementById("question1-other-alert").innerHTML =
        "Please input a reason!";
      document.getElementById("question1Form").scrollIntoView();
      previousMobilitySuccess = false;
    } else {
      document.getElementById("question1-alert").innerHTML = "";
      document.getElementById("question1-other-alert").innerHTML = "";
      previousMobilitySuccess = true;
    }

    if (question11 == "") {
      document.getElementById("question11-alert").innerHTML =
        "Please select an option!";
      previousMobilitySuccess = false;
    } else {
      document.getElementById("question11-alert").innerHTML = "";
      previousMobilitySuccess = true;
    }

    if (transferQuestion == "") {
      // Displays error message
      document.getElementById("question22-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question2Form").scrollIntoView();
      previousMobilitySuccess = false;
    } else {
      document.getElementById("question22-alert").innerHTML = "";
      previousMobilitySuccess = true;
    }

    saveData();
    // Check if value in question1 and question2 isn't empty
    if (previousMobilitySuccess) {
      let tug = "";
      if (question1WalkingAid) {
        tug = `${question1WalkingAid}`;
        if (question1WalkingAidOther && question1WalkingAid == "other")
          tug = ` ${question1WalkingAid} : ${question1WalkingAidOther}`;
      }

      sessionStorage.setItem("TUGQuestion1", tug);
      saveData();

      navigate("/v2/livingStatus");
    }
  }

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/PatientID-team-2"}
          item
          name={"Physical"}
        />

        <h1>Physical</h1>

        <div className="gc-container">
          <h5 style={{ paddingBottom: "0.5em" }}>
            Previous level of physical mobility
          </h5>
          {/* Previous level: all radio buttons */}
          <form className="question1Form" id="question1Form">
            {/* <h4 className="alert" id="question1-alert"></h4> */}
            {/* Container for "Independent" labels and Independent radio buttons */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingRight: "2.5em",
                paddingTop: "1em",
                width: "100%",
              }}
            >
              <Box
                // width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  // transform: `rotate(${-90}deg)`,
                  maxWidth: "1em",
                  paddingRight: "1em",
                }}
              >
                {/* Left "Independent" label */}
                <h5
                  style={{
                    color: "gray",
                    paddingRight: "1em",
                    paddingTop: "2em",
                    transform: `rotate(${-90}deg)`,
                  }}
                >
                  Independent
                </h5>
              </Box>
              <div style={{ paddingLeft: "2em", width: "100%" }}>
                {/* Previous level: "Unaided" radio button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button1-prev-ind-and-unaid"
                    name="group1-1"
                    value="Unaided"
                    onChange={(e) => setQuestion1WalkingAid(e.target.value)}
                    onClick={function (e) {
                      setQuestion1WalkingAid(e.target.value);
                      document.getElementById(
                        "PreviousLevelOfMobilityFieldset"
                      ).disabled = true;
                      document.getElementById(
                        "radio-button-prev-stick"
                      ).checked = false;
                      document.getElementById(
                        "radio-button-prev-roll-frame"
                      ).checked = false;
                      document.getElementById(
                        "radio-button-prev-gutt-frame"
                      ).checked = false;
                      document.getElementById(
                        "radio-button-prev-other"
                      ).checked = false;
                    }}
                    checked={
                      question1WalkingAid &&
                      question1WalkingAid.includes("Unaided")
                    }
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button1-prev-ind-and-unaid"
                  >
                    Unaided
                  </label>
                  {/* Previous level: "Walking aid" radio button */}
                </div>

                {/* Stick button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-stick"
                    name="group1-1"
                    value="Stick"
                    onChange={(e) => {
                      setQuestion1WalkingAid(e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                    checked={
                      question1WalkingAid &&
                      question1WalkingAid.includes("Stick")
                    }
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-stick"
                  >
                    Stick
                  </label>
                  {/* Rollator frame button */}
                </div>
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-roll-frame"
                    name="group1-1"
                    value="Rollator Frame"
                    onChange={(e) => {
                      setQuestion1WalkingAid(e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                    checked={question1WalkingAid == "Rollator Frame"}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-roll-frame"
                  >
                    Rollator Frame
                  </label>
                </div>
                {/* Gutter frame button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-gutt-frame"
                    name="group1-1"
                    value="Gutter Frame"
                    onChange={(e) => {
                      setQuestion1WalkingAid(e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                    checked={question1WalkingAid == "Gutter Frame"}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-gutt-frame"
                  >
                    Gutter Frame
                  </label>
                </div>
                {/* "Other" button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-other"
                    name="group1-1"
                    value="other"
                    onClick={function () {
                      document.getElementById("text-box1").disabled = false;
                    }}
                    onChange={(e) => setQuestion1WalkingAid(e.target.value)}
                    checked={question1WalkingAid == "other"}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-other"
                  >
                    Other:{" "}
                  </label>
                  <input
                    display="block"
                    width="100%"
                    type="text"
                    id="text-box1"
                    disabled={true}
                    onChange={(e) =>
                      setQuestion1WalkingAidOther(e.target.value)
                    }
                    style={{ paddingRight: "4em", height: "2em" }}
                    value={question1WalkingAidOther}
                  />
                  <h4 className="alert" id="question1-other-alert"></h4>
                  <h4 className="alert" id="question1-alert"></h4>
                </div>
              </div>
            </div>
            {/* Container for "Dependent" label and Dependent radio buttons */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "0.6em",
              }}
            >
              <Box
                // width={"100%"}
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                style={{
                  // transform: `rotate(${-90}deg)`,
                  maxWidth: "1em",
                  paddingRight: "1.5em",
                }}
              >
                {/* Left "Dependent" label */}
                <h5
                  style={{
                    color: "gray",
                    paddingTop: "2em",
                    paddingLeft: "1.5em",
                    transform: `rotate(${-90}deg)`,
                  }}
                >
                  Dependent
                </h5>
              </Box>
              {/* Container for all Dependent radio buttons */}
              <div style={{ paddingLeft: "1.5em" }}>
                {/* Previous level: "Help of one person" radio button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-req-help2"
                    name="group1"
                    value="Supervision"
                    checked={question11 && question11.includes("Supervision")}
                    onChange={(e) => {
                      setAnswerQuestion11(e.target.value);
                    }}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-req-help2"
                  >
                    Supervision
                  </label>
                </div>
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-req-help"
                    name="group1"
                    checked={
                      question11 && question11.includes("Help of one Person")
                    }
                    value="Help of one Person"
                    onChange={(e) => {
                      setAnswerQuestion11(e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-req-help"
                  >
                    Help of 1 Person
                  </label>
                </div>
                {/* Previous level: "Dependent, chair user or bedbound" radio button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-req-help-of-two"
                    name="group1"
                    value="Help of two Persons"
                    checked={
                      question11 && question11.includes("Help of two Persons")
                    }
                    onChange={(e) => {
                      setAnswerQuestion11(e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-req-help-of-two"
                  >
                    Help of 2 Persons
                  </label>
                </div>
                {/* Previous level: "Chair User" button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-chair"
                    name="group1"
                    value="Chair User"
                    checked={question11 && question11.includes("Chair User")}
                    onChange={(e) => {
                      setAnswerQuestion11(e.target.value);
                    }}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-chair"
                  >
                    Chair User
                  </label>
                </div>
                {/* Previous level: "Bedbound" button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-bedbound"
                    name="group1"
                    value="Bedbound"
                    checked={question11 && question11.includes("Bedbound")}
                    onChange={(e) => {
                      setAnswerQuestion11(e.target.value);
                    }}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-bedbound"
                  >
                    Bedbound
                  </label>
                </div>
                <h4 className="alert" id="question11-alert"></h4>
              </div>
            </div>
            {/* Container for Transfer lying - sitting  */}
            <h5 style={{ paddingBottom: "0.5em" }}>
              Current transfer from lying to sitting
            </h5>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                paddingTop: "0.6em",
              }}
            >
              {/* Container for all Dependent radio buttons */}
              <div style={{ paddingLeft: "1.5em" }}>
                {/* Previous level: "Help of one person" radio button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-transfer"
                    name="group1-T"
                    value="Independent"
                    checked={
                      transferQuestion &&
                      transferQuestion.includes("Independent")
                    }
                    onChange={(e) => {
                      setTransferQuestion(e.target.value);
                    }}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-transfer"
                  >
                    Independent
                  </label>
                </div>
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-transfer"
                    name="group1-T"
                    value="Dependent"
                    checked={
                      transferQuestion && transferQuestion.includes("Dependent")
                    }
                    onChange={(e) => {
                      setTransferQuestion(e.target.value);
                    }}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-transfer"
                  >
                    Dependent
                  </label>
                </div>

                <h4 className="alert" id="question22-alert"></h4>
              </div>
            </div>
          </form>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => validateForm()}
        currentPage={2}
        numPages={19}
        prevNavigation={() => {
          saveData();
          navigate("/v2/diagnosis");
        }}
      ></BottomNav>
    </div>
  );
}
export default LevelsOfMobilityV2;
