import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import {
  Popover,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import BottomNav from "../../bottomnavg";
import "../../styles/Team1OT.css";
import TextField from "@mui/material/TextField";
import { saveOccupational } from "../../firebase";

function Section4() {
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer5, setAnswer5] = useState("");
  const [answer6, setAnswer6] = useState("");
  const [answer7, setAnswer7] = useState("");
  const [answer8, setAnswer8] = useState("");

  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  const [error6, setError6] = useState(false);
  const [error7, setError7] = useState(false);
  const [error8, setError8] = useState(false);
  
  const [answer9, setAnswer9] = useState("");
  const [answer10, setAnswer10] = useState("");
  const [answer11, setAnswer11] = useState("");
  const [answer12, setAnswer12] = useState("");
  const [answer13, setAnswer13] = useState("");
  const [answer14, setAnswer14] = useState("");
  const [answer15, setAnswer15] = useState("");
  const [answer16, setAnswer16] = useState("");
  
  const [answer17, setAnswer17] = useState("");
  const [answer18, setAnswer18] = useState("");
  const [answer19, setAnswer19] = useState("");
  const [answer20, setAnswer20] = useState("");
  const [answer21, setAnswer21] = useState("");
  const [answer22, setAnswer22] = useState("");
  const [answer23, setAnswer23] = useState("");
  const [answer24, setAnswer24] = useState("");
 
 const saveData =() => {

const alldata=  {
    housebound: sessionStorage.getItem("a"),
    feedingP: sessionStorage.getItem("a1"),
    toiletP: sessionStorage.getItem("a2"),
    bathP: sessionStorage.getItem("a3"),
    groomP: sessionStorage.getItem("a4"),
    dressP: sessionStorage.getItem("a5"),
        feedingC: sessionStorage.getItem("b1"),
    toiletC: sessionStorage.getItem("b2"),
    bathC: sessionStorage.getItem("b3"),
    groomC: sessionStorage.getItem("b4"),
    dressC: sessionStorage.getItem("b5"),
    feedingCom: sessionStorage.getItem("c1"),
    toiletCom: sessionStorage.getItem("c2"),
    bathCom: sessionStorage.getItem("c3"),
    groomCom: sessionStorage.getItem("c4"),
    dressCom: sessionStorage.getItem("c5"),
    telephone: sessionStorage.getItem("a11"),
    shopping: sessionStorage.getItem("a12"),
    food: sessionStorage.getItem("a13"),
    housekeeping: sessionStorage.getItem("a14"),
    laundry: sessionStorage.getItem("a15"),
    transportation: sessionStorage.getItem("a16"),
    medication: sessionStorage.getItem("a17"),
    finances: sessionStorage.getItem("a18"),
    changesinADL: sessionStorage.getItem("a19"),
    changesinADLcomm: sessionStorage.getItem("b19"),
    //To Check (26.08.22)
    addInfo: sessionStorage.getItem("OTAdditionalInfo"),

	bowelComment: (sessionStorage.getItem("cc1")||""),
	bowelCurrent:(sessionStorage.getItem("bc1")||""),
	bowelPrevious:(sessionStorage.getItem("ac1")||""),
	
	bladderComment:(sessionStorage.getItem("cc2")||""),
	bladderCurrent:(sessionStorage.getItem("bc2")||""),
	bladderPrevious:(sessionStorage.getItem("ac2")||""),
	
	  driving:(sessionStorage.getItem("a21")||""),
    employment:(sessionStorage.getItem("a20")||""),
    leisure:(sessionStorage.getItem("a21l")||""),

	draft: true
  };
  	  saveOccupational(alldata, false)

  }

  useEffect(() => {
    if (answer1 !== "") {
      setError1(false);
    }
    if (answer2 !== "") {
      setError2(false);
    }
    if (answer3 !== "") {
      setError3(false);
    }
    if (answer4 !== "") {
      setError4(false);
    }
    if (answer5 !== "") {
      setError5(false);
    }
    if (answer5 !== "") {
      setError5(false);
    }
    if (answer6 !== "") {
      setError6(false);
    }
    if (answer7 !== "") {
      setError7(false);
    }
  }, []);


  const navigate = useNavigate();

  var typography = "Click on the type of dependence, based on past 6 months. ";

  useEffect(() =>{
   setAnswer1(sessionStorage.getItem("a11")||"");
    setAnswer2(sessionStorage.getItem("a12")||"");
    setAnswer3(sessionStorage.getItem("a13")||"");
    setAnswer4(sessionStorage.getItem("a14")||"");
    setAnswer5(sessionStorage.getItem("a15")||"");
    setAnswer6(sessionStorage.getItem("a16")||"");
    setAnswer7(sessionStorage.getItem("a17")||"");
    setAnswer8(sessionStorage.getItem("a18") ||"");
	  setAnswer9(sessionStorage.getItem("a111") ||"")
     setAnswer10( sessionStorage.getItem("a122") || "")
     setAnswer11 (sessionStorage.getItem("a133") || "")
      setAnswer12(sessionStorage.getItem("a144") || "")
    setAnswer13(  sessionStorage.getItem("a155") || "")
      setAnswer14(sessionStorage.getItem("a166") || "")
      setAnswer15(sessionStorage.getItem("a177") || "")
      setAnswer16(sessionStorage.getItem("a188") || "")
	 setAnswer17( sessionStorage.getItem("a11c") || "")
      setAnswer18(sessionStorage.getItem("a12c") || "")
     setAnswer19( sessionStorage.getItem("a13c") || "")
      setAnswer20(sessionStorage.getItem("a14c") || "")
     setAnswer21( sessionStorage.getItem("a15c") || "")
    setAnswer22(  sessionStorage.getItem("a16c") || "")
     setAnswer23( sessionStorage.getItem("a17c") || "")
   setAnswer24(   sessionStorage.getItem("a18c") || "")
  }, [])

  function validation() {
	  saveData()
    if (answer1 === "" || answer9 == "") {
      setError1(true);
    } else {
      setError1(false);
    }

    if (answer2 === ""|| answer10 == "") {
      setError2(true);
    } else {
      setError2(false);
    }
    if (answer3 === ""|| answer11 == "") {
      setError3(true);
    } else {
      setError3(false);
    }
    if (answer4 === ""|| answer12 == "") {
      setError4(true);
    } else {
      setError4(false);
    }
    if (answer5 === ""|| answer13 == "") {
      setError5(true);
    } else {
      setError5(false);
    }
    if (answer6 === ""|| answer14 == "") {
      setError6(true);
    } else {
      setError6(false);
    }
    if (answer7 === ""|| answer15 == "") {
      setError7(true);
    } else {
      setError7(false);
    }
    if (answer8 === ""|| answer16 == "") {
      setError8(true);
    } else {
      setError8(false);
    }

    if (
      answer1 === "" ||
      answer2 === "" ||
      answer3 === "" ||
      answer4 === "" ||
      answer5 === "" ||
      answer6 === "" ||
      answer7 === "" ||
      answer8 === "" ||
	  answer9 === "" ||
      answer10 === "" ||
      answer11 === "" ||
      answer12 === "" ||
      answer13 === "" ||
      answer14 === "" ||
      answer15 === "" ||
      answer16 === ""
    ) {
    } else {
      sessionStorage.setItem("a11", answer1);
      sessionStorage.setItem("a12", answer2);
      sessionStorage.setItem("a13", answer3);
      sessionStorage.setItem("a14", answer4);
      sessionStorage.setItem("a15", answer5);
      sessionStorage.setItem("a16", answer6);
      sessionStorage.setItem("a17", answer7);
      sessionStorage.setItem("a18", answer8);
	       sessionStorage.setItem("a111", answer9);
      sessionStorage.setItem("a122", answer10);
      sessionStorage.setItem("a133", answer11);
      sessionStorage.setItem("a144", answer12);
      sessionStorage.setItem("a155", answer13);
      sessionStorage.setItem("a166", answer14);
      sessionStorage.setItem("a177", answer15);
      sessionStorage.setItem("a188", answer16);
	       sessionStorage.setItem("a11c", answer17);
      sessionStorage.setItem("a12c", answer18);
      sessionStorage.setItem("a13c", answer19);
      sessionStorage.setItem("a14c", answer20);
      sessionStorage.setItem("a15c", answer21);
      sessionStorage.setItem("a16c", answer22);
      sessionStorage.setItem("a17c", answer23);
      sessionStorage.setItem("a18c", answer24);
      navigate("/Section5OT");
    }
  }

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/Section2OT"}
          // name={"Occupational Therapy"}
        />
        <h1>Instrumental ADLs</h1>
        <div className="occupational-container">
          <table className="occupational-table">
{/* Table Header */}
            <TableRow>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  ADL
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Previous
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Current
                </h5>
              </TableCell>
              <TableCell>
                <h5 style={{ fontSize: "1.5em", textAlign: "center" }}>
                  Comments
                </h5>
              </TableCell>
            </TableRow>            
			<TableRow style={{}}>
              <TableCell
                style={{
                  fontSize: "0.7em",
                }}
              >
                Using the Telephone
              </TableCell>

			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer1}
                    onChange={(e) => setAnswer1(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Able"}>
                      Able
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Unable"}>
                      Unable
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			  
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer9}
                    onChange={(e) => setAnswer9(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Able"}>
                      Able
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Unable"}>
                      Unable
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer17}
                  value={answer17}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer17(e.target.value)}
                ></TextField>
              </TableCell>
               
            </TableRow>
			
            {/* Error message Telephone*/}
            <TableRow style={{ display: error1 ? null : "none" }}>
              <TableCell
                style={{ paddingTop: 0, borderBottom: "none" }}
                colSpan={3}
                align="center"
              >
                <h4
                  style={{ margin: 0, fontSize: "1.3em" }}
                  className="alert"
                  id="question1-alert"
                >
                  Please select an option for Telephone Use
                </h4>
              </TableCell>
            </TableRow>

              <TableRow style={{
                    borderBottom: !error2 ? null : "none",
                    fontSize: "0.7em",
                  }}>
                <TableCell
                  
                >
                  Shopping
                </TableCell>
                <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer2}
                    onChange={(e) => setAnswer2(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer10}
                    onChange={(e) => setAnswer10(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer18}
                  value={answer18}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer18(e.target.value)}
                ></TextField>
              </TableCell>
              </TableRow>
              {/* Error message */}
              <TableRow style={{ display: error2 ? null : "none" }}>
                <TableCell></TableCell>
                <TableCell
                  style={{
                    paddingTop: 0,
                  }}
                  colSpan={3}
                  align="center"
                >
                  <h4
                    style={{ margin: 0, fontSize: "1.3em" }}
                    className="alert"
                    id="question1-alert"
                  >
                    Please select an option for Shopping
                  </h4>
                </TableCell>
              </TableRow>

              <TableRow   style={{
                    borderBottom: !error3 ? null : "none",
                    fontSize: "0.7em",
                  }}>
                <TableCell
                
                >
                  Preparing Food
                </TableCell>
                               <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer3}
                    onChange={(e) => setAnswer3(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer11}
                    onChange={(e) => setAnswer11(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer19}
                  value={answer19}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer19(e.target.value)}
                ></TextField>
              </TableCell>
              </TableRow>
              {/* Error message */}
              <TableRow style={{ display: error3 ? null : "none" }}>
                <TableCell></TableCell>
                <TableCell style={{ paddingTop: 0 }} colSpan={3} align="center">
                  <h4
                    style={{ margin: 0, fontSize: "1.3em" }}
                    className="alert"
                    id="question1-alert"
                  >
                    Please select an option for Preparing Food
                  </h4>
                </TableCell>
              </TableRow>

              <TableRow style={{
                    borderBottom: !error4 ? null : "none",
                    fontSize: "0.7em",
                  }}>
                <TableCell
                  
                >
                  Housekeeping
                </TableCell>
                              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer4}
                    onChange={(e) => setAnswer4(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer12}
                    onChange={(e) => setAnswer12(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer20}
                  value={answer20}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer20(e.target.value)}
                ></TextField>
              </TableCell>
              </TableRow>
              {/* Error message */}
              <TableRow style={{ display: error4 ? null : "none" }}>
                <TableCell></TableCell>
                <TableCell style={{ paddingTop: 0 }} colSpan={3} align="center">
                  <h4
                    style={{ margin: 0, fontSize: "1.3em" }}
                    className="alert"
                    id="question1-alert"
                  >
                    Please select an option for Housekeeping
                  </h4>
                </TableCell>
              </TableRow>

              <TableRow style={{
                    borderBottom: !error5 ? null : "none",
                    fontSize: "0.7em",
                  }}>
                <TableCell
                  
                >
                  Doing Laundry
                </TableCell>
                <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer5}
                    onChange={(e) => setAnswer5(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer13}
                    onChange={(e) => setAnswer13(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer21}
                  value={answer21}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer21(e.target.value)}
                ></TextField>
              </TableCell>
              </TableRow>
              {/* Error message */}
              <TableRow style={{ display: error5 ? null : "none" }}>
                <TableCell></TableCell>
                <TableCell style={{ paddingTop: 0 }} colSpan={3} align="center">
                  <h4
                    style={{ margin: 0, fontSize: "1.3em" }}
                    className="alert"
                    id="question1-alert"
                  >
                    Please select an option for Doing Laundry
                  </h4>
                </TableCell>
              </TableRow>

              <TableRow  style={{
                    borderBottom: !error6 ? null : "none",
                    fontSize: "0.7em",
                  }}>
                <TableCell
                 
                >
                  Transportation
                </TableCell>
                  <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer6}
                    onChange={(e) => setAnswer6(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer14}
                    onChange={(e) => setAnswer14(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer22}
                  value={answer22}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer22(e.target.value)}
                ></TextField>
              </TableCell>
              </TableRow>
              {/* Error message */}
              <TableRow style={{ display: error6 ? null : "none" }}>
                <TableCell></TableCell>
                <TableCell style={{ paddingTop: 0 }} colSpan={3} align="center">
                  <h4
                    style={{ margin: 0, fontSize: "1.3em" }}
                    className="alert"
                    id="question1-alert"
                  >
                    Please select an option for Transportation
                  </h4>
                </TableCell>
              </TableRow>

              <TableRow style={{
                    borderBottom: !error7 ? null : "none",
                    fontSize: "0.7em",
                  }}>
                <TableCell
                  
                >
                  Handling Medication
                </TableCell>
                             <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer7}
                    onChange={(e) => setAnswer7(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer15}
                    onChange={(e) => setAnswer15(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer23}
                  value={answer23}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer23(e.target.value)}
                ></TextField>
              </TableCell>
              </TableRow>
              {/* Error message */}
              <TableRow style={{ display: error7 ? null : "none" }}>
                <TableCell></TableCell>
                <TableCell style={{ paddingTop: 0 }} colSpan={3} align="center">
                  <h4
                    style={{ margin: 0, fontSize: "1.3em" }}
                    className="alert"
                    id="question1-alert"
                  >
                    Please select an option for Handling Medication
                  </h4>
                </TableCell>
              </TableRow>

              <TableRow style={{
                    borderBottom: !error8 ? null : "none",
                    fontSize: "0.7em",
                  }}>
                <TableCell
                  
                >
                  Handling Finances
                </TableCell>
                              <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer8}
                    onChange={(e) => setAnswer8(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
			   <TableCell>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px", width: "130px" }}
                    size="small"
                    value={answer16}
                    onChange={(e) => setAnswer16(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
            <TableCell>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={answer24}
                  value={answer24}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setAnswer24(e.target.value)}
                ></TextField>
              </TableCell>
              </TableRow>
              {/* Error message */}
              <TableRow style={{ display: error8 ? null : "none" }}>
                <TableCell></TableCell>
                <TableCell style={{ paddingTop: 0 }} colSpan={3} align="center">
                  <h4
                    style={{ margin: 0, fontSize: "1.3em" }}
                    className="alert"
                    id="question1-alert"
                  >
                    Please select an option for Handling Finances
                  </h4>
                </TableCell>
              </TableRow>
            </table>
      
      </div>
      </div>
      <BottomNav
        nextNavigation={() => validation()}
        prevNavigation={() => {saveData(); navigate("/Section2OT")}}
        currentPage={2}
        numPages={5}
      />
    </div>
  );
}
export default Section4;
