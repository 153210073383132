import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveDataSectionA as saveData } from "../firebase";

function WriteCheck() {
  const navigate = useNavigate();

  function special_next() {
    console.log("jcsdiods");
    saveData();
    //setOpen(true);
    if (document.getElementById("blindCheck").checked == true) {
      navigate("/v2/sec7");
    } else if (
      document.getElementById("blindCheck").checked == false &&
      document.getElementById("writeCheck").checked == true
    ) {
      navigate("/v2/sec6");
    } else {
      navigate("/v2/sec3");
    }
  }
  useEffect(() => {
    if (sessionStorage.getItem("eduCheck") == "1")
      document.getElementById("eduCheck").checked = true;

    if (sessionStorage.getItem("writeCheck") == "1")
      document.getElementById("writeCheck").checked = true;

    if (sessionStorage.getItem("blindCheck") == "1")
      document.getElementById("blindCheck").checked = true;
  }, []);

  function onChangeEduCheck() {
    if (document.getElementById("eduCheck").checked == true)
      sessionStorage.setItem("eduCheck", "1");
    else sessionStorage.setItem("eduCheck", "0");
  }

  function onChangeWriteCheck() {
    if (document.getElementById("writeCheck").checked == true)
      sessionStorage.setItem("writeCheck", "1");
    else sessionStorage.setItem("writeCheck", "0");
  }

  function onChangeBlindCheck() {
    if (document.getElementById("blindCheck").checked == true)
      sessionStorage.setItem("blindCheck", "1");
    else sessionStorage.setItem("blindCheck", "0");
  }

  var typography =
    "These questions will effect final MoCA score calculation. \n \nTest is altered based on the check boxes ticked on this page. \n\nTick the appropriate check boxes, if the patient has visual impairment, unable to write or has less than 12 years of education. Click the next    button to start the test.\n\nPatient is only allowed to carry the MoCA test once. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/mobility"}
          name={"Patient"}
        />

        <h1>Cognitive - MoCA Test</h1>
        <div className="gc-container">
          <h5 style={{ paddingBottom: "0.5em" }}>Tick Appropriate Checkbox:</h5>
          <div>
            <input
              className="check"
              type="checkbox"
              id="writeCheck"
              onClick={onChangeWriteCheck}
            />
            <label className="check-label">Patient is unable to write</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="blindCheck"
              onClick={onChangeBlindCheck}
            />
            <label className="check-label">Patient is visually impaired</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="eduCheck"
              onClick={onChangeEduCheck}
            />
            <label className="check-label">
              Patient has less than 12 years of education
            </label>
          </div>
          <br />
          <br />
          <h5 className="alert">
            Certain parts of the test will be skipped if checkboxes are ticked.
            The test will be marked differently.
          </h5>
        </div>
      </div>

      <BottomNav
        prevNavigation={() => {
          saveData();
          navigate("/v2/livingStatus");
        }}
        nextNavigation={() => special_next()}
        currentPage={4}
        numPages={19}
      />
    </div>
  );
}

export default WriteCheck;
