import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import "../../styles/HomeContent.css";
import { List, ListItem } from "@mui/material";
import { Button, FormControlLabel, Grid } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormControl ,   MenuItem,
  Select,} from "@mui/material";
import "../../styles/header.css";
import BottomNav from "../../bottomnavg";
import { saveSocialWorker } from "../../firebase";

export default function SWHomeSupport() {
  const [radio, setRadio] = useState("");
  const [fragments, setFragments] = useState(<></>);
  const [result, setResult] = useState("");
  const [next, setNext] = useState(true);
  const [error, setError] = useState(false);
  const [maritualStatusHSerror, setmaritualStatusHSError] = useState(false);
  //
  const [disable, setDisable] = useState(true);
  const navigation = useNavigate();
  const [maritualStatusHS, setmaritualStatusHS] = useState(false)

  useEffect(() => {
    setRadio(sessionStorage.getItem("Lives Alone?"));
    setResult(sessionStorage.getItem("Any Support?"));
	setmaritualStatusHS(sessionStorage.getItem("maritualStatusHS"))
  }, []);
  
  useEffect(()=>{
	  sessionStorage.setItem("maritualStatusHS", maritualStatusHS)
  }, [maritualStatusHS])
  
    const saveData = () =>{
	  saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
		LivesAlone: sessionStorage.getItem("Lives Alone?"),
		AnySupport: sessionStorage.getItem("Any Support?"),
	  },
      {
		Stairs: sessionStorage.getItem("Stairs In Home:"),
		Amenities: sessionStorage.getItem("Amenities"),
		AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
		OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
		Amendments: sessionStorage.getItem("Amendments Required:"),
		OtherAmendments: sessionStorage.getItem("Required Amendments"),
	  },
      {
		Patient: sessionStorage.getItem("PatientPlans"),
		Relative: sessionStorage.getItem("RelativePlans"),
	  },
      sessionStorage.getItem("Array"),
      {
		Apps: sessionStorage.getItem("CommunityApps:"),
		TimeSpan: sessionStorage.getItem("TimeSpan"),
	  },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient"),
	  true,
	  false
    );
  }

  const handleChange = (event) => {
    var value = event.target.value;
    setRadio(value);
    console.log(value);
    sessionStorage.setItem("Lives Alone?", value);
    var testing = [];
    setResult("");
    setNext(true);
  };

  const readNewForm = (event) => {
    var value = event.target.value;
    console.log(value);
    setResult(value);
    sessionStorage.setItem("Any Support?", value);
    setNext(false);
  };

  function validation(){
    console.log("validation" + radio + result )
	if(!maritualStatusHS){
		setmaritualStatusHSError(true)
    }
	if (!result ) {
      setError(true);
    }
	saveData()
if(result && maritualStatusHS)	{
      setError(false);
	  setmaritualStatusHSError(false)
      navigation("/SH-Home-Env")
     // sessionStorage.setItem("a", answerhousebound);
    }
  }

  var typography = "Click on the appropriate home support level.";
  return (
    <div className="screen" style={{justifyContent:"space-between"}}>
      <div className="screen" style={{width:"100%", minHeight:"auto"}}>
      <Header
        history={"/Patient-ID-Social-Worker"}
        typography={typography}
        name={"Home Support"}
      ></Header>
      <h1>Home Support</h1>

      {/* <h1 id="Title">Home Support</h1>  */}
      <List className="search-container"
              style={{  paddingLeft: "2em",width:"80%"}}>
        <ListItem style={{ display: "flex", flexDirection: "column", width:"100%"}} >
		    <div          
              style={{ display: "flex", flexDirection: "row",width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
              >Marital Status:</label>
                <FormControl >
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                    value={maritualStatusHS}
                    onChange={(e) => setmaritualStatusHS(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Single"}>
                      Single
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Married"}>
                     Married
                    </MenuItem>
					 <MenuItem style={{ fontSize: "14px" }} value={"Widowed"}>
                     Widowed
                    </MenuItem>
					 <MenuItem style={{ fontSize: "14px" }} value={"Separated"}>
                     Separated
                    </MenuItem>
					 <MenuItem style={{ fontSize: "14px" }} value={"Cohabiting"}>
                     Cohabiting
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
 <h4
              className="alert"
              id="question1-alert"
              style={{
                display: maritualStatusHSerror ? null : "none",
                marginTop: "0.5em",
                fontSize: "0.8em",
              }}
            >
              Please select an option
            </h4>
           </ListItem>
		   <ListItem  >
          <FormControl> 
		 <RadioGroup
              
              onChange={handleChange}
              value={radio}
            >
              <FormControlLabel
                value={"Lives Alone"}
                control={<Radio />}
                label={<h4>Lives Alone</h4>}
              />
              <div
                className="search-container"
                id="LA-Options"
                style={{ width: "80%", paddingLeft: "2em" , paddingTop: "1em", paddingRight: "0"}}
              >
                <RadioGroup onChange={readNewForm} value={result} style={{display:"flex", flexDirection:"row"}}>
                  <FormControlLabel
                    value={"No Support"}
                    control={<Radio />}
                    label="No Support"
                    id="LA-Option1"
                    disabled={radio == "Lives Alone" ? false : true}
                  />
                  <FormControlLabel
                    value={"Average Support"}
                    control={<Radio />}
                    label="Average Support"
                    disabled={radio == "Lives Alone" ? false : true}
                  />
                  <FormControlLabel
                    value={"Good Support"}
                    control={<Radio />}
                    label="Good Support"
                    disabled={radio == "Lives Alone" ? false : true}
                  />
                </RadioGroup>
              </div>
              {/* {radio == "Lives Alone" && fragments} */}
              <br></br>
              <FormControlLabel
                value={"Does not live alone"}
                control={<Radio />}
                label={<h4>Does Not Live Alone</h4>}
                // onClick={() => {
                //   console.log("DNLA clicked entered onClick");
                //   setDisable(false);
                // }}
              />
              <div
                className="search-container"
                id="DNLA-Options"
                style={{ width: "80%", padding: "5%" }}
              >
                <RadioGroup onChange={readNewForm} value={result}  style={{display:"flex", flexDirection:"row"}}>
                  <FormControlLabel
                    value={"Residential Home"}
                    control={<Radio />}
                    label="Residential Home"
                    disabled={radio == "Does not live alone" ? false : true}
                  />
				   <FormControlLabel
                    value={"Live-in carer"}
                    control={<Radio />}
                    label="Live-in carer"
                    disabled={radio == "Does not live alone" ? false : true}
                  />
				   <FormControlLabel
                    value={"Children"}
                    control={<Radio />}
                    label="Children"
                    disabled={radio == "Does not live alone" ? false : true}
                  />
                  <FormControlLabel
                    value={"Partner/Sibling"}
                    control={<Radio />}
                    label="Partner/Sibling"
                    disabled={radio == "Does not live alone" ? false : true}
                  />
                 
                </RadioGroup>
                
              </div>
              {/* {radio == "Does not live alone" && fragments} */}
              <h4
              className="alert"
              id="question1-alert"
              style={{
                display: error ? null : "none",
                marginTop: "0.5em",
                fontSize: "0.8em",
              }}
            >
              Please select an option
            </h4>
            </RadioGroup>
          </FormControl>
        </ListItem>
      </List>
      {/* </Grid> */}
      </div>
      <BottomNav
        nextNavigation={() => validation()}
        prevNavigation={null}
        currentPage={1}
        numPages={6}
      />
    </div>
  );
}

