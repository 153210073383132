import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

const ConfirmDialog = ({
  showDialog,
  confirm,
  cancel,
  confirmButtonText,
  cancelButtonText,
  dialogTitle,
  dialogText,
}) => {
  return (
    <Dialog
      open={showDialog}
      onClose={cancel}
      style={{ padding: "1em", borderRadius: "25px" }}
    >
      <h5
        style={{
          marginTop: "1em",
          marginRight: "1em",
          marginLeft: "1em",
          marginBottom: "0.5em",
        }}
      >
        {dialogTitle}
      </h5>
      <DialogContentText
        style={{
          marginRight: "1.3em",
          marginLeft: "1.3em",
        }}
      >
        {dialogText}
      </DialogContentText>
      <div
        style={{
          padding: "1em",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        {/* Closes dialog, hide button if no cancel option  */}
        <Button
          style={{
            visibility: cancel ? "visible" : "hidden",
            paddingLeft: "2em",
            paddingRight: "2em",
            paddingTop: "2px",
            paddingBottom: "2px",
            borderRadius: "25px",
            fontFamily: "Fenix",
            textTransform: "none",
            fontSize: "16px",
          }}
          onClick={cancel}
          variant="contained"
        >
          {cancelButtonText}
        </Button>
        {/* Confirms dialog and moves to next page*/}
        <Button
          style={{
            paddingLeft: "2em",
            paddingRight: "2em",
            paddingTop: "2px",
            paddingBottom: "2px",
            borderRadius: "25px",
            fontFamily: "Fenix",
            textTransform: "none",
            fontSize: "16px",
          }}
          onClick={confirm}
          variant="contained"
        >
          {confirmButtonText}
        </Button>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
