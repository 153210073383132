import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../header";
import { Grid } from "@mui/material";
import "../../styles/header.css";
import "../../styles/HomeContent.css";
import { Button } from "@mui/material";
import "../../firebase";
import { saveSocialWorker } from "../../firebase";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import "../../styles/Summary.css";
import "../../styles/SocialWorkerSum.css";
import BottomNav from "../../bottomnavg";
import ScrollBtn from "../../ScrollBtn";
import CsvDownloadButton from 'react-json-to-csv'
import JsPDF from 'jspdf';

export default function SocialSummary() {
  const [exp, setExp] = useState(sessionStorage.getItem("Array"));
  const location = useLocation();
  const [HomeSupp, setHomeSupp] = useState({
    LivesAlone: sessionStorage.getItem("Lives Alone?"),
    AnySupport: sessionStorage.getItem("Any Support?"),
  });
  const [HomeEnv, setHomeEnv] = useState({
    Stairs: sessionStorage.getItem("Stairs In Home:"),
    Amenities: sessionStorage.getItem("Amenities"),
    AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
    OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
    Amendments: sessionStorage.getItem("Amendments Required:"),
    OtherAmendments: sessionStorage.getItem("Required Amendments"),
  });
  
    const generatePDF = () => {

    const report = new JsPDF('landscape','pt','a4');
    report.html(document.querySelector('#report')).then(() => {
        report.save('SocialWorkerSummary.pdf');
    });
  }


  const [Expect, setExpect] = useState({
    Patient: sessionStorage.getItem("PatientPlans"),
    Relative: sessionStorage.getItem("RelativePlans"),
  });

  const [SServices, setSocialServices] = useState({
    CommCare: sessionStorage.getItem("CommCare"),
    Telecare: sessionStorage.getItem("Telecare"),
    HomeHelp: sessionStorage.getItem("HomeHelp"),
    MealsWheels: sessionStorage.getItem("Meals On Wheels"),
    Other: sessionStorage.getItem("Other Profession:"),
  });

  const [CommApps, setCommApps] = useState({
    Apps: sessionStorage.getItem("CommunityApps:"),
    TimeSpan: sessionStorage.getItem("TimeSpan"),
  });
  const [open, setOpen] = useState(false);
  const navigation = useNavigate();

  const handleClick = () => {
    var Arr = [];
    const date = format(new Date(), "dd-MM-yyyy HH:mm:ss");
    Arr.push(HomeSupp);
    Arr.push(HomeEnv);
    Arr.push(Expect);
    Arr.push(SServices);
    Arr.push(CommApps);
    console.log(HomeSupp.LivesAlone);
    console.log(HomeEnv);
    saveSocialWorker(
      sessionStorage.getItem("patientId"),
      date,
      HomeSupp,
      HomeEnv,
      Expect,
      sessionStorage.getItem("Array"),
      CommApps,
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient")
    );
    setOpen(false);
    navigation("/PatientView");
  };
  
  const alldata = {
      HomeSupport: HomeSupp,
     StairstoHome: HomeEnv,
	  PatientsPlans: Expect.Patient,
    RelativePlans: Expect.Relative,
      SocialServices: sessionStorage.getItem("Array"),
	   CommLTCData: {
      CommLTCApplication: CommApps.Apps,
      TimeSpan: CommApps.TimeSpan,
    },
     SocialServicesOther: sessionStorage.getItem("Other Profession:"),
     HomeAmendmentsRequired: {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      AddInfo: sessionStorage.getItem("Add Info"),
     MaritalStatus:  sessionStorage.getItem("maritualStatusHS"),
	MainCarer:  sessionStorage.getItem("mainCarerSW"),
	Neighbours:  sessionStorage.getItem("neighboursSW"),
	SupportAtHome:
	  sessionStorage.getItem("supportAtHomeSW"),
	  
	SocialServicesComments:sessionStorage.getItem("socialServiceComments"),
	  
	ReligiousBelief	:sessionStorage.getItem("religiousBelief"),
	  
	  LiftPresent: sessionStorage.getItem("liftPresent"),
	  TypeofHouse: sessionStorage.getItem("typeofhouse"),
	  AimRelative:sessionStorage.getItem("aimrelative"),
	  AimPatient: sessionStorage.getItem("aimPatient")
  }

  const refresh = () => {
    setOpen(true);
    console.log(open);
  };

  const close = () => {
    setOpen(false);
  };

  // console.log(Arr[0]);
  //const dt = new Date().toString();
  var OtherString = sessionStorage.getItem("Other Profession:");
  var TimeSpan = [];

  const [tsdisplay, setsdisplay] = useState(false);
  const [locamenitydisplay, seLocAmenitydisplay] = useState(false);
  const [amenitydisplay, seAmenitydisplay] = useState(false);
  const [amendmentsdisplay, setAmendmentsdisplay] = useState(false);
  //locamenitydisplay

  useEffect(() => {
    //LTC
    if (sessionStorage.getItem("CommunityApps:") == "Yes") {
      setsdisplay(true);
    } else {
      setsdisplay(false);
    }
    //Amenities
    if (sessionStorage.getItem("Amenities") == "No") {
      seLocAmenitydisplay(true);
    } else {
      seLocAmenitydisplay(false);
      seAmenitydisplay(false);
    }

    //Amenities comment
    if (sessionStorage.getItem("Location Of Amenities") == "Other" && sessionStorage.getItem("Amenities") == "No") {
      seAmenitydisplay(true);
    } else {
      seAmenitydisplay(false);
    }

    //Amendments comment
    if (sessionStorage.getItem("Amendments Required:") == "Yes") {
      setAmendmentsdisplay(true);
    } else {
      setAmendmentsdisplay(false);
    }
  });
  // if (sessionStorage.getItem("TimeSpan") !== "") {
  //   TimeSpan.push(
  //     <React.Fragment>
  //       <tr className="grid-data" style={{ display: tsdisplay ? null : "none" }}>
  //         <td className="Table-Names">
  //           <h5>Application Timespan:</h5>
  //         </td>
  //         <td className="Table-Content">
  //           {sessionStorage.getItem("TimeSpan")}
  //         </td>
  //       </tr>
  //     </React.Fragment>
  //   );
  // }
  var Location = [];
  if (sessionStorage.getItem("Location Of Amenities") !== "") {
    Location.push(
      <React.Fragment>
        <tr className="grid-data" style={{ display: locamenitydisplay ? null : "none" }}>
          <td className="Table-Names">Location of Amenities:</td>
          <td className="Table-Content">
            {sessionStorage.getItem("Location Of Amenities")}
          </td>
        </tr>
      </React.Fragment>
    );
  }
  var OtherLocation = [];
  if (sessionStorage.getItem("Other Amenity Location") !== "") {
    OtherLocation.push(
      <React.Fragment>
        <tr
          className="grid-data"
          style={{ display: amenitydisplay ? null : "none" }}
        >
          <td className="Table-Names">{/* // <h5>Other Location:</h5> */}</td>
          <td className="Table-Content">
            {sessionStorage.getItem("Other Amenity Location")}
          </td>
        </tr>
      </React.Fragment>
    );
  }
  var count = 0;
  if (OtherString !== "" && OtherString && exp != "") {
    OtherString = "," + OtherString;
  }

  console.log(open);
  var typography =
    "Confirm your assessment findings. \nEdit from side edit buttons as necessary. \nConfirm and submit your assessment before exiting.";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Grid
          justifyContent={"center"}
          container
          columns={{ xs: 2, sm: 2, md: 6 }}
        >
          <Header
            typography={typography}
            history={"/AdditionalinfoSocialWorker"}
          ></Header>
          <h1 style={{width:"100%"}}>Summary</h1>
				<div id="report" style={{width:"70%"}}>

          <div className="summary-container" style={{width:"auto"}}>
            <table>
              <tr className="grid-data">
                <td className="Table-Names">
                  <h3 style={{ marginBottom: "12px" }}>Support</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td className="Table-Names">
                    <Button
                      variant="outlined"
                      className="EditButton"
                      style={{ marginBottom: "12px" }}
                      onClick={() =>
                        navigation("/SH-Home-Support", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
			   <tr className="grid-data">
                <td className="Table-Names">Marital Status:</td>

                <td className="Table-Content">
				{sessionStorage.getItem("maritualStatusHS")}                </td>
              </tr>
             

              <tr className="grid-data">
                <td className="Table-Names">Home Support:</td>

                <td className="Table-Content">
                  {sessionStorage.getItem("Lives Alone?")}
                </td>
              </tr>
              <tr className="grid-data">
                <td className="Table-Names">
                  {/* {sessionStorage.getItem("Lives Alone?")} */}
                </td>
                <td className="Table-Content">
                  {sessionStorage.getItem("Any Support?")}
                </td>
              </tr>
            </table>
          </div>

          <div className="summary-container" style={{width:"auto"}}>
            <table>
              <tr className="grid-data">
                <td className="Table-Names">
                  <h3 style={{ marginBottom: "12px" }}>Amenities</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td className="Table-Names">
                    <Button
                      style={{ marginBottom: "12px" }}
                      variant="outlined"
                      className="EditButton"
                      onClick={() =>
                        navigation("/SH-Home-Env", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
			   <tr className="grid-data">
                <td className="Table-Names">Type of House:</td>

                <td className="Table-Content">
                  {sessionStorage.getItem("typeofhouse")}
                </td>
              </tr>
              <tr className="grid-data">
                <td className="Table-Names">Stairs to Access House:</td>

                <td className="Table-Content">
                  {sessionStorage.getItem("Stairs In Home:")}
                </td>
              </tr>
			  			   <tr className="grid-data">
                <td className="Table-Names">Lift Present:</td>

                <td className="Table-Content">
                  {sessionStorage.getItem("liftPresent")}
                </td>
              </tr>
              <tr className="grid-data">
                <td className="Table-Names">Amenities on Same Floor:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("Amenities")}
                </td>
              </tr>
              {Location}

              {OtherLocation}

              {console.log(sessionStorage.getItem("Required Amendments"))}

              <tr className="grid-data">
                <td className="Table-Names">Amendments Required:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("Amendments Required:")}
                </td>
              </tr>
              <tr
                className="grid-data"
                style={{ display: amendmentsdisplay ? null : "none" }}
              >
                <td className="Table-Names"></td>
                <td className="Table-Content">
                  {sessionStorage.getItem("Required Amendments")}
                </td>
              </tr>

              {/* Testing */}
              {/* {Location}

          {OtherLocation} */}
            </table>
          </div>

          <div className="summary-container" style={{width:"auto"}}>
            <table>
              <tr className="grid-data">
                <td className="Table-Names">
                  <h3 style={{ marginBottom: "12px" }}>Services</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td className="Table-Names">
                    <Button
                      style={{ marginBottom: "12px" }}
                      variant="outlined"
                      className="EditButton"
                      onClick={() =>
                        navigation("/Social-Services", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
			  <tr className="grid-data">
                <td className="Table-Names">Informal support:</td>
                <td className="Table-Content">
                </td>
              </tr>
			   <tr className="grid-data">
                <td className="Table-Names">Main Carer:</td>
                <td className="Table-Content">{sessionStorage.getItem("mainCarerSW")}
                </td>
              </tr>
			  <tr className="grid-data">
                <td className="Table-Names">Any neighbour/relative who offers support?:</td>
                <td className="Table-Content">{sessionStorage.getItem("neighboursSW")}
                </td>
              </tr>
			   <tr className="grid-data">
                <td className="Table-Names">Level of support at home:</td>
                <td className="Table-Content">{sessionStorage.getItem("supportAtHomeSW")}
                </td>
              </tr>
              <tr className="grid-data">
                <td className="Table-Names">Social Services:</td>
                <td className="Table-Content">
                  {exp} {OtherString}{" "}
                </td>
              </tr>
			    <tr className="grid-data">
                <td className="Table-Names">Social Services Other Comments:</td>
                <td className="Table-Content">
				{sessionStorage.getItem("socialServiceComments")}
                </td>
              </tr>
			    <tr className="grid-data">
                <td className="Table-Names">Religious Belief:</td>
                <td className="Table-Content">
				{sessionStorage.getItem("religiousBelief")}
                </td>
              </tr>
              <tr className="grid-data">
                <td className="Table-Names">Application for LTC:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("CommunityApps:")}
                </td>
              </tr>
              <tr
                className="grid-data"
                style={{ display: tsdisplay ? null : "none" }}
              >
                <td className="Table-Names">Application Timespan:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("TimeSpan")}
                </td>
              </tr>
              {/* // {TimeSpan} */}
            </table>
          </div>
          <div className="summary-container" style={{width:"auto"}}>
            <table>
              <tr className="grid-data">
                <td className="Table-Names">
                  <h3 style={{ marginBottom: "12px" }}>Future Plans</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td className="Table-Names">
                    <Button
                      style={{ marginBottom: "12px" }}
                      variant="outlined"
                      className="EditButton"
                      onClick={() =>
                        navigation("/SH-Relative-Plans", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
              <tr className="grid-data">
                <td className="Table-Names">Patient's Plan:</td>
                <td className="Table-Content">
                </td>
              </tr>
 <tr className="grid-data">
                <td className="Table-Names" style={{paddingLeft:20}}>Aims of Rehab:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("aimPatient")}

                </td>
              </tr>
 <tr className="grid-data">
                <td className="Table-Names" style={{paddingLeft:20}}>After Rehab:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("PatientPlans")}
                </td>
              </tr>
              <tr className="grid-data" >
                <td className="Table-Names">Patient's Plan:</td>
                <td className="Table-Content">
                </td>
              </tr>
 <tr className="grid-data">
                <td className="Table-Names" style={{paddingLeft:20}}>Aims of Rehab:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("aimrelative")}

                </td>
              </tr>

<tr className="grid-data">
                <td className="Table-Names" style={{paddingLeft:20}}>After Rehab:</td>
                <td className="Table-Content">
                  {sessionStorage.getItem("RelativePlans")}
                </td>
              </tr>
            </table>
          </div>
          <div className="summary-container" style={{width:"auto"}}>
            <table>
              <tr className="grid-data">
                <td className="Table-Names">
                  <h3 style={{ marginBottom: "12px" }}>Additional Info</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td className="Table-Names">
                    <Button
                      style={{ marginBottom: "12px" }}
                      variant="outlined"
                      className="EditButton"
                      onClick={() =>
                        navigation("/AdditionalinfoSocialWorker", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
              <tr className="grid-data">
                <td className="Table-Names" colspan="2">
                  {sessionStorage.getItem("Add Info")}
                  {/* <h5>Additional Info:</h5> */}
                </td>
              </tr>
            </table>
          </div>

          <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ padding: "1em", borderRadius: "25px" }}
          >
            <h5
              style={{
                marginTop: "1em",
                marginRight: "1em",
                marginLeft: "1em",
                marginBottom: "0.5em",
              }}
            >
              Submit?
            </h5>
            <DialogContentText
              style={{
                marginRight: "1.3em",
                marginLeft: "1.3em",
              }}
            >
              This will save the data and end the assessment.
            </DialogContentText>
            <div
              style={{
                padding: "1em",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => close()}
                variant="contained"
              >
                No
              </Button>

              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => handleClick()}
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </Dialog>
		  
	  	  		  <button onClick={generatePDF} style={{borderColor:"#015893", backgroundColor:"rgb(50, 201, 83)", borderRadius: 10, fontSize: "large"}} type="button">Export PDF</button>

      </div>
        </Grid>
      </div>

      <ScrollBtn />
      <BottomNav
        prevNavigation={() => navigation("/AdditionalinfoSocialWorker")}
        submitButton={() => refresh()}
        currentPage={6}
        numPages={6}
      />
    </div>
  );
}
