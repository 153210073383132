import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import Header from "../header";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { typography } from "@mui/system";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

export default function Section1a() {
  const [question2, setAnswerQuestion2] = useState("");
  const [question3, setAnswerQuestion3] = useState("");
  const [question4, setAnswerQuestion4] = useState("");
  const [question5, setAnswerQuestion5] = useState("");
  const [errormsg, setErrorMsg] = useState(false);
  const [errormsg1, setErrorMsg1] = useState(false);
  const [errormsg2, setErrorMsg2] = useState(false);
 // sessionStorage.setItem("q2", question2);
 // sessionStorage.setItem("q3", question3);
 // sessionStorage.setItem("q4", question4);


  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }


  const navigate = useNavigate();

  useEffect(() => {
      if(sessionStorage.getItem("q2"))
    setAnswerQuestion2(sessionStorage.getItem("q2"));
    if(sessionStorage.getItem("q3"))
    setAnswerQuestion3(sessionStorage.getItem("q3"));
    if(sessionStorage.getItem("q4"))
    setAnswerQuestion4(sessionStorage.getItem("q4"));
if(sessionStorage.getItem("qsurgical"))
    setAnswerQuestion5(sessionStorage.getItem("qsurgical"));
  }, []);

  const handleChange = (event) => {
    var value = event.target.value;
    setAnswerQuestion2(value);
    sessionStorage.setItem("q2", value);
  };

  const handleChange1 = (event) => {
    var value = event.target.value;
    setAnswerQuestion3(value);
    sessionStorage.setItem("q3", value);
  };

  const handleChange2 = (event) => {
    var value = event.target.value;
    setAnswerQuestion4(value);
    sessionStorage.setItem("q4", value);
  };

  function navToPreviousPage() {
    console.log(sessionStorage.getItem("q1"));
	saveData()
    navigate("/diagnosis");
  }

  function Validation() {
    if (question2 === "" || question2 ===("null") || !question2) {
      setErrorMsg(true);
      console.log("here", errormsg)
    } else {
      setErrorMsg(false);
    }

    if (question3 === "" || question3===( "null") || !question3) {
      setErrorMsg1(true);
    } else {
      setErrorMsg1(false);
    }

    if (question4 === "" || question4===("null" )|| !question4) {
      setErrorMsg2(true);
    } else {
      setErrorMsg2(false);
    }
	saveData()

    if (question2 !== "" && question3 !== "" && question4 !== "") {
      navigate("/socialhabits");
    } else {
      setErrorMsg(true);
      setErrorMsg1(true);
      setErrorMsg2(true);
    }
  }

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setErrorMsg(false);
  };

  var typography = "Type in the correct information, in all the text boxes.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/diagnosis"}
          name={"Geriatric Consultant"}
        />
        <h1>Referral Details</h1>
        <div className="gc-container">
          <div>
            <h5>History of Present Condition:</h5>
            <TextField
              style={{ marginTop: "1em", marginBottom: "1em", width: "100%" }}
              type="text"
              value={question2}
              id="outlined-multiline-static"
              label="Input Patient's Present Condition History"
              multiline
              rows={4}
              onChange={handleChange}
             // onChange={(e) => setAnswerQuestion2(e.target.value)}
            />
          </div>

          <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg ? null : "none" }}
          >
            Please input text
          </h4>
          <br/>

          <div>
            <h5>Past Medical History:</h5>

            <TextField
              style={{ marginTop: "1em", marginBottom: "1em", width: "100%" }}
              type="text"
              id="outlined-multiline-static"
              label="Input Patient's Medical History"
              multiline
              rows={4}
              value={question3}
              onChange={handleChange1}
              //onChange={(e) => setAnswerQuestion3(e.target.value)}
            />
          </div>

          <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg1 ? null : "none" }}
          >
            Please input text
          </h4>
<br/>
          <div>
            <h5>Drug History:</h5>
            <TextField
              style={{ marginTop: "1em", width: "100%" }}
              size="large"
              type="text"
              id="outlined-multiline-static"
              label="Input Patient's Drug History"
              multiline
              rows={4}
              onChange={handleChange2}
             // onChange={(e) => setAnswerQuestion4(e.target.value)}
              value={question4}
            />
          </div>

          <br />
          <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg2 ? null : "none" }}
          >
            Please input text
          </h4>
		  <br/>
          <div>
            <h5>Surgical Procedure:</h5>
            <TextField
              style={{ marginTop: "1em", width: "100%" }}
              size="large"
              type="text"
              id="outlined-multiline-static"
              label="Input Patient's Surgical Procedure"
              multiline
              rows={4}
              onChange={(e) => {setAnswerQuestion5(e.target.value);    sessionStorage.setItem("qsurgical", e.target.value);
}}
             // onChange={(e) => setAnswerQuestion4(e.target.value)}
              value={question5}
            />
          </div>

         
        </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => Validation()}
        currentPage={2}
        numPages={21}
      ></BottomNav>
      {/* Confirm dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ padding: "1em", borderRadius: "25px" }}
      >
        <h5
          style={{
            marginTop: "1em",
            marginRight: "1em",
            marginLeft: "1em",
            marginBottom: "0.5em",
          }}
        >
          Input missing
        </h5>
        <DialogContentText
          style={{
            marginRight: "1.3em",
            marginLeft: "1.3em",
          }}
        >
          Please fill in all the fields.
        </DialogContentText>
        <div
          style={{
            padding: "1em",
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{
              paddingLeft: "2em",
              paddingRight: "2em",
              paddingTop: "2px",
              paddingBottom: "2px",
              borderRadius: "25px",
              fontFamily: "Fenix",
              textTransform: "none",
              fontSize: "16px",
            }}
            onClick={() => handleClose()}
            variant="contained"
          >
            Ok
          </Button>
        </div>
      </Dialog>
    </div>
  );
}
