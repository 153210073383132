import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section9() {
  const [score, setScore] = useState(0);
  // Handles confirm dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }
  function getScore() {
    var score = parseInt(sessionStorage.getItem("letters"));

    var scoretemp =
      parseInt(sessionStorage.getItem("sub")) +
      score +
      parseInt(sessionStorage.getItem("num"));

    sessionStorage.setItem("attention", scoretemp.toString());

    setScore(score);
  }

  function onChangeSec9Check() {
    if (document.getElementById("letters").checked == true)
      sessionStorage.setItem("letters", "1");
    else sessionStorage.setItem("letters", "0");

    getScore();
  }
  
    
  useEffect(()=>{
	  if(sessionStorage.getItem("letters") &&sessionStorage.getItem("letters")=="1"){
		  document.getElementById("letters").checked = true;
		  getScore();
	  }
	  
  }, [])

  const navigate = useNavigate();

  var typography = "Vigilance\n\nRead the list of letters at a rate of one per second, after giving the        following instruction: “I am going to read a sequence of letters. Every time I say the letter A, tap your hand once. If I say a different letter, do not tap your hand”. \n\nScoring:\nGive one point if there is zero to one errors (an error is a tap on a wrong letter or a failure to tap on letter A).\n\nClick whether you would like to allocate the point or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec8"} name={"Attention"} />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          {/*    sx={{ maxWidth: "80%", borderRadius: "20px", textAlign: "left" }}
         >
           <CardContent> */}
          <h5 style={{ marginBottom: "1.5em", alignSelf: "flex-start" }}>
            Attention
          </h5>
          <p style={{ marginBottom: "1.5em", textAlign: "center" }}>
            Read the list of letters. Patient must tap with his hand at each
            letter A. No points if more than 2 errors.
          </p>
          <br />
          {/* Sorry min qalbi LOL */}
          <h5 style={{ textAlign: "center", fontSize: "1.5em" }}>
            F &nbsp; B &nbsp; A &nbsp; C &nbsp; M &nbsp; N &nbsp; A &nbsp; A
            &nbsp; J &nbsp; K &nbsp; L &nbsp; B &nbsp; A &nbsp; F &nbsp; A
            &nbsp; K &nbsp; D &nbsp; E &nbsp; A &nbsp; A &nbsp; A &nbsp; J
            &nbsp; A &nbsp; M &nbsp; O &nbsp; F &nbsp; A &nbsp; A &nbsp; B
            &nbsp;{" "}
          </h5>
          <br />
          {/* <input
              className="check"
              type="checkbox"
              id="letters"
              onChange={onChangeSec9Check}
            />
            <label>Correct? </label>
            <label>[{score} points]</label> */}
          {/* </CardContent> */}
        </div>

        <div className="gc-container" style={{ marginTop: "1em" }}>
          <div style={{ paddingBottom: "0.5em" }}>
            <input
              className="check"
              type="checkbox"
              id="letters"
              onChange={onChangeSec9Check}
              style={{ marginLeft: "0" }}
            />
            <label>Correct </label>
          </div>

          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
nextNavigation={() => {saveData(); navigate("/sec10")}}
prevNavigation={() => {saveData(); navigate("/sec8")}}        currentPage={12}
        numPages={21}
      />


    </div>
  );
}
export default Section9;
