import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import BottomNav from "../bottomnavg";
import { saveDataSectionA as saveData } from "../firebase";

function Section10() {
  const [score, setScore] = useState(0);
  const [checkedV, setChecked] = useState([]);

  function navToNextPage() {
    saveData();
    navigate("/v2/sec11");
  }

  function getScore() {
    var score = 0;

    var one = parseInt(sessionStorage.getItem("one") || 0);
    var two = parseInt(sessionStorage.getItem("two") || 0);
    var three = parseInt(sessionStorage.getItem("three") || 0);
    var four = parseInt(sessionStorage.getItem("four") || 0);
    var five = parseInt(sessionStorage.getItem("five") || 0);

    var temp = one + two + four + three + five;
    if (temp === 5) {
      score = 3;
    } else if (temp === 4) {
      score = 3;
    } else if (temp === 3) {
      score = 2;
    } else if (temp === 2) {
      score = 2;
    } else if (temp === 1) {
      score = 1;
    } else if (temp === 0) {
      score = 0;
    }

    setScore(score);

    sessionStorage.setItem("sub", score.toString());

    var scoretemp =
      score +
      parseInt(sessionStorage.getItem("num") || 0) +
      parseInt(sessionStorage.getItem("letters") || 0);

    sessionStorage.setItem("attention", scoretemp.toString());
    sessionStorage.setItem("checkedV", checkedV.toString());
    console.log(sessionStorage.getItem("attention"));
    console.log(scoretemp);
  }

  const onChangeoneCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("one", "1");
    else sessionStorage.setItem("one", "0");
    if (value) setChecked([...checkedV, 1]);
    else setChecked([...checkedV.filter((i) => i != 1)]);
    getScore();
  };

  const onChangetwoCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("two", "1");
    else sessionStorage.setItem("two", "0");
    if (value) setChecked([...checkedV, 2]);
    else setChecked([...checkedV.filter((i) => i != 2)]);
    getScore();
  };

  const onChangethreeCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("three", "1");
    else sessionStorage.setItem("three", "0");
    if (value) setChecked([...checkedV, 3]);
    else setChecked([...checkedV.filter((i) => i != 3)]);
    getScore();
  };

  const onChangefourCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("four", "1");
    else sessionStorage.setItem("four", "0");
    if (value) setChecked([...checkedV, 4]);
    else setChecked([...checkedV.filter((i) => i != 4)]);
    getScore();
  };

  const onChangefiveCheck = (event) => {
    var value = event.target.checked;
    if (value == true) sessionStorage.setItem("five", "1");
    else sessionStorage.setItem("five", "0");
    if (value == true) setChecked([...checkedV, 5]);
    else setChecked([...checkedV.filter((i) => i != 5)]);
    console.log(checkedV, value);
    getScore();
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("checkedV") &&
      sessionStorage.getItem("checkedV") != "null"
    ) {
      setChecked(sessionStorage.getItem("checkedV").split(",").map(Number));
      if (checkedV) {
        if (checkedV.includes(1)) sessionStorage.setItem("one", "1");
        if (checkedV.includes(2)) sessionStorage.setItem("two", "1");
        if (checkedV.includes(3)) sessionStorage.setItem("three", "1");
        if (checkedV.includes(4)) sessionStorage.setItem("four", "1");
        if (checkedV.includes(5)) sessionStorage.setItem("five", "1");
      }
      getScore();
      console.log(checkedV);
    }
  }, []);

  useEffect(() => {
    if (checkedV) {
      if (checkedV.includes(1)) sessionStorage.setItem("one", "1");
      if (checkedV.includes(2)) sessionStorage.setItem("two", "1");
      if (checkedV.includes(3)) sessionStorage.setItem("three", "1");
      if (checkedV.includes(4)) sessionStorage.setItem("four", "1");
      if (checkedV.includes(5)) sessionStorage.setItem("five", "1");
    }
    getScore();
  }, [checkedV]);

  useEffect(() => {
    if (
      sessionStorage.getItem("checkedV") &&
      JSON.stringify(
        sessionStorage.getItem("checkedV").split(",").map(Number)
      ) == JSON.stringify(checkedV)
    )
      getScore();
  }, [checkedV]);
  const navigate = useNavigate();

  var typography =
    "Serial 7s\n\nInstruct the patient:\n“Now, I will ask you to count by subtracting seven from 100, and then, keep subtracting seven from your answer until I tell you to stop.” \nGive this instruction twice if necessary. \n\nScoring: \nThis item is scored out of 3 points. \nGive no (0) points for no correct subtractions, 1 point for one correction subtraction, 2 points for two-to-three correct subtractions, and 3 points if the participant successfully makes four or five correct subtractions. \nCount each correct subtraction of 7 beginning at 100. \nEach subtraction is evaluated independently; that is, if the participant    responds with an incorrect number but continues to correctly subtract 7 from it, give a point for each correct subtraction. \nFor example, a participant may respond “92 – 85 – 78 – 71 – 64” where the “92” is incorrect, but all subsequent numbers are subtracted correctly. This is one error and the item would be given a score of 3.\n\nClick whether you would like to allocate the points or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec9"} name={"Attention"} />
        <h1>MoCA Test</h1>
        <div
          className="moca-container"
          // enjoy :)
          sx={{
            borderRadius: "20px",
            textAlign: "left",
          }}
        >
          <h5 style={{ alignSelf: "flex-start", marginBottom: "1.7em" }}>
            Attention
          </h5>
          <p
            style={{
              marginBottom: "1.2em",
              textAlign: "center",
            }}
          >
            Serial 7. Patient needs to subtract 7, starting at 100.
          </p>
          <br />
          {/* Table */}
          <TableContainer style={{ padding: "1em" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell
                    style={{ fontSize: "1em", width: "15%", padding: "7px" }}
                  >
                    <b>93</b>
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "1em", width: "15%", padding: "7px" }}
                  >
                    <b>86</b>
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "1em", width: "15%", padding: "7px" }}
                  >
                    <b>79</b>
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "1em", width: "15%", padding: "7px" }}
                  >
                    <b>72</b>
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "1em", width: "15%", padding: "7px" }}
                  >
                    <b>65</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      textAlign: "center",
                      fontSize: "0.8em",
                      width: "25%",
                      padding: "7px",
                    }}
                  >
                    Score
                  </TableCell>
                  <TableCell style={{ padding: "7px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (checkedV &&
                              checkedV.length > 0 &&
                              typeof checkedV == "object" &&
                              checkedV.find((i) => i == 1) &&
                              checkedV.find((i) => i == 1) > 0) ||
                            false
                          }
                        />
                      }
                      id="one"
                      onClick={onChangeoneCheck}
                    />
                  </TableCell>
                  <TableCell style={{ padding: "7px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (checkedV &&
                              checkedV.length > 0 &&
                              typeof checkedV == "object" &&
                              checkedV.find((i) => i == 2) &&
                              checkedV.find((i) => i == 2) > 0) ||
                            false
                          }
                        />
                      }
                      id="two"
                      onClick={onChangetwoCheck}
                    />
                  </TableCell>
                  <TableCell style={{ padding: "7px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (checkedV &&
                              checkedV.length > 0 &&
                              typeof checkedV == "object" &&
                              checkedV.find((i) => i == 3) &&
                              checkedV.find((i) => i == 3) > 0) ||
                            false
                          }
                        />
                      }
                      id="three"
                      onClick={onChangethreeCheck}
                    />
                  </TableCell>
                  <TableCell style={{ padding: "7px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (checkedV &&
                              checkedV.length > 0 &&
                              typeof checkedV == "object" &&
                              checkedV.find((i) => i == 4) &&
                              checkedV.find((i) => i == 4) > 0) ||
                            false
                          }
                        />
                      }
                      id="four"
                      onClick={onChangefourCheck}
                    />
                  </TableCell>
                  <TableCell style={{ padding: "7px" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            (checkedV &&
                              checkedV.length > 0 &&
                              typeof checkedV == "object" &&
                              checkedV.find((i) => i == 5) &&
                              checkedV.find((i) => i == 5) > 0) ||
                            false
                          }
                        />
                      }
                      id="five"
                      onClick={onChangefiveCheck}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => {
          sessionStorage.setItem("checkedV", checkedV.toString());
          navToNextPage();
        }}
        prevNavigation={() => {
          saveData();
          navigate("/v2/sec9");
        }}
        currentPage={12}
        numPages={19}
      />
    </div>
  );
}
export default Section10;
