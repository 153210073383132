import "../styles/CommonStyle.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import Header from "../header";
import { saveTeam2 } from "../firebase";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import BottomNav from "../bottomnavg";
import ScrollBtn from "../ScrollBtn";
import CsvDownloadButton from 'react-json-to-csv'
import ReactToPdf from "react-to-pdf"
import JsPDF from 'jspdf';

const Summary = () => {
  var typography =
    "Confirm your assessment findings. \nEdit from side edit buttons as necessary. \nConfirm and submit your assessment before exiting. ";
  let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);
	const ref =React.createRef();
  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }

  console.log(ActualObjectLeftResult + " Left a")
  console.log(ActualObjectRightResult + " Right a")

  const current = new Date();
  const date = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()} at ${current.getHours()}:${current.getMinutes()}`;
  const dateString = date.toString();

  const navigate = useNavigate();
  const location = useLocation();

  const [ttdisplay, setttdisplay] = useState(false);

  let lastGripResults = null;
  let lastTUGResults = null;
  let PreviousResult = sessionStorage.getItem("PreviousResult");
  let ActualPreviousResult = JSON.parse(PreviousResult);

  const [open, setOpen] = useState(false);
  const navigation = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };
  
  const generatePDF = () => {

    const report = new JsPDF('landscape','pt','a4');
    report.html(document.querySelector('#report')).then(() => {
        report.save('PhysiotherapistSummary.pdf');
    });
  }

  function validateForm() {
    console.log("Results are to be submitted after the SUBMIT is pressed");
    console.log(sessionStorage.getItem("TUGStatus"));
    console.log(sessionStorage.getItem("question1"));
    var patientId = sessionStorage.getItem("patientId");
    console.log("Array:", dataArray);
    saveTeam2(patientId, dateString, dataArray);
    navigation("/PatientView");
  }

  let finalLeft = null;
  let finalRight = null;
  let actualPreviousLeft = null;
  let actualPreviousRight = null;

  if (ActualPreviousResult != null) {
  }

  useEffect(() => {
    if (sessionStorage.getItem("TUGTestCarriedOut") == "Yes") {
      setttdisplay(true);
    } else {
      setttdisplay(false);
    }
    try {
      finalLeft = returnGripStrenghtRiskLeft(finalLeft, ActualObjectLeftResult);
      finalRight = returnGripStrenghtRiskLeft(
        finalRight,
        ActualObjectRightResult
      );

      renderPage();
      document.getElementById("finalLeft").innerHTML = finalLeft;
      document.getElementById("finalRight").innerHTML = finalRight;

      if (ActualPreviousResult != null) {
        actualPreviousLeft = returnGripStrenghtRiskLeft(
          actualPreviousLeft,
          ActualPreviousResult.GripStrengthResults.MaxLeftHandResult
        );
        actualPreviousRight = returnGripStrenghtRiskLeft(
          actualPreviousRight,
          ActualPreviousResult.GripStrengthResults.MaxRightHandResult
        );
        document.getElementById("actualPreviousLeft").innerHTML =
          actualPreviousLeft;
        document.getElementById("actualPreviousRight").innerHTML =
          actualPreviousRight;
      }
    } catch {}
  });

  // Calculated risk after Grip test (Low/medium/high risk)
  function returnGripStrenghtRiskLeft(thisHand, ActualObjectHandResult) {
    if (ActualObjectHandResult != null) {
      if (
        typeof ActualObjectHandResult.Risk === "object" &&
        !Array.isArray(ActualObjectHandResult.Risk) &&
        ActualObjectHandResult.Risk !== null
      ) {
        for (var k in ActualObjectHandResult.Risk) {
          //   thisHand = `
          //     <p className="par">
          //       ${k} Verdict: <b>${ActualObjectHandResult.Risk[k]}</b>
          //     </p>
          // `;
          thisHand = `
          ${k}${ActualObjectHandResult.Risk[k]}
        `;
        }
      } else {
        //   thisHand = `
        //     <p className="par">Verdict: <b>${ActualObjectHandResult.Risk}</b>
        //     </p>
        // `;
        thisHand = `
          ${ActualObjectHandResult.Risk}
      `;
      }
    } else {
      ActualObjectHandResult = { TestResult: "" };
    }

    return thisHand;
  }

  let pageRender = "";
  function renderPage() {
    let count = null;

    if (ActualPreviousResult == null) {
      pageRender = `
  <div>
  
  <div className="physio-summary-container">
  test
  </div>
  <h5>Mobility</h5> </br> 
    <p className="par">Previous Level: </p>
	<p> Independent: <b>${sessionStorage.getItem(
      "TUGQuestion1"
    )}</b></p>
    <p className="par">Current Level: <b>${sessionStorage.getItem(
      "TUGQuestion2"
    )}</b></p>
    
    </br>

    <h5>TUG</h5> </br>

    <p className="par">Time Taken: <b>${sessionStorage.getItem(
      "TUGTimer"
    )} seconds</b></p>
    <p className="par">Status: <b>${sessionStorage.getItem(
      "TUGStatus"
    )}</b></li></p>
    <p className="par">Carried Out?: <b>${sessionStorage.getItem(
      "TUGTestCarriedOut"
    )}</b></p>
        
    </br>

    <h5>Grip Strength test</h5> </br> 

    <p className="par">Dominant Hand: <b>${sessionStorage.getItem(
      "question1"
    )}</b></p> 
   
    </br>

    <p className="par">Left Hand: <b>${
      ActualObjectLeftResult.TestResult
    }</b></p>
    <div id="finalLeft"></div>

    <p className="par">Reason: <b>${sessionStorage.getItem("question4")}</b></p>
    </br>

    <p className="par">Right Hand: <b>${
      ActualObjectRightResult.TestResult
    }</b></p>
    <div id="finalRight"></div>
      
    <p className="par">Reason: <b>${sessionStorage.getItem(
      "question5"
    )}</b></li>
  </div>`;
    } else {
      pageRender = `
  <table style="border-collapse: collapse, text-align: center;">
  <tbody style="align-items: center, align-content: center;">

    <tr>
      <th><label class="subtitle">Previous Session</label></th>
      <th><label class="subtitle">Current Session</label></th>
    </tr>

    <tr>
      <th colspan="2"> </br> </br> <label class="subtitle">Session Conducted at</label> </th>
    </tr>
    
    <tr>
      <td style="border: 2px solid black">
        <label class="subtitle"><center>${
          ActualPreviousResult.DateofSession
        }</center></label>
      </td>

      <td style="border: 2px solid black">
        <label class="subtitle"><center>${dateString}</center></label>
      </td>
    </tr>

    <tr>
      <th colspan="2"> </br> </br> <label class="subtitle">Levels of Mobility</label> </th>
    </tr>

    <tr>
      <td style="border: 2px solid black">
        <p>Previous Level: <b>${
          ActualPreviousResult.TUGTestResults.LevelsOfMobility
            .PreviousLevelofMobility
        }</b></p>
      </td>

      <td style="border: 2px solid black">
        <p>Previous Level: <b>${sessionStorage.getItem("TUGQuestion1")}</b></p>
      </td>
    </tr>

    <tr>
      <td style="border: 2px solid black">
      <p>Current Level: <b>${
        ActualPreviousResult.TUGTestResults.LevelsOfMobility
          .CurrentLevelofMobility
      }</b></p>
      </td>

      <td style="border: 2px solid black">
        <p>Current Level: <b>${sessionStorage.getItem("TUGQuestion2")}</b></p>
      </td>
    </tr>

    <tr>
      <th colspan="2"> </br> </br> <label class="subtitle">Timed Up and Go Test</label> </th>
    </tr>

    <tr>
      <td style="border: 2px solid black">
          <p>Time Taken: <b>${
            ActualPreviousResult.TUGTestResults.RiskOfFallStatus
              .TimeTakenInSeconds
          } seconds</b></p>
          <p>Status: <b>${
            ActualPreviousResult.TUGTestResults.RiskOfFallStatus.Status
          }</b></p>
          <p>Carried Out?: <b>${
            ActualPreviousResult.TUGTestResults.TUGTestCarriedOut
          }</b></p>
      </td>

      <td style="border: 2px solid black">
        <p>Time Taken: <b>${sessionStorage.getItem("TUGTimer")} seconds</b></p>
        <p>Status: <b>${sessionStorage.getItem("TUGStatus")}</b></p>
        <p>Carried Out?: <b>${sessionStorage.getItem(
          "TUGTestCarriedOut"
        )}</b></p>
      </td>
    </tr>

  <tr>
    <th colspan="2">  </br>  </br> <label class="subtitle">Grip Strength test</label> </th>
  </tr>

  <tr>
    <td style="border: 2px solid black">
      <p>Dominant Hand: <b>${
        ActualPreviousResult.GripStrengthResults.Question1
      }</b></p>
    </td>

    <td style="border: 2px solid black">
      <p>Dominant Hand: <b>${sessionStorage.getItem("question1")}</b></p> 
    </td>
  </tr>

  <tr>
    <td style="border: 2px solid black">
      <p>Left Hand: <b>${
        ActualPreviousResult.GripStrengthResults.MaxLeftHandResult.TestResult
      }</b></p>
      <div id="actualPreviousLeft"></div>
      <p>Reason: <b>${
        ActualPreviousResult.GripStrengthResults.Question4
      }</b></p>
    </td>

    <td style="border: 2px solid black;">
      <p>Left Hand: <b>${ActualObjectLeftResult.TestResult}</b></p>
      <div id="finalLeft"></div>
      <p>Reason: <b>${sessionStorage.getItem("question4")}</b></p>
    </td>
  </tr>

  <tr>    
    <td style="border: 2px solid black">
      <p>Right Hand: <b>${
        ActualPreviousResult.GripStrengthResults.MaxRightHandResult.TestResult
      }</b></p>
      <div id="actualPreviousRight"></div>
      <p>Reason: <b>${
        ActualPreviousResult.GripStrengthResults.Question5
      }</b></p>
    </td>

    <td style="border: 2px solid black">
      <p>Right Hand: <b>${ActualObjectRightResult.TestResult}</b></p>
      <div id="finalRight"></div>
      <p>Reason: <b>${sessionStorage.getItem("question5")}</b></p>
    </td>
  </tr>

  </tbody>
</table>
  `;
    }

    document.getElementById("pageRender").innerHTML = pageRender;
  }

  const goBack = () => {
    navigate("/GripStrength4");
  };

  const dataArray = {
    date: dateString,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
  };

  return (
  <div>
    <div className="screen" style={{ justifyContent: "space-between" }} >
			
      <div className="screen" style={{ width: "100%", minHeight: "auto" }} ref={ref}>
        <Header
          typography={typography}
          history={"/GripStrength4"}
          name={"Physiotherapist Summary"}
        />
        <h1>Summary</h1>
        {/* IF ActualPreviousResult == null then do this */}
        {/* Mobility box */}
				<div id="report" style={{width:"70%"}}>

        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Mobility Level</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/LevelsofMobility", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="physio-table-left-column">Previous: </td>
             
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Independent: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("TUGQuestion1")}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Dependent: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("mobilityQuestion11")}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column">Current:</td>
             
            </tr>
            <tr>
              <td className="physio-table-left-column"  style={{paddingLeft:20}}>  Independent: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("TUGQuestion2")}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Dependent: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("mobilityQuestion22")}
              </td>
            </tr>
          </table>
        </div>
		
		
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Current Mobility</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/CurrentMobility", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="physio-table-left-column">UL: </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Right: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("ulRight")}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Left: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("ulLeft")}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column">LL:</td>
             
            </tr>
            <tr>
              <td className="physio-table-left-column"  style={{paddingLeft:20}}>  Right: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("llRight")}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Left: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("llLeft")}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"  >  Bed Mobility: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("bedmobility")}
              </td>
            </tr>
						 <tr>
              <td className="physio-table-left-column">Transfers:</td>
             
            </tr>
            <tr>
              <td className="physio-table-left-column"  style={{paddingLeft:20}}>  Lying - Sitting: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("transfers").split(",")[0]}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Sitting - Standing: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("transfers").split(",")[1]}
              </td>
            </tr>
			            <tr>
              <td className="physio-table-left-column"  style={{paddingLeft:20}}>  Standing - Sitting: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("transfers").split(",")[2]}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"   style={{paddingLeft:20}}>  Sitting - Lying: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("transfers").split(",")[3]}
              </td>
            </tr>
			 <tr>
              <td className="physio-table-left-column"  >  Other comments: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("freeText")}
              </td>
            </tr>
          </table>
        </div>
		
		

        {/* TUG box */}
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Timed Up and Go Test</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  {/* <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/ListOfEquipment", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button> */}
                </td>
              )}
            </tr>
            <tr>
              <td className="physio-table-left-column">Test Carried Out: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("TUGTestCarriedOut")}
              </td>
            </tr>
            <tr style={{ display: ttdisplay ? null : "none" }}>
              <td className="physio-table-left-column">Time Taken: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("TUGTimer")} seconds
              </td>
            </tr>
            <tr style={{ display: ttdisplay ? null : "none" }}>
              <td className="physio-table-left-column">Risk of Fall: </td>
              <td className="physio-table-right-column">
                {sessionStorage.getItem("TUGStatus")}
              </td>
            </tr>
          </table>
        </div>

        {/* HUG (Grip strength)box */}
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto"}}>
          <table>
            <tr>
              <td colSpan="2">
                <h3 style={{ marginBottom: "12px" }}>
                  Hand Grip Strength Test
                </h3>
              </td>
              <td></td>
              {!(location.state && location.state.summary) && (
                <td>
                  {/* <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/GripStrength2", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button> */}
                </td>
              )}
            </tr>
            <tr>
              <td
                className="physio-table-left-column"
                style={{ paddingBottom: "12px" }}
              >
                Dominant Hand:{" "}
              </td>
              <td style={{ width: "0" }}></td>
              <td
                colSpan="2"
                className="physio-table-right-column"
                style={{ paddingBottom: "12px" }}
              >
                {sessionStorage.getItem("question1")}
              </td>
            </tr>
            {/* Results for left hand */}
            <tr>
              <td className="physio-table-left-column">Left Hand:</td>
            </tr>
            <tr>
              <td
                className="physio-table-left-column-indent"
                style={{ paddingLeft: "1em" }}
              >
                Test Carried Out:
              </td>
              <td style={{ width: "0" }}></td>
              <td colSpan={2} className="physio-table-right-column">
                {sessionStorage.getItem("question4")}
              </td>
            </tr>
            <tr>
              <td className="physio-table-left-column-indent">Test Result:</td>
              <td style={{ width: "0" }}></td>
              <td colSpan={2} className="physio-table-right-column">
                {ActualObjectLeftResult && ActualObjectLeftResult.TestResult}
              </td>
            </tr>
            <tr>
              <td
                className="physio-table-left-column-indent"
                style={{ paddingBottom: "12px" }}
              >
                Strength:
              </td>
              <td style={{ width: "0" }}></td>
              <td
                colSpan={2}
                className="physio-table-right-column"
                style={{ paddingBottom: "12px" }}
                id="finalLeft"
              >
                {sessionStorage.getItem("LeftHandResultStrength")}
              </td>
              {/* CHANGE TO STRENGTH LEFT */}
            </tr>
            {/* Results for right hand */}
            <tr>
              <td className="physio-table-left-column">Right Hand:</td>
            </tr>
            <tr>
              <td className="physio-table-left-column-indent">
                Test Carried Out:{" "}
              </td>
              <td style={{ width: "0" }}></td>
              <td colSpan={2} className="physio-table-right-column">
                {sessionStorage.getItem("question5")}
              </td>
            </tr>
            <tr>
              <td className="physio-table-left-column-indent">Test Result: </td>
              <td style={{ width: "0" }}></td>
              <td colSpan={2} className="physio-table-right-column">
                {ActualObjectRightResult && ActualObjectRightResult.TestResult}
              </td>
            </tr>
            {/* Level of risk for right hand */}
            <tr>
              <td className="physio-table-left-column-indent">Strength:</td>
              <td style={{ width: "0" }}></td>
              <td
                colSpan={2}
                className="physio-table-right-column"
                id="finalRight"
              >
                {sessionStorage.getItem("RightHandResultStrength")}
              </td>
              {/* CHANGE TO STRENGTH Right */}
            </tr>
          </table>
        </div>
        <div className="summary-container" style={{ width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Additional Info</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/AdditionalinfoPhysio", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
          </table>
          <div>{sessionStorage.getItem("PhysioAdditionalInfo")}</div>
        </div>
		

        {/* Old page */}
        {/* <div className="pageRenderer">
        <div id="pageRender" justifyContent="center" alignItems="center"></div>
      </div> */}
        {/* <button
        className="next-button"
        onClick={() => {
          setOpen(true);
        }}
      >
        Submit
      </button> */}
        {/* Old dialog */}
        {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are sure you want to save and exit?
        </DialogTitle>
        <Button
          style={{ m: 10, fontSize: "20px" }}
          onClick={() => {
            validateForm();
          }}
        >
          Yes
        </Button>
        <Button
          style={{ m: 10 }}
          onClick={() => {
            handleClose();
          }}
        ></Button>
        <Button
          style={{ m: 10, fontSize: "20px" }}
          onClick={() => {
            handleClose();
          }}
        >
          No
        </Button>
      </Dialog> */}
        {/* new dialog  */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: "1em", borderRadius: "25px" }}
        >
          <h5
            style={{
              marginTop: "1em",
              marginRight: "1em",
              marginLeft: "1em",
              marginBottom: "0.5em",
            }}
          >
            Submit?
          </h5>
          <DialogContentText
            style={{
              marginRight: "1.3em",
              marginLeft: "1.3em",
            }}
          >
            This will save the data and end the assessment.
          </DialogContentText>
          <div
            style={{
              padding: "1em",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleClose()}
              variant="contained"
            >
              No
            </Button>

            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => validateForm()}
              variant="contained"
            >
              Yes
            </Button>
          </div>
        </Dialog>
      </div>
	  		  <button onClick={generatePDF} style={{borderColor:"#015893", backgroundColor:"rgb(50, 201, 83)", borderRadius: 10, fontSize: "large"}} type="button">Export PDF</button>

      </div>
      <ScrollBtn />
      <BottomNav
        prevNavigation={() => navigation("/AdditionalinfoPhysio")}
        submitButton={() => setOpen(true)}
        currentPage={9}
        numPages={9}
      />
    </div>


    </div>
  );
};

export default Summary;
