import React, { useState, useEffect } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section4() {
  const [score, setScore] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const [comment, setComment] = useState("");

  function navToNextPage() {
    sessionStorage.setItem("MOCASec4Comment", comment);
	saveData()
    navigate("/sec5");
  }
  
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  function getScore() {
    var score = parseInt(sessionStorage.getItem("cube"));
    setScore(score);
    var scoretemp =
      score +
      +parseInt(sessionStorage.getItem("clock1")) +
      parseInt(sessionStorage.getItem("clock2")) +
      parseInt(sessionStorage.getItem("clock3")) +
      parseInt(sessionStorage.getItem("pattern"));
    sessionStorage.setItem("visuo", scoretemp.toString());
  }

  function onChangePatternCheck() {
    if (document.getElementById("cube").checked == true) {
      sessionStorage.setItem("cube", "1");
    } else sessionStorage.setItem("cube", "0");
    getScore();
  }

  useEffect(() => {
	  if(sessionStorage.getItem("MOCASec4Comment") && sessionStorage.getItem("MOCASec4Comment") !="null")
    setComment(sessionStorage.getItem("MOCASec4Comment"));
  if(sessionStorage.getItem("cube") && sessionStorage.getItem("cube")=="1"){
  document.getElementById("cube").checked=true;
getScore()}
  }, []);

  var typography = "Visuoconstructional Skills (Cube) \n\nInstruct the patient: \n“Copy this drawing as accurately as you can, in the space below”. \n\nScoring:\nOne point is allocated for a correctly executed drawing. \nDrawing must be:\n•	Three-dimensional. \n•	All lines are drawn. \n•	No line is added.\n•	Lines are relatively parallel and their length is similar (rectangular prisms are accepted).\n\nA point is not assigned if any of the above criteria are not met.\nComment on the picture drawn. \nClick whether you would like to allocate the point or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/sec3"}
          name={"Visuospatial/Executive"}
        ></Header>
        <h1>MoCA Test</h1>
        <div className="moca-container">
          <h5 style={{ marginBottom: "1em", alignSelf: "flex-start" }}>
            Visuospatial / Executive
          </h5>
          <img
            style={{ width: "40%", marginTop: "0.5em" }}
            src="cube_small.png"
          ></img>
        </div>
        {/* Comment box */}
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <h5 style={{ marginBottom: "1em" }}>Comments</h5>
          <TextField
            multiline="true"
            rows="4"
            style={{ width: "100%" }}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter additional comments (optional)"
            value={comment}
            defaultValue={comment}
          ></TextField>
        </div>

        <div
          className="gc-container"
          style={{ paddingTop: "1em", paddingBottom: "1em", marginTop: "1em" }}
        >
          <div style={{ paddingBottom: "0.5em" }}>
            <input
              className="check"
              type="checkbox"
              id="cube"
              onChange={onChangePatternCheck}
              style={{ marginLeft: "0" }}
            />
            <label>Correct </label>
          </div>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
        prevNavigation={() => {saveData(); navigate("/sec3")}}
        currentPage={7}
        numPages={21}
      />
     
    </div>
  );
}

export default Section4;
