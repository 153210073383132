import { Dialog, Button } from "@mui/material";

export default function NoDataDialog({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ padding: "1em", borderRadius: "25px" }}
    >
      <h5
        style={{
          marginTop: "1em",
          marginRight: "1em",
          marginLeft: "1em",
          marginBottom: "0.5em",
        }}
      >
        No Assessment has been done yet
      </h5>
      <div
        style={{
          padding: "1em",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          style={{
            paddingLeft: "2em",
            paddingRight: "2em",
            paddingTop: "2px",
            paddingBottom: "2px",
            borderRadius: "25px",
            fontFamily: "Fenix",
            textTransform: "none",
            fontSize: "16px",
          }}
          onClick={() => setOpen(false)}
          variant="contained"
        >
          Okay
        </Button>
      </div>
    </Dialog>
  );
}
