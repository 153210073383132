import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../../header";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { fontSize } from "@mui/system";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import "../../styles/Team1OT.css";
import { saveOccupational } from "../../firebase";
import { format } from "date-fns";
import BottomNav from "../../bottomnavg";
import ScrollBtn from "../../ScrollBtn";
import { TextIncrease } from "@mui/icons-material";
import CsvDownloadButton from 'react-json-to-csv'
import JsPDF from 'jspdf';

function Section6() {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    saveOccupational(alldata);
    navigate("/PatientView");
  };

  const refresh = () => {
    setOpen(true);
    console.log(open);
  }; 

  const [a, setA] = useState("");
  const [a1, setA1] = useState("");
  const [a2, setA2] = useState("");
  const [a3, setA3] = useState("");
  const [a4, setA4] = useState("");
  const [a5, setA5] = useState("");
  const [a6, setA6] = useState("");
  const [a7, setA7] = useState("");
  const [a8, setA8] = useState("");
  const [a9, setA9] = useState("");
  const [a10, setA10] = useState("");
  const [c1, setC1] = useState("");
  const [c2, setC2] = useState("");
  const [c3, setC3] = useState("");
  const [c4, setC4] = useState("");
  const [c5, setC5] = useState("");
  const [a11, setA11] = useState("");
  const [a12, setA12] = useState("");
  const [a13, setA13] = useState("");
  const [a14, setA14] = useState("");
  const [a15, setA15] = useState("");
  const [a16, setA16] = useState("");
  const [a17, setA17] = useState("");
  const [a18, setA18] = useState("");
  const [a19, setA19] = useState("");
  const [b19, setB19] = useState("");
  const [a20, setA20] = useState("");
  const [a21, setA21] = useState("");
  const [a22, setA22] = useState("");
  
  const [a111, setA111] = useState("");
  const [a121, setA121] = useState("");
  const [a131, setA131] = useState("");
  const [a141, setA141] = useState("");
  const [a151, setA151] = useState("");
  const [a161, setA161] = useState("");
  const [a171, setA171] = useState("");
  const [a181, setA181] = useState("");
  
  const [c111, setC111] = useState("");
  const [c121, setC121] = useState("");
  const [c131, setC131] = useState("");
  const [c141, setC141] = useState("");
  const [c151, setC151] = useState("");
  const [c161, setC161] = useState("");
  const [c171, setC171] = useState("");
  const [c181, setC181] = useState("");
 
   const [bowelsPrevious, setBowelsPrevious] = React.useState("");
  const [bowelscurrent, setBowelsCurrent] = React.useState("");
  const [bowelscomment, setBowelsComment] = React.useState("");
  
  const [bladderprevious, setBladderPrevious] = React.useState("");
  const [bladdercurrent, setBladderCurrent] = React.useState("");
  const [bladdercomment, setBladderComment] = React.useState("");
  //const [tsdisplay, setsdisplay] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
    const [answerleisure, setAnswerleisure] = useState("");
  const [answeremployment, setAnsweremployment] = useState("");
  const [answerdriving, setAnswerDriving] = useState("");


  // This function will called only once
  useEffect(() => {
    loadDataOnlyOnce();

    console.log(a19)
    // if (sessionStorage.getItem("a19").toLowerCase() == "yes") {
    //   setB19(true);
    // } else {
    //   setB19("");
    // }
    if(sessionStorage.getItem("a19") == "No"){
      setB19("");
    }
  }, []);

  function loadDataOnlyOnce() {
    setA(sessionStorage.getItem("a"));
    setA1(sessionStorage.getItem("a1"));
    setA2(sessionStorage.getItem("a2"));
    setA3(sessionStorage.getItem("a3"));
    setA4(sessionStorage.getItem("a4"));
    setA5(sessionStorage.getItem("a5"));
    setA6(sessionStorage.getItem("b1"));
    setA7(sessionStorage.getItem("b2"));
    setA8(sessionStorage.getItem("b3"));
    setA9(sessionStorage.getItem("b4"));
    setA10(sessionStorage.getItem("b5"));
    setC1(sessionStorage.getItem("c1"));
    setC2(sessionStorage.getItem("c2"));
    setC3(sessionStorage.getItem("c3"));
    setC4(sessionStorage.getItem("c4"));
    setC5(sessionStorage.getItem("c5"));
    setA11(sessionStorage.getItem("a11"));
    setA12(sessionStorage.getItem("a12"));
    setA13(sessionStorage.getItem("a13"));
    setA14(sessionStorage.getItem("a14"));
    setA15(sessionStorage.getItem("a15"));
    setA16(sessionStorage.getItem("a16"));
    setA17(sessionStorage.getItem("a17"));
    setA18(sessionStorage.getItem("a18"));
    setA19(sessionStorage.getItem("a19"));
    setB19(sessionStorage.getItem("b19"));
    setA20(sessionStorage.getItem("a20"));
    setA21(sessionStorage.getItem("a21"));
    setA22(sessionStorage.getItem("OTAdditionalInfo"));
 
	 setA111(sessionStorage.getItem("a111"));
    setA121(sessionStorage.getItem("a122"));
    setA131(sessionStorage.getItem("a133"));
    setA141(sessionStorage.getItem("a144"));
    setA151(sessionStorage.getItem("a155"));
    setA161(sessionStorage.getItem("a166"));
    setA171(sessionStorage.getItem("a177"));
    setA181(sessionStorage.getItem("a188"));
	
	setC111(sessionStorage.getItem("a11c"));
    setC121(sessionStorage.getItem("a12c"));
    setC131(sessionStorage.getItem("a13c"));
    setC141(sessionStorage.getItem("a14c"));
    setC151(sessionStorage.getItem("a15c"));
    setC161(sessionStorage.getItem("a16c"));
    setC171(sessionStorage.getItem("a17c"));
    setC181(sessionStorage.getItem("a18c"));
	
	
	
	setBowelsComment(sessionStorage.getItem("cc1")||"")
	setBowelsCurrent(sessionStorage.getItem("bc1")||"")
	setBowelsPrevious(sessionStorage.getItem("ac1")||"")
	
	setBladderComment(sessionStorage.getItem("cc2")||"")
	setBladderCurrent(sessionStorage.getItem("bc2")||"")
	setBladderPrevious(sessionStorage.getItem("ac2")||"")
	
	  setAnswerDriving(sessionStorage.getItem("a21")||"")
    setAnsweremployment(sessionStorage.getItem("a20")||"")
    setAnswerleisure(sessionStorage.getItem("a21l")||"")
	   console.log(sessionStorage.getItem("b19"));
    console.log(b19 +" Answer Changes ")
	
  }

  var typography =
    "Confirm your assessment findings. \nEdit from side edit buttons as necessary. \nConfirm and submit your assessment before exiting. ";

  // Personal ADLs
  function createData(type, previous, current, comment) {
    return { type, previous, current, comment };
  }

  function formatADLs(text) {
    let newformat = text;
    if (text == "Semi-dependent") newformat = "SD";
    else if (text == "Independent") newformat = "I";
    else if (text == "Dependent") newformat = "D";

    return newformat;
  }

  console.log(a1, a6);
  const rows = [
    createData("Feeding", formatADLs(a1), formatADLs(a6), c1),
    createData("Toileting", formatADLs(a2), formatADLs(a7), c2),
    createData("Bathing", formatADLs(a3), formatADLs(a8), c3),
    createData("Grooming", formatADLs(a4), formatADLs(a9), c4),
    createData("Dressing", formatADLs(a5), formatADLs(a10), c5),
  ];
  
    const rowsc = [
    createData("Bowels", bowelsPrevious, bowelscurrent, bowelscomment),
    createData("Bladder", bladderprevious, bladdercurrent, bladdercomment),
   
  ];

  // Intrumental ADLs
  function createData2(type, previous) {
    return { type, previous };
  }
  console.log(a111, c111)
  const rows2 = [
    createData("Using the Telephone", a11, (a111), c111),
    createData("Shopping", a12, a121, c121),
    createData("Preparing Food", a13, a131, c131),
    createData("Housekeeping", a14, a141, c141),
    createData("Doing Laundry", a15, a151, c151),
    createData("Transportation", a16, a161, c161),
    createData("Handling Medication", a17, a171, c171),
    createData("Handling Finances", a18, a181, c181),
  ];

  // Other
  function createData3(type, previous) {
    return { type, previous };
  }
  const rows3 = [
    createData3("", b19),
    createData3("Housebound", a),
    createData3("Driving", answerdriving),
    createData3("Employment", answeremployment),
    createData3("Leisure Activity", answerleisure),
  ];

  const date = format(new Date(), "dd-MM-yyyy HH:mm:ss");
  const alldata = {
    housebound: a,
    feedingP: a1,
    toiletP: a2,
    bathP: a3,
    groomP: a4,
    dressP: a5,
    feedingC: a6,
    toiletC: a7,
    bathC: a8,
    groomC: a9,
    dressC: a10,
    feedingCom: c1,
    toiletCom: c2,
    bathCom: c3,
    groomCom: c4,
    dressCom: c5,
    telephone: a11,
    shopping: a12,
    food: a13,
    housekeeping: a14,
    laundry: a15,
    transportation: a16,
    medication: a17,
    finances: a18,
    changesinADL: a19,
    changesinADLcomm: b19,
    //To Check (26.08.22)
    addInfo: a22,

	bowelComment: (sessionStorage.getItem("cc1")||""),
	bowelCurrent:(sessionStorage.getItem("bc1")||""),
	bowelPrevious:(sessionStorage.getItem("ac1")||""),
	
	bladderComment:(sessionStorage.getItem("cc2")||""),
	bladderCurrent:(sessionStorage.getItem("bc2")||""),
	bladderPrevious:(sessionStorage.getItem("ac2")||""),
	
	  driving:(sessionStorage.getItem("a21")||""),
    employment:(sessionStorage.getItem("a20")||""),
    leisure:(sessionStorage.getItem("a21l")||""),

  };
  
    
  const generatePDF = () => {

    const report = new JsPDF('landscape','pt','a4');
    report.html(document.querySelector('#report')).then(() => {
        report.save('OccupationalTherapistSummary.pdf');
    });
  }
  
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/AdditionalinfoOT"}
          name={"Occupational Therapy Summary"}
        />
        <h1>Summary</h1>
				<div id="report" style={{width:"70%"}}>

        {/* "Personal ADLs" box */}
        <div className="summary-container" style={{ marginBottom: "1em", width:"auto" }}>
          <table>
            <tr>
              <td colspan="2">
                <h3 style={{ marginBottom: "15px" }}>Personal ADLs</h3>
              </td>
              <td></td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "15px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/Section2OT", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td align="right">
                <h5 style={{ marginBottom: "8px", width: "15%" }}></h5>
              </td>
              <td align="left">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "20%",
                    paddingLeft: "1em",
                  }}
                >
                  Previous
                </h5>
              </td>
              <td align="left">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "20%",
                    paddingLeft: "1em",
                  }}
                >
                  Current
                </h5>
              </td>
              <td align="center">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "30%",
                    paddingLeft: "1em",
                  }}
                >
                  Comments
                </h5>
              </td>
            </tr>
            {rows.map((row) => (
              <tr key={row.name}>
                <td scope="row" style={{ width: "15%" }}>
                  {row.type}
                </td>
                <td scope="row" align="center" style={{ width: "20%" }}>
                  {row.previous}
                </td>
                <td scope="row" align="center" style={{ width: "20%" }}>
                  {row.current}
                </td>
                <td scope="row" align="right" style={{ width: "45%" }}>
                  {row.comment}
                </td>
              </tr>
            ))}
			
			 <tr >
              <td colspan="3">
                <h3 style={{ marginBottom: "15px", paddingTop:15 }}>Continence level </h3>
              </td>

            </tr>
            <tr>
              <td align="right">
                <h5 style={{ marginBottom: "8px", width: "15%" }}></h5>
              </td>
              <td align="left">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "20%",
                    paddingLeft: "1em",
                  }}
                >
                  Previous
                </h5>
              </td>
              <td align="left">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "20%",
                    paddingLeft: "1em",
                  }}
                >
                  Current
                </h5>
              </td>
              <td align="center">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "30%",
                    paddingLeft: "1em",
                  }}
                >
                  Comments
                </h5>
              </td>
            </tr>
            {rowsc.map((row) => (
              <tr key={row.name}>
                <td scope="row" style={{ width: "15%" }}>
                  {row.type}
                </td>
                <td scope="row" align="center" style={{ width: "20%" }}>
                  {row.previous}
                </td>
                <td scope="row" align="center" style={{ width: "20%" }}>
                  {row.current}
                </td>
                <td scope="row" align="right" style={{ width: "45%" }}>
                  {row.comment}
                </td>
              </tr>
            ))}
          </table>
        </div>
        {/* "Previous Instrumental ADLs" box*/}
        <div className="summary-container" style={{ marginBottom: "1em", width:"auto" }}>
          <table>
            <tr>
			  <td colspan="2">
                <h3 style={{ marginBottom: "15px" }}>
                  Instrumental ADLs
                </h3>
              </td>
              <td></td>
                
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "15px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/Section4OT", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
			 <tr>
              <td align="right">
                <h5 style={{ marginBottom: "8px", width: "15%" }}></h5>
              </td>
              <td align="left">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "20%",
                    paddingLeft: "1em",
                  }}
                >
                  Previous
                </h5>
              </td>
              <td align="left">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "20%",
                    paddingLeft: "1em",
                  }}
                >
                  Current
                </h5>
              </td>
              <td align="center">
                <h5
                  style={{
                    marginBottom: "8px",
                    width: "30%",
                    paddingLeft: "1em",
                  }}
                >
                  Comments
                </h5>
              </td>
            </tr>
            {rows2.map((row) => (
              <tr key={row.name}>
                <td scope="row" style={{ width: "15%" }}>
                  {row.type}
                </td>
                <td scope="row" align="center" style={{ width: "20%" }}>
                  {row.previous}
                </td>
                <td scope="row" align="center" style={{ width: "20%" }}>
                  {row.current}
                </td>
                <td scope="row" align="right" style={{ width: "45%" }}>
                  {row.comment}
                </td>
              </tr>
            ))}

          </table>
        </div>
        {/* "Other" box */}
			{console.log(rows3)}
        <div className="summary-container" style={{ marginBottom: "1em", width:"auto" }}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "8px" }}>Other</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/Section5OT", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    
                    Edit
                  </Button>
                </td>
              )}
            </tr>

            {/* <tb> */}
            {rows3.map((row2) => (
              <tr key={row2.name}>
                <td scope="row">{row2.type}</td>
                <td scope="row" align="right">
                  {row2.previous}
                </td>
              </tr>
            ))}
            {/* </tb> */}
          </table>
        </div>
        {/* "Additional Info" box */}
        <div className="summary-container"  style={{width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Additional Info</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/AdditionalinfoOT", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
          </table>
          <div className="summary-additional-info">
            {sessionStorage.getItem("OTAdditionalInfo")}
          </div>
        </div>

        {/* Confirmation dialog */}
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: "1em", borderRadius: "25px" }}
        >
          <h5
            style={{
              marginTop: "1em",
              marginRight: "1em",
              marginLeft: "1em",
              marginBottom: "0.5em",
            }}
          >
            Submit?
          </h5>
          <DialogContentText
            style={{
              marginRight: "1.3em",
              marginLeft: "1.3em",
            }}
          >
            This will save the data and end the assessment.
          </DialogContentText>
          <div
            style={{
              padding: "1em",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleClose()}
              variant="contained"
            >
              No
            </Button>

            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleOpen()}
              variant="contained"
            >
              Yes
            </Button>
          </div>
        </Dialog>
      </div>
	  	  		  <button onClick={generatePDF} style={{borderColor:"#015893", backgroundColor:"rgb(50, 201, 83)", borderRadius: 10, fontSize: "large"}} type="button">Export PDF</button>

      </div>
      <ScrollBtn />
      <BottomNav
        submitButton={() => refresh()}
        // nextNavigation={() => refresh()}
        prevNavigation={() => navigate("/AdditionalinfoOT")}
        currentPage={5}
        numPages={5}
      />
    </div>
  );
}
export default Section6;
