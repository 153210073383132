import logo from "./logo.svg";
import "./App.css";
import PatientIDSW from "./components/Team-1/Social-Worker/PatientID-Social-Worker";
import Patient from "./components/Team-4/PatientID";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import currentPatient from "./components/Team-4/PatientID";
import Home from "./components/Home";
import LevelsOfMobility from "./components/Team-2/LevelsOfMobility";
import CurrentMobility from "./components/Team-2/CurrentMobility";
import ListOfEquipment from "./components/Team-2/ListOfEquipment";
import TimedTimer from "./components/Team-2/TimedTimer";
import Instructions from "./components/Team-2/Instructions";
import Timer from "./components/Team-2/Timer";
import RiskOfFallStatus from "./components/Team-2/RiskOfFallStatus";
import ReviewQuestion from "./components/Team-2/ReviewQuestion";
import GripStrength from "./components/Team-2/GripStrength";
import GripStrength2 from "./components/Team-2/GripStrength2";
import GripStrength3 from "./components/Team-2/GripStrength3";
import GripStrength4 from "./components/Team-2/GripStrength4";
import SummaryTeam2 from "./components/Team-2/SummaryTeam2";
import AdditionalinfoPhysio from "./components/Team-2/AdditionalinfoPhysio";
import PatientIDTeam2 from "./components/Team-2/PatientID-Team2";
import CreatePatient from "./components/admin/CreatePatient";
import SWHomeSupport from "./components/Team-1/Social-Worker/Social-History-Home-Support";
import HomeEnvironment from "./components/Team-1/Social-Worker/Home-Environment";
import PatientPlans from "./components/Team-1/Social-Worker/PatientPlans";
import SocialServices from "./components/Team-1/Social-Worker/Social-Services";
import CommunityApps from "./components/Team-1/Social-Worker/CommunityApps";
import SocialSummary from "./components/Team-1/Social-Worker/Summary-Page";
import RelativePlans from "./components/Team-1/Social-Worker/RelativePlans";
import AdditionalinfoSocialWorker from "./components/Team-1/Social-Worker/AdditionalinfoSocialWorker";
import PatientIDOT from "./components/Team-1/Occupational-Therapist/PatientID";
import Section1OT from "./components/Team-1/Occupational-Therapist/Section1";
import Section2OT from "./components/Team-1/Occupational-Therapist/Section2";
import Section3OT from "./components/Team-1/Occupational-Therapist/Section3";
import Section4OT from "./components/Team-1/Occupational-Therapist/Section4";
import Section5OT from "./components/Team-1/Occupational-Therapist/Section5";
import AdditionalinfoOT from "./components/Team-1/Occupational-Therapist/AdditionalinfoOT";
import Section6OT from "./components/Team-1/Occupational-Therapist/Section6";
import PatientIDOP from "./components/Team-1/Other-Profession/PatientIDOP";
import Section1OP from "./components/Team-1/Other-Profession/Section1OP";
import Section2OP from "./components/Team-1/Other-Profession/Section2OP";
import Section3OP from "./components/Team-1/Other-Profession/Section3OP";
import Section4OP from "./components/Team-1/Other-Profession/Section4OP";
import Section5OP from "./components/Team-1/Other-Profession/Section5OP";
import Menu from "./components/menu";
import PatientViewID from "./components/PatientView/PatientView-ID";
import PatientViewDetails from "./components/PatientView/PatientView-Details";
import PatientViewMenu from "./components/PatientView/PatientView-Menu";
import PatientViewSummary from "./components/PatientView/Summary";
import PatientViewOccupation from "./components/PatientView/PatientView-Occupation";
import PatientViewSocialWorker from "./components/PatientView/PatientView-SocialWorker";
import PatientViewGC from "./components/PatientView/PatientView-GC";
import PatientViewPhysio from "./components/PatientView/PatientView-Physio";
import OtherProfRef from "./components/Team-4/OtherProfRef";
import Others from "./components/Team-4/Others";
import AdditionalinfoNurse from "./components/Team-4/AdditionalinfoNurse";

//Geriatric Consultant Components
import PatientID from "./components/Team-3/PatientID";
import Section1 from "./components/Team-3/Section1";
import Section1a from "./components/Team-3/Section1a";
import Section1b from "./components/Team-3/Section1b";
import Section2 from "./components/Team-3/Section2";
import Section3 from "./components/Team-3/Section3";
import Section4 from "./components/Team-3/Section4";
import Section5 from "./components/Team-3/Section5";
import Section6 from "./components/Team-3/Section6";
import Section7 from "./components/Team-3/Section7";
import Section8 from "./components/Team-3/Section8";
import Section9 from "./components/Team-3/Section9";
import Section10 from "./components/Team-3/Section10";
import Section11 from "./components/Team-3/Section11";
import Section12 from "./components/Team-3/Section12";
import Section13 from "./components/Team-3/Section13";
import Section14 from "./components/Team-3/Section14";
import Section15 from "./components/Team-3/Section15";
import MOCAResults from "./components/Team-3/MOCAResults";
import AdditionalinfoGC from "./components/Team-3/AdditionalinfoGC";
import WriteCheck from "./components/Team-3/WriteCheck";
import Review from "./components/Team-3/Review";

// Nurse pages
import SummaryPageNurse from "./components/Team-4/SummaryPageNurse";
import BarthelIndexPage from "./components/Team-4/BarthelIndexPage";
import ReferralDetails from "./components/Team-4/Measurements";
import { createTheme, ThemeProvider, colors } from "@mui/material";

import "./components/styles/style.css";
import "./components/styles/header.css";
import CreateUsers from "./components/admin/CreateUsers";
import AdminMenu from "./components/admin/menu";
import PatientViewOP from "./components/PatientView/PatientView-OP";

//version 2 pages
import Diagnosis from "./components/version2/Diagnosis";
import LevelsOfMobilityV2 from "./components/version2/LevelsOfMobility";
import LivingStatus from "./components/version2/LivingStatus";
import Section3V2 from "./components/version2/Section3";
import Section4V2 from "./components/version2/Section4";
import Section5V2 from "./components/version2/Section5";
import Section6V2 from "./components/version2/Section6";
import Section7V2 from "./components/version2/Section7";
import Section8V2 from "./components/version2/Section8";
import Section9V2 from "./components/version2/Section9";
import Section10V2 from "./components/version2/Section10";
import Section11V2 from "./components/version2/Section11";
import Section12V2 from "./components/version2/Section12";
import Section13V2 from "./components/version2/Section13";
import Section14V2 from "./components/version2/Section14";
import Section15V2 from "./components/version2/Section15";
import MOCAResultsV2 from "./components/version2/MOCAResults";
import WriteCheckV2 from "./components/version2/WriteCheck";
import ReviewV2 from "./components/version2/Review";
import PatientViewDetailsV2 from "./components/version2/PatientView-Details";

//Styling

// const theme = createTheme({
//   components: {
//     MuiTypography: {
//       styleOverrides: {
//         root: {
//           color: "pink",
//         },
//       },
//     },
//   },
//   typography: {
//     fontFamily: "Fenix",
//     h1: {
//       fontFamily: "Fenix",
//       color: "pink",
//     },
//   },
// });
export default function App() {
  return (
    // <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* Login with: NVD001:pass */}
        {/* Team-4 Login with: AMD001:pass */}
        <Route
          path="/Patient"
          element={
            <Patient currentPatient={new currentPatient(0, "", "", "")} />
          }
        />

        {/* Physiotherapist */}
        <Route path="/PatientID-Team-2" element={<PatientIDTeam2 />}></Route>
        <Route path="/LevelsOfMobility" element={<LevelsOfMobility />}></Route>
        <Route path="/CurrentMobility" element={<CurrentMobility />}></Route>
        <Route path="/ListOfEquipment" element={<ListOfEquipment />}></Route>
        <Route path="/Instructions" element={<Instructions />}></Route>
        <Route path="/Timer" element={<Timer />}></Route>
        <Route path="/RiskOfFallStatus" element={<RiskOfFallStatus />}></Route>
        <Route path="/ReviewQuestion" element={<ReviewQuestion />}></Route>
        <Route path="/GripStrength" element={<GripStrength />}></Route>
        <Route path="/GripStrength2" element={<GripStrength2 />}></Route>
        <Route path="/GripStrength3" element={<GripStrength3 />}></Route>
        <Route path="/GripStrength4" element={<GripStrength4 />}></Route>
        <Route path="/SummaryTeam2" element={<SummaryTeam2 />}></Route>
        <Route path="/TimedTimer" element={<TimedTimer />}></Route>
        <Route
          path="/AdditionalinfoPhysio"
          element={<AdditionalinfoPhysio />}
        ></Route>

        {/* Social Worker */}
        <Route
          path="/Patient-ID-Social-Worker"
          element={<PatientIDSW />}
        ></Route>
        <Route path="/SH-Home-Support" element={<SWHomeSupport />}></Route>
        <Route path="/SH-Home-Env" element={<HomeEnvironment />}></Route>
        <Route path="/SH-Patient-Plans" element={<PatientPlans />}></Route>
        <Route path="/SH-Relative-Plans" element={<RelativePlans />}></Route>
        <Route path="/Social-Services" element={<SocialServices />}></Route>
        <Route path="/Community-Apps" element={<CommunityApps />}></Route>
        <Route
          path="/AdditionalinfoSocialWorker"
          element={<AdditionalinfoSocialWorker />}
        ></Route>
        <Route path="/Summary-Social-Worker" element={<SocialSummary />} />

        {/* Geriatric Consultant */}
        <Route path="/Team-3-PatientID" element={<PatientID />}></Route>
        <Route path="/diagnosis" element={<Section1 />}></Route>
        <Route path="/referraldetails" element={<Section1a />}></Route>
        <Route path="/socialhabits" element={<Section1b />}></Route>
        <Route path="/mobility" element={<Section2 />}></Route>
        <Route path="/moca" element={<WriteCheck />}></Route>
        <Route path="/sec3" element={<Section3 />}></Route>
        <Route path="/sec4" element={<Section4 />}></Route>
        <Route path="/sec5" element={<Section5 />}></Route>
        <Route path="/sec6" element={<Section6 />}></Route>
        <Route path="/sec7" element={<Section7 />}></Route>
        <Route path="/sec8" element={<Section8 />}></Route>
        <Route path="/sec9" element={<Section9 />}></Route>
        <Route path="/sec10" element={<Section10 />}></Route>
        <Route path="/sec11" element={<Section11 />}></Route>
        <Route path="/sec12" element={<Section12 />}></Route>
        <Route path="/sec13" element={<Section13 />}></Route>
        <Route path="/sec14" element={<Section14 />}></Route>
        <Route path="/sec15" element={<Section15 />}></Route>
        <Route path="/results" element={<MOCAResults />}></Route>
        <Route path="/AdditionalinfoGC" element={<AdditionalinfoGC />}></Route>
        <Route path="/review" element={<Review />}></Route>

        {/* Occupational Therapist */}
        <Route path="/menu" element={<Menu />}></Route>
        <Route path="/PatientIDOT" element={<PatientIDOT />}></Route>
        <Route path="/Section1OT" element={<Section1OT />}></Route>
        <Route path="/Section2OT" element={<Section2OT />}></Route>
        <Route path="/Section3OT" element={<Section3OT />}></Route>
        <Route path="/Section4OT" element={<Section4OT />}></Route>
        <Route path="/Section5OT" element={<Section5OT />}></Route>
        <Route path="/AdditionalinfoOT" element={<AdditionalinfoOT />}></Route>
        <Route path="/Section6OT" element={<Section6OT />}></Route>

        {/* Other Profession */}
        <Route path="/PatientIDOP" element={<PatientIDOP />}></Route>
        <Route path="/Section1OP" element={<Section1OP />}></Route>
        <Route path="/Section2OP" element={<Section2OP />}></Route>
        <Route path="/Section3OP" element={<Section3OP />}></Route>
        <Route path="/Section4OP" element={<Section4OP />}></Route>
        <Route path="/Section5OP" element={<Section5OP />}></Route>

        {/* Admin */}
        <Route path="/AdminMenu" element={<AdminMenu />}></Route>
        <Route path="/CreatePatient" element={<CreatePatient />} />
        <Route path="/CreateUser" element={<CreateUsers />}></Route>

        {/* Nurse */}
        <Route path="/BarthelIndexPage" element={<BarthelIndexPage />}></Route>
        <Route path="/Measurements" element={<ReferralDetails />}></Route>
        <Route path="/Others" element={<Others />}></Route>
        <Route path="/OtherProfRef" element={<OtherProfRef />}></Route>
        <Route
          path="/AdditionalinfoNurse"
          element={<AdditionalinfoNurse />}
        ></Route>
        <Route path="/SummaryPageNurse" element={<SummaryPageNurse />}></Route>

        {/* PatientView */}
        <Route path="/PatientView" element={<PatientViewID />}></Route>
        <Route
          path="/PatientView-Details"
          element={<PatientViewDetails />}
        ></Route>
        <Route path="/Patient-Menu" element={<PatientViewMenu />}></Route>
        <Route
          path="/PatientView-Nurse"
          element={<PatientViewSummary />}
        ></Route>
        <Route path="/PatientView-Other" element={<PatientViewOP />}></Route>
        <Route
          path="/PatientView-Occupational"
          element={<PatientViewOccupation />}
        ></Route>
        <Route
          path="/PatientView-Social"
          element={<PatientViewSocialWorker />}
        ></Route>
        <Route path="/PatientView-GC" element={<PatientViewGC />}></Route>
        <Route
          path="/PatientView-Physio"
          element={<PatientViewPhysio />}
        ></Route>
        {/* version 2 paths*/}
        <Route path="/v2" element={<Home />} />
        <Route path="/v2/PatientView" element={<PatientViewID />}></Route>
        <Route
          path="/v2/PatientView-Details"
          element={<PatientViewDetailsV2 />}
        ></Route>
        <Route path="/v2/diagnosis" element={<Diagnosis />}></Route>
        <Route
          path="/v2/mobilityLevel"
          element={<LevelsOfMobilityV2 />}
        ></Route>
        <Route path="/v2/livingStatus" element={<LivingStatus />}></Route>
        <Route path="/v2/moca" element={<WriteCheckV2 />}></Route>
        <Route path="/v2/sec3" element={<Section3V2 />}></Route>
        <Route path="/v2/sec4" element={<Section4V2 />}></Route>
        <Route path="/v2/sec5" element={<Section5V2 />}></Route>
        <Route path="/v2/sec6" element={<Section6V2 />}></Route>
        <Route path="/v2/sec7" element={<Section7V2 />}></Route>
        <Route path="/v2/sec8" element={<Section8V2 />}></Route>
        <Route path="/v2/sec9" element={<Section9V2 />}></Route>
        <Route path="/v2/sec10" element={<Section10V2 />}></Route>
        <Route path="/v2/sec11" element={<Section11V2 />}></Route>
        <Route path="/v2/sec12" element={<Section12V2 />}></Route>
        <Route path="/v2/sec13" element={<Section13V2 />}></Route>
        <Route path="/v2/sec14" element={<Section14V2 />}></Route>
        <Route path="/v2/sec15" element={<Section15V2 />}></Route>
        <Route path="/v2/results" element={<MOCAResultsV2 />}></Route>
        <Route path="/v2/review" element={<ReviewV2 />}></Route>
      </Routes>
    </Router>
    // </ThemeProvider>
  );

  //Test
}
