import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContentText } from "@mui/material";
import Header from "../header";
import BottomNav from "../bottomnavg";
import Button from "@mui/material/Button";
import { saveTeam2 } from "../firebase";

export default function TimedTimer() {
  // Calculates the running time
  const [lapse, setLapse] = useState(0);
  //Running state
  const [stopped, setStopped] = useState(true);
  //Checks if timer runs out
  const [timeover, setTimeover] = useState(false);
  const intervalRef = useRef(null);
  const navigate = useNavigate();
  //Color of Start/Stop button
  const [buttoncolor, setButtonColor] = useState("#32c953");
  // Show confirm dialog
  const [showdialog, setShowdialog] = useState(false);
  const handleConfirmdialogClose = () => {
    setShowdialog(false);
  };
  // Show skip dialog
  const [showskipdialog, setShowskipdialog] = useState(false);
  const handleSkipdialogClose = () => {
    setShowskipdialog(false);
  };
  
  
  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }


  function navToNextPage() {
	  saveData()
    navigate("/GripStrength2");
  }

  function navToPreviousPage() {
	  saveData()
    navigate("/ListOfEquipment");
  }

  // Calculates risk of falling and stores the result
  function calculateRisk() {
    // if less than 10 sec
    if (lapse < 10000) {
      sessionStorage.setItem("TUGStatus", "Low Risk");
      // if between 10 and 14 sec
    } else if (lapse > 10000 && lapse < 14000) {
      sessionStorage.setItem("TUGStatus", "Medium Risk");
      // if higher than 14 sec
    } else {
      sessionStorage.setItem("TUGStatus", "High Risk");
    }
  }

  // Stores results and moves to next page
  function saveStoppedTime() {
    sessionStorage.setItem("TUGTimer", lapse / 1000);
    calculateRisk();
    // If time is 0 sec, then test was not carried out
    if (lapse == 0 && (sessionStorage.getItem("TUGTestCarriedOut")=="null"||!sessionStorage.getItem("TUGTestCarriedOut")||sessionStorage.getItem("TUGTestCarriedOut")=="" || !sessionStorage.getItem("TUGTestCarriedOut").includes("No")) ) {
      sessionStorage.setItem("TUGTestCarriedOut", "No");
    } else if(lapse!=0){
      sessionStorage.setItem("TUGTestCarriedOut", "Yes");
    }
    navToNextPage();
  }

  // Handles skipping of test
  function skipTest() {
    sessionStorage.setItem("TUGTimer", 0);
    sessionStorage.setItem("TUGStatus", "-");
    sessionStorage.setItem("TUGTestCarriedOut", "No");
    navToNextPage();
  }

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  //Handles Start/Stop button
  function handleRunClick() {
    // Change button color to red
    setButtonColor("#D2192F");
    // If "stop" is clicked
    if (stopped) {
      const startTime = Date.now() - lapse;
      intervalRef.current = setInterval(() => {
        // If Timer reaches 20 seconds
        if (Date.now() - startTime > 20000) {
          setTimeover(true);
          setStopped(false);
          setLapse(20000);
          setButtonColor("grey");
        } else {
          //Sets lapse to currently displayed time
          setLapse(Date.now() - startTime);
          sessionStorage.setItem("TUGTimer", lapse / 1000);
          setStopped(false);
        }
      }, 0);
    } else {
      // Displays current running time
      clearInterval(intervalRef.current);
    }
    setStopped(false);
  }

  // Resets everything
  function handleClearClick() {
    setLapse(0);
    clearInterval(intervalRef.current);
    setButtonColor("#32c953");
    setStopped(true);
    setTimeover(false);
  }

  var typography ="\nClick on the ‘Start’ button to start the timer for the test.\n \nClick on the ‘Stop’ button to stop the timer.\n\nClick ‘Reset’ if you require the timer to reset, to repeat the test. \n\nThe patient is not allowed to carry out the test more than twice. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header history={"/ListOfEquipment"} name="Timed Up and Go Test"  typography={typography} />
        <h1>Timed Up and Go Test </h1>
        <div className="physio-timer-container">
          {/* Blue circle timer*/}
          <div className="timer-new">
            {/* Displays 0.000s if timer hasn't started */}
            {lapse == 0 ? (
              <span style={{ fontFamily: "Fenix" }}>0.000 s</span>
            ) : (
              [
                // Displays 20.000 s if time runs out, else current running time
                timeover ? (
                  <span style={{ fontFamily: "Fenix" }}>20.000 s</span>
                ) : (
                  <span style={{ fontFamily: "Fenix" }}>{lapse / 1000} s</span>
                ),
              ]
            )}
          </div>
          {/* Start/Stop button, gets disabled once time runs out */}
          <div className="timer-buttons-section">
            <Button
              variant={"contained"}
              onClick={handleRunClick}
              disabled={timeover}
              style={{
                borderRadius: "40px",
                paddingTop: "0.5em",
                paddingBottom: "0.5em",
                paddingLeft: "2em",
                paddingRight: "2em",
                fontFamily: "Fenix",
                fontSize: "1.1em",
                textTransform: "capitalize",
                backgroundColor: buttoncolor,
              }}
            >
              {/* If timer hasnt been clicked yet display "Start" */}
              {stopped ? "Start" : "Stop"}
            </Button>

            {/* Reset button */}
            <Button
              style={{
                borderRadius: "40px",
                paddingTop: "0.5em",
                paddingBottom: "0.5em",
                paddingLeft: "2em",
                paddingRight: "2em",
                fontFamily: "Fenix",
                fontSize: "1.1em",
                textTransform: "capitalize",
              }}
              variant={"contained"}
              onClick={handleClearClick}
            >
              Reset
            </Button>
          </div>
          {/* Skip test button */}
          <div className="timer-skip-section">
            <Button
              // fullWidth={false}
              style={{
                borderRadius: "40px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontFamily: "Fenix",
                // fontSize: "1em",
                textTransform: "capitalize",
                minWidth: "20%",
              }}
              variant={"outlined"}
              onClick={() => setShowskipdialog(true)}
            >
              Skip Test
            </Button>
          </div>
          {/* Skip test dialog */}
          <Dialog
            open={showskipdialog}
            onClose={handleSkipdialogClose}
            style={{ padding: "1em", borderRadius: "25px" }}
          >
            <h5
              style={{
                marginTop: "1em",
                marginRight: "1em",
                marginLeft: "1em",
                marginBottom: "0.5em",
              }}
            >
              Skip test?
            </h5>
            <DialogContentText
              style={{
                marginRight: "1.3em",
                marginLeft: "1.3em",
              }}
            >
              This will skip the test and move to the next page.
            </DialogContentText>
            <div
              style={{
                padding: "1em",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* "No" button in dialog */}
              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => handleSkipdialogClose()}
                variant="contained"
              >
                No
              </Button>

              {/* "Yes" button in Dialog*/}
              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => skipTest()}
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </Dialog>

          {/* Confirm dialog  */}
          <Dialog
            open={showdialog}
            onClose={handleConfirmdialogClose}
            style={{ padding: "1em", borderRadius: "25px" }}
          >
            <h5
              style={{
                marginTop: "1em",
                marginRight: "1em",
                marginLeft: "1em",
                marginBottom: "0.5em",
              }}
            >
              End test?
            </h5>
            <DialogContentText
              style={{
                marginRight: "1.3em",
                marginLeft: "1.3em",
              }}
            >
              This will end the test and save the current time as result.
            </DialogContentText>
            <div
              style={{
                padding: "1em",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* Closes dialog */}
              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => handleConfirmdialogClose()}
                variant="contained"
              >
                No
              </Button>
              {/* Confirms dialog + saves result and moves to next page*/}
              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => saveStoppedTime()}
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </Dialog>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => setShowdialog(true)}
        currentPage={4}
        numPages={9}
      ></BottomNav>
    </div>
  );
}
