// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import {
  collection,
  getDocs,
  setDoc,
  addDoc,
  doc,
  getDoc,
  query,
} from "firebase/firestore";
import { differenceInBusinessDays, format } from "date-fns";
import resetData from "./resetData";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCNs2vgKOww-9z4prMMEBGh_bqU0AlZJ7Q",
  authDomain: "mobile-team-a.firebaseapp.com",
  databaseURL:
    "https://mobile-team-a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "mobile-team-a",
  storageBucket: "mobile-team-a.appspot.com",
  messagingSenderId: "233720108765",
  appId: "1:233720108765:web:c1ff51793c8afe846f2d34",
  measurementId: "G-EZ05HWJHTX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();

export async function getPatients() {
  let globalPatients = [];
  const colRef = collection(db, "patients");
  await getDocs(colRef).then((snapshot) => {
    let patients = [];
    snapshot.docs.forEach((doc) => {
      patients.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    // console.log(patients);
    for (var i = 0; i < patients.length; i++) {
      globalPatients[i] = patients[i];
    }
    // console.log(globalPatients);
    // return globalPatients
  });
  //   console.log(globalPatients);
  return globalPatients;
}

export async function getPatientSectionDetails(id, section) {
  const fullpath = "patients/" + id + "/" + section;
  console.log(fullpath);
  const dbref = query(collection(db, fullpath));
  let arr = null;
  const snapshot = await getDocs(dbref);
  snapshot.forEach((doc) => {
    console.log(doc.id);
    arr = doc.data();
  });
  return arr;
}

export async function getPatientSectionKey(id, section) {
  try {
    const fullpath = "patients/" + id + "/" + section;
    console.log(fullpath);
    const dbref = query(collection(db, fullpath));
    console.log(dbref);
    let arr = null;
    const snapshot = await getDocs(dbref);
    console.log(snapshot);
    snapshot.forEach((doc) => {
      console.log(doc.id);
      arr = doc.id;
    });
    return arr;
  } catch (e) {
    return false;
  }
}

let globalUsers = [];
export function getUsers() {
  const colRefUsers = collection(db, "users");
  getDocs(colRefUsers).then((snapshot) => {
    let users = [];
    snapshot.docs.forEach((doc) => {
      users.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    // console.log(users);
    for (var i = 0; i < users.length; i++) {
      globalUsers[i] = users[i];
      // globalUsers.push(users[i]);
      // console.log(globalUsers[0]);
    }

    // console.log(globalUsers);
    return globalUsers;
  });

  return globalUsers;
}

export function ShowSectionE() {
  let allData = "";
  const patientId = sessionStorage.getItme("patientId");
  const colRefE = collection(db, "patients/" + patientId + "/SectionE");
  getDocs(colRefE).then((snapshot) => {
    let Joe = "";
    Joe = snapshot.docs.find((doc) => doc.id === "i9u0dmhAUnXroxWxg9H2").data();
    console.log(Joe);
    allData = Joe;
  });
  return allData;
}

export function AddData() {
  const patientId = sessionStorage.getItme("patientId");

  addDoc(collection(db, "patients/" + patientId + "/SectionE"), {
    ahw: "some",
    dhw: "random",
    ab: "generic",
    db: "data",
  });
}

export function AddNewVisit(id, time) {
  const fullpath = "patients/" + id + "/SectionE";
  const dbref = doc(db, fullpath, time);
  setDoc(dbref, {
    AdmissionBarthel: "",
    AdmissionDate: "",
    AdmissionHeightWeight: "",
    DischargeBarthel: "",
    DischargeDate: "",
    DischargeHeightWeight: "",
  });
}

export function AddDataB() {
  const patientId = sessionStorage.getItme("patientId");

  addDoc(collection(db, "patients/" + patientId + "/SectionB"), {
    DataofSession: "",
    GripStrengthResults: {
      MaxLeftHandResult: { Risk: "", TestResult: "" },
      MaxRightHandResult: { Risk: "", TestResult: "" },
      Question1: "",
      Question2: "",
      Question3: "",
      Question4: "",
      Question5: "",
    },
    TUGTestResults: {
      LevelsOfMobility: {
        DCurrentLevelofMobility: "",
        DPreviousLevelofMobility: "",
        ICurrentLevelofMobility: "",
        IPreviousLevelofMobility: "",
      },
      RiskOfFallStatus: { Status: "", TimeTakenInSeconds: "" },
      TUGTestCarriedOut: "",
    },
  });
}

//data1: ablist, data2: dblist, data3: ahw, data4: dhw
export function SetDataE(id, time, Adate, Ddate, data1, data2, data3, data4) {
  const fullpath = "patients/" + id + "/SectionE";
  const dbref = doc(db, fullpath, format(new Date(), "yyyy-MM-dd HH:mm:ss"));
  setDoc(dbref, {
    ab: data1,
    AdmissionDate: Adate,
    ahw: data3,
    db: data2,
    DischargeDate: Ddate,
    dhw: data4,
  });
}

export function saveDataSectionA(date = null, draft = true, reset = false) {
  const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: date,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
    surgicalProcedure: sessionStorage.getItem("qsurgical"),
  };
  saveTeam3(
    sessionStorage.getItem("patientId"),
    sessionStorage.getItem("doctor"),
    date,
    data,
    draft,
    reset
  );
}

export function saveDataSectionB(date = null, draft = true, reset = false) {
  let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  let ActualObjectLeftResult = null;
  try {
    ActualObjectLeftResult = JSON.parse(SessionLeftResult);
  } catch (e) {
    ActualObjectLeftResult = SessionLeftResult;
  }

  let ActualObjectRightResult = null;
  try {
    ActualObjectRightResult = JSON.parse(SessionRightResult);
  } catch (e) {
    ActualObjectRightResult = SessionRightResult;
  }
  const dataArray = {
    date: date,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
    dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
    currentMobility: {
      ul: {
        right: sessionStorage.getItem("ulRight"),
        left: sessionStorage.getItem("ulLeft"),
      },
      ll: {
        right: sessionStorage.getItem("llRight"),
        left: sessionStorage.getItem("llLeft"),
      },
      bedMobility: sessionStorage.getItem("bedmobility"),
      transfers:
        sessionStorage.getItem("transfers") &&
        sessionStorage.getItem("transfers").split(","),
      otherComment: sessionStorage.getItem("freeText"),
    },
    smoking: sessionStorage.getItem("q5"),
    diagnosis: sessionStorage.getItem("q1"),
    socialStatus: sessionStorage.getItem("SocialStatus"),
    relativePlans: sessionStorage.getItem("RelativePlans"),
    transferQuestion: sessionStorage.getItem("transferQuestion"),
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
    draft: draft,
  };
  saveTeam2(sessionStorage.getItem("patientId"), date, dataArray, reset);
}

export function setPatient(patient) {
  const dbref = doc(db, "patients", patient.id);
  console.log(patient);
  setDoc(dbref, {
    name: patient.name,
    id: patient.id,
    gender: patient.gender,
    age: patient.age,
    AdmitWard: patient.AdmitWard,
    AdmitConsultant: patient.AdmitConsultant,
    AdmitLastYear: patient.AdmitLastYear,
    locality: patient.locality,
    zipcode: patient.zipcode,
    streetname: patient.streetname,
    KinNumber: patient.KinNumber,
    kinName: patient.kinName,
    kinRelation: patient.kinRelation,
    houseName: patient.houseName,
    houseNum: patient.houseNum,
  });

  // console.log("Big Success!");
}

export async function saveTeam2(patientId, date1, data, reset = true) {
  const date =
    (await getPatientSectionKey(
      sessionStorage.getItem("patientId"),
      "SectionB"
    )) || format(new Date(), "yyyy-MM-dd HH:mm:ss");
  console.log(data);
  console.log(data.currentMobility);
  const fullpath = "patients/" + patientId + "/SectionB/" + date;
  console.log(fullpath);
  const dbref = doc(db, fullpath);
  setDoc(dbref, {
    DateofSession: date,
    AddInfo: data.AddInfo,
    GripStrengthResults: {
      MaxLeftHandResult: {
        Risk: data.lefthandrisk,
        TestResult: data.lefthandresult,
      },
      MaxRightHandResult: {
        Risk: data.righthandrisk,
        TestResult: data.righthandresult,
      },
      Question1: data.question1,
      Question2: data.question2,
      Question3: data.question3,
      Question4: data.question4,
      Question5: data.question5,
    },
    TUGTestResults: {
      LevelsOfMobility: {
        ICurrentLevelofMobility: data.currentmobility,
        IPreviousLevelofMobility: data.previousmobility,
        DCurrentLevelofMobility: data.dcurrentmobility,
        DPreviousLevelofMobility: data.dpreviousmobility,
      },
      RiskOfFallStatus: {
        Status: data.riskoffallstatus,
        TimeTakenInSeconds: data.tugtimetaken,
      },
      TUGTestCarriedOut: data.tugcarriedout,
    },
    smoking: data.smoking,
    diagnosis: data.diagnosis,
    CurrentMobility: data.currentMobility,
    transferQuestion: data.transferQuestion,
    socialStatus: data.socialStatus,
    relativePlans: data.relativePlans,
    draft: data.draft || false,
    HandGripStrengthTest: {
      first: sessionStorage.getItem("gripStrength1"),
      second: sessionStorage.getItem("gripStrength2"),
      third: sessionStorage.getItem("gripStrength3"),
      left1: parseFloat(sessionStorage.getItem("leftInput1temp")),
      left2: parseFloat(sessionStorage.getItem("leftInput2temp")),
      right1: parseFloat(sessionStorage.getItem("rightInput1temp")),
      right2: parseFloat(sessionStorage.getItem("rightInput2temp")),
    },
  });
  if (reset) resetData();
}

export async function saveTeam3(
  patientId,
  init,
  date1,
  data,
  draft = false,
  reset = true
) {
  console.log(sessionStorage.getItem("patientId"));
  const date =
    (await getPatientSectionKey(
      sessionStorage.getItem("patientId"),
      "SectionA"
    )) || format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const fullpath = "patients/" + patientId + "/SectionA/" + date;
  const dbref = doc(db, fullpath);
  setDoc(dbref, {
    consultant: init,
    date: date,
    mobilityIndex: data.mobilityIndex,
    moca: {
      abstraction: data.abstraction,
      attention: data.attention,
      finalScore: data.finalScore,
      language: data.language,
      memory: "no points",
      naming: data.naming,
      orientation: data.orientation,
      recall: data.recall,
      visual: data.visual,
    },
    section1: {
      alcohol: data.alcohol,
      diagnosis: data.diagnosis,
      charlsonIndex: data.charlson,
      drugHistory: data.drugHistory,
      education: data.education,
      medicalHistory: data.medicalHistory,
      presentCondition: data.presentCondition,
      smoking: data.smoking,
      surgicalProcedure: data.surgicalProcedure,
    },
    CharlsonIndexChecklist: sessionStorage.getItem("mobility-index-checked"),
    MocaTest: {
      visioPattern: sessionStorage.getItem("pattern"),
      cube: sessionStorage.getItem("cube"),
      clock1: sessionStorage.getItem("clock1") || 0,
      clock2: sessionStorage.getItem("clock2") || 0,
      clock3: sessionStorage.getItem("clock3") || 0,
      camel: sessionStorage.getItem("camel") || 0,
      rhino: sessionStorage.getItem("rhino") || 0,
      lion: sessionStorage.getItem("lion") || 0,
      trial1: sessionStorage.getItem("trail1"),
      trial2: sessionStorage.getItem("trail2"),
      front: sessionStorage.getItem("front") || 0,
      back: sessionStorage.getItem("back") || 0,
      letters: sessionStorage.getItem("letters"),
      serial: sessionStorage.getItem("checkedV"),
      rep1: sessionStorage.getItem("rep1") || 0,
      rep2: sessionStorage.getItem("rep1") || 0,
      tugTimer: sessionStorage.getItem("TUGTimer"),
      counter: sessionStorage.getItem("counter"),
      meas: sessionStorage.getItem("meas") || 0,
      trans: sessionStorage.getItem("trans") || 0,
      trail22: sessionStorage.getItem("trail22"),
      trail11: sessionStorage.getItem("trail21"),
      trail33: sessionStorage.getItem("trail23"),
      mocaTestOrientation: sessionStorage.getItem("checkedv15"),
      eduCheck: sessionStorage.getItem("eduCheck"),
      writeCheck: sessionStorage.getItem("writeCheck"),
      blindCheck: sessionStorage.getItem("blindCheck"),
      visualPatternComment: sessionStorage.getItem("MOCASec3Comment"),
      cubeComment: sessionStorage.getItem("MOCASec4Comment"),
      clockComment: sessionStorage.getItem("MOCASec5Comment"),
    },

    AddInfo: data.AddInfo,
    draft,
  });
  if (reset) resetData();
}

export async function saveSocialWorker(
  patientId,
  date1,
  Data1,
  Data2,
  Data3,
  Data4,
  Data5,
  Data6,
  Data7,
  Data8,
  MaritalStatus,
  MainCarer,
  Neighbours,
  SupportAtHome,
  SocialServicesComments,
  ReligiousBelief,
  LiftPresent,
  TypeofHouse,
  AimRelative,
  AimPatient,
  draft = false,
  reset = true
) {
  console.log(sessionStorage.getItem("patientId"));
  const date =
    (await getPatientSectionKey(
      sessionStorage.getItem("patientId"),
      "SectionD"
    )) || format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const fullpath = "patients/" + patientId + "/SectionD/" + date;
  const dbref = doc(db, fullpath);
  setDoc(dbref, {
    MaritalStatus: MaritalStatus,
    HomeSupport: {
      LivesAlone: Data1.LivesAlone,
      Support: Data1.AnySupport,
    },
    StairstoHome: {
      Stairs: Data2.Stairs, //yes or no
      AmenitiesOnSameFloor: Data2.Amenities, //yes or no
      AmenitiesLocation: Data2.AmenityLoc,
      OtherAmenities: Data2.OtherAmenLoc,
    },
    PatientsPlans: Data3.Patient,
    RelativePlans: Data3.Relative,
    SocialServices: Data4,
    CommLTCData: {
      CommLTCApplication: Data5.Apps,
      TimeSpan: Data5.TimeSpan,
    },
    SocialServicesOther: Data6,
    HomeAmendmentsRequired: Data7,
    AddInfo: Data8,
    MainCarer,
    Neighbours,
    SupportAtHome,
    SocialServicesComments,
    ReligiousBelief,
    LiftPresent,
    TypeofHouse,
    AimRelative,
    AimPatient,
    draft,
  });
  if (reset) resetData();
}

export async function saveNurse(data, reset = true) {
  console.log(sessionStorage.getItem("patientId"));
  const date =
    (await getPatientSectionKey(
      sessionStorage.getItem("patientId"),
      "SectionE"
    )) || format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const fullpath =
    "patients/" + sessionStorage.getItem("patientId") + "/SectionE/" + date;
  console.log(date, fullpath);
  const dbref = doc(db, fullpath);
  setDoc(dbref, {
    // Admission: sessionStorage.getItem("BarthelIndexAdmission"),
    // Discharge: sessionStorage.getItem("BarthelIndexDischarge"),
    // BarthelDifference: sessionStorage.getItem("BarthelIndex"),
    // CalculatedHeight: sessionStorage.getItem("Patient-CalculatedHeight"),
    // Weight: sessionStorage.getItem("Patient-Weight"),
    // ExerciseWeightLoss: sessionStorage.getItem("Due to exercise?"), //yes or no,
    // WeightLoss: sessionStorage.getItem("Weight Lost?"), //yes or no
    // OtherProfessionReferredSLP: sessionStorage.getItem("Nurse-SLP"),
    // OtherProfessionReferredPodiatry: sessionStorage.getItem("Nurse-Podiatry"),
    // OtherProfessionReferredNaD: sessionStorage.getItem("Nurse-NaD"),
    // OtherProfessionReferredPsychology:
    //   sessionStorage.getItem("Nurse-Psychology"),
    // OtherProfessionReferredPsychiatry:
    //   sessionStorage.getItem("Nurse-Psychiatry"),
    // OtherProfessionReferredOther: sessionStorage.getItem("Nurse-Other"),
    // AddInfo: sessionStorage.getItem("NurseAdditionalInfo"),
    Admission: data.addmission,
    Discharge: data.discharge,
    BarthelDifference: data.BarthelDifference,
    AdmissionA: sessionStorage.getItem("BarthelIndexAdmissionArray"),
    DischargeA: sessionStorage.getItem("BarthelIndexDischargeArray"),
    BarthelDifferenceA: sessionStorage.getItem("BarthelIndexDifference"),
    CalculatedHeight: data.CalculatedHeight,
    Height: sessionStorage.getItem("Patient-Height"),
    Weight: data.Weight,
    ExerciseWeightLoss: data.ExerciseWeightLoss, //yes or no,
    WeightLoss: data.WeightLoss, //yes or no
    OtherProfessionReferredSLP: data.OtherProfessionReferredSLP,
    OtherProfessionReferredPodiatry: data.OtherProfessionReferredPodiatry,
    OtherProfessionReferredNaD: data.OtherProfessionReferredNaD,
    OtherProfessionReferredPsychology: data.OtherProfessionReferredPsychology,
    OtherProfessionReferredPsychiatry: data.OtherProfessionReferredPsychiatry,
    OtherProfessionReferredOther: data.OtherProfessionReferredOther,
    AddInfo: data.AddInfo,
    Appetite: data.Appetite,
    FeedingRoute: data.FeedingRoute,
    AssistiveDevices: data.AssistiveDevices,
    WoundPresent: data.WoundPresent,
    SuturesClipsPresent: data.SuturesClipsPresent,
    BMI: sessionStorage.getItem("bmi"),
    draft: data.draft || false,
  });
  if (reset) resetData();
}

export async function saveOccupational(data, reset = true) {
  console.log(data, reset);
  const patientId = sessionStorage.getItem("patientId");

  console.log(sessionStorage.getItem("patientId"));
  const date =
    (await getPatientSectionKey(
      sessionStorage.getItem("patientId"),
      "SectionC"
    )) || format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const fullpath = "patients/" + patientId + "/SectionC/" + date;
  console.log(date, new Date());
  const dbref = doc(db, fullpath);
  setDoc(dbref, {
    CurrentADLs: {
      Feeding: data.feedingC,
      Toilet: data.toiletC,
      Bathing: data.bathC,
      Grooming: data.groomC,
      Dressing: data.dressC,
    },
    CurrentInstrumentalADL: {
      Changes: data.changesinADL,
      ChangesComm: data.changesinADLcomm,
    },
    Housebound: data.housebound,
    Driving: data.driving,
    Employment: data.employment,
    LeisureActivity: data.leisure,
    PreviousADLs: {
      Feeding: data.feedingP,
      Toilet: data.toiletP,
      Bathing: data.bathP,
      Grooming: data.groomP,
      Dressing: data.dressP,
    },
    PreviousInstrumentalADLs: {
      Telephone: data.telephone,
      Shopping: data.shopping,
      Food: data.food,
      Housekeeping: data.housekeeping,
      Laundry: data.laundry,
      Transportation: data.transportation,
      Medication: data.medication,
      Finances: data.finances,
    },
    CurrentInstrumentalADLs: {
      Telephone: sessionStorage.getItem("a111") || "",
      Shopping: sessionStorage.getItem("a122") || "",
      Food: sessionStorage.getItem("a133") || "",
      Housekeeping: sessionStorage.getItem("a144") || "",
      Laundry: sessionStorage.getItem("a155") || "",
      Transportation: sessionStorage.getItem("a166") || "",
      Medication: sessionStorage.getItem("a177") || "",
      Finances: sessionStorage.getItem("a188") || "",
    },
    CommentInstrumentalADLs: {
      Telephone: sessionStorage.getItem("a11c") || "",
      Shopping: sessionStorage.getItem("a12c") || "",
      Food: sessionStorage.getItem("a13c") || "",
      Housekeeping: sessionStorage.getItem("a14c") || "",
      Laundry: sessionStorage.getItem("a15c") || "",
      Transportation: sessionStorage.getItem("a16c") || "",
      Medication: sessionStorage.getItem("a17c") || "",
      Finances: sessionStorage.getItem("a18c") || "",
    },
    CommentADLs: {
      Feeding: data.feedingCom,
      Toilet: data.toiletCom,
      Bathing: data.bathCom,
      Grooming: data.groomCom,
      Dressing: data.dressCom,
    },
    AddInfo: data.addInfo,
    Continence: {
      BowelComment: data.bowelComment,
      BowelCurrent: data.bowelCurrent,
      BowelPrevious: data.bowelPrevious,
      BladderComment: data.bladderComment,
      BladderCurrent: data.bladderCurrent,
      BladderPrevious: data.bladderPrevious,
      // AddInfo: data.addInfo,
    },
    draft: data.draft || false,
  });
  if (reset) resetData();
}

export async function saveOther(patientId, date1, data, reset = true) {
  console.log(sessionStorage.getItem("patientId"));
  const date =
    (await getPatientSectionKey(
      sessionStorage.getItem("patientId"),
      "SectionF"
    )) || format(new Date(), "yyyy-MM-dd HH:mm:ss");
  const fullpath = "patients/" + patientId + "/SectionF/" + date;
  const dbref = doc(db, fullpath);
  setDoc(dbref, {
    DataOfReferral: data.ReferralDate,
    DataSeen: data.DateSeen,
    Notes: data.Notes,
    Profession: data.Profession,
    ReasonForRefferal: data.Reason,
    SeenBy: data.SeenBy,
    draft: data.draft || false,
  });
  if (reset) resetData();
}

export function setUser(users) {
  const dbref = doc(db, "users", users.id);
  setDoc(dbref, {
    name: users.name,
    id: users.id,
    user: users.user,
    pass: users.pass,
    roles: users.roles,
  });

  console.log("Big Success!");
}

export async function GetDocsE(patientId) {
  const fullpath = "patients/" + patientId + "/SectionE";
  const dbref = await collection(db, fullpath);
  const arr = [];
  await getDocs(dbref).then((snapshot) => {
    snapshot.docs.forEach((doc) => {
      arr.push(doc.id);
    });
  });
  return arr;
}

let data;
// let test = [];
export async function GetDataE(patientId, docId) {
  const fullpath = "patients/" + patientId + "/SectionE";
  const dbref = await collection(db, fullpath);
  await getDoc(dbref).then((snapshot) => {
    data = snapshot.docs.find((doc) => doc.id === docId).data();
    console.log("inside data:", data);
    // test.push(data);
    return data;
  });
  // console.log(data);
  //sessionStorage.setItem("",JSON.stringify(data));
  return data;
}

export async function getPatients2() {
  let NotGlobal = [];
  const colRef = collection(db, "patients");
  await getDocs(colRef).then((snapshot) => {
    let patients = [];
    snapshot.docs.forEach((doc) => {
      patients.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    // console.log(patients);
    for (var i = 0; i < patients.length; i++) {
      NotGlobal[i] = patients[i];
    }
  });
  return NotGlobal;
}

export async function getNurseSummary(id) {
  let global = [];
  const fullpath = "patients/" + id + "/SectionE";
  const ref = collection(db, fullpath);
  await getDocs(ref).then((snapshot) => {
    let documents = [];
    snapshot.docs.forEach((doc) => {
      documents.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    for (var i = 0; i < documents.length; i++) {
      global[i] = documents[i];
    }
  });
  return global;
}

export async function getOtherSummary(id) {
  let global = [];
  const fullpath = "patients/" + id + "/SectionF";
  const ref = collection(db, fullpath);

  await getDocs(ref).then((snapshot) => {
    let documents = [];
    snapshot.docs.forEach((doc) => {
      documents.push({
        ...doc.data(),
        id: doc.id,
      });
    });

    global = documents;
    //console.log(global);
  });

  return global;
}

export async function getOccupational(id) {
  let global = [];
  const fullpath = "patients/" + id + "/SectionC";
  const ref = collection(db, fullpath);

  await getDocs(ref).then((snapshot) => {
    let documents = [];
    snapshot.docs.forEach((doc) => {
      documents.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    global = documents;
  });
  return global;
}

export async function getSocialWorker(id) {
  const fullpath = "patients/" + id + "/SectionD";
  const ref = collection(db, fullpath);
  let global = [];
  await getDocs(ref).then((snapshot) => {
    let documents = [];
    snapshot.docs.forEach((doc) => {
      documents.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    global = documents;
  });
  return global;
}

export async function GetConsultant(id) {
  const fullpath = "patients/" + id + "/SectionA";
  const ref = collection(db, fullpath);
  let global = [];

  await getDocs(ref).then((snapshot) => {
    let documents = [];
    snapshot.docs.forEach((doc) => {
      documents.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    global = documents;
  });
  return global;
}

export async function GetPhysio(id) {
  const fullpath = "patients/" + id + "/SectionB";
  const ref = collection(db, fullpath);
  let global = [];

  await getDocs(ref).then((snapshot) => {
    let documents = [];
    snapshot.docs.forEach((doc) => {
      documents.push({
        ...doc.data(),
        id: doc.id,
      });
    });
    global = documents;
    // console.log(global);
  });
  return global;
}
