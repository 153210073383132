import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import Card from "@mui/material/Card";
import { CardContent, TableCell, TableRow } from "@mui/material";
import { typography } from "@mui/system";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import BottomNav from "../../bottomnavg";
import "../../styles/Team1OT.css";
import { saveOccupational } from "../../firebase";

function Section5() {
  const [answerdisplay, setanswerDisplay] = useState(false);

  const [answerhousebound, setAnswerHousebound] = useState("");
  const [hbdisplay, sethbDisplay] = useState(false);

  const [answerdriving, setAnswerDriving] = useState("");
  const [drivingdisplay, setdrivingDisplay] = useState(false);

  const [answeremployment, setAnsweremployment] = useState("");
  const [employmentdisplay, setemploymentDisplay] = useState(false);

  const [open, setOpen] = useState(false);
  const [otherdisplay, setotherDisplay] = useState(false);
  
    const [answerleisure, setAnswerleisure] = useState("");
  const [leisuredisplay, setleisureDisplay] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  
   const saveData =() => {

const alldata=  {
    housebound: sessionStorage.getItem("a"),
    feedingP: sessionStorage.getItem("a1"),
    toiletP: sessionStorage.getItem("a2"),
    bathP: sessionStorage.getItem("a3"),
    groomP: sessionStorage.getItem("a4"),
    dressP: sessionStorage.getItem("a5"),
        feedingC: sessionStorage.getItem("b1"),
    toiletC: sessionStorage.getItem("b2"),
    bathC: sessionStorage.getItem("b3"),
    groomC: sessionStorage.getItem("b4"),
    dressC: sessionStorage.getItem("b5"),
    feedingCom: sessionStorage.getItem("c1"),
    toiletCom: sessionStorage.getItem("c2"),
    bathCom: sessionStorage.getItem("c3"),
    groomCom: sessionStorage.getItem("c4"),
    dressCom: sessionStorage.getItem("c5"),
    telephone: sessionStorage.getItem("a11"),
    shopping: sessionStorage.getItem("a12"),
    food: sessionStorage.getItem("a13"),
    housekeeping: sessionStorage.getItem("a14"),
    laundry: sessionStorage.getItem("a15"),
    transportation: sessionStorage.getItem("a16"),
    medication: sessionStorage.getItem("a17"),
    finances: sessionStorage.getItem("a18"),
    changesinADL: sessionStorage.getItem("a19"),
    changesinADLcomm: sessionStorage.getItem("b19"),
    //To Check (26.08.22)
    addInfo: sessionStorage.getItem("OTAdditionalInfo"),

	bowelComment: (sessionStorage.getItem("cc1")||""),
	bowelCurrent:(sessionStorage.getItem("bc1")||""),
	bowelPrevious:(sessionStorage.getItem("ac1")||""),
	
	bladderComment:(sessionStorage.getItem("cc2")||""),
	bladderCurrent:(sessionStorage.getItem("bc2")||""),
	bladderPrevious:(sessionStorage.getItem("ac2")||""),
	
	  driving:(sessionStorage.getItem("a21")||""),
    employment:(sessionStorage.getItem("a20")||""),
    leisure:(sessionStorage.getItem("a21l")||""),

	draft: true
  };
  	  saveOccupational(alldata, false)

  }


  const navigate = useNavigate();

  var typography =
    "Q1. Comment on any changes in IADLs, from previous to current.\n \nQ2. Click if patient is housebound or not. \n\nQ3. Click on continence level, for both bowel and bladder.";

  useEffect(()=>{
    setAnswerHousebound(sessionStorage.getItem("a")||"")
    setAnswerDriving(sessionStorage.getItem("a21")||"")
    setAnsweremployment(sessionStorage.getItem("a20")||"")
    setAnswerleisure(sessionStorage.getItem("a21l")||"")
  }, [])

  function validation() {
  
	saveData()
    if (answerhousebound === "" || answerhousebound=="null") {
      sethbDisplay(true);
    } else {
      sethbDisplay(false);
      sessionStorage.setItem("a", answerhousebound);
    }
	console.log(hbdisplay, answerhousebound === "", answerhousebound)

    if (answerdriving === "") {
      setdrivingDisplay(true);
    } else {
      setdrivingDisplay(false);
      sessionStorage.setItem("a21", answerdriving);
    }
    if (answeremployment === "") {
      setemploymentDisplay(true);
    } else {
      setemploymentDisplay(false);
      sessionStorage.setItem("a20", answeremployment);
    }
	
	   if (answerleisure === "") {
      setleisureDisplay(true);
    } else {
      setleisureDisplay(false);
      sessionStorage.setItem("a21l", answerleisure);
    }


      if (
        answerhousebound != "" &&
        answerdriving != "" &&
        answeremployment != ""&&
        answerleisure !=""
      )
        navigate("/AdditionalinfoOT");
    
    // if (
    //   answer != "" &&
    //   answerhousebound != "" &&
    //   answerbowels != "" &&
    //   answerbladder != ""&&
    //   otherText !=""
    // ) {

    // navigate("/AdditionalinfoOT");
    // }else {
    //   if (answer === "Yes") {
    //     if (document.getElementById("area1").value === "") {
    //       //setOpen(true);
    //       setanswerDisplay(true);
    //     }}
    //   navigate("/AdditionalinfoOT");

    // else {
    //   // sessionStorage.setItem("a", answerhousebound);
    //   // sessionStorage.setItem("a19", answer);
    //   // navigate("/AdditionalinfoOT");
    // }

    // sessionStorage.setItem("a20", answerbowels);
    // sessionStorage.setItem("a21", answerbladder);
    //navigate("/AdditionalinfoOT");
  }


  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/Section4OT"}
          name={"Occupational Therapy"}
        />
        <h1>Questions</h1>
        <div className="occupational-container">
          <div
            style={{
              marginBottom: "1em",
              marginLeft: "1em",
              marginRight: "1em",
            }}
          >
            <FormLabel
              sx={{ fontSize: 21, color: "black" }}
              id="demo-controlled-radio-buttons-group"
            >
              <h4 style={{ marginBottom: "0.3em", marginTop: "0.3em" }}>
                Is the patient housebound?
              </h4>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => setAnswerHousebound(e.target.value)}
              row
            >
              <FormControlLabel
                style={{ fontSize: "3em", paddingRight: "2em" }}
                value="Yes"
                control={<Radio />}
                //label="Yes"
                  checked={answerhousebound=="Yes"}
                label={<Typography sx={{ fontSize: 20 }}>Yes</Typography>}
              />
              <FormControlLabel
                // sx={{ fontSize: 30 }}
                value="No"
                control={<Radio />}
                //label="No"
                checked={answerhousebound=="No"}

                label={<Typography sx={{ fontSize: 20 }}>No</Typography>}
              />
            </RadioGroup>
            <h4
              className="alert"
              id="question1-alert"
              style={{
                display: hbdisplay ? null : "none",
                marginTop: "0.5em",
                fontSize: "0.8em",
              }}
            >
              Please select an option
            </h4>
          </div>

 <div
            style={{
              marginBottom: "1em",
              marginLeft: "1em",
              marginRight: "1em",
            }}
          >
            <FormLabel
              sx={{ fontSize: 21, color: "black" }}
              id="demo-controlled-radio-buttons-group"
            >
              <h4 style={{ marginBottom: "0.3em", marginTop: "0.3em" }}>
               Does the patient drive?
              </h4>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => setAnswerDriving(e.target.value)}
              row
			  value={answerdriving}
            >
              <FormControlLabel
                style={{ fontSize: "3em", paddingRight: "2em" }}
                value="Yes"
                control={<Radio />}
                //label="Yes"
                label={<Typography sx={{ fontSize: 20 }}>Yes</Typography>}
              />
              <FormControlLabel
                // sx={{ fontSize: 30 }}
                value="No"
                control={<Radio />}
                //label="No"

                label={<Typography sx={{ fontSize: 20 }}>No</Typography>}
              />
            </RadioGroup>
            <h4
              className="alert"
              id="question1-alert"
              style={{
                display: drivingdisplay ? null : "none",
                marginTop: "0.5em",
                fontSize: "0.8em",
              }}
            >
              Please select an option
            </h4>
          </div>
		  
		   <div
            style={{
              marginBottom: "1em",
              marginLeft: "1em",
              marginRight: "1em",
            }}
          >
            <FormLabel
              sx={{ fontSize: 21, color: "black" }}
              id="demo-controlled-radio-buttons-group"
            >
              <h4 style={{ marginBottom: "0.3em", marginTop: "0.3em" }}>
                Is the patient employed?
              </h4>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => setAnsweremployment(e.target.value)}
              row
			  value={answeremployment}
            >
              <FormControlLabel
                style={{ fontSize: "3em", paddingRight: "2em" }}
                value="Yes"
                control={<Radio />}
                //label="Yes"
                label={<Typography sx={{ fontSize: 20 }}>Yes</Typography>}
              />
              <FormControlLabel
                // sx={{ fontSize: 30 }}
                value="No"
                control={<Radio />}
                //label="No"

                label={<Typography sx={{ fontSize: 20 }}>No</Typography>}
              />
            </RadioGroup>
            <h4
              className="alert"
              id="question1-alert"
              style={{
                display: employmentdisplay ? null : "none",
                marginTop: "0.5em",
                fontSize: "0.8em",
              }}
            >
              Please select an option
            </h4>
          </div>
		  
		   <div
            style={{
              marginBottom: "1em",
              marginLeft: "1em",
              marginRight: "1em",
            }}
          >
            <FormLabel
              sx={{ fontSize: 21, color: "black" }}
              id="demo-controlled-radio-buttons-group"
            >
              <h4 style={{ marginBottom: "0.3em", marginTop: "0.3em" }}>
                Does the patient have leisure activity?
              </h4>
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              onChange={(e) => setAnswerleisure(e.target.value)}
              row
			  value={answerleisure}
            >
              <FormControlLabel
                style={{ fontSize: "3em", paddingRight: "2em" }}
                value="Yes"
                control={<Radio />}
                //label="Yes"
                label={<Typography sx={{ fontSize: 20 }}>Yes</Typography>}
              />
              <FormControlLabel
                // sx={{ fontSize: 30 }}
                value="No"
                control={<Radio />}
                //label="No"

                label={<Typography sx={{ fontSize: 20 }}>No</Typography>}
              />
            </RadioGroup>
            <h4
              className="alert"
              id="question1-alert"
              style={{
                display: leisuredisplay ? null : "none",
                marginTop: "0.5em",
                fontSize: "0.8em",
              }}
            >
              Please select an option
            </h4>
          </div>

         
        </div>
        {/* <div>
          {sessionStorage.getItem("a")}
          {sessionStorage.getItem("a19")}
          {sessionStorage.getItem("a20")}
          {sessionStorage.getItem("a21")}
        </div> */}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogTitle id="alert-dialog-title">
              Please select an option
            </DialogTitle>
          </DialogContent>
          <Button style={{ m: 10, fontSize: "20px" }} onClick={handleClose}>
            close
          </Button>
        </Dialog>
      </div>
      <BottomNav
        nextNavigation={() => validation()}
        prevNavigation={() => {saveData(); navigate("/Section4OT")}}
        currentPage={3}
        numPages={5}
      />
    </div>
  );
}
export default Section5;
