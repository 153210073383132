import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BottomNav from "../bottomnavg";
import Header from "../header";
import { TextField } from "@mui/material";
import { maxWidth } from "@mui/system";
import { saveTeam2 } from "../firebase";

export default function AdditionalinfoPhysio() {
  const navigate = useNavigate();
  const [infotext, setInfotext] = useState("");

  function navToNextPage() {
	  saveData()
    navigate("/SummaryTeam2");
  }

  function navToPreviousPage() {
	  saveData()
    navigate("/GripStrength4");
  }
  
  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
	draft: true
  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }

  useEffect(() => {
    setInfotext(sessionStorage.getItem("PhysioAdditionalInfo"));
  }, []);

  function saveText() {
    sessionStorage.setItem("PhysioAdditionalInfo", infotext);
    navToNextPage();
  }

  var typography = "Add any additional comments as necessary.";

  return (
    <div className="screen" style={{justifyContent:"space-between"}}>
      <div className="screen" style={{width:"100%", minHeight:"auto"}}>
      <Header history={"/GripStrength4"} typography={typography}/>
      <h1>Additional Info</h1>
      <div className="physio-additional-container">
        <TextField
          value={infotext}
          onChange={(e) => setInfotext(e.target.value)}
          multiline="true"
          rows="15"
          placeholder="Add additional information about the patient"
        ></TextField>
      </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => saveText()}
        currentPage={8}
        numPages={9}
      ></BottomNav>
    </div>
  );
}
