import React, { useEffect, useState } from "react";
import { FormControl, FormGroup, Grid, ListItem } from "@mui/material";
import Header from "../../header";
import "../../styles/HomeContent.css";
import "../../styles/header.css";
import { useNavigate } from "react-router-dom";
import { RadioGroup, Radio } from "@mui/material";
import { List } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Button } from "@mui/material";
import { Checkbox } from "@mui/material";
import { TextField, Select, MenuItem } from "@mui/material";
import BottomNav from "../../bottomnavg";
import { saveSocialWorker } from "../../firebase";

export default function SocialServices() {
  const [next, setNext] = useState(false);
  const [exp, setExp] = useState([]);
  const [fragments, setFragments] = useState(<></>);
  const [result, setResult] = useState("");
  const [radio, setRadio] = useState("");
  const [otherText, setOtherText] = useState(false);
  const [showotherText, setShowOtherText] = useState(false);
  const [error, setError] = useState(false);
  const [error5, setError5] = useState(false);
  const [sradio, setSRadio] = useState("");
  const [mainCarer, setMainCarer] = useState("")
  const [neighbours, setNeighbours]=useState("");
  const [supportAtHome, setSupportAtHome] = useState("")
const [socialServiceComments, setsocialServiceComments] = useState("")
  
    const [mainCarere, setMainCarere] = useState()
  const [neighbourse, setNeighbourse]=useState();
  const [supportAtHomee, setSupportAtHomee] = useState();
  const [religiousBelief, setreligiousBelief] = useState("")
  const [religousBelieferr, setreligousBelieferr] = useState(false)

  const navigation = useNavigate();

  const saveData = () =>{
	  saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
		LivesAlone: sessionStorage.getItem("Lives Alone?"),
		AnySupport: sessionStorage.getItem("Any Support?"),
	  },
      {
		Stairs: sessionStorage.getItem("Stairs In Home:"),
		Amenities: sessionStorage.getItem("Amenities"),
		AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
		OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
		Amendments: sessionStorage.getItem("Amendments Required:"),
		OtherAmendments: sessionStorage.getItem("Required Amendments"),
	  },
      {
		Patient: sessionStorage.getItem("PatientPlans"),
		Relative: sessionStorage.getItem("RelativePlans"),
	  },
      sessionStorage.getItem("Array"),
      {
		Apps: sessionStorage.getItem("CommunityApps:"),
		TimeSpan: sessionStorage.getItem("TimeSpan"),
	  },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient"),
	  true,
	  false
    );
  }
  
  useEffect(() => {
    //     sessionStorage.setItem("CommCare", "");
    //     sessionStorage.setItem("HomeHelp", "");
    //     sessionStorage.setItem("Telecare", "");
    //     sessionStorage.setItem("Meals On Wheels", "");
    //     sessionStorage.setItem("Other", "");
    //     sessionStorage.setItem("Other Profession:", "");
    // // //////////////////////
    //     sessionStorage.setItem("TimeSpan", "");
    setRadio(sessionStorage.getItem("CommunityApps:"));
    setResult(sessionStorage.getItem("TimeSpan"));
    setOtherText(sessionStorage.getItem("Other Profession:"));
    setShowOtherText(sessionStorage.getItem("Other Profession:"));
    let a = sessionStorage.getItem("Array");
    console.log(a);
    if (a) setExp(a.split(", "));
    else setExp([]);
    console.log(exp);
	
	setMainCarer(sessionStorage.getItem("mainCarerSW"))
	setNeighbours(sessionStorage.getItem("neighboursSW"))
	setSupportAtHome(sessionStorage.getItem("supportAtHomeSW"))
	setsocialServiceComments(sessionStorage.getItem("socialServiceComments"))
	setreligiousBelief(sessionStorage.getItem("religiousBelief"))

    // if (a){
    //   setExp(a)
    // }
  }, []);

  // useEffect(() => {
  //   setRadio(sessionStorage.getItem("Lives Alone?"))
  //   setResult(sessionStorage.getItem("Any Support?"))
  // },[])

  const handleText = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("Other Profession:", value);
    setOtherText(value);
    setShowOtherText(value);
    // this.state.exp.push(value);
    // sessionStorage.setItem("Array", this.state.exp);
  };

  const handleChange = (event) => {
    var value = event.target.value;
    // console.log(value);
    setSRadio(value);
    var testing = [];

    if (value == "Other") {
      // setOtherText(true);
      // sessionStorage.setItem(value, "Yes");
      setFragments(testing);
    } else {
      let e = exp || [];
	  const stringv = exp.find(a =>a.includes(value))
      let found = e.indexOf(stringv);
      console.log(found);
      //found
      if (found >= 0) {
        //remove the value name
        e = e.filter((v) => v != stringv);
      } else {
        //not found then add
        e.push(value);
      }
      setExp(e);
      console.log(e, exp);
    }
  };
  
  const handleDetailChange = (event, v) =>{
	var value = event.target.value;
    // console.log(value);
    var testing = [];


      let e = exp || [];
      let found = e.indexOf(v);
      console.log(found);
      //found
      if (found >= 0) {
		  e[found] = e[found] + " - " + value
      }
      setExp(e);
      console.log(e, exp);
    
  }

  const handleClick = () => {
    // this.state.exp.push(sessionStorage.getItem("Other Profession:"));
    // var value = sessionStorage.getItem("Other Profession:");
    // if (value !== "") {
    //   let e = exp;
    //   e.push(value);
    //   setExp(e);
    // }
	
	sessionStorage.setItem("mainCarerSW", mainCarer)
	sessionStorage.setItem("neighboursSW", neighbours)
	sessionStorage.setItem("supportAtHomeSW", supportAtHome)
	sessionStorage.setItem("religiousBelief", religiousBelief)

    sessionStorage.setItem("Array", exp.join(", "));
    if (!otherText) {
      setShowOtherText(null);
      sessionStorage.removeItem("Other Profession:");
      setError5(false)
    }
    if(otherText){
      if(showotherText) setError5(false)
      else setError5(true)

    }
	let blocked = false
	if(!mainCarer || mainCarer==""){
		setMainCarere(true)
		blocked=true
	}else setMainCarere(false)
	
		if(!neighbours || neighbours==""){
		setNeighbourse(true)
		blocked=true
	}else setNeighbourse(false)
	
		if(!supportAtHome || supportAtHome==""){
		setSupportAtHomee(true)
		blocked=true
	}else setSupportAtHomee(false)
		
		if(!religiousBelief || religiousBelief==""){
		setreligousBelieferr(true)
		blocked=true
	}else setreligousBelieferr(false)
	
	sessionStorage.setItem("socialServiceComments", socialServiceComments)
	saveData()
    if (radio != null) {
      if (radio == "Yes") {
        if (!result) {
          setError(true);
          console.log("1 hiuhoih");
        } else {
          setError(false);
          console.log("1" + result);
		  if(!blocked)
          navigation("/SH-Relative-Plans");
        }
      }

      if (radio == "No") {
        console.log("2");
		if(!blocked)
        navigation("/SH-Relative-Plans");
      }
    } else {
      setError(true);
    }

    // navigation("/SH-Relative-Plans");
  };

  const handleChangeLTC = (event) => {
    var value = event.target.value;

    sessionStorage.setItem("CommunityApps:", value);
    var testing = [];
    setRadio(value);
    setResult("");
    if (value === "No") {
      setNext(false);
      sessionStorage.setItem("TimeSpan", "");
    }
  };

  const readNewForm = (event) => {
    var value = event.target.value;
    setResult(value);
    sessionStorage.setItem("TimeSpan", value);
    setNext(false);
  };

  var typography =
    "Q1: Tick as many services patient is using. Add additional others as    necessary.\n \nQ2: Click if patient has prior LTC application.";

const searchSector = (value)=>{
	let temp = exp 
	if(!exp || exp.length==0) temp = sessionStorage.getItem("Array").split(", ")
		console.log(temp)
	if(temp && temp.length>0){
	const index = temp.find(a =>a.includes(value+" - "))
	const stringv = isNaN(index) ? index : temp[index]
	console.log(stringv, index, value)
	if(stringv && stringv.includes("Government")){
		return "Government"
	}else if(stringv && stringv.includes("Private")) return "Private"
	}
}
			console.log(exp.find(a =>a.includes("CommCare")), exp.find(a =>a.includes("CommCare"))? true:false, "commcare")
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        {/* <Grid
        justifyContent={"center"}
        container
        rowGap={4}
        columns={{ xs: 2, sm: 2, md: 6 }}
      > */}
        <Header
          typography={typography}
          name={"Social Services"}
          history={"/SH-Home-Env"}
        ></Header>
		 <h1>Informal Support</h1>
        <div className="social-worker-container">
	<div style={{display:"flex", flexDirection:"column"}}>
              <div  style={{ width: "90%" }}>
			  <label>Main Carer</label>
                <TextField
                  style={{ width: "100%" }}
                  onChange={(e)=> setMainCarer(e.target.value)}
                  value={mainCarer}
                  defaultValue={mainCarer}
				  helperText="Enter main carer name, relation and contact"
                ></TextField>
                <h4
                    className="alert"
                    id="question1-alert"
                    style={{
                      display: mainCarere ? null : "none",
                      marginTop: "0.5em",
                      fontSize: "0.8em",
                    }}
                >
                  Please input text
                </h4>
              </div>
		
		      <div  style={{ width: "90%" }}>
			  <label>Any neighbour/relative who offers support?</label>
                <TextField
                  style={{ width: "100%" }}
                  onChange={(e)=> setNeighbours(e.target.value)}
                  value={neighbours}
                  defaultValue={neighbours}
                ></TextField>
                <h4
                    className="alert"
                    id="question1-alert"
                    style={{
                      display: neighbourse ? null : "none",
                      marginTop: "0.5em",
                      fontSize: "0.8em",
                    }}
                >
                  Please input text
                </h4>
              </div>
			  
			     <div  style={{ width: "90%" }}>
			  <label>Level of support at home</label>
                <TextField
                  style={{ width: "100%" }}
                  onChange={(e)=> setSupportAtHome(e.target.value)}
                  value={supportAtHome}
                  defaultValue={supportAtHome}
                ></TextField>
                <h4
                    className="alert"
                    id="question1-alert"
                    style={{
                      display: supportAtHomee ? null : "none",
                      marginTop: "0.5em",
                      fontSize: "0.8em",
                    }}
                >
                  Please input text
                </h4>
              </div>
			  
              </div>

        </div>
		
        <h1>Social Services</h1>
        <div className="social-worker-container">
          {/* <List> */}
          {/* <ListItem> */}
          <FormControl>
            <FormGroup
              // className="search-container"
              onChange={handleChange}
            >
			<div>
              <FormControlLabel
                checked={exp.find(a =>a.includes("CommCare"))? true:false}
                value={"CommCare"}
                control={<Checkbox />}
                label="CommCare"
              />
			  {exp.find(a =>a.includes("CommCare")) &&  <Select
                    style={{ fontSize: "14px", width:"100%"}}
                    size="small"
                    value={searchSector("CommCare")}
                    onChange={(e) => handleDetailChange(e, "CommCare")}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Government"}>
                      Government
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Private"}>
                     Private
                    </MenuItem>
					
			  </Select>}
			  </div>
              <FormControlLabel
		 checked={exp.find(a =>a.includes("HomeHelp"))? true:false}

                value={"HomeHelp"}
                control={<Checkbox />}
                label="HomeHelp"
              />
			  {exp.find(a =>a.includes("HomeHelp")) && <Select
                    style={{ fontSize: "14px", width:"100%"}}
                    size="small"
                    value={searchSector("HomeHelp")}
                    onChange={(e) => handleDetailChange(e, "HomeHelp")}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Government"}>
                      Government
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Private"}>
                     Private
                    </MenuItem>
					
			  </Select>}
              <FormControlLabel
			  	checked={exp.find(a =>a.includes("Telecare"))? true:false}
                value={"Telecare"}
                control={<Checkbox />}
                label="Telecare"
              />
			  {exp.find(a =>a.includes("Telecare")) &&  <Select
                    style={{ fontSize: "14px", width:"100%"}}
                    size="small"
                    value={searchSector("Telecare")}
                    onChange={(e) => handleDetailChange(e, "Telecare")}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Government"}>
                      Government
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Private"}>
                     Private
                    </MenuItem>
					
			  </Select>}
              <FormControlLabel
			  checked={exp.find(a =>a.includes("Meals On Wheels"))? true:false}

                value={"Meals On Wheels"}
                control={<Checkbox />}
                label="Meals On Wheels"
              />
			  {exp.find(a =>a.includes("Meals On Wheels")) &&  <Select
                    style={{ fontSize: "14px", width:"100%"}}
                    size="small"
                    value={searchSector("Meals On Wheels")}
                    onChange={(e) => handleDetailChange(e, "Meals On Wheels")}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Government"}>
                      Government
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Private"}>
                     Private
                    </MenuItem>
					
			  </Select>}
              <FormControlLabel
                checked={otherText}
                value={"Other"}
                control={<Checkbox />}
                label="Other"
                onClick={(e) => {
                  if (otherText) {
                    setOtherText(false);
                    sessionStorage.setItem("Other", "No");
                  } else {
                    setOtherText(true);
                    sessionStorage.setItem("Other", "Yes");
                  }
                }}
              />
            </FormGroup>
		{otherText && (	<Select
                    style={{ fontSize: "14px", width:"100%"}}
                    size="small"
                    value={searchSector("Meals On Wheels")}
                    onChange={(e) => handleDetailChange(e, "Other")}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"Government"}>
                      Government
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"Private"}>
                     Private
                    </MenuItem>
					
		</Select>)}
            {otherText && (
              <div className="search-container" style={{ width: "90%"}}>
                <TextField
                  style={{ width: "100%" }}
                  onChange={handleText}
                  label="Enter Social Service"
                  value={showotherText}
                  defaultValue={showotherText}
                ></TextField>
                <h4
                    className="alert"
                    id="question1-alert"
                    style={{
                      display: error5 ? null : "none",
                      marginTop: "0.5em",
                      fontSize: "0.8em",
                    }}
                >
                  Please input text
                </h4>
              </div>
            )}
			<label>Other comments</label>
			 <TextField
                  style={{ width: "100%" }}
                  onChange={(e)=> setsocialServiceComments(e.target.value)}
                  value={socialServiceComments}
                  defaultValue={socialServiceComments}
                ></TextField>
            {/* <div className="search-container"> */}
          </FormControl>
          {/* </ListItem> */}
          {/* </List> */}
        </div>
 <h1>Religious Belief</h1>
        <div className="social-worker-container" style={{ display:"flex" , flexDirection:"column"}}>
		<TextField
                  style={{ width: "100%" }}
                  onChange={(e)=> setreligiousBelief(e.target.value)}
                  value={religiousBelief}
                  defaultValue={religiousBelief}
				  label="Enter patient religious belief"
                ></TextField>
				 <h4
                    className="alert"
                    id="question1-alert"
                    style={{
                      display: religousBelieferr ? null : "none",
                      marginTop: "0.5em",
                      fontSize: "0.8em",
                    }}
                >
                  Please input text
                </h4>
		</div>
        <h1>LTC Application</h1>
        {/* <List> */}
        {/* <ListItem> */}
        <div className="social-worker-container" style={{ width: "70%" }}>
          <FormControl>
            <RadioGroup
              // className="search-container"
              onChange={handleChangeLTC}
              value={radio}
            >
              <FormControlLabel value={"Yes"} control={<Radio />} label="Yes" />
              <div className="search-container" style={{ width: "80%" }}>
                <RadioGroup onChange={readNewForm} value={result} row>
                  <FormControlLabel
                    value={"<6 Months"}
                    control={<Radio />}
                    label="<6 Months"
                    disabled={radio == "Yes" ? false : true}
                  />
                  <FormControlLabel
                    value={"6 - 12 Months"}
                    control={<Radio />}
                    label="6 - 12 Months"
                    disabled={radio == "Yes" ? false : true}
                  />
                  <FormControlLabel
                    value={"1-2 Years"}
                    control={<Radio />}
                    label="1-2 Years"
                    disabled={radio == "Yes" ? false : true}
                  />
                  <FormControlLabel
                    value={"2-3 Years"}
                    control={<Radio />}
                    label="2-3 Years"
                    disabled={radio == "Yes" ? false : true}
                  />
                  <FormControlLabel
                    value={">3 Years"}
                    control={<Radio />}
                    label=">3 Years"
                    disabled={radio == "Yes" ? false : true}
                  />
                </RadioGroup>
              </div>
              {/* {fragments} */}
              <FormControlLabel value={"No"} control={<Radio />} label="No" />
            </RadioGroup>
            <h4
              className="alert"
              id="question1-alert"
              style={{
                display: error ? null : "none",
                marginTop: "0.5em",
                fontSize: "0.8em",
              }}
            >
              Please select an option
            </h4>
          </FormControl>
        </div>
        {/* </ListItem> */}
        {/* <ListItem>
            <Button
              onClick={handleClick}
              disabled={next}
              variant="contained"
              fullWidth={true}
            >
              Next
            </Button>
          </ListItem> */}
        {/* </List> */}
      </div>
      <BottomNav
        prevNavigation={() => {saveData(); navigation("/SH-Home-Env")}}
        nextNavigation={() => {
          handleClick();
          // navigation("/SH-Relative-Plans");
        }}
        currentPage={3}
        numPages={6}
        buttonDisabled={next}
      />
      {/* </Grid> */}
    </div>
  );
}
