import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BottomNav from "../../bottomnavg";
import Header from "../../header";
import { TextField } from "@mui/material";
import { maxWidth } from "@mui/system";
import { saveSocialWorker } from "../../firebase";


export default function AdditionalinfoSocialWorker() {
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [otherText, setOtherText] = useState("");
  const saveData = () =>{
	  saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
		LivesAlone: sessionStorage.getItem("Lives Alone?"),
		AnySupport: sessionStorage.getItem("Any Support?"),
	  },
      {
		Stairs: sessionStorage.getItem("Stairs In Home:"),
		Amenities: sessionStorage.getItem("Amenities"),
		AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
		OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
		Amendments: sessionStorage.getItem("Amendments Required:"),
		OtherAmendments: sessionStorage.getItem("Required Amendments"),
	  },
      {
		Patient: sessionStorage.getItem("PatientPlans"),
		Relative: sessionStorage.getItem("RelativePlans"),
	  },
      sessionStorage.getItem("Array"),
      {
		Apps: sessionStorage.getItem("CommunityApps:"),
		TimeSpan: sessionStorage.getItem("TimeSpan"),
	  },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient"),
	  true,
	  false
    );
  }
  function navToNextPage() {
	  saveData()
    navigate("/Summary-Social-Worker");
  }

  function navToPreviousPage() {
	  saveData()
    navigate("/SH-Relative-Plans");
  }

  useEffect(() => {
    setOtherText(sessionStorage.getItem("Add Info"));
  }, []);

  const handleText = (event) => {
    var value = event.target.value;
    console.log(value);
    setResult(value);
    //
    setOtherText(value);
    sessionStorage.setItem("Add Info", value);
  };
  var typography = "Add any additional comments as necessary.";
  return (
    <div className="screen" style={{justifyContent:"space-between"}}>
      <div className="screen" style={{width:"100%", minHeight:"auto"}}>
      <Header history={"/SH-Relative-Plans"}typography={typography} />
      <h1>Additional Info</h1>
      <div className="social-worker-container">
        <TextField
          multiline="true"
          rows="15"
          onChange={handleText}
          placeholder="Add additional information about the patient"
          // style={{ width: "100%" }}
          // label="Enter Social Service"
          value={otherText}
          defaultValue={otherText}
        ></TextField>
      </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => navToNextPage()}
        currentPage={5}
        numPages={6}
      ></BottomNav>
    </div>
  );
}
