import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BottomNav from "../bottomnavg";
import Header from "../header";
import { TextField } from "@mui/material";
import { maxWidth } from "@mui/system";
import { saveNurse } from "../firebase";

export default function AdditionalinfoNurse() {
  const navigate = useNavigate();
  const location = useLocation()
  const [result, setResult] = useState("");
  const [otherText, setOtherText] = useState("");

  function navToNextPage() {
	  	  saveData()

	   if(location?.state?.pathname=="/SummaryPageNurse") navigate("/SummaryPageNurse");
    else
    navigate("/SummaryPageNurse");
  }

   const saveData =() => {

const alldata=  {
    addmission: sessionStorage.getItem("BarthelIndexAdmission"),
    discharge: sessionStorage.getItem("BarthelIndexDischarge"),
    BarthelDifference: sessionStorage.getItem("BarthelIndex"),
    CalculatedHeight: sessionStorage.getItem("Patient-CalculatedHeight"),
    Weight: sessionStorage.getItem("Patient-Weight"),
    ExerciseWeightLoss: sessionStorage.getItem("Due to exercise?"), //yes or no,
    WeightLoss: sessionStorage.getItem("Weight Lost?"), //yes or no
    OtherProfessionReferredSLP: sessionStorage.getItem("Nurse-SLP"),
    OtherProfessionReferredPodiatry: sessionStorage.getItem("Nurse-Podiatry"),
    OtherProfessionReferredNaD: sessionStorage.getItem("Nurse-NaD"),
    OtherProfessionReferredPsychology:
      sessionStorage.getItem("Nurse-Psychology"),
    OtherProfessionReferredPsychiatry:
      sessionStorage.getItem("Nurse-Psychiatry"),
    OtherProfessionReferredOther: sessionStorage.getItem("Nurse-Other"),
    AddInfo: sessionStorage.getItem("NurseAdditionalInfo"),
	Appetite: sessionStorage.getItem("Appetite3"),
	FeedingRoute: sessionStorage.getItem("Feeding-Route3"),
	AssistiveDevices: sessionStorage.getItem("assistive-devices-required"),
	WoundPresent: sessionStorage.getItem("wound-present"),
	SuturesClipsPresent: sessionStorage.getItem("Sutures-Clips-Present"),
	draft: true
  };
  	  saveNurse(alldata, false)

  }
  
  function navToPreviousPage() {
saveData()
    navigate("/OtherProfRef");
  }

  useEffect(() => {
    setOtherText(sessionStorage.getItem("NurseAdditionalInfo"));
  }, []);

  const handleText = (event) => {
    var value = event.target.value;
    console.log(value);
    setResult(value);
    //
    setOtherText(value);
    sessionStorage.setItem("NurseAdditionalInfo", value);
  };
  var typography = "Add any additional comments as necessary.";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header history={"/SH-Relative-Plans"} typography={typography} />
        <h1>Additional Info</h1>
        <div className="social-worker-container">
          <TextField
            multiline="true"
            rows="15"
            onChange={handleText}
            placeholder="Add additional information about the patient"
            // style={{ width: "100%" }}
            // label="Enter Social Service"
            value={otherText}
            defaultValue={otherText}
          ></TextField>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => navToNextPage()}
        currentPage={5}
        numPages={6}
      ></BottomNav>
    </div>
  );
}
