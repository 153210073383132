import React, { useState } from "react";
import Header from "../header";
import { Grid, TextField, List, ListItem, Button } from "@mui/material";
import { typography } from "@mui/system";
import "../styles/HomeContent.css";
import "../styles/Summary.css";
import { useNavigate } from "react-router-dom";
import { setPatient } from "../firebase";
import { Snackbar } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function CreatePatient() {
  const navigation = useNavigate();

  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const [gender, setGender] = useState(false);
  const [age, setAge] = useState(false);
  const [housename, setHouseName] = useState("");
  const [houseNum, setHouseNum] = useState(0);
  const [streetname, setStreetname] = useState("");
  const [kinName, setKinName] = useState("");
  const [KinNumber, setKinNumber] = useState("");
  const [locality, setLocality] = useState("");
  const [AdmitConsultant, setAdmitConsultant] = useState("");
  const [AdmitWard, setAdmitWard] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [open, setOpen] = useState(false);
  const [kinRelation, setKinRelation] = useState("");
  const [AdmitLastYear, setAdmitLastYear] = useState("");

  const getName = (event) => {
    let value = event.target.value;
    setName(value);
  };

  const getID = (event) => {
    let value = event.target.value;
    setId(value);
  };

  const submitData = () => {
    let patient = {};
    patient.name = name;
    patient.id = id;
    patient.gender = gender;
    patient.age = age;
    patient.zipcode = zipcode;
    patient.houseNum = houseNum;
    patient.houseName = housename;
    patient.streetname = streetname;
    patient.KinNumber = KinNumber;
    patient.kinRelation = kinRelation;
    patient.kinName = kinName;
    patient.locality = locality;
    patient.AdmitConsultant = AdmitConsultant;
    patient.AdmitWard = AdmitWard;
    patient.AdmitLastYear = AdmitLastYear;

    setPatient(patient);
    setOpen(true);
  };

  const goBack = () => {
    navigation("/AdminMenu");
  };

  const getGender = (event) => {
    let value = event.target.value;
    setGender(value);
  };

  const getAge = (event) => {
    let value = event.target.value;
    setAge(value);
  };

  const getHouseName = (event) => {
    let value = event.target.value;
    setHouseName(value);
  };

  const getZipCode = (event) => {
    let value = event.target.value;
    setZipCode(value);
  };
  const getHouseNum = (event) => {
    let value = event.target.value;
    setHouseNum(value);
  };

  const getStreetName = (event) => {
    let value = event.target.value;
    setStreetname(value);
  };

  const getKinNum = (event) => {
    let value = event.target.value;
    setKinNumber(value);
  };

  const getKinRelation = (event) => {
    let value = event.target.value;
    setKinRelation(value);
  };
  const getKinName = (event) => {
    let value = event.target.value;
    setKinName(value);
  };

  const getLocality = (event) => {
    let value = event.target.value;
    setLocality(value);
  };

  const getAdmitConsultant = (event) => {
    let value = event.target.value;
    setAdmitConsultant(value);
  };

  const getAdmitWard = (event) => {
    let value = event.target.value;
    setAdmitWard(value);
  };

  const getAdmitLastYear = (event) => {
    let value = event.target.value;
    setAdmitLastYear(value);
  };

  var typography = "This page is to create a new patient";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button onClick={goBack}>Go Back to Login</Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="screen">
      <Grid
        justifyContent={"center"}
        container
        rowGap={4}
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <Header
          history={"/AdminMenu"}
          typography={typography}
          name={"Create Patient Page"}
        ></Header>

        <div className="search-container">
          <List>
            <ListItem>
              <TextField
                value={name}
                onChange={getName}
                label={"Full Name"}
              ></TextField>
            </ListItem>

            <ListItem>
              <TextField
                value={id}
                onChange={getID}
                label={"Patient ID"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={gender}
                onChange={getGender}
                label={"Gender"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={age}
                onChange={getAge}
                label={"Age"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={housename}
                onChange={getHouseName}
                label={"House Name"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={houseNum}
                onChange={getHouseNum}
                label={"House No."}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={streetname}
                onChange={getStreetName}
                label={"Street Name"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={zipcode}
                onChange={getZipCode}
                label={"ZipCode"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={kinName}
                onChange={getKinName}
                label={"Kin Name"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={KinNumber}
                onChange={getKinNum}
                label={"Kin Contact Number"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={kinRelation}
                onChange={getKinRelation}
                label={"Kin Relation"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={locality}
                onChange={getLocality}
                label={"Locality"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={AdmitConsultant}
                onChange={getAdmitConsultant}
                label={"Admitting Consultant"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={AdmitWard}
                onChange={getAdmitWard}
                label={"Admitting Ward"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={AdmitLastYear}
                onChange={getAdmitLastYear}
                label={"Admissions Last Year"}
              ></TextField>
            </ListItem>

            <ListItem>
              <Button onClick={submitData} fullWidth={true} variant="contained">
                Create Patient
              </Button>
            </ListItem>
          </List>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message="Great Success!"
          action={action}
        />
      </Grid>
    </div>
  );
}
