import React from "react";
import Header from "../header";
import { Grid, TextField, List, ListItem, Button } from "@mui/material";
import { useState } from "react";
import { typography } from "@mui/system";
import "../styles/HomeContent.css";
import "../styles/Summary.css";
import { useNavigate } from "react-router-dom";
import { setUser } from "../firebase";
import { Snackbar } from "@mui/material";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function CreateUsers() {
  const [name, setName] = useState("");
  const [id, setId] = useState(0);
  const [pass, setPass] = useState("");
  const [roles, setRoles] = useState("");
  const [user, setAUser] = useState("");
  const [open, setOpen] = useState(false);

  const navigation = useNavigate();

  const getName = (event) => {
    let value = event.target.value;
    setName(value);
  };

  const getID = (event) => {
    let value = event.target.value;
    setId(value);
  };

  const submitData = () => {
    let patient = {};
    patient.name = name;
    patient.id = id;
    patient.pass = pass;
    patient.user = user;
    patient.roles = roles;
    console.log(patient);
    setUser(patient);
    setOpen(true);
  };

  const goBack = () => {
    navigation("/");
  };

  const getPass = (event) => {
    let value = event.target.value;
    setPass(value);
  };

  const getRoles = (event) => {
    let value = event.target.value;
    setRoles(value);
  };

  const getUser = (event) => {
    let value = event.target.value;
    setAUser(value);
  };

  var typography = "This page is to create a new User";

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <Button onClick={goBack}>Go Back to Login</Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="screen">
      <Grid
        justifyContent={"center"}
        container
        rowGap={4}
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <Header
          history={"/AdminMenu"}
          typography={typography}
          name={"Create Patient Page"}
        ></Header>

        <div className="search-container">
          <List>
            <ListItem>
              <TextField
                value={name}
                onChange={getName}
                label={"Full Name"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={id}
                onChange={getID}
                label={"User ID"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={roles}
                onChange={getRoles}
                label={"Roles"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={user}
                onChange={getUser}
                label={"UserName"}
              ></TextField>
            </ListItem>
            <ListItem>
              <TextField
                value={pass}
                onChange={getPass}
                label={"Password"}
              ></TextField>
            </ListItem>
            <ListItem>
              <Button onClick={submitData} fullWidth={true} variant="contained">
                Create User
              </Button>
            </ListItem>
          </List>
        </div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          message="Great Success!"
          action={action}
        />
      </Grid>
    </div>
  );
}
