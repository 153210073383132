import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import "../styles/HomeContent.css";
import { List, ListItem } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { Radio } from "@mui/material";
import { FormControl } from "@mui/material";
import "../styles/header.css";
import BottomNav from "../bottomnavg";
import { saveDataSectionB as saveData } from "../firebase";

export default function LivingStatus() {
  const [radio, setRadio] = useState(false);
  const [error, setError] = useState(false);
  const navigation = useNavigate();
  const [relative, setRelative] = useState(false);
  const [error1, setError1] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("SocialStatus"))
      setRadio(sessionStorage.getItem("SocialStatus"));
    if (sessionStorage.getItem("RelativePlans"))
      setRelative(sessionStorage.getItem("RelativePlans"));
  }, []);

  const handleChange = (event) => {
    var value = event.target.value;
    setRadio(value);
    sessionStorage.setItem("SocialStatus", value);
  };

  function validation() {
    if (!radio || radio == "" || radio == "false") {
      setError(true);
    } else setError(false);

    if (!relative || relative == "" || relative == "false") {
      setError1(true);
    } else {
      setError1(false);
    }
    saveData();
    if (radio && relative) {
      navigation("/v2/moca");
    }
  }

  const handleChangeR = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("RelativePlans", value);
    setRelative(value);
  };

  var typography = "Click on the appropriate home support level.";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          history={"/Patient-ID-Social-Worker"}
          typography={typography}
          name={"Social"}
        ></Header>
        <h1>Social</h1>
        <List
          className="search-container"
          style={{ paddingLeft: "2em", width: "80%" }}
        >
          <ListItem>
            <FormControl>
              <h4>Living Arrangements</h4>
              <RadioGroup onChange={handleChange} value={radio}>
                <FormControlLabel
                  value={"Lives Alone"}
                  control={<Radio />}
                  label="Lives Alone"
                />
                <FormControlLabel
                  value={"Residential Home"}
                  control={<Radio />}
                  label="Residential Home"
                />
                <FormControlLabel
                  value={"Live-in carer"}
                  control={<Radio />}
                  label="Live-in carer"
                />
                <FormControlLabel
                  value={"Children"}
                  control={<Radio />}
                  label="Children"
                />
                <FormControlLabel
                  value={"Partner/Spouse/Sibling of same age"}
                  control={<Radio />}
                  label="Partner/Spouse/Sibling of same age"
                />
                <h4
                  className="alert"
                  id="question1-alert"
                  style={{
                    display: error ? null : "none",
                    marginTop: "0.5em",
                    fontSize: "0.8em",
                  }}
                >
                  Please select an option
                </h4>
              </RadioGroup>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl>
              <h5 style={{ marginTop: "1em" }}>
                Main Carer's plan for patient after rehabilitation
              </h5>
              <RadioGroup onChange={handleChangeR} value={relative}>
                <FormControlLabel
                  value={"Return Home"}
                  control={<Radio />}
                  label="Return Home"
                />
                <FormControlLabel
                  value={"Re-location of own Home"}
                  control={<Radio />}
                  label="Re-location of own Home"
                />

                <FormControlLabel
                  value={"Residential Care Home Admission"}
                  control={<Radio />}
                  label="Residential Care Home Admission"
                />
                <FormControlLabel
                  value={"Undecided"}
                  control={<Radio />}
                  label="Undecided"
                />
                <FormControlLabel
                  value={"Unable"}
                  control={<Radio />}
                  label="Unable"
                />
                <FormControlLabel
                  value={"Not present/none available"}
                  control={<Radio />}
                  label="Not present/none available"
                />
              </RadioGroup>
              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: error1 ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please select an option
              </h4>
            </FormControl>
          </ListItem>
        </List>
      </div>
      <BottomNav
        nextNavigation={() => validation()}
        currentPage={3}
        numPages={19}
        prevNavigation={() => {
          saveData();
          navigation("/v2/mobilityLevel");
        }}
      />
    </div>
  );
}
