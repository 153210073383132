import React, { useEffect } from "react";
import Header from "../../header";
import { Grid, TextField } from "@mui/material";
import { Box, fontSize } from "@mui/system";
import "../../styles/HomeContent.css";
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { Button } from "@mui/material";
import { useState } from "react";
import "../../styles/header.css";
import SHHomeSupport from "./Social-History-Home-Support";
import { getPatients } from "../../firebase";
import "../../firebase";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { saveSocialWorker } from "../../firebase";

export default function PatientIDSW() {
  const [currentPatient, setCurrentPatient] = useState({
    id: 0,
    name: "",
    surname: "",
    gender: "",
  });
  const [patients, setPatients] = useState([]);
  const [fragments, setFragments] = useState(<></>);

const saveData = () =>{
	  saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
		LivesAlone: sessionStorage.getItem("Lives Alone?"),
		AnySupport: sessionStorage.getItem("Any Support?"),
	  },
      {
		Stairs: sessionStorage.getItem("Stairs In Home:"),
		Amenities: sessionStorage.getItem("Amenities"),
		AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
		OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
		Amendments: sessionStorage.getItem("Amendments Required:"),
		OtherAmendments: sessionStorage.getItem("Required Amendments"),
	  },
      {
		Patient: sessionStorage.getItem("PatientPlans"),
		Relative: sessionStorage.getItem("RelativePlans"),
	  },
      sessionStorage.getItem("Array"),
      {
		Apps: sessionStorage.getItem("CommunityApps:"),
		TimeSpan: sessionStorage.getItem("TimeSpan"),
	  },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient"),
	  true,
	  false
    );
  }
  
  const navigation = useNavigate();

  useEffect(() => {
    let role = sessionStorage.getItem("roles");
    sessionStorage.clear();
    sessionStorage.setItem("roles", role);
    getPatients().then((results) => {
      setPatients(results);
    });
  }, []);

  useEffect(() => {
    if (currentPatient.id !== 0) {
      // return <SHHomeSupport ahwStore = {ahw} history = {"/"} patient = {this.state.currentPatient}/>
      navigation("/SH-Home-Support");
    }
  }, [currentPatient]);

  const SetCurrentPatient = (patient) => {
    //console.log("hello");
    setCurrentPatient(patient);
    sessionStorage.setItem("patientId", patient.id);
  };

  const DisplayPatients = (event) => {
    var value = event.target.value.toUpperCase();
    var testing = [];

    if (value !== "") {
      //console.log(getPatients());
      patients.map((patient, index) => {
        var tempID = patient.id.toUpperCase().substr(0, value.length);
        let regex = new RegExp(tempID, "g");

        if (regex.test(value)) {
          testing.push(
            <React.Fragment key={index}>
              <ListItemButton onClick={() => SetCurrentPatient(patient)}>
                <ListItemText primary={patient.name} secondary={patient.id} />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        }
      });
      setFragments(testing);
    } else {
      setFragments(<></>);
    }
  };

  var typography =
    "Input a Patient ID to be directed to his Barthel & Measurements Summary Page";
  // console.log(getPatients());

  return (
    <Grid
      justifyContent={"center"}
      rowGap={4}
      container
      columns={{ xs: 2, sm: 2, md: 6 }}
    >
      <Header
        typography={typography}
        history={"/"}
        item
        name={"Patient Page"}
      />

      <Grid justifyContent={"center"} container item>
        <div className="search-container">
          <TextField
            label={"Patient ID"}
            onChange={DisplayPatients}
          ></TextField>
          <List>
            <Divider />
            {fragments}
          </List>
        </div>
      </Grid>
      <Grid container item></Grid>
    </Grid>
  );
}
