import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import BottomNav from "../bottomnavg";
import Button from "@mui/material/Button";
import { saveTeam3 } from "../firebase";

function MOCAResults() {
  const [score, setScore] = useState(0);
  const [mis, setMIS] = useState(0);
  const [max, setMax] = useState(30);
  const [viso, setviso] = useState(0);
  const [name, setname] = useState(0);
  const [attn, setattn] = useState(0);
  const [lang, setlang] = useState(0);
  const [abs, setabs] = useState(0);
  const [recall, setrecall] = useState(0);
  const [orien, setorien] = useState(0);
  const [status, setstatus] = useState("");
  const [pathcomment, setPathcomment] = useState("");
  const [cubecomment, setCubecomment] = useState("");
  const [clockcomment, setClockcomment] = useState("");

  const navigate = useNavigate();
  
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  // This function will called only once
  useEffect(() => {
    loadDataOnlyOnce();
  }, []);
  const isNull = (v)=>{
	  if(!v || v=="null") return true
	  else return false;
  }

  function navToNextPage() {
    sessionStorage.setItem("moca-score", score);
    sessionStorage.setItem("moca-max", max);
    sessionStorage.setItem("moca-status", status);
	saveData()
    navigate("/AdditionalinfoGC");
  }

  function loadDataOnlyOnce() {
    setMIS(parseInt(sessionStorage.getItem("mis")));

    sessionStorage.setItem("max", "30");

    if (isNull(sessionStorage.getItem("visuo"))) {
      sessionStorage.setItem("visuo", 0);
    }

    if (isNull(sessionStorage.getItem("naming"))) {
      sessionStorage.setItem("naming", 0);
    }

    if (isNull(sessionStorage.getItem("attention"))) {
      sessionStorage.setItem("attention", 0);
    }

    if (isNull(sessionStorage.getItem("abstraction"))) {
      sessionStorage.setItem("abstraction", 0);
    }

    if (isNull(sessionStorage.getItem("language"))) {
      sessionStorage.setItem("language", 0);
    }

    if (isNull(sessionStorage.getItem("delayedrecall"))) {
      sessionStorage.setItem("delayedrecall", 0);
    }

    if (isNull(sessionStorage.getItem("orientation"))) {
      sessionStorage.setItem("orientation", 0);
    }

    setviso(parseInt(sessionStorage.getItem("visuo")));
    setname(parseInt(sessionStorage.getItem("naming")));
    setattn(parseInt(sessionStorage.getItem("attention")));
    setlang(parseInt(sessionStorage.getItem("language")));
    setabs(parseInt(sessionStorage.getItem("abstraction")));
    setrecall(parseInt(sessionStorage.getItem("delayedrecall")));
    setorien(parseInt(sessionStorage.getItem("orientation")));

    //Get text in Comment boxes in Section 3-5
    setPathcomment(sessionStorage.getItem("MOCASec3Comment"));
    setCubecomment(sessionStorage.getItem("MOCASec4Comment"));
    setClockcomment(sessionStorage.getItem("MOCASec5Comment"));

    var total;

    total =
      parseInt(sessionStorage.getItem("visuo")) +
      parseInt(sessionStorage.getItem("naming")) +
      parseInt(sessionStorage.getItem("attention")) +
      parseInt(sessionStorage.getItem("language")) +
      parseInt(sessionStorage.getItem("abstraction")) +
      parseInt(sessionStorage.getItem("delayedrecall")) +
      parseInt(sessionStorage.getItem("orientation"));

    if (sessionStorage.getItem("eduCheck") === "1") {
      total = total + 1;
    }

    if (sessionStorage.getItem("blindCheck") === "1") {
      sessionStorage.setItem("max", "22");
      total =
        parseInt(sessionStorage.getItem("attention")) +
        parseInt(sessionStorage.getItem("language")) +
        parseInt(sessionStorage.getItem("abstraction")) +
        parseInt(sessionStorage.getItem("delayedrecall")) +
        parseInt(sessionStorage.getItem("orientation"));

        if (sessionStorage.getItem("eduCheck") === "1") {
          total = total + 1;
        }

    } else if (sessionStorage.getItem("writeCheck") === "1") {
      sessionStorage.setItem("max", "25");
      total =
        parseInt(sessionStorage.getItem("naming")) +
        parseInt(sessionStorage.getItem("attention")) +
        parseInt(sessionStorage.getItem("language")) +
        parseInt(sessionStorage.getItem("abstraction")) +
        parseInt(sessionStorage.getItem("delayedrecall")) +
        parseInt(sessionStorage.getItem("orientation"));

        if (sessionStorage.getItem("eduCheck") === "1") {
          total = total + 1;
        }
    }

    

    setMax(parseInt(sessionStorage.getItem("max")));

    sessionStorage.setItem("total ", total.toString());

    // var temptot = Math.round(
    //   (total * 30) / parseInt(sessionStorage.getItem("max"))
    // );

    // if (temptot > 30) {
    //   temptot = 30;
    // }

    // setScore(temptot);

    if(sessionStorage.getItem("max") == "22"  || sessionStorage.getItem("max") == "25"){
      console.log("skpdjisos kdl;snzscz")

      var temptot = Math.round(
        (total * 30) / parseInt(sessionStorage.getItem("max"))
      );
  
      if (temptot > 30) {
        temptot = 30;
      }
  
      setScore(temptot);
      if (temptot > 25) {
        setstatus("No Cognitive Impairment");
      } else if (temptot > 17) {
        setstatus("Mild Cognitive Impairment");
      } else if (temptot > 9) {
        setstatus("Moderate Cognitive Impairment");
      } else {
        setstatus("Severe Cognitive Impairment");
      }
      

      console.log(temptot +" "+"30")


    }
    else{
      
      if (total > 25) {
        setstatus("No Cognitive Impairment");
      } else if (total > 17) {
        setstatus("Mild Cognitive Impairment");
      } else if (total > 9) {
        setstatus("Moderate Cognitive Impairment");
      } else {
        setstatus("Severe Cognitive Impairment");
      }

      if (total > 30) {
        total = 30;
      }
      setScore(total);
    }

    // if (total > 25) {
    //   setstatus("No Cognitive Impairment");
    // } else if (total > 17) {
    //   setstatus("Mild Cognitive Impairment");
    // } else if (total > 9) {
    //   setstatus("Moderate Cognitive Impairment");
    // } else {
    //   setstatus("Severe Cognitive Impairment");
    // }

    // setScore(total);
    // if (total > 30) {
    //   total = 30;
    // }
    console.log(total)
    console.log(score)
  }

  var typography = "This page displays the Moca test Results. No changes allowed. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/sec15"}
          name={"Geriatric Consultant MOCA(R) Score"}
        />
        <h1>MoCA Test Results</h1>

        <table className="moca-summary-container">
          <tr className="grid-data">
            <td className="moca-table-left">
              <h5>Visuospatial/Executive:</h5>
            </td>
            <td className="moca-table-right">{viso}</td>
          </tr>
          <tr className="grid-data">
            <td className="moca-table-left-indent">Path Test:</td>
            <td className="moca-table-right-indent">{pathcomment}</td>
          </tr>
          <tr className="grid-data">
            <td className="moca-table-left-indent">Cube Test:</td>
            <td className="moca-table-right-indent">{cubecomment}</td>
          </tr>
          <tr className="grid-data">
            <td className="moca-table-left-indent">Clock Test:</td>
            <td className="moca-table-right-indent">{clockcomment}</td>
          </tr>

          <tr className="grid-data">
            <td className="moca-table-left">
              <h5>Naming:</h5>
            </td>
            <td className="moca-table-right">{name}</td>
          </tr>

          <tr className="grid-data">
            <td className="moca-table-left">
              <h5>Attention:</h5>
            </td>
            <td className="moca-table-right">{attn}</td>
          </tr>
          <tr className="grid-data">
            <td className="moca-table-left">
              <h5>Language:</h5>
            </td>
            <td className="moca-table-right">{lang}</td>
          </tr>
          <tr className="grid-data">
            <td className="moca-table-left">
              <h5>Abstraction:</h5>
            </td>
            <td className="moca-table-right">{abs}</td>
          </tr>
          <tr className="grid-data">
            <td className="moca-table-left">
              <h5>Delayed Recall:</h5>
            </td>
            <td className="moca-table-right">{recall}</td>
          </tr>
          <tr className="grid-data">
            <td className="moca-table-left">
              <h5>Orientation:</h5>
            </td>
            <td className="moca-table-right">{orien}</td>
          </tr>
          <tr className="grid-data"></tr>
        </table>
        {/* <div className="moca-summary-container"> */}
        <table className="moca-summary-container">
          <tr className="grid-data">
            {/* <td align="left" colspan="2"> */}
            <td className="moca-table-left">
              <h4>Total:</h4>
            </td>
            <td className="moca-table-right">
              <h4>
                {/* {score}/{max} */}
                {score}/{"30"}
              </h4>
            </td>
          </tr>
          <tr className="grid-data">
            {/* <td align="left" colspan="2"> */}
            <td className="moca-table-left">
              <h4>Status:</h4>
            </td>
            <td className="moca-table-right">
              <h4>{status}</h4>
            </td>
          </tr>
        </table>
      </div>

      <BottomNav
        nextNavigation={() => navToNextPage()}
        currentPage={19}
        numPages={21}
		prevNavigation={() => navigate("/sec15")}
      ></BottomNav>
    </div>
  );
}
export default MOCAResults;
