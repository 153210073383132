import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveDataSectionA as saveData } from "../firebase";

function Section9() {
  const [score, setScore] = useState(0);

  function getScore() {
    var score = parseInt(sessionStorage.getItem("letters"));

    var scoretemp =
      parseInt(sessionStorage.getItem("sub")) +
      score +
      parseInt(sessionStorage.getItem("num"));

    sessionStorage.setItem("attention", scoretemp.toString());

    setScore(score);
  }

  function onChangeSec9Check() {
    if (document.getElementById("letters").checked == true)
      sessionStorage.setItem("letters", "1");
    else sessionStorage.setItem("letters", "0");

    getScore();
  }

  useEffect(() => {
    if (
      sessionStorage.getItem("letters") &&
      sessionStorage.getItem("letters") == "1"
    ) {
      document.getElementById("letters").checked = true;
      getScore();
    }
  }, []);

  const navigate = useNavigate();

  var typography =
    "Vigilance\n\nRead the list of letters at a rate of one per second, after giving the        following instruction: “I am going to read a sequence of letters. Every time I say the letter A, tap your hand once. If I say a different letter, do not tap your hand”. \n\nScoring:\nGive one point if there is zero to one errors (an error is a tap on a wrong letter or a failure to tap on letter A).\n\nClick whether you would like to allocate the point or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec8"} name={"Attention"} />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          <h5 style={{ marginBottom: "1.5em", alignSelf: "flex-start" }}>
            Attention
          </h5>
          <p style={{ marginBottom: "1.5em", textAlign: "center" }}>
            Read the list of letters. Patient must tap with his hand at each
            letter A. No points if more than 2 errors.
          </p>
          <br />
          {/* Sorry min qalbi LOL */}
          <h5 style={{ textAlign: "center", fontSize: "1.5em" }}>
            F &nbsp; B &nbsp; A &nbsp; C &nbsp; M &nbsp; N &nbsp; A &nbsp; A
            &nbsp; J &nbsp; K &nbsp; L &nbsp; B &nbsp; A &nbsp; F &nbsp; A
            &nbsp; K &nbsp; D &nbsp; E &nbsp; A &nbsp; A &nbsp; A &nbsp; J
            &nbsp; A &nbsp; M &nbsp; O &nbsp; F &nbsp; A &nbsp; A &nbsp; B
            &nbsp;{" "}
          </h5>
          <br />
        </div>

        <div className="gc-container" style={{ marginTop: "1em" }}>
          <div style={{ paddingBottom: "0.5em" }}>
            <input
              className="check"
              type="checkbox"
              id="letters"
              onChange={onChangeSec9Check}
              style={{ marginLeft: "0" }}
            />
            <label>Correct </label>
          </div>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => {
          saveData();
          navigate("/v2/sec10");
        }}
        prevNavigation={() => {
          saveData();
          navigate("/v2/sec8");
        }}
        currentPage={11}
        numPages={19}
      />
    </div>
  );
}
export default Section9;
