import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { typography } from "@mui/system";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function WriteCheck() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  //let [doctor, setDoctor] = useState("");

  // function Validation() {
  //   if (doctor === "") {
  //     setOpen(true);
  //   } else {
  //     sessionStorage.setItem("doctor", doctor);
  //     special_next();
  //   }
  // }

  function special_next() {
    console.log("jcsdiods");
	saveData()
    //setOpen(true);
    if (document.getElementById("blindCheck").checked == true) {
      navigate("/sec7");
    } else if (
      document.getElementById("blindCheck").checked == false &&
      document.getElementById("writeCheck").checked == true
    ) {
      navigate("/sec6");
    } else {
      navigate("/sec3");
    }
  }
  useEffect(()=>{
  if(sessionStorage.getItem("eduCheck")== "1")
	  document.getElementById("eduCheck").checked = true
  
    if(sessionStorage.getItem("writeCheck")== "1")
	  document.getElementById("writeCheck").checked = true
  
    if(sessionStorage.getItem("blindCheck")== "1")
	  document.getElementById("blindCheck").checked = true
  }, [])
  function onChangeEduCheck() {
    if (document.getElementById("eduCheck").checked == true)
      sessionStorage.setItem("eduCheck", "1");
    else sessionStorage.setItem("eduCheck", "0");
  }

  function onChangeWriteCheck() {
    if (document.getElementById("writeCheck").checked == true)
      sessionStorage.setItem("writeCheck", "1");
    else sessionStorage.setItem("writeCheck", "0");
  }

  function onChangeBlindCheck() {
    if (document.getElementById("blindCheck").checked == true)
      sessionStorage.setItem("blindCheck", "1");
    else sessionStorage.setItem("blindCheck", "0");
  }

  const handleClose = () => {
    setOpen(false);
	saveData()
    if (document.getElementById("blindCheck").checked == true) {
      navigate("/sec7");
    } else if (
      document.getElementById("blindCheck").checked == false &&
      document.getElementById("writeCheck").checked == true
    ) {
      navigate("/sec6");
    } else {
      setOpen(false);
    }
  };

  var typography = "These questions will effect final MoCA score calculation. \n \nTest is altered based on the check boxes ticked on this page. \n\nTick the appropriate check boxes, if the patient has visual impairment, unable to write or has less than 12 years of education. Click the next    button to start the test.\n\nPatient is only allowed to carry the MoCA test once. ";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/mobility"}
          name={"Patient"}
        />

        <h1>MoCA Test</h1>
        <div className="gc-container">
          <h5 style={{ paddingBottom: "0.5em" }}>Tick Appropriate Checkbox:</h5>
          <div>
            <input
              className="check"
              type="checkbox"
              id="writeCheck"
              onClick={onChangeWriteCheck}
            />
            <label className="check-label">Patient is unable to write</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="blindCheck"
              onClick={onChangeBlindCheck}
            />
            <label className="check-label">Patient is visually impaired</label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="eduCheck"
              onClick={onChangeEduCheck}
            />
            <label className="check-label">
              Patient has less than 12 years of education
            </label>
          </div>
          <br />
          <br />
          <h5 className="alert">
            Certain parts of the test will be skipped if checkboxes are ticked.
            The test will be marked differently.
          </h5>
        </div>
      </div>


      <BottomNav
        prevNavigation={() => {saveData(); navigate("/mobility")}}
        nextNavigation={() =>special_next()}
        currentPage={5}
        numPages={21}
      />
    </div>
  );
}

export default WriteCheck;
