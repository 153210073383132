import "../styles/CommonStyle.css";
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveTeam2 } from "../firebase";

function GripStrength2() {
  const navigate = useNavigate();

  const [question1, setAnswerQuestion1] = useState("");
  const [question2, setAnswerQuestion2] = useState("");
  const [question3, setAnswerQuestion3] = useState("");
  // sessionStorage.setItem("question1", question1);
  // sessionStorage.setItem("question2", question2);
  // sessionStorage.setItem("question3", question3);
  var typography = "Click on the appropriate answers for all the questions. ";
  // console.log("Question 1: ", question1);
  // console.log("Question 2: ", question2);
  // console.log("Question 3: ", question3);
  // console.log("\n");


  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true,
		

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }
  useEffect(()=>{
    console.log(sessionStorage.getItem("gripStrength1"))
    setAnswerQuestion1(sessionStorage.getItem("gripStrength1"))
    setAnswerQuestion2(sessionStorage.getItem("gripStrength2"))
    setAnswerQuestion3(sessionStorage.getItem("gripStrength3"))
  }, [])



  useEffect(()=>{
    console.log(question1)
    sessionStorage.setItem("gripStrength1", question1)
  }, [question1])

  useEffect(()=>{
    console.log(question2)
    sessionStorage.setItem("gripStrength2", question2)
  }, [question2])

  useEffect(()=>{
    console.log(question3)
    sessionStorage.setItem("gripStrength3", question3)
  }, [question3])

  function validateForm() {
    // console.log("Entered into the validate form");
    if (question3 == "") {
      document.getElementById("question3-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question3Form").scrollIntoView(false);
    } else {
      document.getElementById("question3-alert").innerHTML = "";
    }
    if (question2 == "") {
      document.getElementById("question2-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question2Form").scrollIntoView();
    } else {
      document.getElementById("question2-alert").innerHTML = "";
    }
    if (question1 == "") {
      document.getElementById("question1-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question1Form").scrollIntoView();
    } else {
      document.getElementById("question1-alert").innerHTML = "";
    }
	saveData()
    if (question1 != "" && question2 != "" && question3 != "") {
      // console.log("Passing results to GripStrength");
      sessionStorage.setItem("question1", question1);
      sessionStorage.setItem("question2", question2);
      sessionStorage.setItem("question3", question3);
      // GripStrengthResults2(question1, question2, question3);
      navigate("/GripStrength3");
    }
  }

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/TimedTimer"}
          name={"Grip Strength Test"}
        />
        {/* <h1 style={{ paddingTop: "4%", paddingBottom: "4%", fontSize: "3em" }}> */}
        <h1> Hand Grip Strength Test</h1>
        {/* // data-role="controlgroup" data-type="horizontal" data-role="fieldcontain" */}
        <div
          className="main-section"
          style={{ alignItems: "flex-start", width: "70%" }}
        >
          {/* First question */}
          <form id="question1Form">
            <p className="grip-question-container">
              1. Which hand do you use to sign your name?
              <br />
              (This is the dominant hand)
            </p>
            <h5 className="alert-physio" id="question1-alert"></h5>
            {/* First question radio buttons */}
            <fieldset className="indented-radio-buttons fieldset">
              <div>
                <input
                  className="radio"
                  type="radio"
                  id="gst-question1-radio1"
                  name="question1"
                  value="Right Hand"
                  checked={question1=="Right Hand"}
                  onChange={(e) => setAnswerQuestion1(e.target.value)}
                />
                <label
                  className="radio-button-label"
                  htmlFor="gst-question1-radio1"
                >
                  Right
                </label>
              </div>
              <div>
                <input
                  className="radio"
                  type="radio"
                  id="gst-question1-radio2"
                  name="question1"
                  value="Left Hand"
                  checked={question1=="Left Hand"}
                  onChange={(e) => setAnswerQuestion1(e.target.value)}
                />
                <label
                  className="radio-button-label"
                  htmlFor="gst-question1-radio2"
                >
                  Left
                </label>
              </div>
            </fieldset>
          </form>
          {/* Second question */}
          <form className="grip-question2" id="question2Form">
            <p className="grip-question-container">
              2. Have you had any recent pain in your hand or wrist or any acute
              flare-up in your hand or wrist from conditions like arthritis?
              <br /> (If yes to dominant hand, <b>do not test</b>)
            </p>
            <h5 className="alert-physio" id="question2-alert"></h5>
            {/* Second question radio buttons */}
            <fieldset className="indented-radio-buttons fieldset">
              <input
                className="radio"
                type="radio"
                id="gst-question2-radio1"
                name="question2-yes-no"
                onChange={(e) => setAnswerQuestion2("yes")}
                onClick={function () {
                  document.getElementById(
                    "recentPainFieldset"
                  ).disabled = false;
                }}
                value="yes"
                checked={question2 && question2.includes("yes")}
              />
              <label
                className="radio-button-label"
                htmlFor="gst-question2-radio1"
              >
                Yes
              </label>
              <fieldset
                id="recentPainFieldset"
                className="indented-radio-buttons fieldset"
                disabled={!question2 || (question2 && !question2.includes("yes"))}
              >
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="gst-question2-radio2"
                    name="question2"
                    value="yes recent pain right-hand"
                    checked={question2=="yes recent pain right-hand"}
                    onChange={(e) => setAnswerQuestion2(e.target.value)}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="gst-question2-radio2"
                  >
                    Right
                  </label>
                </div>
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="gst-question2-radio3"
                    name="question2"
                    value="yes recent pain left-hand"
                    checked={question2=="yes recent pain left-hand"}
                    onChange={(e) => setAnswerQuestion2(e.target.value)}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="gst-question2-radio3"
                  >
                    Left
                  </label>
                </div>
              </fieldset>
              <input
                className="radio"
                type="radio"
                id="gst-question2-radio4"
                name="question2-yes-no"
                onClick={function (e) {
                  document.getElementById("recentPainFieldset").disabled = true;
                  document.getElementById(
                    "gst-question2-radio2"
                  ).checked = false;
                  document.getElementById(
                    "gst-question2-radio3"
                  ).checked = false;
                }}
                value="no recent pain on either hand"
                checked={question2 && question2.includes("no")}
                onChange={(e) => {
                  setAnswerQuestion2(e.target.value)
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="gst-question2-radio4"
              >
                No
              </label>
            </fieldset>
          </form>
          {/* Third question */}
          <form className="grip-question3" id="question3Form">
            <p className="grip-question-container">
              3. Have you had any surgery on your hands or arms duing the past 3
              months? <br />
              (If yes to dominant hand, <b>do not test</b>)
            </p>
            <h5 className="alert-physio" id="question3-alert"></h5>
            {/* Third question radio buttons */}
            <fieldset className="indented-radio-buttons fieldset">
              <input
                className="radio"
                type="radio"
                id="gst-question3-radio1"
                name="question3-yes-no"
                onChange={(e) => setAnswerQuestion3("yes")}
                onClick={function () {
                  document.getElementById("surgeryFieldset").disabled = false;
                }}
                value="yes"
                checked={question3 && question3.includes("yes")}
              />
              <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
              >
                Yes
              </label>
              <fieldset
                id="surgeryFieldset"
                className="indented-radio-buttons fieldset"
                disabled={!question3 || (question3 && !question3.includes("yes"))}
              >
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="gst-question3-radio2"
                    name="question3"
                    value="yes recent surgery right-hand"
                    checked={question3=="yes recent surgery right-hand"}
                    onChange={(e) => setAnswerQuestion3(e.target.value)}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="gst-question3-radio2"
                  >
                    Right
                  </label>
                </div>
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="gst-question3-radio3"
                    name="question3"
                    value="yes recent surgery left-hand"
                    checked={question3=="yes recent surgery left-hand"}
                    onChange={(e) => setAnswerQuestion3(e.target.value)}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="gst-question3-radio3"
                  >
                    Left
                  </label>
                </div>
              </fieldset>
              <input
                className="radio"
                type="radio"
                id="gst-question3-radio4"
                name="question3-yes-no"
                onClick={function () {
                  document.getElementById("surgeryFieldset").disabled = true;
                  document.getElementById(
                    "gst-question3-radio2"
                  ).checked = false;
                  document.getElementById(
                    "gst-question3-radio3"
                  ).checked = false;
                }}
                value="no recent surgery in either hand"
                checked={question3=="no recent surgery in either hand"}
                onChange={(e) => setAnswerQuestion3(e.target.value)}
              />
              <label
                className="radio-button-label"
                htmlFor="gst-question3-radio4"
              >
                No
              </label>
            </fieldset>
          </form>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => {saveData(); navigate("/TimedTimer")}}
        nextNavigation={() => validateForm()}
        currentPage={5}
        numPages={9}
      ></BottomNav>
      {/* <button className="next-button" onClick={validateForm}>
        Next
      </button> */}
    </div>
  );
}

export default GripStrength2;
