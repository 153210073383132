import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../header";
import { saveDataSectionA, saveDataSectionB as saveData2 } from "../firebase";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import BottomNav from "../bottomnavg";
import ScrollBtn from "../ScrollBtn";
import JsPDF from "jspdf";

function Review() {
  const [cmi, setCmi] = useState(0);
  const [moca_score, setMocaScore] = useState(0);
  const [moca_max, setMocaMax] = useState(0);
  const [moca_status, setMocaStatus] = useState(0);
  const [diagnosis, setDiagnosis] = useState("");
  const [condition, setCondition] = useState("");
  const [medical, setMedical] = useState("");
  const [surgical, setSurgical] = useState("");
  const [drug, setDrug] = useState("");
  const [smoking, setSmoking] = useState();
  const [alcohol, setAlcohol] = useState();
  const [education, setEducation] = useState("");
  const [openSave, setOpenSave] = useState(false);
  const dt = format(new Date(), "dd-MM-yyyy HH:mm:ss");
  const [previousMobilityIndependent, setPreviousMobilityIndependent] =
    useState("");
  const [previousMobilityDependent, setPreviousMobilityDependent] =
    useState("");
  const [transfer, setTransfer] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [socialStatus, setSocialStatus] = useState("");
  const [relativePlans, setRelativePlans] = useState("");
  const [predictions, setPredictions] = useState([]);

  // This function will called only once
  useEffect(() => {
    loadDataOnlyOnce();
  }, []);

  const handleClose = () => {
    setOpenSave(false);
  };

  function submitResult() {
    saveDataSectionA(dt, false, true);
    saveData2(dt, false, true);
    handleClose();
    navigate("/v2/PatientView");
  }

  const generatePDF = () => {
    const report = new JsPDF("landscape", "pt", "a4");
    report.html(document.querySelector("#report")).then(() => {
      report.save("Summary.pdf");
    });
  };

  function loadDataOnlyOnce() {
    setDiagnosis(sessionStorage.getItem("q1"));
    setCondition(sessionStorage.getItem("q2"));
    setMedical(sessionStorage.getItem("q3"));
    if (sessionStorage.getItem("qsurgical"))
      setSurgical(sessionStorage.getItem("qsurgical"));
    setDrug(sessionStorage.getItem("q4"));
    setSmoking(sessionStorage.getItem("q5"));
    setAlcohol(sessionStorage.getItem("q6"));
    setEducation(sessionStorage.getItem("q7"));
    setCmi(parseInt(sessionStorage.getItem("mobility-index")));
    setMocaScore(parseInt(sessionStorage.getItem("moca-score")));
    setMocaMax(parseInt(sessionStorage.getItem("moca-max")));
    setMocaStatus(sessionStorage.getItem("moca-status"));
    setTransfer(sessionStorage.getItem("transferQuestion"));
    setPreviousMobilityDependent(sessionStorage.getItem("mobilityQuestion11"));
    setPreviousMobilityIndependent(sessionStorage.getItem("TUGQuestion1"));
    setSocialStatus(sessionStorage.getItem("SocialStatus"));
    setRelativePlans(sessionStorage.getItem("RelativePlans"));
  }

  useEffect(() => {
    handlePredict();
  }, [relativePlans]);

  var typography =
    "Confirm your assessment findings. \nEdit from side edit buttons as necessary. \nConfirm and submit your assessment before exiting. ";

  const handlePredict = async () => {
    const features = {
      ReasonAdmission: diagnosis,
      Smoking: smoking,
      MoCa: moca_score,
      MoCAClassification: moca_status,
      PreviousMobilityD: previousMobilityDependent,
      PreviousMobilityI: previousMobilityIndependent,
      LyingSitting: transfer,
      SocialSituation: socialStatus,
      MainCarerPlan: relativePlans,
    };
    const response = await fetch("https://10.249.45.90:443/predict", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ features }),
    });

    const data = await response.json();
    setPredictions(data.predictions);
  };
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/results"}
          name={"Geriatric Consultant Results Obtained"}
        />
        <h1>Summary</h1>
        <div id="report" style={{ width: "70%" }}>
          {/* Diagnosis box */}
          <div
            className="summary-container"
            style={{ marginBottom: "1em", width: "auto" }}
          >
            <table>
              <tr>
                <td>
                  <h3 style={{ marginBottom: "12px" }}>Medical</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td>
                    <Button
                      style={{ marginBottom: "12px" }}
                      variant="outlined"
                      className="EditButton"
                      onClick={() =>
                        navigate("/v2/diagnosis", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
              <tr>
                <td className="summary-leftcolumn">
                  Reason for Admission: {diagnosis}
                </td>
                <td className="summary-rightcolumn"></td>
              </tr>
              <tr>
                <td className="summary-leftcolumn">Smoking: {smoking}</td>
                <td className="summary-rightcolumn"></td>
              </tr>
            </table>
          </div>
          {/* level of mobility */}
          <div
            className="summary-container"
            style={{ marginBottom: "1em", width: "auto" }}
          >
            <table>
              <tr>
                <td>
                  <h3 style={{ marginBottom: "12px" }}>Physical</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td>
                    <Button
                      style={{ marginBottom: "12px" }}
                      variant="outlined"
                      className="EditButton"
                      onClick={() =>
                        navigate("/v2/mobilityLevel", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
              <tr>
                <td className="summary-leftcolumn">
                  Independent Mobility: {previousMobilityIndependent}
                </td>
                <td className="summary-rightcolumn"></td>
              </tr>
              <tr>
                <td className="summary-leftcolumn">
                  Dependent Mobility: {previousMobilityDependent}
                </td>
                <td className="summary-rightcolumn"></td>
              </tr>
              <tr>
                <td className="summary-leftcolumn">Transfer: {transfer}</td>
                <td className="summary-rightcolumn"></td>
              </tr>
            </table>
          </div>

          {/* living status */}
          <div
            className="summary-container"
            style={{ marginBottom: "1em", width: "auto" }}
          >
            <table>
              <tr>
                <td>
                  <h3 style={{ marginBottom: "12px" }}>Social</h3>
                </td>
                {!(location.state && location.state.summary) && (
                  <td>
                    <Button
                      style={{ marginBottom: "12px" }}
                      variant="outlined"
                      className="EditButton"
                      onClick={() =>
                        navigate("/v2/livingStatus", {
                          state: { from: "Summary" },
                        })
                      }
                    >
                      Edit
                    </Button>
                  </td>
                )}
              </tr>
              <tr>
                <td className="summary-leftcolumn">
                  Living Arrangements: {socialStatus}
                </td>
                <td className="summary-rightcolumn"></td>
              </tr>
              <tr>
                <td className="summary-leftcolumn">
                  Main Carer's Plan: {relativePlans}
                </td>
                <td className="summary-rightcolumn"></td>
              </tr>
            </table>
          </div>

          {/* MoCA box */}
          <div
            className="summary-container"
            style={{ marginBottom: "1em", width: "auto" }}
          >
            <table>
              <tr>
                <td>
                  <h3 style={{ marginBottom: "12px" }}>MoCA Score</h3>
                </td>
                <td></td>
              </tr>
              <tr>
                <td className="summary-leftcolumn">Total:</td>
                <td className="summary-rightcolumn">
                  {moca_score}/{moca_max}
                </td>
              </tr>
              <tr>
                <td className="summary-leftcolumn">Classification:</td>
                <td className="summary-rightcolumn">{moca_status}</td>
              </tr>
            </table>
          </div>

          {/* prediction */}
          <div
            className="summary-container"
            style={{ marginBottom: "1em", width: "auto" }}
          >
            <button
              onClick={handlePredict}
              style={{
                borderColor: "#015893",
                backgroundColor: "rgb(50, 201, 83)",
                borderRadius: 10,
                fontSize: "large",
              }}
              type="button"
            >
              Prediction
            </button>
            <table>
              <tr>
                <td className="summary-leftcolumn">Result:</td>
                <td className="summary-rightcolumn">
                  {predictions[1] > 0.5
                    ? "Likely to benefit"
                    : "Unlikely to benefit"}
                </td>
              </tr>
              <tr>
                <td className="summary-leftcolumn">
                  Prediction confidence (Higher means more confidence in the
                  result):
                </td>
                <td className="summary-rightcolumn">
                  {(
                    (predictions[1] > 0.5 ? predictions[1] : predictions[0]) *
                    100
                  ).toFixed(2)}
                  %
                </td>
              </tr>
            </table>
          </div>

          {/* Confirm dialog  */}
          <Dialog
            open={openSave}
            onClose={handleClose}
            style={{ padding: "1em", borderRadius: "25px" }}
          >
            <h5
              style={{
                marginTop: "1em",
                marginRight: "1em",
                marginLeft: "1em",
                marginBottom: "0.5em",
              }}
            >
              Submit?
            </h5>
            <DialogContentText
              style={{
                marginRight: "1.3em",
                marginLeft: "1.3em",
              }}
            >
              This will save the data and end the assessment.
            </DialogContentText>
            <div
              style={{
                padding: "1em",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* Closes dialog */}
              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => handleClose()}
                variant="contained"
              >
                No
              </Button>
              {/* Confirms dialog and moves to next page*/}
              <Button
                style={{
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  paddingTop: "2px",
                  paddingBottom: "2px",
                  borderRadius: "25px",
                  fontFamily: "Fenix",
                  textTransform: "none",
                  fontSize: "16px",
                }}
                onClick={() => submitResult()}
                variant="contained"
              >
                Yes
              </Button>
            </div>
          </Dialog>
        </div>
        <button
          onClick={generatePDF}
          style={{
            borderColor: "#015893",
            backgroundColor: "rgb(50, 201, 83)",
            borderRadius: 10,
            fontSize: "large",
          }}
          type="button"
        >
          Export PDF
        </button>
      </div>
      <ScrollBtn />
      <BottomNav
        prevNavigation={() => navigate("/v2/results")}
        submitButton={() => setOpenSave(true)}
        currentPage={19}
        numPages={19}
      ></BottomNav>
    </div>
  );
}
export default Review;
