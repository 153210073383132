import React, { useEffect, useState } from "react";
import "../styles/CommonStyle.css";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar, Button } from "@mui/material";
import Header from "../header";
import { saveTeam2 } from "../firebase";

var typography =
  "On the word GO you will stand up, walk to the line on the floor, turn around and walk back to the chair and sit down. Walk at you regular pace.";
const Timer = () => {
  const navigate = useNavigate();
  function navToNextPage() {
	  saveData()
    navigate("/GripStrength2");
  }


  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }

  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [trial, setTrial] = useState(true);
  const [clicks, setClicks] = useState(0);
  let [status, setStatus] = useState("Low Risk");

  function toggle() {
    setClicks(clicks + 1);
    setIsActive(!isActive);
  }

  function start() {}

  function reset() {
    setSeconds(0);
    status = "Low Risk";
    setIsActive(false);
    setTrial(false);
  }

  useEffect(() => {
    try {
      let interval = null;
      document.getElementById("status").innerHTML =
        seconds + " seconds - " + status;
      if (clicks < 2) {
        document.getElementById("main-button").disabled = false;
        document.getElementById("reset-button").disabled = true;
        document.getElementById("next-button").disabled = true;
      } else {
        document.getElementById("next-button").disabled = false;
        document.getElementById("main-button").disabled = true;
        if (trial) {
          document.getElementById("reset-button").disabled = false;
        }
        setClicks(0);
      }
      if (trial && !isActive) {
        document.getElementById("main-button").innerHTML =
          "TRIAL:<br/>Press Here to begin ...";
        document.getElementById("main-button").className =
          "TimerLayoutBtnStart";
      } else if (trial && isActive) {
        if (seconds <= 10) {
          status = "Low Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnStart";
        } else if (seconds >= 11 && seconds <= 14) {
          status = "Medium Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnMed";
        } else if (seconds > 14 && seconds < 20) {
          status = "High Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnStop";
        } else if (seconds >= 20) {
          status = "High Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnStop";
          document.getElementById("main-button").click();
        }
        document.getElementById("main-button").innerHTML =
          "" + seconds + "<br/>" + status + "<br/>" + "Press here to Stop";
        document.getElementById("status").innerHTML =
          seconds + " seconds - " + status;
      } else if (!trial && !isActive) {
        document.getElementById("main-button").innerHTML =
          "Press here to begin ...";
        document.getElementById("main-button").className =
          "TimerLayoutBtnStart";
      } else if (!trial && isActive) {
        if (seconds <= 10) {
          status = "Low Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnStart";
        } else if (seconds >= 11 && seconds <= 14) {
          status = "Medium Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnMed";
        } else if (seconds > 14 && seconds < 20) {
          status = "High Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnStop";
        } else if (seconds >= 20) {
          status = "High Risk";
          document.getElementById("main-button").className =
            "TimerLayoutBtnStop";
          document.getElementById("main-button").click();
        }
        document.getElementById("main-button").innerHTML =
          "" + seconds + "<br/>" + status + "<br/>" + "Press here to Stop";
        document.getElementById("status").innerHTML =
          seconds + " seconds - " + status;
      }

      if (isActive) {
        interval = setInterval(() => {
          setSeconds((seconds) => seconds + 1);
        }, 1000);
      } else if (!isActive && seconds !== 0) {
        clearInterval(interval);
      }

      if (seconds <= 1) {
        sessionStorage.setItem("TUGTimer", 0);
        sessionStorage.setItem("TUGStatus", "Not Recorded");
      } else {
        sessionStorage.setItem("TUGTimer", seconds);
        sessionStorage.setItem("TUGStatus", status);
      }
      setStatus(status);
      return () => clearInterval(interval);
    } catch {}
  }, [isActive, seconds]);

  return (
    <div className="screen">
      <Header
        typography={typography}
        history={"/Instructions"}
        name="Timed Up and Go Test"
      />
      <h1>Timed Up and Go Test</h1>
      <div className="main-section">
        <div className="TimerLayoutWords">
          <div
            className="TimerDiv"
            style={{ textAlignHorizontal: "center", textAlign: "center" }}
          >
            <button
              id="main-button"
              className="TimerLayoutBtnStart"
              onClick={toggle}
            ></button>
            {/* <br /> */}
            <div className="buttons-section">
              <Button variant="contained" id="start-button" onClick={toggle}>
                Start
              </Button>
              <Button variant="contained" id="reset-button" onClick={reset}>
                Reset
              </Button>
              {/* <button
                id="reset-button"
                className="TimerLayoutBtnReset"
                onClick={reset}
              >
                Reset
              </button> */}
            </div>
            <h4 id="status"></h4>
          </div>
        </div>
      </div>
      <button id="next-button" className="next-button" onClick={navToNextPage}>
        Next
      </button>
    </div>
  );
};

export default Timer;
