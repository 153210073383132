import "../styles/CommonStyle.css";
import React from "react";
import GripPhoto from "../assets/Grip-Strength-Test-Equipment.png";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import BottomNav from "../bottomnavg";
import { saveTeam2 } from "../firebase";

function GripStrength() {
  var typography =
    "This page explains the equipment needed and how it should be used. I also lists the instructions that should be told to the patient";
  const navigate = useNavigate();

  function navToNextPage() {
    sessionStorage.setItem("GripStrength2", true);
	saveData()
    navigate("/GripStrength2");
  }
  
  
  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }

  return (
    <div className="screen">
      <Header
        typography={typography}
        history={"/ReviewQuestion"}
        name={"Grip Strength Test"}
      /> 
      <h1 style={{ paddingTop: "5%", padding: 0 }}>Hand Grip Strength Test</h1>

      <div className="main-section">
        <img
          src={GripPhoto}
          style={{
            paddingLeft: "5%",
          }}
        />
        {/* add padding and centre image 
        inc font size <p>
        */}
        <br />
        <p>
          "In this exercise, I am going to use the dynamometer to test the
          strength in your hands"
        </p>
        <br />
        <p>
          "I would like you to take this in you hand. I will ask you to do this
          two times , both hands. When I say 'GO' press as hard as you can."
        </p>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
        currentPage={1}
        numPages={8}
      ></BottomNav>
      {/* <button className="next-button" onClick={navToNextPage}>
        Next
      </button> */}
    </div>
  );
}

export default GripStrength;
