import * as React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Grid } from "@material-ui/core";
import { Popover } from "@mui/material";
import "./styles/header.css";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Logo from "./assets/logo-big.png";
import "./styles/Summary.css";
import ConfirmDialog from "./ConfirmDialog";

export default function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const location = useLocation();

  var data = props.disabled;
  // const goBack = () => {
  //   var path = props.history;
  // console.log(path);
  //   navigate(path);
  // };

  // Show Confirm dialog
  const [opendialog, setOpendialog] = useState(false);
  const handleDialogCancel = () => {
    setOpendialog(false);
  };
  const handleDialogConfirm = () => {
    sessionStorage.clear();
    if (location.pathname.includes("v2")) {
      navigate("/v2/PatientView");
    } else navigate("/PatientView");
  };
  const handleDialogReturntoHome = () => {
    sessionStorage.clear();
    if (location.pathname.includes("v2")) navigate("/v2");
    else navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <AppBar
        className="appbar"
        position="static"
        style={{
          justifyContent: "center",
          background: "#015893",
        }}
      >
        {/* Header container */}
        <Grid container spacing={0}>
          {/* Home button */}
          <Grid
            item
            xs={1}
            sm={1}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Fab
                variant="circular"
                color="white"
                className="mui-icons"
                disabled={data}
                onClick={goBack}
                aria-label="add"
                sx={{
                  ["@media (min-width:720px)"]: { marginTop: 2 },
                  ["@media (max-width:720px)"]: { marginTop: 1 },
                  marginLeft: "10px",
                }}
              >
                {/* <ArrowBack fontSize="large"></ArrowBack> *
                <HomeIcon fontSize="large"></HomeIcon>
              </Fab> */}
            <IconButton
              className="mui-icons"
              disabled={data}
              // onClick={setOpendialog(true)}
              onClick={() => {
                if (
                  (location.state && location.state.summary) ||
                  location.pathname == "/Patient-Menu" ||
                  location.pathname == "/PatientView-Details"
                )
                  handleDialogConfirm();
                else setOpendialog(true);
              }}
              // onClick={goBack}
              aria-label="add"
              sx={{ padding: 0 }}
            >
              <HomeIcon
                fontSize="large"
                sx={{
                  margin: 0,
                  justifyContent: "center",
                  color: "white",
                  marginLeft: "0.5em",
                }}
              ></HomeIcon>
            </IconButton>
          </Grid>
          {/* Logo  */}
          <Grid
            item
            xs={10}
            sm={10}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={Logo} id="logoHeader" alt="" />
            {/* <Typography
                variant="h7"
                color="inherit"
                component="div"
                align="center"
                style={{ marginTop: 20, marginBottom: 3.2, fontSize: "1.75em" }}
              >
                {/* <label className="title">{props.name}</label> *
              </Typography> */}
          </Grid>
          {/* Info button */}
          <Grid
            item
            xs={1}
            sm={1}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <IconButton
              className="mui-icons"
              onClick={handleClick}
              aria-label="add"
              sx={{ padding: 0 }}
            >
              <HelpOutlineIcon
                fontSize="large"
                sx={{
                  margin: 0,
                  justifyContent: "center",
                  color: "white",
                  marginRight: "0.5em",
                }}
              ></HelpOutlineIcon>
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>

      {/* If current page is "Patient List" then return to Login page */}
      {location.pathname.includes("/PatientView") ? (
        <ConfirmDialog
          showDialog={opendialog}
          cancel={handleDialogCancel}
          confirmButtonText="Yes"
          cancelButtonText="No"
          confirm={handleDialogReturntoHome}
          dialogTitle="Return to Login page?"
          dialogText="Are you sure you want to log out?"
        />
      ) : (
        <ConfirmDialog
          showDialog={opendialog}
          cancel={handleDialogCancel}
          confirmButtonText="Yes"
          cancelButtonText="No"
          confirm={handleDialogConfirm}
          dialogTitle="Return to Patient List page?"
          dialogText="Are you sure you want to return to patient list? Data will be saved as draft"
        />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {" "}
        <Typography
          sx={{
            paddingTop: "1em",
            paddingLeft: "1em",
            paddingRight: "1em",
            paddingBottom: "0.5em",
            fontSize: "1em",
          }}
        >
          <b style={{ fontSize: "1.2em" }}>Please note:</b>
        </Typography>
        <Typography
          sx={{
            lang: "en",
            marginTop: 0,
            paddingBottom: "1em",
            paddingLeft: "1em",
            paddingRight: "1em",
            fontSize: "1em",
            hyphens: "auto",
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
          }}
        >
          {props.typography}
        </Typography>
      </Popover>
    </>
  );
}
