import React, { useEffect } from "react";
import Header from "../header";
import { Grid, TextField } from "@mui/material";
import { Box, fontSize } from "@mui/system";
import "../styles/HomeContent.css";
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { Button } from "@mui/material";
import { useState } from "react";
import "../styles/header.css";
import { getPatients } from "../firebase";
import "../firebase";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

export default function PatientView() {
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [surname, setSurname] = useState("");
  const [exist, setExists] = useState(false);
  const [patients, setPatients] = useState([]);
  const [fragments, setFragments] = useState(<></>);
  const [entered, setEntered] = useState(true);
  const [text, setText] = useState("");

  const [currentPatient, setCurrentPatient] = useState({
    id: 0,
    name: "",
    gender: "",
    surname: "",
  });

  const navigation = useNavigate();

  useEffect(() => {
    getPatients().then((result) => {
      setPatients(result);
      console.log(result);
    });
    // setPatients(p);
    // console.log(p);
    console.log(patients);
  }, []);

  const SetCurrentPatient = (patient) => {
    console.log("hello SP");
    setId(patient.id);
    setEntered(false);
    setCurrentPatient(patient);
    sessionStorage.setItem("patientId", patient.id);
    sessionStorage.setItem("patientAge", patient.age);
    sessionStorage.setItem("Patientgender", patient.gender);
  };

  const DisplayPatients = (event) => {
    console.log("hello");
    var value = event.target.value.toUpperCase();
    var testing = [];

    if (value !== "") {
      //console.log(getPatients());
      patients.map((patient, index) => {
        var tempID = patient.id.toUpperCase().substr(0, value.length);
        let regex = new RegExp(tempID, "g");

        if (regex.test(value)) {
          testing.push(
            <React.Fragment key={index}>
              <ListItemButton onClick={() => SetCurrentPatient(patient)}>
                <ListItemText primary={patient.name} secondary={patient.id} />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        }
      });
      setFragments(testing);
    } else {
      setFragments(<></>);
    }
  };

  const ClickTable = (event) => {
    console.log("hello");
  };
  sessionStorage.setItem("Patientage", currentPatient.age);
  sessionStorage.setItem("Patientgender", currentPatient.gender);
  console.log(sessionStorage.getItem("Patientage")+ " and " + sessionStorage.getItem("Patientgender"))
  var typography = "Click on the patient you wish to assess.";
  // console.log(getPatients());

  if (id !== 0) {
    sessionStorage.setItem("id", id);
	if(window.location.href.includes("v2"))
		navigation("/v2/PatientView-Details");
	else
		navigation("/PatientView-Details");
  }

  return (
    <div className="screen">
      <Grid
        justifyContent={"center"}
        rowGap={4}
        container
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <Header
          typography={typography}
          history={"../menu"}
          item
          name={"Patient Page"}
        />

        <Grid justifyContent={"center"} container item className={"patientListGrid"} >
          <h1 style={{ paddingTop: "0.4em" }}>Patient List</h1>
          <div className="search-container" style={{ width: "76%" }}>
            <TextField
              fullWidth={true}
              id="search-patient"
              label={"Patient ID"}
              onChange={DisplayPatients}
            ></TextField>
            <List>
              <Divider id="patient-list-search">{fragments}</Divider>
            </List>
          </div>
        </Grid>
        <Grid container item></Grid>

        <table className="search-container-ID" style={{ width: "80%" }}>
          <tr
            className="grid-data"
            style={{ fontWeight: "bold", fontSize: "23px" }}
          >
            <td>
              <h5>No.</h5>
            </td>
            <td>
              <h5>Name</h5>
            </td>
            <td>
              <h5>ID Number</h5>
            </td>
          </tr>
          {patients &&
            patients.map((a, i) => {
              // console.log(a, i);
              return (
                <tr
                  onClick={() => SetCurrentPatient(a)}
                  className="grid-data-1"
                >
                  <td>{i}</td>
                  <td>
                    {a.name} {a.surname}
                  </td>
                  <td>{a.id}</td>
                </tr>
              );
            })}
        </table>
      </Grid>
    </div>
  );
}
