import "../styles/CommonStyle.css";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar, Button, IconButton } from "@mui/material";
import Header from "../header";
import BottomNav from "../bottomnavg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { saveTeam2 } from "../firebase";

function LevelsOfMobility() {
  var typography =
    "Click on the type of mobility level, for both previous (past 6 months) and current level.";

  const [question11, setAnswerQuestion11] = useState("");
  const [question22, setAnswerQuestion22] = useState("");
  const [question1WalkingAid, setQuestion1WalkingAid] = useState("")
  const [question1WalkingAidOther, setQuestion1WalkingAidOther] = useState("")
    const [question2WalkingAid, setQuestion2WalkingAid] = useState("")
    const [question2WalkingAidOther, setQuestion2WalkingAidOther] = useState("")
  console.log(sessionStorage.getItem("Patientgender") + "GENDER")

  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }

  const navigate = useNavigate();

  useEffect(()=>{
    
     if(sessionStorage.getItem("mobilityQuestion11"))
    setAnswerQuestion11(sessionStorage.getItem("mobilityQuestion11"))
      if(sessionStorage.getItem("mobilityQuestion22"))
      setAnswerQuestion22(sessionStorage.getItem("mobilityQuestion22"))
      if(sessionStorage.getItem("mobilityQuestion1WalkingAid"))
      setQuestion1WalkingAid(sessionStorage.getItem("mobilityQuestion1WalkingAid"))
      if(sessionStorage.getItem("mobilityQuestion1WalkingAidOther"))
      setQuestion1WalkingAidOther(sessionStorage.getItem("mobilityQuestion1WalkingAidOther"))
      if(sessionStorage.getItem("mobilityQuestion2WalkingAid"))
      setQuestion2WalkingAid(sessionStorage.getItem("mobilityQuestion2WalkingAid"))
      if(sessionStorage.getItem("mobilityQuestion2WalkingAidOther"))
      setQuestion2WalkingAidOther(sessionStorage.getItem("mobilityQuestion2WalkingAidOther"))
  
  setQuestion1WalkingAid(sessionStorage.getItem("TUGQuestion1")?.split(":")[0]?.trim())
  console.log(question1WalkingAid)
  setQuestion1WalkingAidOther(sessionStorage.getItem("TUGQuestion1")?.split(":")[1]?.trim())
  
    setQuestion2WalkingAid(sessionStorage.getItem("TUGQuestion2")?.split(":")[0]?.trim())
  setQuestion2WalkingAidOther(sessionStorage.getItem("TUGQuestion2")?.split(":")[1]?.trim())

  }, [])


    useEffect(()=>{
        console.log(question11)
        sessionStorage.setItem("mobilityQuestion11", question11)
    }, [question11])


    useEffect(()=>{
        sessionStorage.setItem("mobilityQuestion22", question22)
    }, [question22])

    useEffect(()=>{
        sessionStorage.setItem("mobilityQuestion1WalkingAid", question1WalkingAid)
    }, [question1WalkingAid])


    useEffect(()=>{
        sessionStorage.setItem("mobilityQuestion1WalkingAidOther", question1WalkingAidOther)
    }, [question1WalkingAidOther])


    useEffect(()=>{
        sessionStorage.setItem("mobilityQuestion2WalkingAid", question2WalkingAid)
    }, [question2WalkingAid])



    useEffect(()=>{
        sessionStorage.setItem("mobilityQuestion2WalkingAidOther", question2WalkingAidOther)
    }, [question2WalkingAidOther])

  // console.log("Previous Level of Mobility ", sessionStorage.getItem("TUGQuestion1"));
  // console.log("Current Level of Mobility ", sessionStorage.getItem("TUGQuestion2"));
  // console.log("TUG Timer ", sessionStorage.getItem("TUGTimer"));
  // console.log("TUG Status ", sessionStorage.getItem("TUGStatus"));
  // console.log("TUG Carried out ", sessionStorage.getItem("TUGTestCarriedOut"));
  // console.log("GST Question 1 ", sessionStorage.getItem("question1"));
  // console.log("GST Question 2 ", sessionStorage.getItem("question2"));
  // console.log("GST Question 3 ", sessionStorage.getItem("question3"));
  // console.log("GST Question 4 ", sessionStorage.getItem("question4"));
  // console.log("GST Question 5 ", sessionStorage.getItem("question5"));
  // console.log("Instructions ", sessionStorage.getItem("Instructions"));
  // console.log("Timer ", sessionStorage.getItem("Timer"));
  // console.log("\n");
  //
  // useEffect(() => {
  //   setAnswerQuestion1(sessionStorage.getItem("TUGQuestion1"));
  //   setAnswerQuestion2(sessionStorage.getItem("TUGQuestion2"));
  // }, []);

  //Check if there is an option selected and if textbox is filled
  function validateForm() {
	  let previousMobilitySuccess = false
	  let currentMobilitySuccess = false
	  
	  if (question22 == "") {
      // Displays error message
      document.getElementById("question22-alert").innerHTML =
        "Please select an option!";
     } else {
      document.getElementById("question22-alert").innerHTML = "";
	  currentMobilitySuccess = true
    }
    if (question11 == "") {
      document.getElementById("question11-alert").innerHTML =
        "Please select an option!";
    } else {
      document.getElementById("question11-alert").innerHTML = "";
	  previousMobilitySuccess = true
    }
	
    if (question2WalkingAid == "") {
      document.getElementById("question2-other-alert").innerHTML = "";
      // Displays error message
      document.getElementById("question22-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question2Form").scrollIntoView();
    } else if ((question2WalkingAid == "other" ) && (!question2WalkingAidOther || (question2WalkingAidOther && question2WalkingAidOther == ""))) {
      document.getElementById("question22-alert").innerHTML = "";
      document.getElementById("question2-other-alert").innerHTML =
        "Please input a reason!";
      document.getElementById("question2Form").scrollIntoView();
	  currentMobilitySuccess = false
    } else {
      document.getElementById("question22-alert").innerHTML = "";
      document.getElementById("question2-other-alert").innerHTML = "";
	  	  currentMobilitySuccess = true

    }
	console.log(question1WalkingAid)
    if (question1WalkingAid == "") {
      document.getElementById("question1-other-alert").innerHTML = "";
      document.getElementById("question11-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question1Form").scrollIntoView();
    } else if ((question1WalkingAid == "other" || (question1WalkingAid && question1WalkingAid.includes("other"))) && (!question1WalkingAidOther || (question1WalkingAidOther && question1WalkingAidOther == ""))) {
      document.getElementById("question11-alert").innerHTML = "";
      document.getElementById("question1-other-alert").innerHTML =
        "Please input a reason!";
      document.getElementById("question1Form").scrollIntoView();
	  previousMobilitySuccess = false
    } else {
      document.getElementById("question11-alert").innerHTML = "";
      document.getElementById("question1-other-alert").innerHTML = "";
	  	  previousMobilitySuccess = true

    }
	
	
saveData()
    // Check if value in question1 and question2 isn't empty
    if (
	previousMobilitySuccess && currentMobilitySuccess
   
    ) {
        let tug = ''
        if(question1WalkingAid ) {
            tug = `${question1WalkingAid}`
            if (question1WalkingAidOther && question1WalkingAid=="other")
                tug = ` ${question1WalkingAid} : ${question1WalkingAidOther}`
        }
        let tug2 = ''
        if(question2WalkingAid ) {
            tug2 = `${question2WalkingAid}`
            if (question2WalkingAidOther && question2WalkingAid=="other")
                tug2 = ` ${question2WalkingAid} : ${question2WalkingAidOther}`
        }
	
        sessionStorage.setItem("TUGQuestion1", tug);
      sessionStorage.setItem("TUGQuestion2", tug2);
	  saveData()

      navigate("/CurrentMobility");
    }
  }
  
  return (
    <div className="screen" style={{justifyContent:"space-between"}}>
      <div className="screen" style={{width:"100%", minHeight:"auto"}}>
      <Header
        typography={typography}
        history={"/PatientID-team-2"}
        item
        name={"Levels of Mobility"}
      />

      <h1>Mobility Level</h1>
      
      <div className="physio-container" style={{padding: "2px", paddingBottom: "1em",paddingLeft: "1em", width: "85%"}}>
        {/* Previous level: all radio buttons */}
        <form className="question1Form" id="question1Form">
          {/* <h4 className="alert" id="question1-alert"></h4> */}
          

          {/* Container for "Independent" labels and Independent radio buttons */}
          <div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
            <Box
              // width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                // transform: `rotate(${-90}deg)`,
                maxWidth: "1em",
                paddingRight: "1em",
              }}
            >
              {/* Left "Independent" label */}
              <h5
                style={{
                  color: "gray",
                  paddingRight: "1em",
                  paddingTop: "2em",
                  transform: `rotate(${-90}deg)`,
                }}
              >
                Independent
              </h5>
            </Box>
            <div style={{paddingLeft: "2em", width: "100%"}}>
                <h4 style={{ paddingBottom: "1em" }}>Previous Mobility</h4>

                {/* Previous level: "Unaided" radio button */}
              <div>
                <input
                  className="radio"
                  type="radio"
                  id="radio-button1-prev-ind-and-unaid"
                  name="group1-1"
                  value="Unaided"
                  onChange={(e) => setQuestion1WalkingAid(e.target.value)}
                  onClick={function (e) {
                      setQuestion1WalkingAid(e.target.value)
                    document.getElementById(
                      "PreviousLevelOfMobilityFieldset"
                    ).disabled = true;
                    document.getElementById(
                      "radio-button-prev-stick"
                    ).checked = false;
                    document.getElementById(
                      "radio-button-prev-roll-frame"
                    ).checked = false;
                    document.getElementById(
                      "radio-button-prev-gutt-frame"
                    ).checked = false;
                    document.getElementById(
                      "radio-button-prev-other"
                    ).checked = false;
                  }}
                  checked={question1WalkingAid && question1WalkingAid.includes("Unaided")}
                />
                <label
                  className="radio-button-label"
                  htmlFor="radio-button1-prev-ind-and-unaid"
                >
                  Unaided
                </label>
                {/* Previous level: "Walking aid" radio button */}
              </div>

                {/* Stick button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-stick"
                    name="group1-1"
                    value="Stick"
                    onChange={(e) => {
                      setQuestion1WalkingAid( e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                    checked={question1WalkingAid && question1WalkingAid.includes("Stick")}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-stick"
                  >
                    Stick
                  </label>
                  {/* Rollator frame button */}
                </div>
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-roll-frame"
                    name="group1-1"
                    value="Rollator Frame"
                    onChange={(e) => {
                        setQuestion1WalkingAid( e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                    checked={question1WalkingAid=="Rollator Frame"}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-roll-frame"
                  >
                    Rollator Frame
                  </label>
                </div>
                {/* Gutter frame button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-gutt-frame"
                    name="group1-1"
                    value="Gutter Frame"
                    onChange={(e) => {
                        setQuestion1WalkingAid( e.target.value);
                      document.getElementById("text-box1").disabled = true;
                    }}
                    checked={question1WalkingAid=="Gutter Frame"}
                  />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-gutt-frame"
                  >
                    Gutter Frame
                  </label>
                </div>
                {/* "Other" button */}
                <div>
                  <input
                    className="radio"
                    type="radio"
                    id="radio-button-prev-other"
                    name="group1-1"
                    value="other"
                    onClick={function () {
                      document.getElementById("text-box1").disabled = false;
                    }}
                    onChange={(e) =>  setQuestion1WalkingAid( e.target.value)}
                    checked={question1WalkingAid=="other"}
                        />
                  <label
                    className="radio-button-label"
                    htmlFor="radio-button-prev-other"
                  >
                    Other:{" "}
                  </label>
                  <input
                    display="block"
                    width="100%"
                    type="text"
                    id="text-box1"
                    disabled={true}
                    onChange={(e) =>
                      setQuestion1WalkingAidOther(
                        e.target.value
                      )
                    }
					
                    style={{paddingRight: "4em", height: "2em"}}
                    value={question1WalkingAidOther}
                  />
                  <h4 className="alert" id="question1-other-alert"></h4>
                </div>
          </div>

          </div>

          {/* Container for "Dependent" label and Dependent radio buttons */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              paddingTop: "0.6em",
            }}
          >
            <Box
              // width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{
                // transform: `rotate(${-90}deg)`,
                maxWidth: "1em",
                paddingRight: "1.5em",
              }}
            >
              {/* Left "Dependent" label */}
              <h5
                style={{
                  color: "gray",
                  paddingTop: "2em",
                  paddingLeft: "1.5em",
                  transform: `rotate(${-90}deg)`,
                }}
              >
                Dependent
              </h5>
            </Box>
            {/* Container for all Dependent radio buttons */}
            <div style={{paddingLeft: "1.5em",}}>
              {/* Previous level: "Help of one person" radio button */}
			   <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-req-help2"
                name="group1"
                value="Supervision"
                checked={question11 && question11.includes("Supervision")}
             
                onChange={(e) => {
                  setAnswerQuestion11(e.target.value);
                 
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-req-help2"
              >
                Supervision
              </label>
            </div>
              <div>
                <input
                  className="radio"
                  type="radio"
                  id="radio-button-prev-req-help"
                  name="group1"
                  checked={question11 && question11.includes("Help of one Person")}
                  value="Help of one Person"
    
                  onChange={(e) => {
                    setAnswerQuestion11(e.target.value);
                    document.getElementById("text-box1").disabled = true;
                  }}
                />
                <label
                  className="radio-button-label"
                  htmlFor="radio-button-prev-req-help"
                >
                  Help of 1 Person
                </label>
              </div>
              {/* Previous level: "Dependent, chair user or bedbound" radio button */}
              <div>
                <input
                  className="radio"
                  type="radio"
                  id="radio-button-prev-req-help-of-two"
                  name="group1"
                  value="Help of two Persons"
                  checked={question11 && question11.includes("Help of two Persons")}
                 
                  onChange={(e) => {
                    setAnswerQuestion11(e.target.value);
                    document.getElementById("text-box1").disabled = true;
                  }}
                />
                <label
                  className="radio-button-label"
                  htmlFor="radio-button-prev-req-help-of-two"
                >
                  Help of 2 Persons
                </label>
              </div>
              {/* Previous level: "Chair User" button */}
              <div>
                <input
                  className="radio"
                  type="radio"
                  id="radio-button-prev-chair"
                  name="group1"
                  value="Chair User"
                  checked={question11 && question11.includes("Chair User")}
          
                  onChange={(e) => {
                    setAnswerQuestion11(e.target.value);
                   
                  }}
                />
                <label
                  className="radio-button-label"
                  htmlFor="radio-button-prev-chair"
                >
                  Chair User
                </label>
              </div>
              {/* Previous level: "Bedbound" button */}
              <div>
                <input
                  className="radio"
                  type="radio"
                  id="radio-button-prev-bedbound"
                  name="group1"
                  value="Bedbound"
                  checked={question11 && question11.includes("Bedbound")}
                 
                  onChange={(e) => {
                    setAnswerQuestion11(e.target.value);
                    
                  }}
                />
                <label
                  className="radio-button-label"
                  htmlFor="radio-button-prev-bedbound"
                >
                  Bedbound
                </label>
              </div>
              <h4 className="alert" id="question11-alert"></h4>
            </div>
          </div>
        </form>
        {/* Current level: all radio buttons */}
        <form className="question2Form" id="question2Form" style={{paddingLeft:"3em",width:"85%"}}>
          {/* <h4 className="alert" id="question2-alert"></h4> */}
            <h4 style={{ paddingBottom: "1em", paddingTop: "1em" }}>Current Mobility</h4>

            {/* Current level: "Unaided" button */}
				{console.log(question2WalkingAid, question22)}
          <div >
            <input
              className="radio"
              type="radio"
              id="radio-button2-prev-ind-and-unaid"
              name="group2-1"
              value="Unaided"
              checked={question2WalkingAid && question2WalkingAid.includes("Unaided")}
              onChange={(e) => setQuestion2WalkingAid(e.target.value)}
             
            />
            <label
              className="radio-button-label"
              htmlFor="radio-button2-prev-ind-and-unaid"
            >
              Unaided
            </label>
            {/* Current level: "walking aid" button */}
          </div>
         
            {/* "Stick" button */}
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-stick2"
                name="group2-1"
                value="Stick"
                checked={question2WalkingAid=="Stick"}
                onChange={(e) => {
                  setQuestion2WalkingAid( e.target.value);
                  document.getElementById("text-box2").disabled = true;
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-stick2"
              >
                Stick
              </label>
            </div>
            {/* "Rollator frame" button */}
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-roll-frame2"
                name="group2-1"
                value="Rollator Frame"
                checked={question2WalkingAid=="Rollator Frame"}
                onChange={(e) => {
                    setQuestion2WalkingAid( e.target.value);
                  document.getElementById("text-box2").disabled = true;
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-roll-frame2"
              >
                Rollator Frame
              </label>
            </div>
            {/* "Gutter frame" button */}
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-gutt-frame2"
                name="group2-1"
                value="Gutter Frame"
                checked={question2WalkingAid=="Gutter Frame"}
                onChange={(e) => {
                  setQuestion2WalkingAid( e.target.value);
                  document.getElementById("text-box2").disabled = true;
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-gutt-frame2"
              >
                Gutter Frame
              </label>
            </div>
            {/* "Other" button */}
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-other2"
                name="group2-1"
                value="other"
                checked={question2WalkingAid=="other"}
                onClick={function () {
                  document.getElementById("text-box2").disabled = false;
                }}
                onChange={(e) => setQuestion2WalkingAid( e.target.value)}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-other2"
              >
                Other:{" "}
              </label>
              <input
                display="block"
                width="100%"
                type="text"
                id="text-box2"
                disabled={true}
                onChange={(e) =>
                  setQuestion2WalkingAidOther(e.target.value)
                }
                value={question2WalkingAidOther}
                style={{paddingRight:"4em", height: "2em"}}
              />
              <h4 className="alert" id="question2-other-alert"></h4>
            </div>

          {/* Container for all Dependent radio buttons (Current level) */}
          <div style={{ paddingTop: "0.6em" }}>
            {/* Current level: "Help of one person" button */}
			 <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-req-help22"
                name="group2"
                value="Supervision"
                checked={question22 && question22.includes("Supervision")}
               
                onChange={(e) => {
                  setAnswerQuestion22(e.target.value);
                
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-req-help22"
              >
                Supervision
              </label>
            </div>
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-req-help-of-one-2"
                name="group2"
                value="Help of one Person"
                checked={question22 && question22.includes("Help of one Person")}
              
                onChange={(e) => {
                  setAnswerQuestion22(e.target.value);
                
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-req-help-of-one-2"
              >
                Help of 1 Person
              </label>
            </div>
            {/* Current level: "Help of two Persons" radio button */}
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-req-help-of-two2"
                name="group2"
                value="Help of two Persons"
                checked={question22 && question22.includes("Help of two Persons")}
               
                onChange={(e) => {
                  setAnswerQuestion22( e.target.value);
                  //Sets radio buttons underneath "Walking Aid" to false
                  
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-req-help-of-two2"
              >
                Help of 2 Persons
              </label>
            </div>
            {/* Current level: "Chair user" radio button */}
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-chair2"
                name="group2"
                value="Chair User"
                checked={question22=="Chair User"}
               
                onChange={(e) => {
                  setAnswerQuestion22(e.target.value);
                  //Sets radio buttons underneath "Walking Aid" to false
                
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-chair2"
              >
                Chair User
              </label>
            </div>
            {/* Current level: "Bedbound" radio button */}
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-bedbound2"
                name="group2"
                value="Bedbound"
                checked={question22=="Bedbound"}
                onClick={function () {
                  document.getElementById(
                    "CurrentLevelofMobilityFieldset"
                  ).disabled = true;
                }}
                onChange={(e) => {
                  setAnswerQuestion22(e.target.value);
                  //Sets radio buttons underneath "Walking Aid" to false
                 
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-bedbound2"
              >
                Bedbound
              </label>
            </div>
            <h4 className="alert" id="question22-alert"></h4>
          </div>
        </form>
      </div>
      </div>
      <BottomNav
        nextNavigation={() => validateForm()}
        currentPage={1}
        numPages={9}
      ></BottomNav>
    </div>
  );
}
export default LevelsOfMobility;
