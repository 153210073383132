import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BottomNav from "../../bottomnavg";
import Header from "../../header";
import { TextField } from "@mui/material";
import { maxWidth } from "@mui/system";
import { saveOccupational } from "../../firebase";

export default function AdditionalinfoGC() {
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [otherText, setOtherText] = useState("");

  function navToNextPage() {
	  saveData()
    navigate("/Section6OT");
  }

  function navToPreviousPage() {
	  saveData()
    navigate("/Section5OT");
  }

  useEffect(() => {
    setOtherText(sessionStorage.getItem("OTAdditionalInfo"));
  }, []);

  const saveData =() => {

const alldata=  {
    housebound: sessionStorage.getItem("a"),
    feedingP: sessionStorage.getItem("a1"),
    toiletP: sessionStorage.getItem("a2"),
    bathP: sessionStorage.getItem("a3"),
    groomP: sessionStorage.getItem("a4"),
    dressP: sessionStorage.getItem("a5"),
        feedingC: sessionStorage.getItem("b1"),
    toiletC: sessionStorage.getItem("b2"),
    bathC: sessionStorage.getItem("b3"),
    groomC: sessionStorage.getItem("b4"),
    dressC: sessionStorage.getItem("b5"),
    feedingCom: sessionStorage.getItem("c1"),
    toiletCom: sessionStorage.getItem("c2"),
    bathCom: sessionStorage.getItem("c3"),
    groomCom: sessionStorage.getItem("c4"),
    dressCom: sessionStorage.getItem("c5"),
    telephone: sessionStorage.getItem("a11"),
    shopping: sessionStorage.getItem("a12"),
    food: sessionStorage.getItem("a13"),
    housekeeping: sessionStorage.getItem("a14"),
    laundry: sessionStorage.getItem("a15"),
    transportation: sessionStorage.getItem("a16"),
    medication: sessionStorage.getItem("a17"),
    finances: sessionStorage.getItem("a18"),
    changesinADL: sessionStorage.getItem("a19"),
    changesinADLcomm: sessionStorage.getItem("b19"),
    //To Check (26.08.22)
    addInfo: sessionStorage.getItem("OTAdditionalInfo"),

	bowelComment: (sessionStorage.getItem("cc1")||""),
	bowelCurrent:(sessionStorage.getItem("bc1")||""),
	bowelPrevious:(sessionStorage.getItem("ac1")||""),
	
	bladderComment:(sessionStorage.getItem("cc2")||""),
	bladderCurrent:(sessionStorage.getItem("bc2")||""),
	bladderPrevious:(sessionStorage.getItem("ac2")||""),
	
	  driving:(sessionStorage.getItem("a21")||""),
    employment:(sessionStorage.getItem("a20")||""),
    leisure:(sessionStorage.getItem("a21l")||""),

	draft: true
  };
  	  saveOccupational(alldata, false)

  }
  const handleText = (event) => {
    var value = event.target.value;
    console.log(value);
    setResult(value);
    setOtherText(value);
    sessionStorage.setItem("OTAdditionalInfo", value);
  };
  var typography = "Add any additional comments as necessary.";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header history={"/results"} typography={typography} />
        <h1>Additional Info</h1>
        <div className="social-worker-container">
          <TextField
            multiline="true"
            rows="15"
            onChange={handleText}
            placeholder="Add additional information about the patient"
            // style={{ width: "100%" }}
            // label="Enter Social Service"
            value={otherText}
            defaultValue={otherText}
          ></TextField>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => navToNextPage()}
        currentPage={4}
        numPages={5}
      ></BottomNav>
    </div>
  );
}
