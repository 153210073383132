import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import BottomNav from "../bottomnavg";
import Header from "../header";
import { TextField } from "@mui/material";
import { maxWidth } from "@mui/system";
import { saveTeam3 } from "../firebase";

export default function AdditionalinfoGC() {
  const navigate = useNavigate();
  const [result, setResult] = useState("");
  const [otherText, setOtherText] = useState("");

  function navToNextPage() {
	  saveData()
    navigate("/review");
  }

  function navToPreviousPage() {
	  saveData()
    navigate("/results");
  }

  useEffect(() => {
    setOtherText(sessionStorage.getItem("GCAdditionalInfo"));
  }, []);
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  const handleText = (event) => {
    var value = event.target.value;
    console.log(value);
    setResult(value);
    setOtherText(value);
    sessionStorage.setItem("GCAdditionalInfo", value);
  };
  var typography = "Add any additional comments as necessary.";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header history={"/results"} typography={typography} />
        <h1>Additional Info</h1>
        <div className="social-worker-container">
          <TextField
            multiline="true"
            rows="15"
            onChange={handleText}
            placeholder="Add additional information about the patient"
            // style={{ width: "100%" }}
            // label="Enter Social Service"
            value={otherText}
            defaultValue={otherText}
          ></TextField>
        </div> 
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => navToNextPage()}
        currentPage={20}
        numPages={21}
      ></BottomNav>
    </div>
  );
}
