import "../styles/CommonStyle.css";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import Header from "../header";
import GripPhoto from "../assets/Grip-Strength-Test-Equipment.png";
import BottomNav from "../bottomnavg";
import { saveTeam2 } from "../firebase";

function GripStrength3() {
  var typography =
    "\nInstructions:\n \n1.  Use the Jamar dynamometer to carry out this test. \n2.  Patient is seated with shoulder adducted, elbow flexed to 90 degrees, and forearm and wrist neutral. \n3.  Place the dynamometer in the patient’s hand while gently supporting the base of the dynamometer. \n4.  Instruct the patient to squeeze as hard as possible.\n5.  Grip force should be applied smoothly, without rapid jerking motion. \n6.  Allow the wrist to extend during the grip. \n7. Carry out the test twice, for each hand. ";
  const [leftInput1, setLeftInput1] = useState(0);
  const [leftInput2, setLeftInput2] = useState(0);
  const [rightInput1, setRightInput1] = useState(0);
  const [rightInput2, setRightInput2] = useState(0);
  const [error, setError] = useState("");
  const [errorLeft, setErrorLeft] = useState("");
  const [errorRight, setErrorRight] = useState("");
  const [errorLeftRight, setErrorLeftRight] = useState("");
  const [errorConfirm, setErrorConfirm] = useState(false);

  const question1 = sessionStorage.getItem("question1");
  const question2 = sessionStorage.getItem("question2");
  const question3 = sessionStorage.getItem("question3");

  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }
  const [state, setState] = useState({
    gender: sessionStorage.getItem("Patientgender"),
   // gender: "Male"
  });
  console.log(sessionStorage.getItem("Patientgender") + "GENDER")

  const LeftResult = {
    // Changed "No Left Result" to "Test not conducted"
    TestResult: "Test not conducted",
    Risk: "Unidentified Risk",
  };
  const RightResult = {
    // Changed "No Right Result" to "Test not conducted"
    TestResult: "Test not conducted",
    Risk: "Unidentified Risk",
  };
  sessionStorage.setItem("MaxLeftHandResult", JSON.stringify(LeftResult));
  sessionStorage.setItem("MaxRightHandResult", JSON.stringify(RightResult));
  sessionStorage.setItem("Strength", JSON.stringify(RightResult));
  //sessionStorage.setItem("MaxRightHandResult", JSON.stringify(RightResult));

  let risk = "Unidentified Risk";

  const navigate = useNavigate();
  
  useEffect(()=>{
	   setLeftInput1(parseFloat(sessionStorage.getItem("leftInput1temp")));
     setLeftInput2(parseFloat( sessionStorage.getItem("leftInput2temp")));
     setRightInput1(parseFloat( sessionStorage.getItem("rightInput1temp")));
     setRightInput2(parseFloat( sessionStorage.getItem("rightInput2temp")));
  },[])

  useEffect(() => {
    if (
      sessionStorage.getItem("question1") !== "" ||
      sessionStorage.getItem("question2") !== "" ||
      sessionStorage.getItem("question3") !== ""
    ) {
      if (
        question2 == "recent pain right-hand" ||
        question3 == "yes recent surgery right-hand"
      ) {
        // document.getElementById("rightHandFieldset").hidden = true;
        // setErrorRight(
        //   "Results disabled due to recent pain or surgery in right hand"
        // );
      //  document.getElementById("FirstReadingR").disabled = true;
       // document.getElementById("SecondReadingR").disabled = true;

        setErrorLeftRight(
          "TEST CONTRA-INDICATED. Rigth Hand Results disabled due to recent pain or surgery in Right hand."
        );
        ////////////////////////////////////////////////////////////////////
      }
      if (
        question2 == "recent pain left-hand" ||
        question3 == "yes recent surgery left-hand"
      ) {
        // document.getElementById("leftHandFieldset").hidden = true;
        // setErrorLeft(
        //   "Results disabled due to recent pain or surgery in left hand"
        // );
        ///////////////////////////////
       // document.getElementById("FirstReadingL").disabled = true;
       // document.getElementById("SecondReadingL").disabled = true;

        setErrorLeftRight(
          "TEST CONTRA-INDICATED. Left Hand Results disabled due to recent pain or surgery in Left hand."
        );
      }
      if (question1 == "Right Hand") {
        if (
          question2 == "recent pain right-hand" ||
          question3 == "yes recent surgery right-hand"
        ) {
          // document.getElementById("rightHandFieldset").hidden = true;
          // document.getElementById("leftHandFieldset").hidden = true;
          // setErrorRight(
          //   "Results disabled due to recent pain or surgery in dominant hand"
          // );
          // setErrorLeft(
          //   "Results disabled due to recent pain or surgery in dominant hand"
          // );

          /////////////
    // document.getElementById("FirstReadingL").disabled = true;
          //document.getElementById("SecondReadingL").disabled = true;
          //document.getElementById("FirstReadingR").disabled = true;
          //document.getElementById("SecondReadingR").disabled = true;


          setErrorLeftRight(
            "TEST CONTRA-INDICATED. Results disabled due to recent pain or surgery in dominant hand."
          );
        }
      }
      if (question1 == "Left Hand") {
        if (
          question2 == "recent pain left-hand" ||
          question3 == "yes recent surgery left-hand"
        ) {
          // document.getElementById("rightHandFieldset").hidden = true;
          // document.getElementById("leftHandFieldset").hidden = true;
          // setErrorRight(
          //   "Results disabled due to recent pain or surgery in dominant hand"
          // );
          // setErrorLeft(
          //   "Results disabled due to recent pain or surgery in dominant hand"
          // );

         // document.getElementById("FirstReadingL").disabled = true;
          //document.getElementById("SecondReadingL").disabled = true;
          //document.getElementById("FirstReadingR").disabled = true;
          //document.getElementById("SecondReadingR").disabled = true;

          setErrorLeftRight(
            "TEST CONTRA-INDICATED. Results disabled due to recent pain or surgery in dominant hand."
          );
        }
      }
    }
  });

  function calculateRisk(result, gender) {
    console.log(gender)
    if (gender == "Male"||gender=="male") {
      if (result <= 25) {
        // sessionStorage.setItem("Strength", "Weak");
        return (risk = "Weak");
      } else {
        // sessionStorage.setItem("Strength", "Not Weak");
        return (risk = "Not Weak");
      }
    } else if (gender == "Female" || gender=="female") {
      if (result <= 15) {
        //sessionStorage.setItem("Strength", "Weak");
        return (risk = "Weak");
      } else {
        // sessionStorage.setItem("Strength", "Not Weak");
        return (risk = "Not Weak");
      }
    } else {
      const MResult = calculateRisk(result, "Male");
      const FResult = calculateRisk(result, "Female");
      const AllRisks = { MResult, FResult };
      return AllRisks;
    }
  }

  function maxVal(input, input2) {
    const values = [input, input2];
    const maxValue = Math.max(...values);
    return maxValue;
  }

  async function onSubmit() {
    // let patientData = sessionStorage.getItem("PatientData");
    // let actualPatientData = JSON.parse(patientData);

    // console.log("The Patients object: " , actualPatientData);
    // let DatabaseGender = actualPatientData.gender;
    // console.log("The patient gender: ", DatabaseGender);
    console.log(RightResult.Risk)
    console.log(LeftResult.Risk)
    console.log(state.gender + " gender")
	

      sessionStorage.setItem("leftInput1temp", (leftInput1));
      sessionStorage.setItem("leftInput2temp", (leftInput2));
      sessionStorage.setItem("rightInput1temp", (rightInput1));
      sessionStorage.setItem("rightInput2temp", (rightInput2));
	saveData()
    if (errorConfirm == true) {
      navigate("/GripStrength4");
    }
    setError("");

    if (
      leftInput1 <= 0 &&
      leftInput2 <= 0 &&
      rightInput1 <= 0 &&
      rightInput2 <= 0
    ) {
      setErrorRight("");
      setErrorLeft("");
      setError(
        "You're about to proceed without inputting anything, click NEXT button again to proceed without any values."
      );
      setErrorConfirm(true);
    } else if (leftInput1 <= 0 && leftInput2 <= 0) {
      setError("");
      setErrorRight("");
      setErrorLeft(
        "You're about to proceed without inputting LEFT HAND results, click NEXT button again to proceed without Left Hand values."
      );
      sessionStorage.setItem("MaxLeftHandResult", JSON.stringify(LeftResult));
      setErrorConfirm(true);
    } else if (rightInput1 <= 0 && rightInput2 <= 0) {
      setError("");
      setErrorLeft("");
      setErrorRight(
        "You're about to proceed without inputting RIGHT HAND results, click NEXT button again to proceed without Right Hand values."
      );
      sessionStorage.setItem("MaxRightHandResult", JSON.stringify(RightResult));
      setErrorConfirm(true);
    }
    if (leftInput1 > 0 || leftInput2 > 0) {
      let maxValue = maxVal(leftInput1, leftInput2);
      //Remember to Replace "X" with gender from Database!
      LeftResult.Risk = calculateRisk(maxValue, state.gender);

      LeftResult.TestResult = `${maxValue} kg`;
      sessionStorage.setItem("MaxLeftHandResult", JSON.stringify(LeftResult));
      sessionStorage.setItem("LeftHandResultStrength", LeftResult.Risk);
    }
    if (rightInput1 > 0 || rightInput2 > 0) {
      let maxValue = maxVal(rightInput1, rightInput2);
      //Remember to Replace "X" with gender from Database!
      RightResult.Risk = calculateRisk(maxValue, state.gender);
      RightResult.TestResult = `${maxValue} kg`;

      sessionStorage.setItem("MaxRightHandResult", JSON.stringify(RightResult));
      sessionStorage.setItem("RightHandResultStrength", RightResult.Risk);
    }

    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
    let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

    // let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
    //  let ActualObjectRightResult = JSON.parse(SessionRightResult);
saveData()
    if (
      leftInput1 > 0 ||
      leftInput2 > 0 ||
      rightInput1 > 0 ||
      rightInput2 > 0
    ) {
      // calculateRisk();
      navigate("/GripStrength4");
    }
  }
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/GripStrength2"}
          name={"Grip Strength Test"}
        />
        <h1>Hand Grip Strength Test</h1>
        <div className="main-section">
          <img
            style={{ width: "60%", paddingBottom: "0.8em" }}
            src={GripPhoto}
          />
          <p style={{ fontSize: "1em" }}>
            "In this exercise, I am going to use the dynamometer to test the
            strength in your hands"
          </p>
          <p style={{ fontSize: "1em" }}>
            "I would like you to take this in your hand. I will ask you to do
            this two times, both hands. When I say 'GO' press as hard as you
            can."
          </p>
        </div>

        <div className="main-section" style={{ marginTop: "1.2em" }}>
          <p style={{ fontSize: "1em", alignSelf: "center" }}>
            Set the dynamometer to zero (0) prior to each squeeze
          </p>
          <h5
            style={{
              color: "rgb(195, 0, 0)",
              marginTop: "0.6em",
              marginBottom: "0.6em",
            }}
          >
            {error}
          </h5>
          {/* <h2>Left Hand Results</h2> */}
          <p></p>
          <form>
            <fieldset id="leftHandFieldset" className="fieldset">
              <table style={{ width: "100%", fontSize: "1em" }}>
                <tr>
                  <td></td>
                  <td style={{ textAlign: "center" }}>
                    <h5>Right Hand</h5>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <h5>Left Hand</h5>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: "1em" }}>Measurement 1</td>

                  <td
                    style={{
                      textAlign: "left",
                      width: "33%",
                      paddingTop: "0.3em",
                    }}
                  >
                    <TextField
                      // disabled
                      // required
                      size="small"
                      id="FirstReadingR"
                      className="gripInputs"
                      //label="Required"
                      type="number"
                      // variant="outlined"
					value={rightInput1}
					defaultValue={rightInput1}

                      onChange={(event) => {
                        setRightInput1(event.target.value);
                        setErrorConfirm(false);
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment
                            style={{
                              paddingLeft: "0.5em",
                            }}
                            position="start"
                          >
                            kg
                          </InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                      width: "33%",
                      paddingTop: "0.3em",
                    }}
                  >
                    <TextField
                      size="small"
                      // fullWidth
                      id="FirstReadingL"
                      className="gripInputs"
                      // label="Required"
                      type="number"
                      variant="outlined"
                      onChange={(event) => {
                        setLeftInput1(event.target.value);
                        setErrorConfirm(false);
                      }}
					  value={leftInput1}
					defaultValue={leftInput1}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment
                            style={{
                              paddingLeft: "0.5em",
                            }}
                            position="start"
                          >
                            kg
                          </InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: "1em" }}>Measurement 2</td>

                  <td
                    style={{
                      textAlign: "left",
                      width: "33%",
                      paddingTop: "0.3em",
                    }}
                  >
                    <TextField
                      size="small"
                      id="SecondReadingR"
                      className="gripInputs"
                      //label="Required"
                      type="number"
                      variant="outlined"
                      onChange={(event) => {
                        setRightInput2(event.target.value);
                        setErrorConfirm(false);
                      }}
					  value={rightInput2}
					defaultValue={rightInput2}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment
                            style={{
                              paddingLeft: "0.5em",
                            }}
                            position="start"
                          >
                            kg
                          </InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td>

                  <td
                    style={{
                      textAlign: "left",
                      width: "33%",
                      paddingTop: "0.3em",
                    }}
                  >
                    <TextField
                      size="small"
                      sx={{ fontSize: "24px" }}
                      id="SecondReadingL"
                      className="gripInputs"
                      //label="Required"
                      type="number"
                      variant="outlined"
                      onChange={(event) => {
                        setLeftInput2(event.target.value);
                        setErrorConfirm(false);
                      }}
					  value={leftInput2}
					defaultValue={leftInput2}
                      InputProps={{
                        inputProps: { min: 0 },
                        endAdornment: (
                          <InputAdornment
                            style={{
                              paddingLeft: "0.5em",
                            }}
                            position="start"
                          >
                            kg
                          </InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td>
                </tr>

                {/* 
              <tbody>
                <tr>
                  {/* <td
                    style={{
                      textAlign: "left",
                      width: "33%",
                    }}
                  >
                    <label>1st Reading LEFT</label>
                    <TextField
                      fullWidth
                      id="FirstReading"
                      className="gripInputs"
                      label="Required"
                      type="number"
                      variant="filled"
                      onChange={(event) => {
                        setLeftInput1(event.target.value);
                        setErrorConfirm(false);
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">kg</InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td> */}

                {/* <td
                    style={{
                      textAlign: "center",
                      width: "33%",
                    }}
                  ></td> */}
                {/* <td
                    style={{
                      textAlign: "right",
                      width: "33%",
                    }}
                  >
                    <label>2nd Reading LEFT</label>
                    <TextField
                      sx={{ fontSize: "24px" }}
                      id="Second Reading"
                      className="gripInputs"
                      label="Required"
                      type="number"
                      variant="filled"
                      onChange={(event) => {
                        setLeftInput2(event.target.value);
                        setErrorConfirm(false);
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">kg</InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td> *
                </tr>
              </tbody> */}
              </table>
              {/* <b style={{ color: "red" }}>{errorLeftRight}</b> */}
              <h5 style={{ color: "rgb(195, 0, 0)", marginTop: "0.8em" }}>
                {errorLeftRight}
              </h5>
            </fieldset>

            {/* <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    width: "33%",
                  }}
                ></td>
                <td
                  style={{
                    textAlign: "center",
                    width: "33%",
                  }}
                ></td>
                <td
                  style={{
                    textAlign: "right",
                    width: "33%",
                  }}
                ></td>
              </tr>
            </tbody>
          </table> */}
            {/* {errorLeft}

          <br />
          <br />

          <fieldset id="rightHandFieldset" className="fieldset">
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  {/* <td
                    style={{
                      textAlign: "left",
                      width: "33%",
                    }}
                  >
                    <label>1st Reading RIGHT</label>
                    <TextField
                      fullWidth
                      id="FirstReading"
                      className="gripInputs"
                      label="Required"
                      type="number"
                      variant="filled"
                      onChange={(event) => {
                        setRightInput1(event.target.value);
                        setErrorConfirm(false);
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">kg</InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td> */}
            {/* <td
                    style={{
                      textAlign: "center",
                      width: "33%",
                    }}
                  ></td> */}
            {/* <td
                    style={{
                      textAlign: "right",
                      width: "33%",
                    }}
                  >
                    <label>2nd Reading RIGHT</label>
                    <TextField
                      id="Second Reading"
                      className="gripInputs"
                      label="Required"
                      type="number"
                      variant="filled"
                      onChange={(event) => {
                        setRightInput2(event.target.value);
                        setErrorConfirm(false);
                      }}
                      InputProps={{
                        inputProps: { min: 0 },
                        startAdornment: (
                          <InputAdornment position="start">kg</InputAdornment>
                        ),
                        inputMode: "numeric",
                      }}
                    />
                  </td> *
                </tr>
              </tbody>
            </table>
          </fieldset> */}
            {/* <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "left",
                    width: "33%",
                  }}
                ></td>
                <td
                  style={{
                    textAlign: "center",
                    width: "33%",
                  }}
                ></td>
                <td
                  style={{
                    textAlign: "right",
                    width: "33%",
                  }}
                ></td>
              </tr>
            </tbody>
          </table>
          {errorRight} */}
          </form>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => {saveData(); navigate("/GripStrength2")}}
        nextNavigation={() => onSubmit()}
        currentPage={6}
        numPages={9}
      ></BottomNav>
      {/* <button className="next-button" onClick={onSubmit}>
        Next
      </button> */}
    </div>
  );
}

export default GripStrength3;
