// Actually "Measurements" page
import React, { useContext, useEffect, useState } from "react";
import { Text } from "react";
import Header from "../header";
import TextField from "@mui/material/TextField";
import { width } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  InputLabel,
  ListItem,
  List,
  Typography,
  Box,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import BottomNav from "../bottomnavg";
// import Input from "./Input";
import { saveNurse } from "../firebase";


export default function Measurements(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [radio, setRadio] = useState("");
  const [result, setResult] = useState("");
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [calheight, setCalheight] = useState(0);
    const [bmicalc, setBmicalc] = useState("");
	

  //var patient = props.patient;
  const [weightlossdisplay, setWeightlossDisplay] = useState(false);
  const [measurementsdisplay, setMeasurementsDisplay] = useState(false);
  const [weightdisplay, setWieghtDisplay] = useState(false);
  
  const calcBmi = () =>{
	  console.log(weight, height)
	  if(!weight || !calheight) return;
	  const h = parseFloat(calheight)/100
	  const bmi = parseFloat(weight)/(h*h);
	  console.log(bmi)
	  if(bmi<18.5)
		setBmicalc("Underweight");
	  if( bmi>=18.5 && bmi<=24.9)
		setBmicalc("Normal");
	  if(bmi>=25 && bmi<=29.9)
		setBmicalc("Overweight");
	  if(bmi>30)
		setBmicalc("Obese");
  }
  
  useEffect(()=>{
	  calcBmi()
  }, [weight])
  
    useEffect(()=>{
	  calcBmi()
  }, [calheight])
  
      useEffect(()=>{
	  calcBmi()
  }, [height])
  
    const saveData =() => {

const alldata=  {
    addmission: sessionStorage.getItem("BarthelIndexAdmission"),
    discharge: sessionStorage.getItem("BarthelIndexDischarge"),
    BarthelDifference: sessionStorage.getItem("BarthelIndex"),
    CalculatedHeight: sessionStorage.getItem("Patient-CalculatedHeight"),
    Weight: sessionStorage.getItem("Patient-Weight"),
    ExerciseWeightLoss: sessionStorage.getItem("Due to exercise?"), //yes or no,
    WeightLoss: sessionStorage.getItem("Weight Lost?"), //yes or no
    OtherProfessionReferredSLP: sessionStorage.getItem("Nurse-SLP"),
    OtherProfessionReferredPodiatry: sessionStorage.getItem("Nurse-Podiatry"),
    OtherProfessionReferredNaD: sessionStorage.getItem("Nurse-NaD"),
    OtherProfessionReferredPsychology:
      sessionStorage.getItem("Nurse-Psychology"),
    OtherProfessionReferredPsychiatry:
      sessionStorage.getItem("Nurse-Psychiatry"),
    OtherProfessionReferredOther: sessionStorage.getItem("Nurse-Other"),
    AddInfo: sessionStorage.getItem("NurseAdditionalInfo"),
	Appetite: sessionStorage.getItem("Appetite3"),
	FeedingRoute: sessionStorage.getItem("Feeding-Route3"),
	AssistiveDevices: sessionStorage.getItem("assistive-devices-required"),
	WoundPresent: sessionStorage.getItem("wound-present"),
	SuturesClipsPresent: sessionStorage.getItem("Sutures-Clips-Present"),
	draft: true
  };
  	  saveNurse(alldata, false)

  }

  function validation() {
	  sessionStorage.setItem("bmi", bmicalc)
    console.log(" asdsd " + sessionStorage.getItem("Weight Lost?"));
    if (!height) {
      setMeasurementsDisplay(true);
    } else {
      setMeasurementsDisplay(false);
    }

    if (!weight) {
      setWieghtDisplay(true);
    } else {
      setWieghtDisplay(false);
    }

    if (!radio) {
      setWeightlossDisplay(true);
    } else {
      //setWeightlossDisplay(false);
      if (radio == "No") {
        setWeightlossDisplay(false);
        if (height != null && weight != null) {
						 saveData()

			 if(location?.state?.from=="Summary") navigate("/SummaryPageNurse");
    else{

          navigate("/Others");
	}
        }
      }

      if (radio == "Yes") {
        if(!result){
          setWeightlossDisplay(true)
        }
        else{
        setWeightlossDisplay(false);
        if (height != null && weight != null) {
						  saveData()

			 if(location?.state?.from=="Summary") navigate("/SummaryPageNurse");
    else
{

          navigate("/Others");
	}        }
      }
      }
    }

    if (sessionStorage.getItem("Weight Lost?") === "Yes") {
      if (sessionStorage.getItem("Due to exercise?") === null) {
        setWeightlossDisplay(true);
      }
    } else if (sessionStorage.getItem("Weight Lost?") === null) {
      setWeightlossDisplay(true);
    } else {
      setWeightlossDisplay(false);
    }

    // navigate("/OtherProfRef")
  }

  useEffect(() => {
    setRadio(sessionStorage.getItem("Weight Lost?"));
    setResult(sessionStorage.getItem("Due to exercise?"));
    setWeight(sessionStorage.getItem("Patient-Weight"));
    setHeight(sessionStorage.getItem("Patient-Height"));
    setCalheight(sessionStorage.getItem("Patient-CalculatedHeight"));
    // setState(sessionStorage.getItem("Patient-CalculatedHeight"))
    //state.height(sessionStorage.getItem("Patient-CalculatedHeight"));
    //setCalheight(sessionStorage.getItem("Patient-CalculatedHeight"))
  }, []);

  const [state, setState] = useState({
    demispan: "",
    height: "",
    age: sessionStorage.getItem("patientAge"),
    gender: sessionStorage.getItem("Patientgender"),
  });

  const handleWeightChange = (event) => {
    let value = parseFloat(event.target.value);
    console.log(value);
    setWeight(value);
    sessionStorage.setItem("Patient-Weight", value);
  };

  const handleHeightChange = (event) => {
    let result;
    let value = parseFloat(event.target.value);
    console.log(value);
    console.log(state);
    setHeight(value);
    sessionStorage.setItem("Patient-Height", value);
    if (value) {
      result = 67.51 + 1.29 * value - 0.12 * state.age;
      console.log(state.age +" " +state.gender + " age and gender")
      if (state.gender === "Male") {
        result += 4.13;
      }
      setState({
        demispan: parseFloat(value),
        height: result.toFixed(2),
        age: state.age,
        gender: state.gender,
      });
      sessionStorage.setItem("Patient-CalculatedHeight", result.toFixed(2));
      setCalheight(result.toFixed(2));
      // console.log(result)
    } else {
      setState({ demispan: "", height: "", age: state.age });
      console.log(setState({ demispan: "", height: "", age: state.age }));
    }
  };

  const handleChange = (event) => {
    var value = event.target.value;
    setRadio(value);
    console.log(value);
    sessionStorage.setItem("Weight Lost?", value);
    sessionStorage.setItem("Due to exercise?", "");
    var testing = [];
    setResult("");
    // setNext(true);
  };

  const readNewForm = (event) => {
    var value = event.target.value;
    console.log(value);
    setResult(value);
    sessionStorage.setItem("Due to exercise?", value);
    // setNext(false);
  };

  var typography =
    "Q1. Input demispan height, in centimetres. \nMeasure the distance from the middle of the sternal notch to the tip of the middle finger. \nCheck that patient’s arm is horizontal and in line with shoulder. \n\nQ2. Input patient weight, in kilograms. \nClick on the appropriate answers for the questions to follow. ";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          history={"/BarthelIndexPage"}
          name={"Nurse Assessment"}
          typography={typography}
        />
        <h1>Measurements</h1>
        <div className="nurse-container">
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            columns={{ xs: 2, sm: 2, md: 3 }}
            paddingTop={"1em"}
          >
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
              >
                Height:
              </Typography>

              <TextField
                className="height-box"
                variant="outlined"
                inputMode="numeric"
                onChange={handleHeightChange}
                demispan={state.demispan}
                label="Enter Demispan"
                type={"number"}
                value={height}
                // required
              ></TextField>
              <Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
              >
                cm
              </Typography>

              <TextField
                className="height-box"
                variant="outlined"
                inputMode="numeric"
                label="Calculated Height"
                // defaultValue={calheight}
                value={calheight}
                onChange={(e) => console.log(e)}
                disabled
                readOnly
              ></TextField>
              <Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
              >
                cm
              </Typography>
            </Box>
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: measurementsdisplay ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please input measurement
            </h5>
            {/* Weight box */}
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
              >
                Weight:
              </Typography>
              <TextField
                className="weight-box"
                variant="outlined"
                inputMode="numeric"
                type={"number"}
                onChange={handleWeightChange}
                value={weight}
              ></TextField>
              <Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
              >
                kg
              </Typography>
            </Box>
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: weightdisplay ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please input measurement
            </h5>
			{/* BMI box */}
			
            <Box display="flex" flexDirection="row" justifyContent="center">
              <Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
              >
                BMI:
              </Typography>
			   <Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
				className="weight-box"
              >
			  {bmicalc}
				  </Typography>
			  {weight && calheight &&<Typography
                display="flex"
                justifyContent="center"
                alignContent="center"
                flexDirection="column"
                paddingLeft={"1em"}
                paddingRight={"1em"}
				  >({(parseFloat(weight)/((parseFloat(calheight)/100)*(parseFloat(calheight)/100))).toFixed(2)})</Typography>}

            </Box>
          </Grid>

          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            // paddingTop={"1em"}
            padding={"1em"}
            //   columns={{ xs: 2, sm: 2, md: 3 }}
          >
            {/* ////// */}

            <RadioGroup value={radio} onChange={handleChange}>
              {/* //value={q3} onChange={handleAmendments} */}
              <Typography
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                paddingTop={"3em"}
                paddingBottom={"0.5em"}
              >
                Did the patient report any loss of weight in the past year?
              </Typography>

              <FormControlLabel
                className="FormControlLabel"
                value={"Yes"}
                control={<Radio />}
                label="Yes"
              />
              <div
                className="search-container"
                style={{ width: "70%", marginLeft: "3em" }}
              >
                <Typography
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  // padding={"1em"}
                >
                  Was the weight loss due to exercise or dieting?
                </Typography>
                <br />
                <RadioGroup value={result} onChange={readNewForm}>
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"Yes"}
                    control={<Radio />}
                    label="Yes"
                    disabled={radio == "Yes" ? false : true}
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"No"}
                    control={<Radio />}
                    label="No"
                    disabled={radio == "Yes" ? false : true}
                  />
                </RadioGroup>
                <br />
              </div>

              <FormControlLabel
                className="FormControlLabel"
                value={"No"}
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            {/* ///// */}
            {/* Alert message */}
            <h5
              className="alert"
              id="question1-alert"
              style={{
                display: weightlossdisplay ? null : "none",
                margin: "0",
                fontSize: "0.8em",
                paddingLeft: "1em",
              }}
            >
              Please select options
            </h5>
          </Grid>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => navigate("/BarthelIndexPage")}
        // nextNavigation={true}
        nextNavigation={() => validation()}
        currentPage={2}
        numPages={6}
      ></BottomNav>
    </div>
  );
}
