import React from "react";
// import PatientID from "./PatientID";
import { useState } from "react";
import { InputAdornment } from "@material-ui/core";
import {
  Button,
  InputLabel,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import {
  useNavigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { getUsers } from "./firebase";
import "./styles/Summary.css";
import Header from "./header";
import { List } from "@mui/material";
import { CenterFocusStrong } from "@material-ui/icons";
import Logo from "./assets/logo-big.png";

class Credentials {
  constructor(name, pass) {
    this.name = name;
    this.pass = pass;
    this.nameToken = false;
    this.passToken = false;
  }
}
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creds: new Credentials("", ""),
      users: getUsers(),
      errorMessage: false,
    };
  }

  StoreUsername = (event) => {
    let value = event.target.value;
    this.state.creds.name = value;
  };

  StorePassword = (event) => {
    let value = event.target.value;
    this.state.creds.pass = value;
  };

  Version2() {
    const { navigation } = this.props;
    navigation("/v2");
  }
  CompareCreds() {
    let users = this.state.users;
    const { navigation } = this.props;
    const version2 = window.location.href.includes("v2");
    console.log(this.state.users, "users");
    users.forEach((User) => {
      if (User.user === this.state.creds.name) {
        if (User.pass === this.state.creds.pass) {
          console.log(User.roles, "roles");
          if (User.roles) {
            if (version2) navigation("/v2/PatientView");
            else navigation("/PatientView");
            this.setState({ ...this.state, errorMessage: false });
          }
          if (User.roles === "nurse") {
            sessionStorage.setItem("roles", "nurse");
            this.setState({ ...this.state, errorMessage: false });
            // navigation("/menu");
          } else if (User.roles === "Physio") {
            sessionStorage.setItem("roles", "physio");
            this.setState({ ...this.state, errorMessage: false });
            // navigation("/menu");
          } else if (User.roles === "admin") {
            navigation("/AdminMenu");
            this.setState({ ...this.state, errorMessage: false });
          } else if (User.roles === "social worker") {
            sessionStorage.setItem("roles", "social worker");
            this.setState({ ...this.state, errorMessage: false });
            // navigation("/menu");
          } else if (User.roles === "geriatric consultant") {
            sessionStorage.setItem("roles", "geriatric consultant");
            this.setState({ ...this.state, errorMessage: false });
            //  navigation("/menu");
          } else if (User.roles === "occupational") {
            sessionStorage.setItem("roles", "occupational");
            this.setState({ ...this.state, errorMessage: false });
            //  navigation("/menu");
          } else if (User.roles === "other profession") {
            sessionStorage.setItem("roles", "other");
            this.setState({ ...this.state, errorMessage: false });
            // navigation("/menu");
          } else {
            this.setState({
              ...this.state,
              errorMessage: "Username and password are incorrect",
            });
          }
        } else {
          this.setState({
            ...this.state,
            errorMessage: "Username and password are incorrect",
          });
        }
      } else {
        this.setState({
          ...this.state,
          errorMessage: "Username and password are incorrect",
        });
      }
      console.log("error", this.state.errorMessage);
    });
  }

  render() {
    var typography =
      "Please insert your Username and password to be able to login to the app";
    return (
      <div
        className="screen"
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <Grid
          container
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          paddingTop={"20vh"}
          justifyContent={"center"}
        >
          <div item className="logo">
            <img src={Logo} />
          </div>
          <div item className="login-container">
            <List>
              <ListItem
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {console.log("state", this.state)}
                <p style={{ color: "red" }}>{this.state.errorMessage}</p>
                <TextField
                  onChange={this.StoreUsername}
                  item
                  style={{
                    minWidth: "225px",
                    width: "100%",
                  }}
                  label={"Username"}
                  InputLabelProps={{
                    style: { fontFamily: "Fenix", fontSize: "1.1em" },
                  }}
                  inputProps={{
                    style: {
                      fontFamily: "Fenix",
                      color: "grey",
                      fontSize: "1.2em",
                    },
                  }}
                >
                  {" "}
                </TextField>
              </ListItem>
              <ListItem
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <TextField
                  onChange={this.StorePassword}
                  item
                  style={{
                    minWidth: "225px",
                    width: "100%",
                  }}
                  label={"Password"}
                  type={"password"}
                  InputLabelProps={{
                    style: { fontFamily: "Fenix", fontSize: "1.1em" },
                  }}
                  inputProps={{
                    style: {
                      fontFamily: "Fenix",
                      color: "grey",
                      fontSize: "1.2em",
                    },
                  }}
                ></TextField>
              </ListItem>

              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{
                    fontSize: "0.85em",
                    fontFamily: "Fenix",
                    color: "grey",
                  }}
                >
                  Forgot password
                </Typography>
              </ListItem>

              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  style={{
                    paddingLeft: "3em",
                    paddingRight: "3em",
                    borderRadius: "25px",
                    fontFamily: "Fenix",
                    textTransform: "none",
                  }}
                  onClick={() => this.CompareCreds()}
                  variant={"contained"}
                >
                  Login
                </Button>
                {!window.location.href.includes("v2") && (
                  <Button
                    style={{
                      paddingLeft: "3em",
                      paddingRight: "3em",
                      borderRadius: "25px",
                      fontFamily: "Fenix",
                      textTransform: "none",
                    }}
                    onClick={() => this.Version2()}
                    variant={"contained"}
                  >
                    Version 2
                  </Button>
                )}
                {/* <Button onClick={() => navigation("/Patient")} fullWidth={"true"} variant = {"contained"}>Submit</Button> */}
              </ListItem>
            </List>
          </div>
        </Grid>
      </div>
    );
  }
}

export default function (props) {
  const navigate = useNavigate();

  return <Login {...props} navigation={navigate}></Login>;
}
