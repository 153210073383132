import "../styles/CommonStyle.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar } from "@mui/material";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveTeam2 } from "../firebase";

function GripStrength4() {
  var typography =
    "Click on the appropriate answers for both questions.";
  // console.log(sessionStorage.getItem("MaxLeftHandResult"));
  // console.log(sessionStorage.getItem("MaxRightHandResult"));
  const navigate = useNavigate();
  const [question1, setAnswerQuestion0] = useState("");
  const [question4, setAnswerQuestion1] = useState("");
  const [question5, setAnswerQuestion2] = useState("");
  const ObjectMaxLeftHandResult = sessionStorage.getItem("MaxLeftHandResult");
  const ObjectMaxRightHandResult = sessionStorage.getItem("MaxRightHandResult");

  const MaxLeftHandResult = JSON.parse(ObjectMaxLeftHandResult);
  const MaxRightHandResult = JSON.parse(ObjectMaxRightHandResult);
  // console.log("Question 1: ", question1);
  // console.log("Question 2: ", question2);
  // console.log("\n");
  
  
  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }

  useEffect(()=>{
    console.log(sessionStorage.getItem("TUGTestCarriedOut"))
    if(sessionStorage.getItem("TUGTestCarriedOut"))
      setAnswerQuestion0(sessionStorage.getItem("TUGTestCarriedOut"))
    if(sessionStorage.getItem("question4"))
      setAnswerQuestion1(sessionStorage.getItem("question4"))
    if(sessionStorage.getItem("question5"))
    setAnswerQuestion2(sessionStorage.getItem("question5"))
  }, [])

  useEffect(() => {
    if (MaxLeftHandResult != null) {
      try {
        // let status = sessionStorage.getItem("TUGStatus");
        // console.log("Status: ", status);
        // if (status !== "false") {
        //   if (status === "Not Recorded") {
        //     document.getElementById("radio-button-yes").disabled = true;
        //   } else {
        //     document.getElementById("radio-button-no").disabled = true;
        //   }
       // }
        // Answer to question "Test Result (Left hand)"
        // Changed "No Left Result" to "Test not conducted"
        if (MaxLeftHandResult.TestResult == "Test not conducted") {
      //    document.getElementById("gst-question1-radio1").disabled = true;
        } else {
       //   document.getElementById("gst-question1-radio2").disabled = true;
        }
        // Answer to question "Test Result (Right hand)"
        // Changed "No Right Result" to "Test not conducted"
        if (MaxRightHandResult.TestResult == "Test not conducted") {
     //     document.getElementById("gst-question2-radio1").disabled = true;
        } else {
      //    document.getElementById("gst-question2-radio2").disabled = true;
        }
      } catch {}
    }
  });

  function validateForm() {
    // console.log("\nEntered into the validate form");
    if (question1 == "") {
      document.getElementById("question0-other-alert").innerHTML = "";
      document.getElementById("question0-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question0Form").scrollIntoView();
    } else if (question1 == "other" || question1 == "No, Other: ") {
      document.getElementById("question0-alert").innerHTML = "";
      document.getElementById("question0-other-alert").innerHTML =
        "Please input a reason!";
      document.getElementById("question0Form").scrollIntoView();
    } else {
      document.getElementById("question0-alert").innerHTML = "";
      document.getElementById("question0-other-alert").innerHTML = "";
    }

    // if (question1 != "" && question1 != "other" && question1 != "No, Other: ") {
    //   sessionStorage.setItem("TUGTestCarriedOut", question1);
    //   navigate("/GripStrength");
    // }

    if (question5 == "") {
      document.getElementById("question2-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question2Form").scrollIntoView();
    } else if (question5 == "No, other" || question5 == "No, Other: ") {
      document.getElementById("question2-alert").innerHTML = "";
      document.getElementById("question2-other-alert").innerHTML =
        "Please input a reason!";
      document.getElementById("question2Form").scrollIntoView();
    } else {
      document.getElementById("question2-alert").innerHTML = "";
      document.getElementById("question2-other-alert").innerHTML = "";
    }

    if (question4 == "") {
      document.getElementById("question1-alert").innerHTML =
        "Please select an option!";
      document.getElementById("question1Form").scrollIntoView();
    } else if (question4 == "No, other" || question4 == "No, Other: ") {
      document.getElementById("question1-alert").innerHTML = "";
      document.getElementById("question1-other-alert").innerHTML =
        "Please input a reason!";
      document.getElementById("question1Form").scrollIntoView();
    } else {
      document.getElementById("question1-alert").innerHTML = "";
      document.getElementById("question1-other-alert").innerHTML = "";
    }
	saveData()
    if (question4 != "" && question5 != "" && question1 != "") {
      if (question4 != "No, other" && question5 != "No, other" && question1 != "other") {
        if (question4 != "No,Other: " && question5 != "No, Other: " && question1 != "No, Other: ") {
          // console.log("Passing results to GripStrength");
          sessionStorage.setItem("question4", question4);
          sessionStorage.setItem("question5", question5);
          sessionStorage.setItem("TUGTestCarriedOut", question1);
	saveData()

          // console.log(question4);
          // console.log(question5, "\n");
          navigate("/AdditionalinfoPhysio");
        }
      }
    }
  }

  return (
    <div className="screen" style={{justifyContent:"space-between"}}>
      <div className="screen" style={{width:"100%", minHeight:"auto"}}>
      <Header
        typography={typography}
        history={"/GripStrength3"}
        // name={"Grip Strength Test"}
      />
      <h1 style={{ paddingTop: "3%", paddingBottom: "3%", fontSize: "3em" }}>
        Tests Carried Out
      </h1>

      <div className="main-section">
        <h4>Timed Up and Go Test carried out</h4>
        {/*  */}
        <h3 className="alert" id="question0-alert"></h3>
        <form id="question0Form" style={{ width: "100%" }}>
          <div>
            <input
              className="radio"
              type="radio"
              id="radio-button-yes"
              name="group1"
              value="Yes"
              checked={question1 && question1.toLowerCase().includes("yes")}
              onChange={(e) => setAnswerQuestion0(e.target.value)}
              onClick={function () {
            //    document.getElementById("TUGTestCarriedOut").disabled = true;
                document.getElementById("radio-button-att").checked = false;
                document.getElementById("radio-button-unsafe").checked = false;
                document.getElementById("radio-button-unable").checked = false;
                document.getElementById("radio-button-refused").checked = false;
                document.getElementById(
                  "radio-button-prev-other"
                ).checked = false;
              }}
            />
            <label className="radio-button-label" htmlFor="radio-button-yes">
              Yes
            </label>
          </div>
          <div>
            <input
              className="radio"
              type="radio"
              id="radio-button-no"
              name="group1"
              value="no"
              checked={question1 && question1.toLowerCase().includes("no")}
              onChange={(e) => setAnswerQuestion0("no")}
              onClick={function () {
             //   document.getElementById("TUGTestCarriedOut").disabled = false;
              }}
            />

            <label className="radio-button-label" htmlFor="radio-button-no">
              No
            </label>
            {console.log(question1)}
          </div>
          <fieldset
            id="TUGTestCarriedOut"
            className="indented-radio-buttons fieldset"
            disabled={!(question1 && question1.toLowerCase().includes("no"))}
          >
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-att"
                name="group1-1"
                value="No, Attempted but unable"
                checked={question1 && question1.toLowerCase().includes("Attempted but unable".toLowerCase())}
                onChange={(e) => {
                  setAnswerQuestion0(e.target.value);
               //   document.getElementById("text-box").disabled = true;
                }}
              />
              <label className="radio-button-label" htmlFor="radio-button-att">
                Attempted, but unable
              </label>
            </div>
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-unsafe"
                name="group1-1"
                value="No, Unsafe"
                checked={question1 && question1.toLowerCase().includes("Unsafe".toLowerCase())}
                onChange={(e) => {
                  setAnswerQuestion0(e.target.value);
              //    document.getElementById("text-box").disabled = true;
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-unsafe"
              >
                Unsafe
              </label>
            </div>
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-unable"
                name="group1-1"
                value="No, Unable to understand command"
                checked={question1 && question1.toLowerCase().includes("Unable to understand command".toLowerCase())}
                onChange={(e) => {
                  setAnswerQuestion0(e.target.value);
              //    document.getElementById("text-box").disabled = true;
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-unable"
              >
                Unable to understand command
              </label>
            </div>
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-refused"
                name="group1-1"
                value="No, Refused"
                checked={question1 && question1.toLowerCase().includes("Refused".toLowerCase())}
                onChange={(e) => {
                  setAnswerQuestion0(e.target.value);
               //   document.getElementById("text-box").disabled = true;
                }}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-refused"
              >
                Refused
              </label>
            </div>
            <div>
              <input
                className="radio"
                type="radio"
                id="radio-button-prev-other"
                name="group1-1"
                value="other"
                checked={question1 && question1.toLowerCase().includes("other".toLowerCase())}
                onClick={function () {
              //    document.getElementById("text-box").disabled = false;
                }}
                onChange={(e) => setAnswerQuestion0("no, " + e.target.value)}
              />
              <label
                className="radio-button-label"
                htmlFor="radio-button-prev-other"
              >
                Other:{" "}
              </label>
              <input
                className="area"
                type="text"
                id="text-box"
                disabled={!(question1 && question1.toLowerCase().includes("other".toLowerCase()))}
                onBlur={(e) =>
                  setAnswerQuestion0("No, Other: " + e.target.value)
                }
                defaultValue={question1 && question1.includes("No, Other:") && question1.replace("No, Other: ", "")||""}
              />
              <h3 className="alert" id="question0-other-alert"></h3>
            </div>
          </fieldset>
        </form>
        <br />
        {/*  */}
        <h4>Hand Grip Strength Test carried out</h4>
        <br />
        <form id="question1Form" style={{ width: "100%" }}>
          <p className="par">
            <b>Left Hand</b>
          </p>
          <h3 className="alert" id="question1-alert" style={{textAlign: "center"}}></h3>
          <input
            className="radio"
            type="radio"
            id="gst-question1-radio1"
            name="question1-yes-no"
            onClick={function () {
            //  document.getElementById("leftHandFieldset").disabled = true;
              document.getElementById("gst-question1-radio3").checked = false;
              document.getElementById("gst-question1-radio4").checked = false;
              document.getElementById("gst-question1-radio5").checked = false;
              document.getElementById("gst-question1-radio6").checked = false;
              document.getElementById("gst-question1-radio7").checked = false;
            }}
            onChange={(e) => setAnswerQuestion1(e.target.value)}
            value="Yes"
            checked={question4 && question4.toLowerCase().includes("yes".toLowerCase())}
          />
          <label className="radio-button-label" htmlFor="gst-question1-radio1">
            Yes
          </label>
          <br />
          <input
            className="radio"
            type="radio"
            id="gst-question1-radio2"
            name="question1-yes-no"
            onClick={function () {
          //    document.getElementById("leftHandFieldset").disabled = false;
            }}
            checked={question4 && question4.toLowerCase().includes("no".toLowerCase())}
            value={"no"}
            onChange={(e) => setAnswerQuestion1("No")}
          />
          <label className="radio-button-label" htmlFor="gst-question1-radio2">
            No
          </label>
          <br />

          <fieldset
            id="leftHandFieldset"
            className="indented-radio-buttons fieldset"
            disabled={!(question4 && question4.toLowerCase().includes("no".toLowerCase()))}
          >
            <input
              className="radio"
              type="radio"
              id="gst-question1-radio3"
              name="question1-sub-questions"
              value="No, Attempted but unable"
              checked={question4 && question4.toLowerCase().includes("Attempted but unable".toLowerCase())}
              onClick={function () {
           //     document.getElementById("left-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion1(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question1-radio3"
            >
              Attempted but unable
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question1-radio4"
              name="question1-sub-questions"
              value="No, Unsafe"
              checked={question4 && question4.toLowerCase().includes("unsafe".toLowerCase())}
              onClick={function () {
          //      document.getElementById("left-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion1(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question1-radio4"
            >
              Unsafe
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question1-radio5"
              name="question1-sub-questions"
              value="No, Unable to understand command"
              checked={question4 && question4.toLowerCase().includes("Unable to understand command".toLowerCase())}
              onClick={function () {
            //    document.getElementById("left-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion1(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question1-radio5"
            >
              Unable to understand command
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question1-radio6"
              name="question1-sub-questions"
              value="No, Refused"
              checked={question4 && question4.toLowerCase().includes("Refused".toLowerCase())}
              onClick={function () {
            //    document.getElementById("left-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion1(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question1-radio6"
            >
              Refused
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question1-radio7"
              name="question1-sub-questions"
              value="No, other"
              checked={question4 && question4.toLowerCase().includes("other".toLowerCase())}
              onClick={function () {
           //     document.getElementById("left-hand-text-box").disabled = false;
              }}
              onChange={(e) => setAnswerQuestion1(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question1-radio7"
            >
              Other:{" "}
            </label>
            <input
              className="area"
              type="text"
              id="left-hand-text-box"
              disabled={!(question4 && question4.toLowerCase().includes("other".toLowerCase()))}
              onBlur={(e) => setAnswerQuestion1("No, Other: " + e.target.value)}
              defaultValue={question4 && question4.includes("No, Other: ") && question4.replace("No, Other: ", "") || ""}
            />
            <h3 className="alert" id="question1-other-alert"></h3>
          </fieldset>
        </form>
        <br />
        <form id="question2Form" style={{ width: "100%" }}>
          <p className="par">
            <b>Right Hand</b>
          </p>
          <h3 className="alert" id="question2-alert" style={{textAlign: "center"}}></h3>
          <div>
            <input
              className="radio"
              type="radio"
              id="gst-question2-radio1"
              name="question2-yes-no"
              onClick={function () {
              //  document.getElementById("rightHandFieldset").disabled = true;
                document.getElementById("gst-question2-radio3").checked = false;
                document.getElementById("gst-question2-radio4").checked = false;
                document.getElementById("gst-question2-radio5").checked = false;
                document.getElementById("gst-question2-radio6").checked = false;
                document.getElementById("gst-question2-radio7").checked = false;
              }}
              onChange={(e) => setAnswerQuestion2(e.target.value)}
              value="Yes"
              checked={question5 && question5.toLowerCase().includes("yes".toLowerCase())}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question2-radio1"
            >
              Yes
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question2-radio2"
              name="question2-yes-no"
              onClick={function () {
            //    document.getElementById("rightHandFieldset").disabled = false;
              }}
              onChange={(e) => setAnswerQuestion2("no")}
              checked={question5 && question5.toLowerCase().includes("no".toLowerCase())}
              value={"no"}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question2-radio2"
            >
              No
            </label>
          </div>

          <fieldset
            id="rightHandFieldset"
            className="indented-radio-buttons fieldset"
            disabled={!(question5 && question5.toLowerCase().includes("no".toLowerCase()))}
          >
            <input
              className="radio"
              type="radio"
              id="gst-question2-radio3"
              name="question2-sub-questions"
              value="No, Attempted but unable"
              checked={question5 && question5.toLowerCase().includes("Attempted but unable".toLowerCase())}
              onClick={function () {
            //    document.getElementById("right-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion2(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question2-radio3"
            >
              Attempted but unable
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question2-radio4"
              name="question2-sub-questions"
              value="No, Unsafe"
              checked={question5 && question5.toLowerCase().includes("Unsafe".toLowerCase())}
              onClick={function () {
            //    document.getElementById("right-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion2(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question2-radio4"
            >
              Unsafe
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question2-radio5"
              name="question2-sub-questions"
              value="No, Unable to understand command"
              checked={question5 && question5.toLowerCase().includes("Unable to understand command".toLowerCase())}
              onClick={function () {
            //    document.getElementById("right-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion2(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question2-radio5"
            >
              Unable to understand command
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question2-radio6"
              name="question2-sub-questions"
              value="No, Refused"
              checked={question5 && question5.toLowerCase().includes("refused".toLowerCase())}
              onClick={function () {
           //     document.getElementById("right-hand-text-box").disabled = true;
              }}
              onChange={(e) => setAnswerQuestion2(e.target.value)}
            />

            <label
              className="radio-button-label"
              htmlFor="gst-question2-radio6"
            >
              Refused
            </label>
            <br />
            <input
              className="radio"
              type="radio"
              id="gst-question2-radio7"
              name="question2-sub-questions"
              value="No, other"
              checked={question5 && question5.toLowerCase().includes("other".toLowerCase())}
              onClick={function () {
        //        document.getElementById("right-hand-text-box").disabled = false;
              }}
              onChange={(e) => setAnswerQuestion2(e.target.value)}
            />
            <label
              className="radio-button-label"
              htmlFor="gst-question2-radio7"
            >
              Other:{" "}
            </label>
            <input
              className="area"
              type="text"
              id="right-hand-text-box"
              disabled={!(question5 && question5.toLowerCase().includes("other".toLowerCase()))}
              onBlur={(e) => setAnswerQuestion2("No, Other: " + e.target.value)}
              defaultValue={question5 &&question5.includes("No, Other: ")&& question5.replace("No, Other: ", "") || ""}
            />
            <h3 className="alert" id="question2-other-alert"></h3>
          </fieldset>
        </form>
      </div>
      </div>
      <BottomNav
       prevNavigation={() => {saveData(); navigate("/GripStrength3")}}
        nextNavigation={() => validateForm()}
        currentPage={7}
        numPages={9}
      ></BottomNav>
      {/* <button className="next-button" onClick={validateForm}>
        Next
      </button> */}
      {/* <button className="next-button" onClick={validateForm}>
        Submit Results
      </button> */}
    </div>
  );
}
export default GripStrength4;
