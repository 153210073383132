import React, { useState, useEffect } from "react";
import "react-html5-camera-photo/build/css/index.css";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section5() {
  const [score, setScore] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();
  const [comment, setComment] = useState("");

  function navToNextPage() {
    sessionStorage.setItem("MOCASec5Comment", comment);
	saveData()
    navigate("/sec6");
  //  sessionStorage.setItem("clock1", "0");
    //sessionStorage.setItem("clock2", "0");
    //sessionStorage.setItem("clock3", "0");
  }
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  function getScore() {
    var score =
      parseInt(sessionStorage.getItem("clock1")) +
      parseInt(sessionStorage.getItem("clock2")) +
      parseInt(sessionStorage.getItem("clock3"));
	if(isNaN(score))setScore(0)
    else setScore(score);

    var scoretemp =
      score +
      parseInt(sessionStorage.getItem("pattern")) +
      parseInt(sessionStorage.getItem("cube"));

    sessionStorage.setItem("visuo", scoretemp.toString());
  }

  function onChangeClock1Check() {
	  console.log(document.getElementById("clock1").checked )
    if (document.getElementById("clock1").checked == true)
      sessionStorage.setItem("clock1", "1");
    else sessionStorage.setItem("clock1", "0");
console.log(sessionStorage.getItem("clock1"))
    getScore();
  }

  function onChangeClock2Check() {
    if (document.getElementById("clock2").checked == true)
      sessionStorage.setItem("clock2", "1");
    else sessionStorage.setItem("clock2", "0");

    getScore();
  }

  function onChangeClock3Check() {
    if (document.getElementById("clock3").checked == true)
      sessionStorage.setItem("clock3", "1");
    else sessionStorage.setItem("clock3", "0");

    getScore();
  }

  useEffect(() => {
	  if(sessionStorage.getItem("MOCASec5Comment") && sessionStorage.getItem("MOCASec5Comment")!="null")
    setComment(sessionStorage.getItem("MOCASec5Comment"));
console.log(sessionStorage.getItem("clock1"));
  if(sessionStorage.getItem("clock1") && sessionStorage.getItem("clock1")=="1"){
	  document.getElementById("clock1").checked = true;
	  getScore();
  }
   if(sessionStorage.getItem("clock2") && sessionStorage.getItem("clock2")=="1"){
	  document.getElementById("clock2").checked = true;
	  getScore();
  }
   if(sessionStorage.getItem("clock3") && sessionStorage.getItem("clock3")=="1"){
	  document.getElementById("clock3").checked = true;
	  getScore();
  }
  }, []);

  var typography = "Visuoconstructional Skills (Clock)\n\nInstruct the patient: \n“Draw a clock. Put in all the numbers and set the time to 10 past 11”. \n\nScoring: \nOne point is allocated for each of the following three criteria: \n•	Contour (1 pt.): the clock face must be a circle with only minor      distortion acceptable (e.g., slight imperfection on closing the circle).\n•	Numbers (1 pt.): all clock numbers must be present with no          additional numbers; numbers must be in the correct order and placed in the approximate quadrants on the clock face; Roman numerals are      acceptable; numbers can be placed outside the circle contour.\n•	Hands (1 pt.): there must be two hands jointly indicating the correct time; the hour hand must be clearly shorter than the minute hand; hands must be centred within the clock face with their junction close to the clock centre. \n\nA point is not assigned for a given element if any of the above criteria are not met.\nComment on the picture drawn. \nClick whether you would like to allocate the points or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/sec4"}
          name={"Visuospatial/Executive"}
        />
        <h1>MoCA Test</h1>
        <div className="gc-container">
          <h5 style={{ marginBottom: "1.5em" }}>Visuospatial / Executive</h5>
          <p style={{ textAlign: "center" }}>
            Draw Clock (Ten Past Eleven)
          </p>{" "}
          <br />
        </div>

        {/* Comment box */}
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <h5 style={{ marginBottom: "0.5em" }}>Comments</h5>
          <TextField
            multiline="true"
            rows="4"
            style={{ width: "100%" }}
            onChange={(e) => setComment(e.target.value)}
            placeholder="Enter additional comments (optional)"
            value={comment}
            defaultValue={comment}
          ></TextField>
        </div>
        {/* Score box */}
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <h5 style={{ marginBottom: "0.5em" }}>Patient correctly drew:</h5>
          <div>
            <input
              className="check"
              type="checkbox"
              id="clock1"
              onChange={onChangeClock1Check}
            />
            <label>Contour </label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="clock2"
              onChange={onChangeClock2Check}
            />
            <label>Numbers </label>
          </div>
          <div>
            <input
              className="check"
              type="checkbox"
              id="clock3"
              onChange={onChangeClock3Check}
            />
            <label>Hands </label>
          </div>
          <br />
          <label style={{ marginLeft: "0.5em" }}>
            <span>Score: {score} points</span>
          </label>
        </div>
      </div>
      <BottomNav
 nextNavigation={() => navToNextPage()}
        prevNavigation={() => {saveData(); navigate("/sec4")}}
        currentPage={8}
        numPages={21}
      />
 
    </div>
  );
}

export default Section5;
