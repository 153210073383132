import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import DoneIcon from "@mui/icons-material/Done";
import "./styles/footer.css";

import { useLocation, useNavigate } from "react-router-dom";

const BottomNav = ({
  prevNavigation,
  currentPage,
  numPages,
  nextNavigation,
  buttonPrevDisabled,
  buttonDisabled,
  submitButton, //if pass true shows the submit button
  goToSummary, //for the barthel
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const role = sessionStorage.getItem("roles");
  const prevLocation = location?.state?.from;
  // console.log(location);

  //Navigates to page depending on role
  const handleSummaryClick = () => {
    const allow = nextNavigation();
    if (allow == "false") return;
    if (goToSummary) {
      goToSummary();
    }
    console.log(location);
    if (location.pathname.includes("/v2")) {
      navigate("/v2/review");
    } else {
      if (role == "nurse") {
        navigate("/SummaryPageNurse");
      }

      if (role == "occupational") {
        navigate("/Section6OT");
      }

      if (role == "other") {
        navigate("/PatientView-Other");
      }

      if (role == "physio") {
        navigate("/SummaryTeam2");
      }

      if (role == "social worker") {
        navigate("/Summary-Social-Worker");
      }
      if (role == "geriatric consultant") {
        navigate("/review");
      }
    }
  };

  return (
    <div className="row-display footer-display">
      {/* if preNavigation is null or false the button is hidden */}
      <IconButton
        size="large"
        onClick={prevNavigation}
        style={{
          visibility:
            prevNavigation && !(location.state && location.state.summary)
              ? "visible"
              : "hidden",
          color: "white",
          marginRight: "1em",
          marginLeft: "1em",
          padding: "0.1em",
          backgroundColor: "#1976d2",
          borderRadius: "8px",
          boxShadow: "1px 1px 3px #2e2e1f",
        }}
      >
        <ArrowBackOutlinedIcon fontSize="large" />
      </IconButton>

      {/* if there is no current page and no number of pages nothing shows */}
      {currentPage &&
        numPages &&
        !(location.state && location.state.summary) && (
          <span style={{ fontSize: "1.8em", fontFamily: "Fenix" }}>
            {currentPage} of {numPages}
          </span>
        )}

      {/* if submit button true then show submit else show the arrow button */}
      {location.state && location.state.summary ? (
        <IconButton
          size="large"
          onClick={() => navigate("/Patient-Menu")}
          style={{
            color: "white",
            marginRight: "1em",
            marginLeft: "1em",
            padding: "0.1em",
            backgroundColor: "#32c953",
            borderRadius: "8px",
            boxShadow: "1px 1px 3px #2e2e1f",
          }}
        >
          <DoneIcon fontSize="large" />
        </IconButton>
      ) : location.state && location.state.from == "Summary" ? (
        <IconButton
          size="large"
          onClick={() => handleSummaryClick()}
          style={{
            color: "white",
            marginRight: "1em",
            marginLeft: "1em",
            padding: "0.1em",
            backgroundColor: "#32c953",
            borderRadius: "8px",
            boxShadow: "1px 1px 3px #2e2e1f",
          }}
        >
          <DoneIcon fontSize="large" />
        </IconButton>
      ) : submitButton ? (
        <IconButton
          size="large"
          onClick={submitButton}
          style={{
            color: "white",
            marginRight: "1em",
            marginLeft: "1em",
            padding: "0.1em",
            backgroundColor: "#32c953",
            borderRadius: "8px",
            boxShadow: "1px 1px 3px #2e2e1f",
          }}
        >
          <DoneIcon fontSize="large" />
        </IconButton>
      ) : (
        [
          // if button is disabled show gray forward button instead of blue one
          buttonDisabled ? (
            <IconButton
              disabled
              size="large"
              style={{
                color: "white",
                marginLeft: "1em",
                marginRight: "1em",
                padding: "0.1em",
                backgroundColor: "gray",
                borderRadius: "8px",
                boxShadow: "1px 1px 3px #2e2e1f",
              }}
            >
              <ArrowForwardIcon fontSize="large"></ArrowForwardIcon>
            </IconButton>
          ) : (
            <IconButton
              onClick={nextNavigation}
              size="large"
              style={{
                color: "white",
                marginLeft: "1em",
                marginRight: "1em",
                padding: "0.1em",
                backgroundColor: "#1976d2",
                borderRadius: "8px",
                boxShadow: "1px 1px 3px #2e2e1f",
              }}
            >
              <ArrowForwardOutlinedIcon fontSize="large" />
            </IconButton>
          ),
        ]
      )}
    </div>
  );
};

export default BottomNav;
