import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import BottomNav from "../bottomnavg";
import { saveDataSectionA } from "../firebase";

function Diagnosis() {
  const [question1, setAnswerQuestion1] = useState("");
  const [errormsg, setErrorMsg] = useState(false);
  const [errormsg2, setErrorMsg2] = useState(false);
  const [question5, setAnswerQuestion5] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    setAnswerQuestion1(sessionStorage.getItem("q1"));
    if (sessionStorage.getItem("q5"))
      setAnswerQuestion5(sessionStorage.getItem("q5"));
  }, []);

  useEffect(() => {
    setAnswerQuestion1(sessionStorage.getItem("q1"));
    if (sessionStorage.getItem("q5"))
      setAnswerQuestion5(sessionStorage.getItem("q5"));
  }, [sessionStorage.getItem("q5")]);

  const handleChange = (event) => {
    var value = event.target.value;
    setAnswerQuestion1(value);
  };

  const handleChange5 = (event) => {
    var value = event.target.value;
    setAnswerQuestion5(value);
    sessionStorage.setItem("q5", value);
  };

  function Validation() {
    saveDataSectionA();
    let question1Filled = question1 !== "" && question1 && question1 != "null";
    let question5Filled = question5 !== "" && question5 && question5 != "null";
    if (question1Filled && question5Filled) {
      sessionStorage.setItem("q1", question1);
      saveDataSectionA();
      navigate("/v2/mobilityLevel");
      return true;
    } else {
      if (!question5Filled) setErrorMsg(true);
      if (!question5Filled) setErrorMsg2(true);
    }
    return false;
  }

  var typography =
    "Click on the correct diagnosis / reason for admission of the patient.";
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/v2"} name={"Medical"} />
        <h1>Medical</h1>
        <div className="gc-container">
          <h5 style={{ paddingBottom: "0.5em" }}>
            Reason for admission to MDH
          </h5>

          <FormControl>
            <RadioGroup onChange={handleChange} value={question1}>
              <FormControlLabel
                value={"Falls no fracture"}
                control={<Radio />}
                label={"Falls no fracture"}
                style={{ paddingBottom: "0.3em" }}
              />

              <FormControlLabel
                value={"Falls with fracture"}
                control={<Radio />}
                label={"Falls with fracture"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Deterioration in general condition"}
                control={<Radio />}
                label={"Deterioration in general condition"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Respiratory"}
                control={<Radio />}
                label={"Respiratory"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Neurology"}
                control={<Radio />}
                label={"Neurology"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Amputation"}
                control={<Radio />}
                label={"Amputation"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"General Surgery"}
                control={<Radio />}
                label={"General Surgery"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Cognitive Decline / Dementia Care"}
                control={<Radio />}
                label={"Cognitive Decline / Dementia Care"}
                style={{ paddingBottom: "0.3em" }}
              />
              <FormControlLabel
                value={"Other"}
                control={<Radio />}
                label={"Other"}
                style={{ paddingBottom: "0.3em" }}
              />
            </RadioGroup>
          </FormControl>

          <br />
          <h4
            className="alert"
            id="question1-alert"
            style={{ margin: "0", display: errormsg ? "block" : "none" }}
          >
            Please select an option
          </h4>
          <br />
          <h5 style={{ paddingBottom: "0.5em" }}>Smoking</h5>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={question5}
              onChange={handleChange5}
              row
              style={{ paddingLeft: "3em" }}
            >
              <FormControlLabel
                className="FormControlLabel"
                value="Yes"
                control={<Radio />}
                label="Yes"
              />
              <FormControlLabel
                className="FormControlLabel"
                style={{ paddingLeft: "3.5em" }}
                value="No"
                control={<Radio />}
                label="No"
              />
            </RadioGroup>
            <br />
            <h4
              className="alert"
              id="question1-alert"
              style={{ margin: "0", display: errormsg2 ? null : "none" }}
            >
              Please select an option
            </h4>
          </FormControl>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => Validation()}
        currentPage={1}
        numPages={19}
      ></BottomNav>
    </div>
  );
}
export default Diagnosis;
