import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "../header";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { saveTeam3 } from "../firebase";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import BottomNav from "../bottomnavg";
import ScrollBtn from "../ScrollBtn";
import CsvDownloadButton from 'react-json-to-csv'
import JsPDF from 'jspdf';

function Review() {
  const [cmi, setCmi] = useState(0);
  const [moca_score, setMocaScore] = useState(0);
  const [moca_max, setMocaMax] = useState(0);
  const [moca_status, setMocaStatus] = useState(0);
  const [diagnosis, setDiagnosis] = useState("");
  const [condition, setCondition] = useState("");
  const [medical, setMedical] = useState("");
  const [surgical, setSurgical] = useState("");
  const [drug, setDrug] = useState("");
  const [smoking, setSmoking] = useState();
  const [alcohol, setAlcohol] = useState();
  const [education, setEducation] = useState("");
  const [openSave, setOpenSave] = useState(false);
  const dt = format(new Date(), "dd-MM-yyyy HH:mm:ss");

  const navigate = useNavigate();
  const location = useLocation();

  // This function will called only once
  useEffect(() => {
    loadDataOnlyOnce();
  }, []);

  const handleClose = () => {
    setOpenSave(false);
  };

  function submitResult() {
    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      dt,
      data
    );
    handleClose();
    navigate("/PatientView");
  }
  
    const generatePDF = () => {

    const report = new JsPDF('landscape','pt','a4');
    report.html(document.querySelector('#report')).then(() => {
        report.save('GeriatricsSummary.pdf');
    });
  }

  function loadDataOnlyOnce() {
    setDiagnosis(sessionStorage.getItem("q1"));
    setCondition(sessionStorage.getItem("q2"));
    setMedical(sessionStorage.getItem("q3"));
	if(sessionStorage.getItem("qsurgical"))
    setSurgical(sessionStorage.getItem("qsurgical"));
    setDrug(sessionStorage.getItem("q4"));
    setSmoking(sessionStorage.getItem("q5"));
    setAlcohol(sessionStorage.getItem("q6"));
    setEducation(sessionStorage.getItem("q7"));
    setCmi(parseInt(sessionStorage.getItem("mobility-index")));
    setMocaScore(parseInt(sessionStorage.getItem("moca-score")));
    setMocaMax(parseInt(sessionStorage.getItem("moca-max")));
    setMocaStatus(sessionStorage.getItem("moca-status"));
  }

  var typography =
    "Confirm your assessment findings. \nEdit from side edit buttons as necessary. \nConfirm and submit your assessment before exiting. ";

  const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: dt,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: cmi,
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          history={"/results"}
          name={"Geriatric Consultant Results Obtained"}
        />
        <h1>Summary</h1>
				<div id="report" style={{width:"70%"}}>

        {/* Diagnosis box */}
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto" }}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Diagnosis</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/diagnosis", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">{diagnosis}</td>
              <td className="summary-rightcolumn"></td>
            </tr>
          </table>
        </div>

        {/* Referral Details box */}
        <div className="summary-container" style={{ marginBottom: "1em"  , width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Referral Details</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/referraldetails", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">
                History of Present Condition:{" "}
              </td>
              <td className="summary-rightcolumn">{condition}</td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Past Medical History:</td>
              <td className="summary-rightcolumn">{medical}</td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Drug History:</td>
              <td td className="summary-rightcolumn">
                {drug}
              </td>
            </tr>
			 <tr>
              <td className="summary-leftcolumn">
                Surgical Procedure:{" "}
              </td>
              <td className="summary-rightcolumn">{surgical || "None"}</td>
            </tr>
          </table>
        </div>

        {/* Social Habits box */}
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto" }}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Social Habits</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/socialhabits", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">Smoking:</td>
              <td className="summary-rightcolumn">{smoking}</td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Alcohol:</td>
              <td className="summary-rightcolumn">{alcohol}</td>
            </tr>
          </table>
        </div>
        {/* Level of Education box */}
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto" }}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Level of Education</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/socialhabits", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">{education}</td>
              <td className="summary-rightcolumn"></td>
            </tr>
          </table>
        </div>

        {/* CCI box */}
        <div className="summary-container" style={{ marginBottom: "1em"  , width:"auto"}}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>
                  Charlson CoMorbidity Index
                </h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/mobility", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
            <tr>
              <td className="summary-leftcolumn">Total:</td>
              <td className="summary-rightcolumn">{cmi}</td>
            </tr>
          </table>
        </div>

        {/* MoCA box */}
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto" }}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>MoCA Score</h3>
              </td>
              <td></td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Total:</td>
              <td className="summary-rightcolumn">
                {moca_score}/{moca_max}
              </td>
            </tr>
            <tr>
              <td className="summary-leftcolumn">Classification:</td>
              <td className="summary-rightcolumn">{moca_status}</td>
            </tr>
          </table>
        </div>

        {/* Additional Info box */}
        <div className="summary-container" style={{ marginBottom: "1em" , width:"auto" }}>
          <table>
            <tr>
              <td>
                <h3 style={{ marginBottom: "12px" }}>Additional Info</h3>
              </td>
              {!(location.state && location.state.summary) && (
                <td>
                  <Button
                    style={{ marginBottom: "12px" }}
                    variant="outlined"
                    className="EditButton"
                    onClick={() =>
                      navigate("/AdditionalinfoGC", {
                        state: { from: "Summary" },
                      })
                    }
                  >
                    Edit
                  </Button>
                </td>
              )}
            </tr>
          </table>
          <div className="summary-additional-info">
            {sessionStorage.getItem("GCAdditionalInfo")}
          </div>
        </div>

        {/* Confirm dialog  */}
        <Dialog
          open={openSave}
          onClose={handleClose}
          style={{ padding: "1em", borderRadius: "25px" }}
        >
          <h5
            style={{
              marginTop: "1em",
              marginRight: "1em",
              marginLeft: "1em",
              marginBottom: "0.5em",
            }}
          >
            Submit?
          </h5>
          <DialogContentText
            style={{
              marginRight: "1.3em",
              marginLeft: "1.3em",
            }}
          >
            This will save the data and end the assessment.
          </DialogContentText>
          <div
            style={{
              padding: "1em",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {/* Closes dialog */}
            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => handleClose()}
              variant="contained"
            >
              No
            </Button>
            {/* Confirms dialog and moves to next page*/}
            <Button
              style={{
                paddingLeft: "2em",
                paddingRight: "2em",
                paddingTop: "2px",
                paddingBottom: "2px",
                borderRadius: "25px",
                fontFamily: "Fenix",
                textTransform: "none",
                fontSize: "16px",
              }}
              onClick={() => submitResult()}
              variant="contained"
            >
              Yes
            </Button>
          </div>
        </Dialog>
      </div>
	  	  		  <button onClick={generatePDF} style={{borderColor:"#015893", backgroundColor:"rgb(50, 201, 83)", borderRadius: 10, fontSize: "large"}} type="button">Export PDF</button>

      </div>
      <ScrollBtn />
      <BottomNav
        prevNavigation={() => navigate("/AdditionalinfoGC")}
        submitButton={() => setOpenSave(true)}
        currentPage={21}
        numPages={21}
      ></BottomNav>
    </div>
  );
}
export default Review;
