export default function resetData() {
  const role = sessionStorage.getItem("roles");
  const age = sessionStorage.getItem("patientAge");
  const gender = sessionStorage.getItem("Patientgender");
  const patientId = sessionStorage.getItem("patientId");
  sessionStorage.clear();
  sessionStorage.setItem("roles", role);
  sessionStorage.setItem("patientAge", age);
  sessionStorage.setItem("Patientgender", gender);
  sessionStorage.setItem("patientId", patientId); 
  //console.log(sessionStorage.setItem("gender"));
}
