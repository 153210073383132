import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveDataSectionA as saveData } from "../firebase";

function Section11() {
  const [score, setScore] = useState(0);

  function navToNextPage() {
    saveData();
    navigate("/v2/sec12");
  }

  function getScore() {
    var r1 = parseInt(sessionStorage.getItem("rep1"));
    var r2 = parseInt(sessionStorage.getItem("rep2"));

    var temp = 0;

    var rep = r1 + r2;
    if (rep === 2) {
      temp = 2;
    } else if (rep === 1) {
      temp = 1;
    } else if (rep === 0) {
      temp = 0;
    }

    setScore(temp);

    sessionStorage.setItem("lang", temp.toString());

    var scoretemp = temp + parseInt(sessionStorage.getItem("sec12"));

    sessionStorage.setItem("language", scoretemp.toString());
  }

  function onChangerep1Check() {
    if (document.getElementById("rep1").checked == true)
      sessionStorage.setItem("rep1", "1");
    else sessionStorage.setItem("rep1", "0");

    getScore();
  }

  function onChangerep2Check() {
    if (document.getElementById("rep2").checked == true)
      sessionStorage.setItem("rep2", "1");
    else sessionStorage.setItem("rep2", "0");

    getScore();
  }

  useEffect(() => {
    if (
      sessionStorage.getItem("rep1") &&
      sessionStorage.getItem("rep1") == "1"
    ) {
      document.getElementById("rep1").checked = true;
      getScore();
    }
    if (
      sessionStorage.getItem("rep2") &&
      sessionStorage.getItem("rep2") == "1"
    ) {
      document.getElementById("rep2").checked = true;
      getScore();
    }
  }, []);

  const navigate = useNavigate();

  var typography =
    "Sentence repetition\n\nInstruct the patient: \n“I am going to read you a sentence. Repeat it after me, exactly as I say it [pause]: I only know that John is the one to help today.” \n\nFollowing the response, say: “Now I am going to read you another      sentence. Repeat it after me, exactly as I say it [pause]: The cat always hid under the couch when dogs were in the room.” \n\nScoring:\nAllocate 1 point for each sentence correctly repeated. \nRepetition must be exact. Be alert for errors that are omissions (e.g., omitting “only”, “always”) and substitutions/additions (e.g., “John is the one who helped today;” substituting “hides” for “hid”, altering plurals, etc.). \n\nClick whether you would like to allocate the points or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec10"} name={"Language"} />
        <h1>MoCA Test</h1>
        <div className="moca-container">
          <h5 style={{ alignSelf: "flex-start", marginBottom: "1.7em" }}>
            Language
          </h5>
          <p style={{ marginBottom: "1.5em", textAlign: "center" }}>
            Patient needs to repeat the exact sentence.
          </p>
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignSelf: "flex-start",
            }}
          >
            <input
              className="check"
              type="checkbox"
              id="rep1"
              onClick={onChangerep1Check}
            />
            <label>I only know that John is the one to help today</label>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <input
              style={{
                marginLeft: "1em",
                marginTop: "0.4em",
                marginBottom: 0,
                marginRight: "1em",
              }}
              className="check"
              type="checkbox"
              id="rep2"
              onClick={onChangerep2Check}
            />
            <div style={{ maxWidth: "490px" }}>
              <label>
                The cat always hid under the couch when dogs were in the room
              </label>
            </div>
          </div>
        </div>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
        prevNavigation={() => {
          saveData();
          navigate("/v2/sec10");
        }}
        currentPage={13}
        numPages={19}
      />
    </div>
  );
}
export default Section11;
