import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { getNurseSummary } from "../firebase";
import { getPatients2 } from "../firebase";
import { Tabs } from "@mui/material";
import { Tab } from "@mui/material";
import Header from "../header";

export default function PatientViewSummary() {
  const navigation = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [sumAb, setSumAb] = useState(0);
  const [sumDb, setSumDb] = useState(0);
  const [patients, setPatients] = useState([]);
  const [current, setCurrent] = useState({});
  const [value, setValue] = useState("Barthel");

  const goBack = () => {
    navigation("/Patient-Menu");
  };

  useEffect(() => {
    getNurseSummary(sessionStorage.getItem("patientId")).then((result) => {
      setDocuments(result);
    });
    getPatients2().then((result) => {
      setPatients(result);
    });
  }, []);

  var typography = "Help Data";
  var Aseverity = "";
  var Dseverity = "";
  var AWeightLoss = "Yes";
  var DWeightLoss = "Yes";

  if (documents[3] !== undefined) {
    console.log(documents);

    if (documents[3].ahw.weightloss === 0) {
      AWeightLoss = "No";
    }
    if (documents[3].dhw.weightloss === 0) {
      DWeightLoss = "No";
    }
    if (documents[3].dhw.exercise === "no") {
      documents[3].dhw.exercise = "No";
    }
    if (documents[3].ahw.exercise === 1) {
      documents[3].ahw.exercise = "Yes";
    }

    patients.forEach((patient) => {
      if (patient.id === sessionStorage.getItem("patientId")) {
        setCurrent(patient);
        //console.log(current);
      }
    });
    if (sumAb === 0) {
      for (var i = 0; i < documents[3].ab.length; i++) {
        setSumAb(sumAb + documents[3].ab[i]);
      }
    }
    if (sumDb === 0) {
      for (var i = 0; i < documents[3].db.length; i++) {
        setSumDb(sumDb + documents[3].db[i]);
      }
    }

    if (sumAb >= 0 && sumAb <= 9) {
      Aseverity = "Severe";
    } else if (sumAb >= 10 && sumAb <= 14) {
      Aseverity = "Moderate";
    } else if (sumAb >= 15 && sumAb <= 19) {
      Aseverity = "Minimal";
    } else if (sumAb >= 20) {
      Aseverity = "Independent";
    }
    if (sumDb >= 0 && sumDb <= 9) {
      Dseverity = "Severe";
    } else if (sumDb >= 10 && sumDb <= 14) {
      Dseverity = "Moderate";
    } else if (sumDb >= 15 && sumDb <= 19) {
      Dseverity = "Minimal";
    } else if (sumDb >= 20) {
      Dseverity = "Independent";
    }
    const tabChange = (event, newValue) => {
      console.log(newValue);
      console.log(value);
      setValue(newValue);
      console.log(value);
    };

    console.log(documents[3]);
    console.log(value);
    const value = value;
    return (
      <div className="screen">
        <Header
          typography={typography}
          history={"/Patient-Menu"}
          name={"Nurse Summary"}
        />

        <div className="card">
          <p className="name">{current.name} </p>
          <p className="id">{current.id}</p>

          <div className="lastModifiedBy">
            <p>Last Modified by: Doctor</p>
          </div>
        </div>

        <Tabs
          className="tabs"
          textColor="black"
          value={value}
          onChange={tabChange}
        >
          <Tab value="Barthel" label="Barthel Index"></Tab>
          <Tab value={"HeightWeight"} label="Height & Weight"></Tab>
        </Tabs>
        {value === "Barthel" ? (
          <div className="card">
            <div className="grid-title">
              <p className="name">Barthel Score</p>
            </div>

            <div className="grid-page">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>ADMISSION</th>
                    <th>DISCHARGE</th>
                    <th>+/-</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="grid-data">
                    <td className="section">Mobility</td>
                    <td>{documents[3].ab[0]}</td>
                    <td>{documents[3].db[0]}</td>
                    <td className="total-diff">
                      {documents[3].db[0] - documents[3].ab[0]}
                    </td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Grooming</td>
                    <td>{documents[3].ab[1]}</td>
                    <td>{documents[3].db[1]}</td>
                    <td>{documents[3].db[1] - documents[3].ab[1]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Dressing</td>
                    <td>{documents[3].ab[2]}</td>
                    <td>{documents[3].db[2]}</td>
                    <td>{documents[3].db[2] - documents[3].ab[2]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Bathing</td>
                    <td>{documents[3].ab[3]}</td>
                    <td>{documents[3].db[3]}</td>
                    <td>{documents[3].db[3] - documents[3].ab[3]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Stairs</td>
                    <td>{documents[3].ab[4]}</td>
                    <td>{documents[3].db[4]}</td>
                    <td>{documents[3].db[4] - documents[3].ab[4]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Bowels</td>
                    <td>{documents[3].ab[5]}</td>
                    <td>{documents[3].db[5]}</td>
                    <td>{documents[3].db[5] - documents[3].ab[5]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Transfers</td>
                    <td>{documents[3].ab[6]}</td>
                    <td>{documents[3].db[6]}</td>
                    <td>{documents[3].db[6] - documents[3].ab[6]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Bladder</td>
                    <td>{documents[3].ab[7]}</td>
                    <td>{documents[3].db[7]}</td>
                    <td>{documents[3].db[7] - documents[3].ab[7]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Feeding</td>
                    <td>{documents[3].ab[8]}</td>
                    <td>{documents[3].db[8]}</td>
                    <td>{documents[3].db[8] - documents[3].ab[8]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Toilet Use</td>
                    <td>{documents[3].ab[9]}</td>
                    <td>{documents[3].db[9]}</td>
                    <td>{documents[3].db[9] - documents[3].ab[9]}</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Total</td>
                    <td className="total">{sumAb}</td>
                    <td className="total">{sumDb}</td>
                    <td className="total">{sumDb - sumAb} </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td className={`dependency ${Aseverity}`}>{Aseverity}</td>
                    <td className={`dependency ${Dseverity}`}>{Dseverity}</td>
                  </tr>
                  <br></br>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}

        {value === "HeightWeight" ? (
          <div className="card">
            <div className="grid-title">
              <p className="name">Height & Weight</p>
            </div>

            <div className="grid-page">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>ADMISSION</th>
                    <th>DISCHARGE</th>
                    <th>+/-</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="grid-data">
                    <td className="section">Height (cm)</td>
                    <td className="grid-row">{documents[3].ahw.height}</td>
                    <td className="grid-row">{documents[3].dhw.height}</td>
                    <td className="total-diff">
                      {(
                        documents[3].dhw.height - documents[3].ahw.height
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">Weight (kg)</td>
                    <td>{documents[3].ahw.weight}</td>
                    <td>{documents[3].dhw.weight}</td>
                    <td className="total-diff">
                      {(
                        documents[3].dhw.weight - documents[3].ahw.weight
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">
                      Has patient<br></br>lost weight?
                    </td>
                    <td>{AWeightLoss}</td>
                    <td>{DWeightLoss}</td>
                    <td className="total-diff">N/A</td>
                  </tr>
                  <tr className="grid-data">
                    <td className="section">
                      Was weight lost<br></br>due to exercise?
                    </td>
                    <td>{documents[3].ahw.exercise}</td>
                    <td>{documents[3].dhw.exercise}</td>
                    <td className="total-diff">N/A</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div>
          <Button
            sx={{
              backgroundColor: "#01497A",
              height: "15%",
              ["@media (min-width:720px)"]: { fontSize: "24px" },
              ["@media (max-width:720px)"]: { fontSize: "20px" },
              borderRadius: "20px",
              margin: "15px 15px",
            }}
            onClick={goBack}
            variant="contained"
          >
            Go To Menu
          </Button>
        </div>
      </div>
    );
  }
}
