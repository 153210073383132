import React, { useEffect, useState } from "react";
import { Text } from "react";
import Header from "../header";
import TextField from "@mui/material/TextField";
import { width } from "@mui/system";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  InputLabel,
  ListItem,
  List,
  Typography,
  Box,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import BottomNav from "../bottomnavg";
import ConfirmDialog from "../ConfirmDialog";
import {saveNurse} from "../firebase.js";
export default function ReferralDetails() {
  const navigate = useNavigate();
  const location = useLocation()
  // Texts in textfields
  const [SLPText, setSLPText] = useState("");
  const [PodiatryText, setPodiatryText] = useState("");
  const [NutritionText, setNutritionText] = useState("");
  const [PsychologyText, setPsychologyText] = useState("");
  const [PsychiatryText, setPsychiatryText] = useState("");
  const [otherText, setOtherText] = useState("");

  // Status of checkboxes
  const [SLPCheckbox, setSLPCheckbox] = useState(false);
  const [PodiatryCheckbox, setPodiatryCheckbox] = useState(false);
  const [NutritionCheckbox, setNutritionCheckbox] = useState(false);
  const [PsychologyCheckbox, setPsychologyCheckbox] = useState(false);
  const [PsychiatryCheckbox, setPsychiatryCheckbox] = useState(false);
  const [OtherCheckbox, setOtherCheckbox] = useState(false);
  
     const saveData =() => {

const alldata=  {
    addmission: sessionStorage.getItem("BarthelIndexAdmission"),
    discharge: sessionStorage.getItem("BarthelIndexDischarge"),
    BarthelDifference: sessionStorage.getItem("BarthelIndex"),
    CalculatedHeight: sessionStorage.getItem("Patient-CalculatedHeight"),
    Weight: sessionStorage.getItem("Patient-Weight"),
    ExerciseWeightLoss: sessionStorage.getItem("Due to exercise?"), //yes or no,
    WeightLoss: sessionStorage.getItem("Weight Lost?"), //yes or no
    OtherProfessionReferredSLP: sessionStorage.getItem("Nurse-SLP"),
    OtherProfessionReferredPodiatry: sessionStorage.getItem("Nurse-Podiatry"),
    OtherProfessionReferredNaD: sessionStorage.getItem("Nurse-NaD"),
    OtherProfessionReferredPsychology:
      sessionStorage.getItem("Nurse-Psychology"),
    OtherProfessionReferredPsychiatry:
      sessionStorage.getItem("Nurse-Psychiatry"),
    OtherProfessionReferredOther: sessionStorage.getItem("Nurse-Other"),
    AddInfo: sessionStorage.getItem("NurseAdditionalInfo"),
	Appetite: sessionStorage.getItem("Appetite3"),
	FeedingRoute: sessionStorage.getItem("Feeding-Route3"),
	AssistiveDevices: sessionStorage.getItem("assistive-devices-required"),
	WoundPresent: sessionStorage.getItem("wound-present"),
	SuturesClipsPresent: sessionStorage.getItem("Sutures-Clips-Present"),
	draft: true
  };
  	  saveNurse(alldata, false)

  }
  

  //TODO (22.08.22): Store data in Firebase!
  //Stores text in textfields
  useEffect(() => {
    setSLPCheckbox(sessionStorage.getItem("SLPcheck"));
    setPodiatryCheckbox(sessionStorage.getItem("PodiatryCheck"));
    setNutritionCheckbox(sessionStorage.getItem("NutritionCheck"));
    setPsychologyCheckbox(sessionStorage.getItem("PsychologyCheck"));
    setPsychiatryCheckbox(sessionStorage.getItem("PsychiatryCheck"));
    setOtherCheckbox(sessionStorage.getItem("OtherCheckbox"));

    setSLPText(sessionStorage.getItem("Nurse-SLP"));
    setPodiatryText(sessionStorage.getItem("Nurse-Podiatry"));
    setNutritionText(sessionStorage.getItem("Nurse-NaD"));
    setPsychologyText(sessionStorage.getItem("Nurse-Psychology"));
    setPsychiatryText(sessionStorage.getItem("Nurse-Psychiatry"));
    setOtherText(sessionStorage.getItem("Nurse-Other"));
  }, []);

  const handleSLPText = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("Nurse-SLP", value);
    setSLPText(value);
  };
  const handlePodiatryText = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("Nurse-Podiatry", value);
    setPodiatryText(value);
  };
  const handleNutritionText = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("Nurse-NaD", value);
    setNutritionText(value);
  };
  const handlePsychologyText = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("Nurse-Psychology", value);
    setPsychologyText(value);
  };
  const handlePsychiatryText = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("Nurse-Psychiatry", value);
    setPsychiatryText(value);
  };
  const handleOtherText = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("Nurse-Other", value);
    setOtherText(value);
  };

  //Handle changes in checkboxes
  const handleSLPCheckbox = (event) => {
    var value = event.target.checked;
    sessionStorage.setItem("SLPcheck", value);
    setSLPCheckbox(value);
    if (!event.target.checked) {
      sessionStorage.removeItem("Nurse-SLP");
    }
    // sessionStorage.setItem("SLPcheck", event.target.checked);
    // setSLPCheckbox(event.target.checked);
  };
  const handlePodiatryCheckbox = (event) => {
    sessionStorage.setItem("PodiatryCheck", event.target.checked);
    setPodiatryCheckbox(event.target.checked);
    if (!event.target.checked) {
      sessionStorage.removeItem("Nurse-Podiatry");
    }
  };
  const handleNutritionCheckbox = (event) => {
    sessionStorage.setItem("NutritionCheck", event.target.checked);
    setNutritionCheckbox(event.target.checked);
    if (!event.target.checked) {
      sessionStorage.removeItem("Nurse-NaD");
    }
  };
  const handlePsychologyCheckbox = (event) => {
    sessionStorage.setItem("PsychologyCheck", event.target.checked);
    if (!event.target.checked) {
      sessionStorage.removeItem("Nurse-Psychology");
    }
    setPsychologyCheckbox(event.target.checked);
  };
  const handlePsychiatryCheckbox = (event) => {
    sessionStorage.setItem("PsychiatryCheck", event.target.checked);
    setPsychiatryCheckbox(event.target.checked);
    if (!event.target.checked) {
      sessionStorage.removeItem("Nurse-Psychiatry");
    }
  };
  const handleOtherCheckbox = (event) => {
    sessionStorage.setItem("OtherCheckbox", event.target.checked);
    setOtherCheckbox(event.target.checked);
    if (!event.target.checked) {
      sessionStorage.removeItem("Nurse-Other");
    }
  };
  var typography =
    "Tick on the check boxes for any other professionals who as treating the patient. \nInput appropriate detail. ";

  // Handles confirmation dialog
  const [opendialog, setOpendialog] = useState(false);
  const handleDialogCancel = () => {
    setOpendialog(false);
  };

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/"}></Header>
        <h1>Other Profession Referred</h1>
        <div className="nurse-container" style={{ padding: "1.3em" }}>
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            rowGap={2}
            columns={{ xs: 2, sm: 2, md: 3 }}
            //paddingTop={"1em"}
          >
            {/* Speech and Language Checkbox */}
            <FormGroup>
              <FormControlLabel
                control={<Checkbox />}
                id="SLPCheckbox"
                checked={SLPCheckbox}
                defaultChecked={SLPCheckbox}
                onChange={handleSLPCheckbox}
                label="Speech and Language Pathologist"
              />
              <TextField
                style={{
                  width: "100%",
                  display: SLPCheckbox ? "flex" : "none",
                }}
                onChange={handleSLPText}
                label="Enter Relevant Details"
                value={SLPText}
                defaultValue={SLPText}
              ></TextField>
              {/* Podiatry Checkbox */}
              <FormControlLabel
                control={<Checkbox />}
                checked={PodiatryCheckbox}
                defaultChecked={PodiatryCheckbox}
                onChange={handlePodiatryCheckbox}
                label="Podiatry"
              />
              <TextField
                style={{
                  width: "100%",
                  display: PodiatryCheckbox ? "flex" : "none",
                }}
                onChange={handlePodiatryText}
                value={PodiatryText}
                defaultValue={PodiatryText}
                label="Enter Relevant Details"
              ></TextField>
              {/* Nutrition Checkbox */}
              <FormControlLabel
                control={<Checkbox />}
                checked={NutritionCheckbox}
                defaultChecked={NutritionCheckbox}
                onChange={handleNutritionCheckbox}
                label="Nutrition and Dietetics"
              />
              <TextField
                style={{
                  width: "100%",
                  display: NutritionCheckbox ? "flex" : "none",
                }}
                onChange={handleNutritionText}
                value={NutritionText}
                defaultValue={NutritionText}
                label="Enter Relevant Details"
              ></TextField>
              {/* Psychology Checkbox */}
              <FormControlLabel
                control={<Checkbox />}
                checked={PsychologyCheckbox}
                defaultChecked={PsychologyCheckbox}
                onChange={handlePsychologyCheckbox}
                label="Psychology"
              />
              <TextField
                style={{
                  width: "100%",
                  display: PsychologyCheckbox ? "flex" : "none",
                }}
                onChange={handlePsychologyText}
                value={PsychologyText}
                defaultValue={PsychologyText}
                label="Enter Relevant Details"
              ></TextField>
              {/* Psychiatry Checkbox */}
              <FormControlLabel
                control={<Checkbox />}
                checked={PsychiatryCheckbox}
                defaultChecked={PsychiatryCheckbox}
                onChange={handlePsychiatryCheckbox}
                label="Psychiatry"
              />
              <TextField
                style={{
                  width: "100%",
                  display: PsychiatryCheckbox ? "flex" : "none",
                }}
                onChange={handlePsychiatryText}
                value={PsychiatryText}
                defaultValue={PsychiatryText}
                label="Enter Relevant Details"
              ></TextField>
              {/* Other Checkbox */}
              <FormControlLabel
                control={<Checkbox />}
                checked={OtherCheckbox}
                defaultChecked={OtherCheckbox}
                onChange={handleOtherCheckbox}
                label="Other"
              />
              <TextField
                style={{
                  width: "100%",
                  display: OtherCheckbox ? "flex" : "none",
                }}
                onChange={handleOtherText}
                value={otherText}
                defaultValue={otherText}
                label="Enter Relevant Details"
              ></TextField>
            </FormGroup>
          </Grid>
        </div>
      </div>


      <BottomNav
        prevNavigation={() => navigate("/Others")}
        nextNavigation={() => {saveData();
 if(location?.state?.from=="Summary") navigate("/SummaryPageNurse");
		else{
		navigate("/AdditionalinfoNurse")}}}
        currentPage={4}
        numPages={6}
      ></BottomNav>
    </div>
  );
}
