import React, { useEffect, useState } from "react";
import Header from "../header";
import { Grid, TextField } from "@mui/material";
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import patients from "../assets/patients.json";
import { getPatients } from "../firebase";
import "../firebase";

export default function PatientID() {
  const [currentPatient, setCurrentPatient] = useState({
    id: 0,
    gender: "",
    name: "",
    surname: "",
  });
  const [patients, setPatients] = useState([]);
  const [fragments, setFragments] = useState(<></>);

  const navigation = useNavigate();

  useEffect(() => {
    getPatients().then((results) => {
      setPatients(results);
    });
  });

  function SetCurrentPatient(patient) {
    setCurrentPatient(patient);
    sessionStorage.setItem("PatientData", patient.id);
  }

  const DisplayPatients = (event) => {
    var value = event.target.value.toUpperCase();
    var testing = [];

    if (value !== "") {
      //console.log(getPatients());
      patients.map((patient, index) => {
        var tempID = patient.id.toUpperCase().substr(0, value.length);
        let regex = new RegExp(tempID, "g");

        if (regex.test(value)) {
          testing.push(
            <React.Fragment key={index}>
              <ListItemButton onClick={() => SetCurrentPatient(patient)}>
                <ListItemText primary={patient.name} secondary={patient.id} />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        }
      });

      setFragments(testing);
    } else {
      setFragments(<></>);
    }
  };

  // const [error, setError] = useState("");
  sessionStorage.setItem("PatientData", currentPatient.id);
  var typography = "Input a Patient ID";
  //const json = JSON.stringify(patients);
  //const obj = JSON.parse(json);

  if (currentPatient.id !== 0) {
    navigation("/diagnosis");
  }

  return (
    <div className="screen">
      <Grid
        justifyContent={"center"}
        rowGap={4}
        container
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <Header
          typography={typography}
          history={"/"}
          item
          name={"Patient Page"}
        />

        <Grid justifyContent={"center"} container item>
          <div className="search-container">
            <TextField
              label={"Patient ID"}
              // onClick ={() => navigation("/diagnosis")}
              onChange={DisplayPatients}
            ></TextField>
            <List>
              <Divider />
              {fragments}
            </List>
          </div>
        </Grid>
        <Grid container item></Grid>
      </Grid>
    </div>
  );
}
