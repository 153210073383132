import React, { useEffect } from "react";
import Header from "../header";
import { Grid, List, ListItem } from "@mui/material";
import { useState } from "react";
import "../styles/HomeContent.css";
import "../styles/Summary.css";
import { useNavigate } from "react-router-dom";
import { getPatients2 } from "../firebase";
import "../styles/SocialWorkerSum.css";
import BottomNav from "../bottomnavg";
import { getPatientSectionDetails } from "../firebase";

export default function PatientViewDetails() {
  const [patients, setPatients] = useState([]);
  const [currentPatient, setCurrentPatient] = useState({});

  const navigation = useNavigate();

  useEffect(() => {
    getPatients2().then((result) => {
      setPatients(result);
    });
  }, []);

  useEffect(() => {
    setPatient();
  }, [patients]);

  const setValues = (data) => {
    console.log(data);
    sessionStorage.setItem("pattern", "0");
    sessionStorage.setItem("cube", "0");
    sessionStorage.setItem("clock1", "0");
    sessionStorage.setItem("clock2", "0");
    sessionStorage.setItem("clock3", "0");
    sessionStorage.setItem("camel", "0");
    sessionStorage.setItem("lion", "0");
    sessionStorage.setItem("rhino", "0");
    sessionStorage.setItem("front", "0");
    sessionStorage.setItem("back", "0");
    sessionStorage.setItem("letters", "0");
    sessionStorage.setItem("one", "0");
    sessionStorage.setItem("two", "0");
    sessionStorage.setItem("three", "0");
    sessionStorage.setItem("four", "0");
    sessionStorage.setItem("five", "0");
    sessionStorage.setItem("rep1", "0");
    sessionStorage.setItem("rep2", "0");
    sessionStorage.setItem("sec12", "0");
    sessionStorage.setItem("count", "0");
    sessionStorage.setItem("trans", "0");
    sessionStorage.setItem("meas", "0");
    sessionStorage.setItem("date", "0");
    sessionStorage.setItem("day", "0");
    sessionStorage.setItem("month", "0");
    sessionStorage.setItem("year", "0");
    sessionStorage.setItem("place", "0");
    sessionStorage.setItem("city", "0");
    sessionStorage.setItem("eduCheck", "0");
    sessionStorage.setItem("blindCheck", "0");
    sessionStorage.setItem("writeCheck", "0");
    sessionStorage.setItem("face", "0");
    sessionStorage.setItem("velvet", "0");
    sessionStorage.setItem("red", "0");
    sessionStorage.setItem("daisy", "0");
    sessionStorage.setItem("church", "0");
    sessionStorage.setItem("cubepic", "");
    sessionStorage.setItem("clockpic", "");
    sessionStorage.setItem("patternpic", "");
    sessionStorage.setItem("subtraction", "0");
    sessionStorage.setItem("orientation", "0");
    sessionStorage.setItem("delayedrecall", "0");
    sessionStorage.setItem("abstraction", "0");
    sessionStorage.setItem("language", "0");
    sessionStorage.setItem("attention", "0");
    sessionStorage.setItem("naming", "0");
    sessionStorage.setItem("visuo", "0");

    //   setDiagnosis(
    sessionStorage.setItem("q1", data.section1.diagnosis);
    // setCondition(
    sessionStorage.setItem("q2", data.section1.presentCondition);
    // setMedical(
    sessionStorage.setItem("q3", data.section1.medicalHistory);
    // setDrug(
    sessionStorage.setItem("q4", data.section1.drugHistory);
    // setSmoking(
    sessionStorage.setItem("q5", data.section1.smoking);
    // setAlcohol(
    sessionStorage.setItem("q6", data.section1.alcohol);
    // setEducation(
    sessionStorage.setItem("q7", data.section1.education);
    sessionStorage.setItem("qsurgical", data.section1.surgicalProcedure);
    // setCmi(parseInt(
    sessionStorage.setItem("mobility-index", data.section1.charlsonIndex);
    // setMocaScore(parseInt(
    sessionStorage.setItem("moca-score", data.moca.finalScore.split("/")[0]);
    // // setMocaMax(parseInt(
    const maxMoca =
      data.moca.finalScore.split("/")[1] &&
      data.moca.finalScore.split("/")[1].split(" -")[0];
    sessionStorage.setItem("moca-max", maxMoca);
    // // setMocaStatus(
    sessionStorage.setItem("moca-status", data.moca.finalScore.split("-")[1]);
    sessionStorage.setItem("GCAdditionalInfo", data.AddInfo);
    sessionStorage.setItem(
      "mobility-index-checked",
      data.CharlsonIndexChecklist
    );
    sessionStorage.setItem("pattern", data.MocaTest?.visioPattern);
    sessionStorage.setItem("cube", data.MocaTest?.cube);
    sessionStorage.setItem("clock1", data.MocaTest?.clock1);
    sessionStorage.setItem("clock2", data.MocaTest?.clock2);
    sessionStorage.setItem("clock3", data.MocaTest?.clock3);
    sessionStorage.setItem("camel", data.MocaTest?.camel);
    sessionStorage.setItem("rhino", data.MocaTest?.rhino);
    sessionStorage.setItem("lion", data.MocaTest?.lion);
    sessionStorage.setItem("trail1", data.MocaTest?.trial1);
    sessionStorage.setItem("trail2", data.MocaTest?.trial2);
    sessionStorage.setItem("front", data.MocaTest?.front);
    sessionStorage.setItem("back", data.MocaTest?.back);
    sessionStorage.setItem("letters", data.MocaTest?.letters);
    sessionStorage.setItem("checkedV", data.MocaTest?.serial);
    sessionStorage.setItem("rep1", data.MocaTest?.rep1);
    sessionStorage.setItem("rep2", data.MocaTest?.rep2);

    sessionStorage.setItem("TUGTimer", data.MocaTest?.tugTimer);
    sessionStorage.setItem("counter", data.MocaTest?.counter);
    sessionStorage.setItem("trans", data.MocaTest?.trans);
    sessionStorage.setItem("meas", data.MocaTest?.meas);
    sessionStorage.setItem("trail22", data.MocaTest?.trail22);
    sessionStorage.setItem("trail21", data.MocaTest?.trail11);
    sessionStorage.setItem("trail23", data.MocaTest?.trail33);
    sessionStorage.setItem("checkedv15", data.MocaTest?.mocaTestOrientation);
    sessionStorage.setItem("writeCheck", data.MocaTest?.writeCheck);
    sessionStorage.setItem("eduCheck", data.MocaTest?.eduCheck);
    sessionStorage.setItem("blindCheck", data.MocaTest?.blindCheck);
    sessionStorage.setItem(
      "MOCASec3Comment",
      data.MocaTest?.visualPatternComment
    );
    sessionStorage.setItem("MOCASec4Comment", data.MocaTest?.cubeComment);
    sessionStorage.setItem("MOCASec5Comment", data.MocaTest?.clockComment);

    sessionStorage.setItem("attention", data.moca.attention);
    sessionStorage.setItem("abstraction", data.moca.abstraction);
    sessionStorage.setItem("language", data.moca.language);
    sessionStorage.setItem("naming", data.moca.naming);
    sessionStorage.setItem("orientation", data.moca.orientation);
    sessionStorage.setItem("delayedrecall", data.moca.recall);
    sessionStorage.setItem("visuo", data.moca.visual);

    sessionStorage.setItem(
      "TUGQuestion1",
      data.TUGTestResults.LevelsOfMobility.PreviousLevelofMobility ||
        data.TUGTestResults.LevelsOfMobility.IPreviousLevelofMobility
    );
    sessionStorage.setItem("q5", data.smoking);
    sessionStorage.setItem("q1", data.diagnosis);
    sessionStorage.setItem("transferQuestion", data.transferQuestion);
    sessionStorage.setItem("SocialStatus", data.socialStatus);
    sessionStorage.setItem("RelativePlans", data.relativePlans);
    sessionStorage.setItem(
      "TUGQuestion2",
      data.TUGTestResults.LevelsOfMobility.CurrentLevelofMobility ||
        data.TUGTestResults.LevelsOfMobility.ICurrentLevelofMobility
    );
    sessionStorage.setItem(
      "mobilityQuestion11",
      data.TUGTestResults.LevelsOfMobility.DPreviousLevelofMobility
    );
    sessionStorage.setItem(
      "mobilityQuestion22",
      data.TUGTestResults.LevelsOfMobility.DCurrentLevelofMobility
    );
    sessionStorage.setItem(
      "TUGTestCarriedOut",
      data.TUGTestResults.TUGTestCarriedOut
    );
    sessionStorage.setItem(
      "TUGTimer",
      data.TUGTestResults.RiskOfFallStatus.TimeTakenInSeconds
    );
    sessionStorage.setItem(
      "TUGStatus",
      data.TUGTestResults.RiskOfFallStatus.Status
    );
    sessionStorage.setItem("question1", data.GripStrengthResults.Question1);
    sessionStorage.setItem("question4", data.GripStrengthResults.Question4);
    //LeftResultCHECK
    sessionStorage.setItem(
      "MaxLeftHandResult",
      JSON.stringify(data.GripStrengthResults.MaxLeftHandResult)
    );

    sessionStorage.setItem("question5", data.GripStrengthResults.Question5);
    //Right result CHECK
    sessionStorage.setItem(
      "MaxRightHandResult",
      JSON.stringify(data.GripStrengthResults.MaxRightHandResult)
    );
    sessionStorage.setItem("PhysioAdditionalInfo", data.AddInfo);

    //current mobility
    sessionStorage.setItem("ulRight", data.CurrentMobility?.ul?.right || "");
    sessionStorage.setItem("ulLeft", data.CurrentMobility?.ul?.left);

    sessionStorage.setItem("llRight", data.CurrentMobility?.ll?.right);
    sessionStorage.setItem("llLeft", data.CurrentMobility?.ll?.left);

    sessionStorage.setItem("bedmobility", data.CurrentMobility?.bedMobility);
    sessionStorage.setItem("transfers", data.CurrentMobility?.transfers);
    sessionStorage.setItem("freeText", data.CurrentMobility?.otherComment);
    sessionStorage.setItem("gripStrength1", data.HandGripStrengthTest.first);
    sessionStorage.setItem("gripStrength2", data.HandGripStrengthTest.second);
    sessionStorage.setItem("gripStrength3", data.HandGripStrengthTest.third);
    sessionStorage.setItem("leftInput1temp", data.HandGripStrengthTest.left1);
    sessionStorage.setItem("leftInput2temp", data.HandGripStrengthTest.left2);
    sessionStorage.setItem("rightInput1temp", data.HandGripStrengthTest.right1);
    sessionStorage.setItem("rightInput2temp", data.HandGripStrengthTest.right2);
  };

  const handleClick = () => {
    getPatientSectionDetails(
      sessionStorage.getItem("patientId"),
      "SectionA"
    ).then((data1) => {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionB"
      ).then((data) => {
        const allData = { ...data, ...data1 };
        console.log(data, data1);
        if (allData) {
          setValues(allData);
        }
        if (!data || !data1 || data.draft || data1.draft)
          navigation("/v2/diagnosis");
        else {
          navigation("/v2/review", { state: { summary: true } });
        }
      });
    });
    navigation(
      window.location.href.includes("v2") ? "/v2/diagnosis" : "/Patient-Menu"
    );
  };

  var typography =
    "Confirm patient details are correct. \nProceed to assessment or go back to choose another patient.";

  // sessionStorage.setItem("id","45");
  var id = sessionStorage.getItem("id");

  const setPatient = () => {
    patients.forEach((patient) => {
      if (patient.id === id) {
        setCurrentPatient(patient);
        console.log(currentPatient);
      }
    });
    console.log(patients);
  };

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Grid
          justifyContent={"center"}
          container
          rowGap={4}
          columns={{ xs: 2, sm: 2, md: 6 }}
        >
          <Header
            history={
              window.location.href.includes("v2")
                ? "/v2/PatientView"
                : "/PatientView"
            }
            typography={typography}
            name={"Patient Details"}
          ></Header>
          <h1 style={{ paddingTop: "0.4em", paddingBottom: "0.1em" }}>
            Patient Details
          </h1>
          <List>
            <ListItem>
              <table
                className="search-container"
                style={{ borderSpacing: "0.7em" }}
              >
                {/* <Button onClick={() => this.forceUpdate()}  > Hello</Button> */}
                <tr className="grid-data">
                  <td>
                    <h5>Name :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>{currentPatient.name}</td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>ID :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>{currentPatient.id}</td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Age :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>{currentPatient.age}</td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Gender :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.gender}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Address :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {[
                      currentPatient.houseNum,
                      currentPatient.houseName,
                      currentPatient.streetname,
                      currentPatient.locality,
                    ]
                      .join(", ")
                      .replace(",", "")
                      .replace(", ,", ",")}
                  </td>
                </tr>
              </table>
            </ListItem>
            <ListItem>
              <table
                className="search-container"
                style={{ borderSpacing: "0.7em" }}
              >
                <tr className="grid-data">
                  <td>
                    <h5>Next of Kin Name :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.kinName}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Relationship :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.kinRelation}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Contact Number :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.KinNumber}
                  </td>
                </tr>
              </table>
            </ListItem>
            <ListItem>
              <table
                className="search-container"
                style={{ borderSpacing: "0.7em" }}
              >
                {/* <td className="Table-Names"><h3>Support</h3></td><br /> */}
                {/* <h5 style={{paddingBottom:"3em"}}>jifdsojois</h5>  */}
                <tr className="grid-data">
                  <td>
                    <h5>Admitting Consultant :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.AdmitConsultant}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5>Admitted Ward :</h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.AdmitWard}
                  </td>
                </tr>
                <tr className="grid-data">
                  <td>
                    <h5> No. of Admissions in Past Year : </h5>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {currentPatient.AdmitLastYear}
                  </td>
                </tr>
              </table>
            </ListItem>
          </List>
        </Grid>
      </div>
      <BottomNav
        nextNavigation={() => handleClick()}
        prevNavigation={() =>
          navigation(
            window.location.href.includes("v2")
              ? "/v2/PatientView"
              : "/PatientView"
          )
        }

        // buttonDisabled={next}
      />
      {/* <ListItem>
            <Button onClick={handleClick} variant="contained" fullWidth={true}>
              Next
            </Button>
          </ListItem> */}
    </div>
  );
}
