import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { CardContent } from "@mui/material";
import { typography } from "@mui/system";
import BottomNav from "../bottomnavg";
import { saveTeam3 } from "../firebase";

function Section8() {
  const [score, setScore] = useState(0);
  // Handles confirm dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  
  
  const saveData = () =>{
	    const data = {
    consultant: sessionStorage.getItem("doctor"),
    date: null,
    mobilityIndex: sessionStorage.getItem("mobility-index"),
    abstraction: sessionStorage.getItem("abstraction"),
    attention: sessionStorage.getItem("attention"),
    finalScore:
      sessionStorage.getItem("moca-score") +
      "/" +
      sessionStorage.getItem("moca-max") +
      " - " +
      sessionStorage.getItem("moca-status"),
    language: sessionStorage.getItem("language"),
    naming: sessionStorage.getItem("naming"),
    orientation: sessionStorage.getItem("orientation"),
    recall: sessionStorage.getItem("delayedrecall"),
    visual: sessionStorage.getItem("visuo"),
    alcohol: sessionStorage.getItem("q6"),
    diagnosis: sessionStorage.getItem("q1"),
    drugHistory: sessionStorage.getItem("q4"),
    education: sessionStorage.getItem("q7"),
    medicalHistory: sessionStorage.getItem("q3"),
    presentCondition: sessionStorage.getItem("q2"),
    smoking: sessionStorage.getItem("q5"),
    charlson: parseInt(sessionStorage.getItem("mobility-index")),
    AddInfo: sessionStorage.getItem("GCAdditionalInfo"),
	surgicalProcedure: sessionStorage.getItem("qsurgical")
  };
	    saveTeam3(
      sessionStorage.getItem("patientId"),
      sessionStorage.getItem("doctor"),
      null,
      data, true, false
    );
  }

  function navToNextPage() {
	  saveData()
    navigate("/sec9");
  //  sessionStorage.setItem("front", "0");
  //  sessionStorage.setItem("back", "0");
  }

  function getScore() {
    var num1 = parseInt(sessionStorage.getItem("front"));
    var num2 = parseInt(sessionStorage.getItem("back"));

    var num3 = num1 + num2;
    var temp = 0;
	console.log(num3)

    if (num3 === 1) {
      temp = 1;
    } else if (num3 === 2) {
      temp = 2;
    } else if (num3 === 0) {
      temp = 0;
    }
    setScore(temp);

    sessionStorage.setItem("num", temp.toString());

    var scoretemp =
      parseInt(sessionStorage.getItem("sub")||0) +
      temp +
      parseInt(sessionStorage.getItem("letters")||0);
	console.log(scoretemp)

    sessionStorage.setItem("attention", scoretemp.toString());
  }

  function onChangeFrontCheck() {
    if (document.getElementById("front").checked == true)
      sessionStorage.setItem("front", "1");
    else sessionStorage.setItem("front", "0");

    getScore();
  }

  function onChangeBackCheck() {
    if (document.getElementById("back").checked == true)
      sessionStorage.setItem("back", "1");
    else sessionStorage.setItem("back", "0");

    getScore();
  }
  
  useEffect(()=>{
	  if(sessionStorage.getItem("front") &&sessionStorage.getItem("front")=="1"){
		  document.getElementById("front").checked = true;
		  getScore();
	  }
	  	  if(sessionStorage.getItem("back") &&sessionStorage.getItem("back")=="1"){
		  document.getElementById("back").checked = true;
		  getScore();
	  }
  }, [])

  const navigate = useNavigate();

  var typography = "Attention \n\n1.  Forward Digit Span\nInstruct the patient: \n“I am going to say some numbers and when I am through, repeat them to me exactly as I said them”. \nRead the five number sequence at a rate of one digit per second. \n\n2.  Backward Digit Span\nInstruct the patient:\n“Now I am going to say some more numbers, but when I am through you must repeat them to me in the backwards order.”\nRead the three number sequence at a rate of one digit per second. \n\nScoring:\nAllocate one point for each sequence correctly repeated, \n(N.B.: the correct response for the backwards trial is 2-4-7).\n\nClick whether you would like to allocate the points or not.\n";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec7"} name={"Attention"} />
        <h1>MoCA Test</h1>
        
        <Card
        className="moca-container"
          sx={{ width: "72.75%", borderRadius: "20px", textAlign: "left",paddingLeft:"0" }}
        >
          <CardContent style={{paddingLeft: "0"}}>
          <h5 style={{ marginBottom: "1.7em" }}>Attention</h5>
            <p style={{ marginBottom: "1.5em", textAlign: "center" }}>
              Read the list of digits. Read at 1 digit per second.
            </p>
            
            <br />
            <input
              className="check"
              type="checkbox"
              id="front"
              onClick={onChangeFrontCheck}
            />
            <label>Patient has to repeat them in the forward order </label>
            <br />
            <br />
            <h4 style={{textAlign: "center", fontSize: "1.5em"}}> 2  &nbsp; 1  &nbsp; 8  &nbsp; 5  &nbsp; 4  &nbsp;</h4>
            <br />
            <input
              className="check"
              type="checkbox"
              id="back"
              onClick={onChangeBackCheck}
            />
            <label> Patient has to repeat them in the backward order</label>
            <br />
            <br />
            <h4 style={{textAlign: "center", fontSize: "1.5em"}}>7  &nbsp; 4  &nbsp; 2  &nbsp;</h4>
            <br />
            {/* <span style={{ textAlign: "left" }}>Score: </span> */}
            {/* <br /> */}
            {/* <label>Score: {score} points</label> */}
          </CardContent>
        </Card>
        <div className="gc-container" style={{ marginTop: "1em" }}>
          <span style={{textAlign: "left"}}>
              Score: {score} points
            </span>

          </div>
      </div>
      <BottomNav
nextNavigation={() => navToNextPage()}
        prevNavigation={() => {saveData(); navigate("/sec7")}}        currentPage={11}
        numPages={21}
      />
   
    </div>
  );
}
export default Section8;
