import React, { useState, useEffect } from "react";
import { FormControl, Grid, ListItem, TextField } from "@mui/material";
import Header from "../../header";
import "../../styles/HomeContent.css";
import "../../styles/header.css";
import { useNavigate } from "react-router-dom";
import { RadioGroup, Radio } from "@mui/material";
import { List } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import BottomNav from "../../bottomnavg";
import { saveSocialWorker } from "../../firebase";

export default function PatientPlans() {
  const [next, setNext] = useState(true);
  const [patient, setPatient] = useState(false);
  const [relative, setRelative] = useState(false);
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);

  const [aimpatient, setaimPatient] = useState("");
  const [aimrelative, setaimRelative] = useState("");
  const [aimerror, setaimError] = useState(false);
  const [aimerror1, setaimError1] = useState(false);

  const navigation = useNavigate();

  useEffect(() => {
    setPatient(sessionStorage.getItem("PatientPlans"));
    setRelative(sessionStorage.getItem("RelativePlans"));
    setaimPatient(sessionStorage.getItem("aimPatientPlans"));
    setaimRelative(sessionStorage.getItem("aimRelativePlans"));
  }, []);

  const handleChangeP = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("PatientPlans", value);
    setPatient(value);
  };

  const handleChangeR = (event) => {
    var value = event.target.value;
    sessionStorage.setItem("RelativePlans", value);
    setNext(false);
    setRelative(value);
  };

  function validation() {
    sessionStorage.setItem("aimPatient", aimpatient);
    sessionStorage.setItem("aimrelative", aimrelative);
    if (!aimpatient) setaimError(true);
    else setaimError(false);
    if (!aimrelative) setaimError1(true);
    else setaimError1(false);
    if (!patient) {
      setError(true);
    } else {
      setError(false);
    }

    if (!relative) {
      setError1(true);
    } else {
      setError1(false);
    }
    saveData();
    if (patient != null && relative != null) {
      navigation("/AdditionalinfoSocialWorker");
    }
  }

  var typography = "Click on appropriate patient and carer future plans.";

  const saveData = () => {
    saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
        LivesAlone: sessionStorage.getItem("Lives Alone?"),
        AnySupport: sessionStorage.getItem("Any Support?"),
      },
      {
        Stairs: sessionStorage.getItem("Stairs In Home:"),
        Amenities: sessionStorage.getItem("Amenities"),
        AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
        OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
        Amendments: sessionStorage.getItem("Amendments Required:"),
        OtherAmendments: sessionStorage.getItem("Required Amendments"),
      },
      {
        Patient: sessionStorage.getItem("PatientPlans"),
        Relative: sessionStorage.getItem("RelativePlans"),
      },
      sessionStorage.getItem("Array"),
      {
        Apps: sessionStorage.getItem("CommunityApps:"),
        TimeSpan: sessionStorage.getItem("TimeSpan"),
      },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
      sessionStorage.getItem("mainCarerSW"),
      sessionStorage.getItem("neighboursSW"),
      sessionStorage.getItem("supportAtHomeSW"),
      sessionStorage.getItem("socialServiceComments"),
      sessionStorage.getItem("religiousBelief"),
      sessionStorage.getItem("liftPresent"),
      sessionStorage.getItem("typeofhouse"),
      sessionStorage.getItem("aimrelative"),
      sessionStorage.getItem("aimPatient"),
      true,
      false
    );
  };

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/Social-Services"}></Header>
        <h1>Future Plans</h1>
        <div className="social-worker-container">
          <FormControl>
            <br />
            <h5>Patient's Plan</h5>
            <div
              className="search-container"
              style={{
                width: "80%",
                paddingLeft: "2em",
                paddingRight: "0",
              }}
            >
              <h5>Aims of Rehab</h5>
              <TextField
                onChange={(e) => setaimPatient(e.target.value)}
                label="Enter patient aim of rehab"
                value={aimpatient}
              ></TextField>
              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: aimerror ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please input text
              </h4>
              <RadioGroup onChange={handleChangeP} value={patient}>
                <FormControlLabel
                  value={"Return Home"}
                  control={<Radio />}
                  label="Return Home"
                />
                <FormControlLabel
                  value={"Re-Allocation of Own Home"}
                  control={<Radio />}
                  label="Re-Allocation of Own Home"
                />
                <FormControlLabel
                  value={"Residential Home"}
                  control={<Radio />}
                  label="Residential Home"
                />
                <FormControlLabel
                  value={"Undecided"}
                  control={<Radio />}
                  label="Undecided"
                />
              </RadioGroup>
              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: error ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please select an option
              </h4>
            </div>
            <br />
            <h5 style={{ marginTop: "1em" }}>Main Carer's Plan</h5>
            <div
              className="search-container"
              style={{
                width: "80%",
                paddingLeft: "2em",
                paddingRight: "0",
              }}
            >
              <h5>Aims of Rehab</h5>
              <TextField
                onChange={(e) => setaimRelative(e.target.value)}
                label="Enter Relative aim of rehab"
                value={aimrelative}
              ></TextField>
              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: aimerror1 ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please input text
              </h4>
              <h5>Plan after Rehab</h5>
              <RadioGroup onChange={handleChangeR} value={relative}>
                <FormControlLabel
                  value={"Return Home"}
                  control={<Radio />}
                  label="Return Home"
                />
                <FormControlLabel
                  value={"Re-Allocation of Own Home"}
                  control={<Radio />}
                  label="Re-Allocation of Own Home"
                />
                <FormControlLabel
                  value={"Residential Care Home"}
                  control={<Radio />}
                  label="Residential Care Home"
                />
                <FormControlLabel
                  value={"Undecided"}
                  control={<Radio />}
                  label="Undecided"
                />
              </RadioGroup>
              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: error1 ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please select an option
              </h4>
            </div>
          </FormControl>
        </div>
      </div>
      <BottomNav
        prevNavigation={() => {
          saveData();
          navigation("/Social-Services");
        }}
        nextNavigation={() => validation()}
        currentPage={4}
        numPages={6}
      />
    </div>
  );
}
