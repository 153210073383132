import React, { useState, useEffect } from "react";
import { Divider, Grid, TextField } from "@mui/material";
import "../../styles/HomeContent.css";
import { useNavigate } from "react-router-dom";
import Header from "../../header";
import { List, ListItem } from "@mui/material";
import { FormControl } from "@mui/material";
import { RadioGroup } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import { Radio } from "@mui/material";
import { Button } from "@mui/material";
import BottomNav from "../../bottomnavg";
import { height } from "@mui/system";
import { saveSocialWorker } from "../../firebase";

export default function HomeEnv() {
  const [next, setNext] = useState(true);
  const [stairs, setStairs] = useState("");
  const [amenities, setAmenities] = useState("");
  const [location, setLocation] = useState("");
  const [fragments, setFragments] = useState(<></>);
  const [button, setButton] = useState([]);
  const [disable, setDisable] = useState(true);
  const [disableText, setDisableText] = useState(true);
  const [radio, setRadio] = useState("");
  const [result, setResult] = useState("");
  const [q1, setQ1] = useState("");
  const [q2, setQ2] = useState("");
  const [q3, setQ3] = useState("");
  const navigation = useNavigate();
  const [otherText, setOtherText] = useState("");
  const [yesText, setYesText] = useState("");
  const [error, setError] = useState(false);
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);
  const [error3, setError3] = useState(false);
  const [error4, setError4] = useState(false);
  const [error5, setError5] = useState(false);
  
  const [typeofHouse, setTypeofHouse] = useState("")
  const [typeofHouseError, settypeofHouseError] = useState(false)
  
    const [liftPresent, setliftPresent] = useState("")
  const [liftPresentError, setliftPresentError] = useState(false)

  useEffect(() => {
    setQ1(sessionStorage.getItem("Stairs In Home:"));
    setQ2(sessionStorage.getItem("Amenities"));
    setQ3(sessionStorage.getItem("Amendments Required:"));
    setResult(sessionStorage.getItem("Location Of Amenities"));
    setOtherText(sessionStorage.getItem("Other Amenity Location"));
    setYesText(sessionStorage.getItem("Required Amendments"));
    console.log(otherText, yesText);
    console.log(sessionStorage.getItem("Other Amenity Location"));
	if(sessionStorage.getItem("typeofhouse")!="null")
	setTypeofHouse(sessionStorage.getItem("typeofhouse")||"")
if(sessionStorage.getItem("liftPresent")!="null")
	setliftPresent(sessionStorage.getItem("liftPresent")||"")
console.log(q1)
  }, []);

  const handleStairs = (event) => {
    var value = event.target.value;
    //console.log(value);
    sessionStorage.setItem("Stairs In Home:", value);
    setQ1(value);
    //setNext(true);
  };
  const handleAmenities = (event) => {
    var testing = [];
    var value = event.target.value;
    // console.log(value);
    //setResult(value);
    setQ2(value);
    setRadio(value);
    setResult("");
    sessionStorage.setItem("Amenities", value);
    sessionStorage.removeItem("Location Of Amenities")
    // if (value === "Yes") {
    //   // setNext(false);

    //   sessionStorage.setItem("Location Of Amenities", "");
    //   //Other Amenity Location
    //   sessionStorage.setItem("Other Amenity Location", "");
    // }
    // if (value === "No") {

    //   // testing.push(
    //   //   // <React.Fragment>
    //   //   //   {/* <Divider /> */}
    //   //   // </React.Fragment>
    //   // );
    // }
    setFragments(testing);
  };

  const handleText = (event) => {
    var value = event.target.value;
    console.log(value);
    setOtherText(value);
    sessionStorage.setItem("Other Amenity Location", value);
    console.log(sessionStorage.getItem("Other Amenity Location"));
  };

  const handleOther = (event) => {
    // sessionStorage.setItem("Other Amenity Location", "N/A");
    var value = event.target.value;
    setResult(value);
    // console.log(value);
    sessionStorage.setItem("Location Of Amenities", value);
  };

  const handleAmendments = (event) => {
    var value = event.target.value;
    console.log(value);
    sessionStorage.setItem("Amendments Required:", value);
    setQ3(value);
    setNext(false);
    setYesText(null)
    sessionStorage.removeItem("Required Amendments");

  };
  const handleAmendmentsText = (event) => {
    var value = event.target.value;
    console.log(value);
    setYesText(value);
    sessionStorage.setItem("Required Amendments", value);
  };
  
    const saveData = () =>{
	  saveSocialWorker(
      sessionStorage.getItem("patientId"),
      null,
      {
		LivesAlone: sessionStorage.getItem("Lives Alone?"),
		AnySupport: sessionStorage.getItem("Any Support?"),
	  },
      {
		Stairs: sessionStorage.getItem("Stairs In Home:"),
		Amenities: sessionStorage.getItem("Amenities"),
		AmenityLoc: sessionStorage.getItem("Location Of Amenities"),
		OtherAmenLoc: sessionStorage.getItem("Other Amenity Location"),
		Amendments: sessionStorage.getItem("Amendments Required:"),
		OtherAmendments: sessionStorage.getItem("Required Amendments"),
	  },
      {
		Patient: sessionStorage.getItem("PatientPlans"),
		Relative: sessionStorage.getItem("RelativePlans"),
	  },
      sessionStorage.getItem("Array"),
      {
		Apps: sessionStorage.getItem("CommunityApps:"),
		TimeSpan: sessionStorage.getItem("TimeSpan"),
	  },
      sessionStorage.getItem("Other Profession:"),
      {
        Required: sessionStorage.getItem("Amendments Required:"),
        AmendmentsRequired: sessionStorage.getItem("Required Amendments"),
      },
      sessionStorage.getItem("Add Info"),
      sessionStorage.getItem("maritualStatusHS"),
	  sessionStorage.getItem("mainCarerSW"),
	  sessionStorage.getItem("neighboursSW"),
	  sessionStorage.getItem("supportAtHomeSW"),
	  sessionStorage.getItem("socialServiceComments"),
	  sessionStorage.getItem("religiousBelief"),
	  sessionStorage.getItem("liftPresent"),
	  sessionStorage.getItem("typeofhouse"),
	  sessionStorage.getItem("aimrelative"),
	  sessionStorage.getItem("aimPatient"),
	  true,
	  false
    );
  }

  function Validation() {
	  
	  sessionStorage.setItem("typeofhouse", typeofHouse)
	  sessionStorage.setItem("liftPresent", liftPresent)
	  	let blocked = false
sessionStorage.setItem("Stairs In Home:", q1);
    if (!q1 || (q1.includes("Yes") && q1.replace("Yes", "").length<1)) {
      setError(true);
	  blocked = true
    } else {
      setError(false);
    }

    if (!q2) {
      setError1(true);
    } else {
      setError1(false);
    }

    if (!q3) {
      setError2(true);
    } else {
      setError2(false);
    }console.log(!typeofHouse || typeofHouse=="" || (typeofHouse.includes("Other:")&& typeofHouse.replace("Other:", "").trim().length<1))
	if(!typeofHouse || typeofHouse=="" || (typeofHouse.includes("Other:")&& typeofHouse.replace("Other:", "").trim().length<1)){
		blocked=true
		settypeofHouseError(true)
	}else settypeofHouseError(false)
	console.log(!liftPresent || liftPresent =="", liftPresent)
	if(!liftPresent || liftPresent ==""){
		setliftPresentError(true)
		blocked=true
	}else setliftPresentError(false)

    console.log(result);
    if (q2 == "No" && !result) {
      setError3(true);
      //error message result
    } else {
      setError3(false);
      if (result == "Other" && !otherText) {
        setError4(true);
      } else {
        setError4(false);
      }
      console.log(q3, yesText, q3 == "Yes" && !yesText)
      if (q3 == "Yes" && !yesText) setError5(true);
      else {
        setError5(false);
      }
      //navigation("/Social-Services");
    }
    // if(q3 == "Yes" && !yesText)
    // {
    //   setError5(true)
    // }
    // else{
    //   setError5(false)
    // }
    // if (result == "Other" && !otherText ) {
    //   setError4(true);
    // } else {
    //   setError4(false);
    // }
saveData();
if(blocked) return;
    if (q2 == "No") {
      if (result != null) {
        if (result == "Other" && otherText != "") {
          if (q3 == "Yes" && (yesText != ""&& yesText)) {
            if (q1 != null && q2 != null && q3 != null) {
              console.log("1");
              navigation("/Social-Services");
            } 
          }
          else if (q3 == "No") {
            if (q1 != null && q2 != null && q3 != null){
              console.log("2" + yesText);
              navigation("/Social-Services");
            }
          }
        }else if (error4) return
        else if (q3 != null) {
          if (q3 != "Yes") {
            if (q1 != null && q2 != null && q3 != null){
              console.log("2" + yesText);
            navigation("/Social-Services");
          }
        }
      } else if (q3 != null) {
        if (q3 == "No") {
          if (q1 != null && q2 != null && q3 != null){
            console.log("3" + yesText);
            navigation("/Social-Services");
          }
        } else if (q3 == "Yes" && (yesText != ""&& yesText)) {
      if (q1 != null && q2 != null && q3 != null)
            console.log("3" + yesText);
            navigation("/Social-Services");
          }
        }
      }
    } else if (q2 != null && q1 != null) {
      if (q3 == "Yes" && yesText != null) {
        if (q1 != null) {
          console.log("4" + yesText);
          navigation("/Social-Services");
        }
      } else if (q3 != null) {
        if (q3 != "Yes") {
          if (q1 != null && q2 != null && q3 != null){
            console.log("5" + yesText);
            navigation("/Social-Services");
          }
        }
      }
    }

    // if (!q1 || !q2 || !q3) {
    //   //setError(true);
    // } else {
    //   //setOpen(true);
    //   setError(false);
    //   navigation("/Social-Services");
    // }
  }

  var typography = "Click on the appropriate home environment state.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header
          typography={typography}
          name={"Home Environment"}
          history={"/SH-Home-Support"}
        ></Header>
        <h1>Home Environment</h1>
        <div className="social-worker-container">
          {/* <List> */}
          {/* <ListItem> */}
          <FormControl>
		  <RadioGroup>
		  <h5>Type of House</h5>
		  <FormControlLabel
                    className="FormControlLabel"
                    value={"Apartment"}
                    control={<Radio />}
                    label="Apartment"
					checked={typeofHouse == "Apartment" ? true:false}
                    // disabled={disable}
                    onChange={(e) => {
                      setTypeofHouse(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"Terraced House"}
                    control={<Radio />}
                    label="Terraced House"
                    checked={typeofHouse == "Terraced House" ? true:false}
                    // disabled={disable}
                    onChange={(e) => {
                      setTypeofHouse(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"Other:"}
                    control={<Radio />}
                    label="Other:"
                   checked={typeofHouse.includes("Other") ? true:false}
                    // disabled={disable}
                    onChange={(e) => {
                      setTypeofHouse(e.target.value);
                    }}
                  />
                  <TextField
                    onChange={(e)=> setTypeofHouse("Other: " + e.target.value)}
                    label="Enter type of house"
                    disabled={ typeofHouse.includes("Other:") ? false : true}
                    value={typeofHouse.includes("Other: ")  ? typeofHouse.replace("Other: ", ""):""}
                  ></TextField>
                  <h4
                      className="alert"
                      id="question1-alert"
                      style={{
                        display: typeofHouseError ? null : "none",
                        marginTop: "0.5em",
                        fontSize: "0.8em",
                      }}
                  >
                    Please input text
                  </h4>
                </RadioGroup>
                <h4
                  className="alert"
                  id="question1-alert"
                  style={{
                    display: typeofHouseError ? null : "none",
                    marginTop: "0.5em",
                    fontSize: "0.8em",
                  }}
                >
                  Please select an option
                </h4>
				<br/>
            <RadioGroup
              // className="search-container"
              onChange={handleStairs}
              value={q1}
            >
              <h5>Stairs to Access House</h5>
              <br></br>
              <FormControlLabel
                className="FormControlLabel"
                value={"Yes"}
				checked={q1 && q1.includes("Yes")?true:false}
                control={<Radio />}
                label="Yes"
              />
			  {console.log(q1)}
			   <RadioGroup onChange={(e)=> {setQ1("Yes"+e.target.value)}}  style={{marginLeft: 20}}>
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"(1-5)"}
                    control={<Radio />}
                    label="1-5 stairs"
					checked={q1 && q1.replace("Yes", "").trim()=="(1-5)"?true:false}
                    disabled={q1 && q1.includes("Yes") ? false : true}
                    // disabled={disable}
                   
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"(6-10)"}
                    control={<Radio />}
                    label="6-10 stairs"
					checked={q1 && q1.replace("Yes", "").trim()=="(6-10)"?true:false}

                    disabled={q1 && q1.includes("Yes") ? false : true}
                  
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"(<11)"}
                    control={<Radio />}
                    label="<11 stairs"
checked={q1 && q1.replace("Yes", "").trim()=="(<11)"?true:false}

                    disabled={q1 && q1.includes("Yes") ? false : true}
                   
                  />
                
                  <h4
                      className="alert"
                      id="question1-alert"
                      style={{
                        display: error ? null : "none",
                        marginTop: "0.5em",
                        fontSize: "0.8em",
                      }}
                  >
                    Please select option
                  </h4>
                </RadioGroup>
              <FormControlLabel
                className="FormControlLabel"
                value={"No"}
			checked={q1 && q1.includes("No")?true:false}

                control={<Radio />}
                label="No"
              />
              {/* <br></br> */}
              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: error ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please select an option
              </h4>
              <br />
			    <RadioGroup>
		  <h5>Lift Present</h5>
		  <FormControlLabel
                    className="FormControlLabel"
                    value={"No"}
                    control={<Radio />}
                    label="No"
					checked={liftPresent == "No" ? true:false}
                    // disabled={disable}
                    onChange={(e) => {
                      setliftPresent(e.target.value);
                    }}
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"Yes"}
                    control={<Radio />}
                    label="Yes"
                    checked={liftPresent == "Yes" ? true:false}
                    // disabled={disable}
                    onChange={(e) => {
                      setliftPresent(e.target.value);
                    }}
                  />
               
                </RadioGroup>
                <h4
                  className="alert"
                  id="question1-alert"
                  style={{
                    display: liftPresentError ? null : "none",
                    marginTop: "0.5em",
                    fontSize: "0.8em",
                  }}
                >
                  Please select an option
                </h4>
              <br />
              <h5>Amenities on the Same Floor </h5>
              <br></br>
              <RadioGroup onChange={handleAmenities} value={q2}>
                <FormControlLabel
                  className="FormControlLabel"
                  value={"Yes"}
                  control={<Radio />}
                  label="Yes"
                  // onClick={() => {
                  //   setDisable(true);
                  // }}
                />
                <FormControlLabel
                  className="FormControlLabel"
                  value={"No"}
                  control={<Radio />}
                  label="No"
                  // onClick={() => {
                  //   setDisable(false);
                  // }}
                />
              </RadioGroup>
              <div className="search-container" style={{ width: "80%" }}>
                <RadioGroup onChange={handleOther} value={result}>
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"Stairs Between Rooms"}
                    control={<Radio />}
                    label="Stairs Between Rooms"
                    disabled={q2 == "No" ? false : true}
                    // disabled={disable}
                    onClick={() => {
                      setDisable(true);
                      setOtherText("");
                    }}
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"Amenities on Different Floors"}
                    control={<Radio />}
                    label="Amenities on Different Floors"
                    disabled={q2 == "No" ? false : true}
                    onClick={() => {
                      setDisable(true);
                      setOtherText("");
                    }}
                  />
                  <FormControlLabel
                    className="FormControlLabel"
                    value={"Other"}
                    control={<Radio />}
                    label="Other :"
                    disabled={q2 == "No" ? false : true}
                    onClick={() => {
                      setDisable(false);
                    }}
                  />
                  <TextField
                    onChange={handleText}
                    label="Enter Other Amenity Location"
                    disabled={result == "Other" ? false : true}
                    value={otherText}
                  ></TextField>
                  <h4
                      className="alert"
                      id="question1-alert"
                      style={{
                        display: error4 ? null : "none",
                        marginTop: "0.5em",
                        fontSize: "0.8em",
                      }}
                  >
                    Please input text
                  </h4>
                </RadioGroup>
                <h4
                  className="alert"
                  id="question1-alert"
                  style={{
                    display: error3 ? null : "none",
                    marginTop: "0.5em",
                    fontSize: "0.8em",
                  }}
                >
                  Please select an option
                </h4>
              </div>
              {/* <br /> */}

              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: error1 ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please select an option
              </h4>
              <br />
              <h5>Home Amendments Required</h5>
              <br></br>
              <RadioGroup value={q3} onChange={handleAmendments}>
                <FormControlLabel
                  className="FormControlLabel"
                  value={"Yes"}
                  control={<Radio />}
                  label="Yes"
                  onClick={() => {
                    setDisableText(false);
                  }}
                />
                <TextField
                  style={{ width: "90%" }}
                  onChange={handleAmendmentsText}
                  label="State Amendments Required"
                  disabled={q3 != "Yes"}
                  value={yesText}
                ></TextField>
                <h4
                    className="alert"
                    id="question1-alert"
                    style={{
                      display: error5 ? null : "none",
                      marginTop: "0.5em",
                      fontSize: "0.8em",
                    }}
                >
                  Please input text
                </h4>
                <FormControlLabel
                  className="FormControlLabel"
                  value={"No"}
                  control={<Radio />}
                  label="No"
                  onClick={() => {
                    setDisableText(true);
                    setYesText("");
                  }}
                />
              </RadioGroup>
              {/* <br /> */}
              <h4
                className="alert"
                id="question1-alert"
                style={{
                  display: error2 ? null : "none",
                  marginTop: "0.5em",
                  fontSize: "0.8em",
                }}
              >
                Please select an option
              </h4>


              {/* {fragments} */}
              {button}
            </RadioGroup>
          </FormControl>
          {/* </ListItem> */}
          {/* <ListItem> */}
        </div>
      </div>
      <BottomNav
        nextNavigation={() => Validation()}
        prevNavigation={() => navigation("/SH-Home-Support")}
        currentPage={2}
        numPages={6}
        // buttonDisabled={next}
      />
      {/* </ListItem> */}
      {/* </List> */}
      {/* </Grid> */}
    </div>
  );
}
///SH-Patient-Plans
//Social-Services
