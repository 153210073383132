import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveDataSectionA as saveData } from "../firebase";

function Section6() {
  const [score, setScore] = useState(0);

  function getScore() {
    var score =
      parseInt(sessionStorage.getItem("lion")) +
      parseInt(sessionStorage.getItem("rhino")) +
      parseInt(sessionStorage.getItem("camel"));
    setScore(score);
    sessionStorage.setItem("naming", score.toString());
  }

  useEffect(() => {
    if (
      sessionStorage.getItem("lion") &&
      sessionStorage.getItem("lion") == "1"
    ) {
      document.getElementById("lion").checked = true;
      getScore();
    }
    if (
      sessionStorage.getItem("rhino") &&
      sessionStorage.getItem("rhino") == "1"
    ) {
      document.getElementById("rhino").checked = true;
      getScore();
    }
    if (
      sessionStorage.getItem("camel") &&
      sessionStorage.getItem("camel") == "1"
    ) {
      document.getElementById("camel").checked = true;
      getScore();
    }
  }, []);

  function onChangeCamelCheck() {
    if (document.getElementById("camel").checked == true)
      sessionStorage.setItem("camel", "1");
    else sessionStorage.setItem("camel", "0");

    getScore();
  }

  function onChangeLionCheck() {
    if (document.getElementById("lion").checked == true)
      sessionStorage.setItem("lion", "1");
    else sessionStorage.setItem("lion", "0");

    getScore();
  }

  function onChangeRhinoCheck() {
    if (document.getElementById("rhino").checked == true)
      sessionStorage.setItem("rhino", "1");
    else sessionStorage.setItem("rhino", "0");

    getScore();
  }

  function navToNextPage() {
    saveData();
    navigate("/v2/sec7");
  }

  const navigate = useNavigate();

  var typography =
    "Naming \n\nBeginning on the left, point to each figure and say: “Tell me the name of this animal”.\n \nScoring:\nOne point each is given for the following responses: \n(1)	lion \n(2)	rhinoceros or rhino\n(3)	camel or dromedary\n\nClick whether you would like to allocate the point or not.";

  return (
    <div className="screen" style={{ justifyContent: "space-between" }}>
      <div className="screen" style={{ width: "100%", minHeight: "auto" }}>
        <Header typography={typography} history={"/sec5"} name={"Naming"} />
        <h1>MoCA Test</h1>

        <div
          className="gc-container"
          style={{ paddingTop: "1.2em", paddingBottom: 0 }}
        >
          <h5 style={{ marginBottom: "0em" }}>Naming</h5>

          <div
            className="moca-picture-container"
            style={{ alignItems: "center", MaxWidth: "100%" }}
          >
            <div>
              <input
                className="check"
                type="checkbox"
                id="lion"
                onChange={onChangeLionCheck}
              ></input>
              <img src="/lion.png" style={{ width: "90%" }}></img>
            </div>
            <div>
              <input
                className="check"
                type="checkbox"
                id="rhino"
                onChange={onChangeRhinoCheck}
              ></input>
              <img src="/rhino.png" style={{ width: "90%" }}></img>
            </div>
            <div>
              <input
                className="check"
                type="checkbox"
                id="camel"
                onChange={onChangeCamelCheck}
              ></input>
              <img src="/camel.png" style={{ width: "90%" }}></img>
              <label></label>
            </div>
          </div>
        </div>
        <div
          className="gc-container"
          style={{ marginTop: "1em", paddingTop: "1em", paddingBottom: "1em" }}
        >
          <span style={{ textAlign: "left" }}>Score: {score} points</span>
        </div>
      </div>
      <BottomNav
        nextNavigation={() => navToNextPage()}
        prevNavigation={() => {
          saveData();
          navigate("/v2/sec5");
        }}
        currentPage={8}
        numPages={19}
      />
    </div>
  );
}
export default Section6;
