import React, { useState } from "react";
import Fab from "@mui/material/Fab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Fade from "@mui/material/Fade";
const ScrollBtn = () => {
  const [visible, setVisible] = useState(true);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 0) {
      setVisible(false);
    } else if (scrolled <= 0) {
      setVisible(true);
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <Fade in={visible} style={{ transitionDelay: "100ms" }}>
      <Fab
        size="small"
        color="lightgrey"
        onClick={scrollToBottom}
        style={{
          display: visible ? "inline-flex" : "none",
          margin: 0,
          top: "auto",
          right: "1.8em",
          bottom: "1.8em",
          left: "auto",
          position: "fixed",
            marginBottom: 50
        }}
      >
        <KeyboardArrowDownIcon />
      </Fab>
    </Fade>
  );
};

export default ScrollBtn;
