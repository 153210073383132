import "../styles/CommonStyle.css";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Header from "../header";
import BottomNav from "../bottomnavg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
	AppBar, Button, IconButton ,
  Popover,
  Grid,Typography,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,FormControl, TextField
} from "@mui/material";
import { saveTeam2 } from "../firebase";

function CurrentMobility() {
  var typography =
    "Click on the type of mobility level, for both previous (past 6 months) and current level.";
	const [ulRight, setUlRight] = useState("");
	const [ulLeft, setUlLeft] = useState("");
	const [llRight, setLlRight] = useState("");
	const [llLeft, setLlLeft] = useState("");
	const [bedmobility, setBedmobility] = useState("");
	const [transfers, setTransfers]=useState(["","","",""]);
	const [freeText, setFreeText] = useState("");
  console.log(sessionStorage.getItem("Patientgender") + "GENDER")

  
  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }
  
  const navigate = useNavigate();

  useEffect(()=>{
      if(sessionStorage.getItem("ulRight"))
    setUlRight(sessionStorage.getItem("ulRight"))
      if(sessionStorage.getItem("ulLeft"))
      setUlLeft(sessionStorage.getItem("ulLeft"))
     if(sessionStorage.getItem("llRight"))
    setLlRight(sessionStorage.getItem("llRight"))
      if(sessionStorage.getItem("llLeft"))
      setLlLeft(sessionStorage.getItem("llLeft"))
      if(sessionStorage.getItem("bedmobility"))
      setBedmobility(sessionStorage.getItem("bedmobility"))
      if(sessionStorage.getItem("transfers"))
      setTransfers(sessionStorage.getItem("transfers").split(","))
      if(sessionStorage.getItem("freeText"))
      setFreeText(sessionStorage.getItem("freeText"))
   
  }, [])
    useEffect(()=>{
        console.log(ulRight)
        sessionStorage.setItem("ulRight", ulRight)
    }, [ulRight])


    useEffect(()=>{
        sessionStorage.setItem("ulLeft", ulLeft)
    }, [ulLeft])

    useEffect(()=>{
        console.log(llRight)
        sessionStorage.setItem("llRight", llRight)
    }, [llRight])


    useEffect(()=>{
        sessionStorage.setItem("llLeft", llLeft)
    }, [llLeft])

    useEffect(()=>{
        sessionStorage.setItem("bedmobility", bedmobility)
    }, [bedmobility])


    useEffect(()=>{
        sessionStorage.setItem("transfers", transfers)
    }, [transfers])


    useEffect(()=>{
        sessionStorage.setItem("freeText", freeText)
    }, [freeText])


  // console.log("Previous Level of Mobility ", sessionStorage.getItem("TUGQuestion1"));
  // console.log("Current Level of Mobility ", sessionStorage.getItem("TUGQuestion2"));
  // console.log("TUG Timer ", sessionStorage.getItem("TUGTimer"));
  // console.log("TUG Status ", sessionStorage.getItem("TUGStatus"));
  // console.log("TUG Carried out ", sessionStorage.getItem("TUGTestCarriedOut"));
  // console.log("GST Question 1 ", sessionStorage.getItem("question1"));
  // console.log("GST Question 2 ", sessionStorage.getItem("question2"));
  // console.log("GST Question 3 ", sessionStorage.getItem("question3"));
  // console.log("GST Question 4 ", sessionStorage.getItem("question4"));
  // console.log("GST Question 5 ", sessionStorage.getItem("question5"));
  // console.log("Instructions ", sessionStorage.getItem("Instructions"));
  // console.log("Timer ", sessionStorage.getItem("Timer"));
  // console.log("\n");
  //
  // useEffect(() => {
  //   setAnswerQuestion1(sessionStorage.getItem("TUGQuestion1"));
  //   setAnswerQuestion2(sessionStorage.getItem("TUGQuestion2"));
  // }, []);

  //Check if there is an option selected and if textbox is filled
  function validateForm() {
    if (ulRight == "" || ulLeft=="") {
      document.getElementById("ul-alert").innerHTML =
        "Please select an option!";
    } else {
      document.getElementById("ul-alert").innerHTML = "";
    }
	
	    if (llRight == "" || llLeft=="") {
      document.getElementById("ll-alert").innerHTML =
        "Please select an option!";
    } else {
      document.getElementById("ll-alert").innerHTML = "";
    }
    if (bedmobility == "") {
      document.getElementById("bedmobility-alert").innerHTML =
        "Please select an option!";
  
    } else {
      document.getElementById("bedmobility-alert").innerHTML = "";

    }
	
	if (transfers[0] ==""||transfers[1] ==""||transfers[2] ==""||transfers[3] =="") {
      // Displays error message
      document.getElementById("transfers-alert").innerHTML =
        "Please select an option!";
     } else {
      document.getElementById("transfers-alert").innerHTML = "";
    }
   saveData()

    // Check if value in question1 and question2 isn't empty
    if (
	ulRight != "" && ulLeft!="" && llRight != "" && llLeft!="" && 
    
bedmobility != "" && !(transfers[0] ==""||transfers[1] ==""||transfers[2] ==""||transfers[3] =="")
    ) {

      navigate("/ListOfEquipment");
    }
  }

  return (
    <div className="screen" style={{justifyContent:"space-between"}}>
      <div className="screen" style={{width:"100%", minHeight:"auto"}}>
      <Header
        typography={typography}
        history={"/LevelsOfMobility"}
        item
        name={"Current Mobility"}
      />

      <h1>Mobility Level</h1>
      
      <div className="physio-container" style={{padding: "2px", paddingBottom: "1em",paddingLeft: "1em",paddingRight: "1em", width: "85%"}}>
        {/* Previous level: all radio buttons */}
        <form className="question1Form" id="question1Form">
          {/* <h4 className="alert" id="question1-alert"></h4> */}
          

          {/* Container for "Independent" labels and Independent radio buttons */}
          <div style={{ display: "flex", flexDirection: "column" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
              >UL:</label>
			   <div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20}}
              >Right:</label>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                    value={ulRight}
                    onChange={(e) => setUlRight(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"F"}>
                      Functional
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"NF"}>
                      Non-Functional
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
 <div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20}}
              >Left:</label>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                    value={ulLeft}
                    onChange={(e) => setUlLeft(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"F"}>
                      Functional
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"NF"}>
                      Non-Functional
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
                  <h4 className="alert" id="ul-alert"></h4>
				  </div>
<div style={{ display: "flex", flexDirection: "column" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
              >LL:</label>
			   <div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20}}
              >Right:</label>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                    value={llRight}
                    onChange={(e) => setLlRight(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"F"}>
                      Functional
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"NF"}>
                      Non-Functional
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
  <div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20}}
              >Left:</label>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                    value={llLeft}
                    onChange={(e) => setLlLeft(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"F"}>
                      Functional
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"NF"}>
                      Non-Functional
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
                  <h4 className="alert" id="ll-alert"></h4>
			   <div style={{ display: "flex", flexDirection: "column" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
				   <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
              >Bed Mobility:</label>
                <FormControl fullWidth>
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                    value={bedmobility}
                    onChange={(e) => setBedmobility(e.target.value)}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
				                  <h4 className="alert" id="bedmobility-alert"></h4>

</div>

<div style={{ display: "flex", flexDirection: "column" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
              >Transfers:</label>
			   <div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20, width:"50%"}}
              >Lying - Sitting:</label>
                <FormControl >
                   <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                    value={transfers[0]}
                    onChange={(e) =>{
const temp = [...transfers];
temp[0] = e.target.value;
					setTransfers(temp)}}
                  >
                   <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
  
  <div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20, width:"50%"}}
              >Sitting - Standing:</label>
                <FormControl >
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                   value={transfers[1]}
                    onChange={(e) =>{
const temp = [...transfers];
temp[1] = e.target.value;
					setTransfers(temp)}}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
<div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20, width:"50%"}}
              >Standing - Sitting:</label>
                <FormControl >
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                   value={transfers[2]}
                    onChange={(e) =>{
const temp = [...transfers];
temp[2] = e.target.value;
					setTransfers(temp)}}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
<div style={{ display: "flex", flexDirection: "row" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>
			  <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
				style={{paddingLeft: 20, paddingRight: 20, width:"50%"}}
              >Sitting - Lying:</label>
                <FormControl >
                  <Select
                    style={{ fontSize: "14px"}}
                    size="small"
                   value={transfers[3]}
                    onChange={(e) =>{
const temp = [...transfers];
temp[3] = e.target.value;
					setTransfers(temp)}}
                  >
                    <MenuItem style={{ fontSize: "14px" }} value={"I"}>
                      Independent
                    </MenuItem>
                   
                    <MenuItem style={{ fontSize: "14px" }} value={"D"}>
                      Dependent
                    </MenuItem>
                  </Select>
                </FormControl>
</div>
                  <h4 className="alert" id="transfers-alert"></h4>
</div>				  
  <div style={{ display: "flex", flexDirection: "column" , paddingRight:"2.5em",paddingTop: "1em", width:"100%"}}>

		    <label
                className="radio-button-label"
                htmlFor="gst-question3-radio1"
              >Other comments:</label>
		   <TextField
		   fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  // helperText="Max. 20 characters"
                  size="small"
                  inputProps={{ maxLength: 500 }}
                  defaultValue={freeText}
                  value={freeText}
                  ///////////////////////////////////////////////////////////////////////////////////////////////////
                  onChange={(e) => setFreeText(e.target.value)}
                ></TextField>
				</div>
</div>
</form>
      </div>
      </div>
      <BottomNav
        nextNavigation={() => validateForm()}
        currentPage={2}
        numPages={9}
		        prevNavigation={() => {saveData(); navigate("/LevelsOfMobility")}}

      ></BottomNav>
    </div>
  );
}
export default CurrentMobility;
