import React, { useEffect, useState } from "react";
import Header from "../../header";
import { Grid, TextField } from "@mui/material";
import {
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getPatients } from "../../firebase";

export default function PatientIDOP() {
  const [currentPatient, setCurrentPatient] = useState({
    id: 0,
    name: "",
    gender: "",
    surname: "",
  });
  const [patients, setPatients] = useState([]);
  const [fragments, setFragments] = useState(<></>);

  const navigation = useNavigate();

  var typography = "Input a Patient ID";

  useEffect(() => {
    let p = getPatients();
    setPatients(p);
    // const [error, setError] = useState("");
    sessionStorage.setItem("PatientData", currentPatient.id);
    //const json = JSON.stringify(patients);
    //const obj = JSON.parse(json);

    if (currentPatient.id !== 0) {
      navigation("/Section1OP");
    }
  }, []);

  const SetCurrentPatient = (patient) => {
    setCurrentPatient(patient);
    sessionStorage.setItem("PatientData", patient.id);
  };

  const DisplayPatients = (event) => {
    var value = event.target.value.toUpperCase();
    var testing = [];

    if (value !== "") {
      //console.log(getPatients());
      patients.map((patient, index) => {
        var tempID = patient.id.toUpperCase().substr(0, value.length);
        let regex = new RegExp(tempID, "g");

        if (regex.test(value)) {
          testing.push(
            <React.Fragment key={index}>
              <ListItemButton onClick={() => SetCurrentPatient(patient)}>
                <ListItemText primary={patient.name} secondary={patient.id} />
              </ListItemButton>
              <Divider />
            </React.Fragment>
          );
        }
      });
      setFragments(testing);
    } else {
      setFragments(<></>);
    }
  };

  return (
    <div className="screen">
      <Grid
        justifyContent={"center"}
        rowGap={4}
        container
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <Header
          typography={typography}
          history={"/"}
          item
          name={"Patient Page"}
        />

        <Grid justifyContent={"center"} container item>
          <div className="search-container">
            <TextField
              label={"Patient ID"}
              onChange={DisplayPatients}
            ></TextField>
            <List>
              <Divider />
              {fragments}
            </List>
          </div>
        </Grid>
        <Grid container item></Grid>
      </Grid>
    </div>
  );
}
