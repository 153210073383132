import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Header from "../header";
// import { List, ListItem, Typography } from "@material-ui/core";
import { List, ListItem, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Styles from "../styles/style.css";
import { getPatientSectionDetails } from "../firebase";
import NoDataDialog from "../NoDataDialog";
import resetData from "../resetData";
import { SettingsCell } from "@material-ui/icons";

export default function PatientMenu() {
  const navigation = useNavigate();

  var selectedrole = sessionStorage.getItem("roles");

  console.log(sessionStorage.getItem("Patientgender") + "GENDER")

  const [dialogOpen, setDialogOpen] = useState(false);
  //Navigates to page depending on role
  const handleRoleClick = (role) => {
    if (role == "nurse") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionE"
      ).then((data) => {
        console.log(data);
        if (!data) navigation("/BarthelIndexPage");
       else if (data && data.draft) {setNurse(data);
	   navigation("/BarthelIndexPage");
       } else {
          setNurse(data);
          navigation("/SummaryPageNurse", { state: { summary: true } });
        }
      });
    }

    if (role == "occupational") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionC"
      ).then((data) => {
        console.log(data, "OT Data");
        if (!data) navigation("/Section2OT");
        else if (data && data.draft){ setOccupational(data); navigation("/Section2OT");}
        else {
          setOccupational(data);
          navigation("/Section6OT", { state: { summary: true } });
        }
      });
    }

    if (role == "other") {
      navigation("/PatientView-Other", { state: { summary: true } });
    }

    if (role == "physio") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionB"
      ).then((data) => {
        if (!data) navigation("/LevelsOfMobility");
        else if (data && data.draft){setPhysio(data); navigation("/LevelsOfMobility");}
        else {
          setPhysio(data);
          navigation("/SummaryTeam2", { state: { summary: true } });
        }
      });
    }

    if (role == "social worker") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionD"
      ).then((data) => {
        console.log(data);
        if (!data) navigation("/SH-Home-Support");
        else if (data && data.draft){setSW(data); navigation("/SH-Home-Support");}
        else {
          setSW(data);

          navigation("/Summary-Social-Worker", { state: { summary: true } });
        }
      });
    }
    if (role == "geriatric consultant") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionA"
      ).then((data) => {
		   sessionStorage.setItem("pattern", "0");
  sessionStorage.setItem("cube", "0");
  sessionStorage.setItem("clock1", "0");
  sessionStorage.setItem("clock2", "0");
  sessionStorage.setItem("clock3", "0");
  sessionStorage.setItem("camel", "0");
  sessionStorage.setItem("lion", "0");
  sessionStorage.setItem("rhino", "0");
  sessionStorage.setItem("front", "0");
  sessionStorage.setItem("back", "0");
  sessionStorage.setItem("letters", "0");
  sessionStorage.setItem("one", "0");
  sessionStorage.setItem("two", "0");
  sessionStorage.setItem("three", "0");
  sessionStorage.setItem("four", "0");
  sessionStorage.setItem("five", "0");
  sessionStorage.setItem("rep1", "0");
  sessionStorage.setItem("rep2", "0");
  sessionStorage.setItem("sec12", "0");
  sessionStorage.setItem("count", "0");
  sessionStorage.setItem("trans", "0");
  sessionStorage.setItem("meas", "0");
  sessionStorage.setItem("date", "0");
  sessionStorage.setItem("day", "0");
  sessionStorage.setItem("month", "0");
  sessionStorage.setItem("year", "0");
  sessionStorage.setItem("place", "0");
  sessionStorage.setItem("city", "0");
  sessionStorage.setItem("eduCheck", "0");
  sessionStorage.setItem("blindCheck", "0");
  sessionStorage.setItem("writeCheck", "0");
  sessionStorage.setItem("face", "0");
  sessionStorage.setItem("velvet", "0");
  sessionStorage.setItem("red", "0");
  sessionStorage.setItem("daisy", "0");
  sessionStorage.setItem("church", "0");
  sessionStorage.setItem("cubepic", "");
  sessionStorage.setItem("clockpic", "");
  sessionStorage.setItem("patternpic", "");
  sessionStorage.setItem("subtraction", "0");
  sessionStorage.setItem("orientation", "0");
  sessionStorage.setItem("delayedrecall", "0");
  sessionStorage.setItem("abstraction", "0");
  sessionStorage.setItem("language", "0");
  sessionStorage.setItem("attention", "0");
  sessionStorage.setItem("naming", "0");
  sessionStorage.setItem("visuo", "0");
        if (!data) navigation("/diagnosis");
        else if (data && data.draft){setGC(data); navigation("/diagnosis");}
        else {
          setGC(data);
          navigation("/review", { state: { summary: true } });
        }
      });
    }
  };

  const setPhysio = (data) => {
    console.log(data);
    sessionStorage.setItem("TUGQuestion1", data.TUGTestResults.LevelsOfMobility.PreviousLevelofMobility || data.TUGTestResults.LevelsOfMobility.IPreviousLevelofMobility);
    sessionStorage.setItem("TUGQuestion2", data.TUGTestResults.LevelsOfMobility.CurrentLevelofMobility ||data.TUGTestResults.LevelsOfMobility.ICurrentLevelofMobility);
	  sessionStorage.setItem("mobilityQuestion11", data.TUGTestResults.LevelsOfMobility.DPreviousLevelofMobility);
    sessionStorage.setItem("mobilityQuestion22", data.TUGTestResults.LevelsOfMobility.DCurrentLevelofMobility);
    sessionStorage.setItem("TUGTestCarriedOut", data.TUGTestResults.TUGTestCarriedOut);
    sessionStorage.setItem("TUGTimer", data.TUGTestResults.RiskOfFallStatus.TimeTakenInSeconds);
    sessionStorage.setItem("TUGStatus", data.TUGTestResults.RiskOfFallStatus.Status);
    sessionStorage.setItem("question1", data.GripStrengthResults.Question1);
    sessionStorage.setItem("question4", data.GripStrengthResults.Question4);
    //LeftResultCHECK
  sessionStorage.setItem("MaxLeftHandResult", JSON.stringify(data.GripStrengthResults.MaxLeftHandResult));
	const gender = sessionStorage.getItem("Patientgender")
	let left = data.GripStrengthResults.MaxLeftHandResult.Risk
  if(typeof data.GripStrengthResults.MaxLeftHandResult.Risk == 'object'){
		if(gender=="Female" || gender=="female")
			left = data.GripStrengthResults.MaxLeftHandResult.Risk.FResult
		else
			left = data.GripStrengthResults.MaxLeftHandResult.Risk.MResult
  }
  
  	let right = data.GripStrengthResults.MaxRightHandResult.Risk
  if(typeof data.GripStrengthResults.MaxLeftHandResult.Risk == 'object'){
		if(gender=="Female" || gender=="female")
			right = data.GripStrengthResults.MaxRightHandResult.Risk.FResult
		else
			right = data.GripStrengthResults.MaxRightHandResult.Risk.MResult
  }
    sessionStorage.setItem("LeftHandResultStrength", left);
    sessionStorage.setItem("question5", data.GripStrengthResults.Question5);
    //Right result CHECK
    sessionStorage.setItem("MaxRightHandResult", JSON.stringify(data.GripStrengthResults.MaxRightHandResult));
    sessionStorage.setItem("RightHandResultStrength", right);
    sessionStorage.setItem("PhysioAdditionalInfo", data.AddInfo);
	
	//current mobility
	sessionStorage.setItem("ulRight", data.CurrentMobility?.ul?.right || "");
	sessionStorage.setItem("ulLeft", data.CurrentMobility?.ul?.left);
	
	sessionStorage.setItem("llRight", data.CurrentMobility?.ll?.right);
	sessionStorage.setItem("llLeft", data.CurrentMobility?.ll?.left);
	
	sessionStorage.setItem("bedmobility", data.CurrentMobility?.bedMobility);
	sessionStorage.setItem("transfers", data.CurrentMobility?.transfers);
	sessionStorage.setItem("freeText", data.CurrentMobility?.otherComment);
	    sessionStorage.setItem("gripStrength1", data.HandGripStrengthTest.first)
	    sessionStorage.setItem("gripStrength2", data.HandGripStrengthTest.second)
	    sessionStorage.setItem("gripStrength3", data.HandGripStrengthTest.third)
	    sessionStorage.setItem("leftInput1temp", data.HandGripStrengthTest.left1)
	    sessionStorage.setItem("leftInput2temp", data.HandGripStrengthTest.left2)
	    sessionStorage.setItem("rightInput1temp", data.HandGripStrengthTest.right1)
	    sessionStorage.setItem("rightInput2temp", data.HandGripStrengthTest.right2)
	
		
		

  };

  const setOccupational = (data) => {
    sessionStorage.setItem("a", data.Housebound);
    sessionStorage.setItem("a1", data.PreviousADLs.Feeding);
    sessionStorage.setItem("a2", data.CurrentADLs.Toilet);
    sessionStorage.setItem("a3", data.PreviousADLs.Bathing);
    sessionStorage.setItem("a4", data.PreviousADLs.Grooming);
    sessionStorage.setItem("a5", data.PreviousADLs.Dressing);
    sessionStorage.setItem("b1", data.CurrentADLs.Feeding);
    sessionStorage.setItem("b2", data.CurrentADLs.Toilet);
    sessionStorage.setItem("b3", data.CurrentADLs.Bathing);
    sessionStorage.setItem("b4", data.CurrentADLs.Grooming);
    sessionStorage.setItem("b5", data.CurrentADLs.Dressing);
    sessionStorage.setItem("c1", data.CommentADLs.Feeding);
    sessionStorage.setItem("c2", data.CommentADLs.Toilet);
    sessionStorage.setItem("c3", data.CommentADLs.Bathing);
    sessionStorage.setItem("c4", data.CommentADLs.Grooming);
    sessionStorage.setItem("c5", data.CommentADLs.Dressing);
    sessionStorage.setItem("a11", data.PreviousInstrumentalADLs.Telephone);
    sessionStorage.setItem("a12", data.PreviousInstrumentalADLs.Shopping);
    sessionStorage.setItem("a13", data.PreviousInstrumentalADLs.Food);
    sessionStorage.setItem("a14", data.PreviousInstrumentalADLs.Housekeeping);
    sessionStorage.setItem("a15", data.PreviousInstrumentalADLs.Laundry);
    sessionStorage.setItem("a16", data.PreviousInstrumentalADLs.Transportation);
    sessionStorage.setItem("a17", data.PreviousInstrumentalADLs.Medication);
    sessionStorage.setItem("a18", data.PreviousInstrumentalADLs.Finances);
	
	  sessionStorage.setItem("a111",  data.CurrentInstrumentalADLs?.Telephone || "")
      sessionStorage.setItem("a122",  data.CurrentInstrumentalADLs?.Shopping || "")
     sessionStorage.setItem("a133",  data.CurrentInstrumentalADLs?.Food || "")
     sessionStorage.setItem("a144",  data.CurrentInstrumentalADLs?.Housekeeping || "")
      sessionStorage.setItem("a155",  data.CurrentInstrumentalADLs?.Laundry || "")
    sessionStorage.setItem("a166",  data.CurrentInstrumentalADLs?.Transportation || "")
    sessionStorage.setItem("a177",  data.CurrentInstrumentalADLs?.Medication || "")
    sessionStorage.setItem("a188",  data.CurrentInstrumentalADLs?.Finances || "")
	  sessionStorage.setItem("a11c" ,  data.CommentInstrumentalADLs?.Telephone || "")
    sessionStorage.setItem("a12c" ,  data.CommentInstrumentalADLs?.Shopping || "")
    sessionStorage.setItem("a13c" ,  data.CommentInstrumentalADLs?.Food || "")
    sessionStorage.setItem("a14c" ,  data.CommentInstrumentalADLs?.Housekeeping || "")
    sessionStorage.setItem("a15c" ,  data.CommentInstrumentalADLs?.Laundry || "")
    sessionStorage.setItem("a16c" ,  data.CommentInstrumentalADLs?.Transportation || "")
    sessionStorage.setItem("a17c" ,  data.CommentInstrumentalADLs?.Medication || "")
    sessionStorage.setItem("a18c" ,  data.CommentInstrumentalADLs?.Finances || "")
    // const CurrentInstrumentalADL = data.CurrentInstrumentalADL.split(/,(.*)/s);
    // console.log(CurrentInstrumentalADL, CurrentInstrumentalADL[1]);
    // sessionStorage.setItem("a19", CurrentInstrumentalADL[0]);
    // sessionStorage.setItem("b19", CurrentInstrumentalADL[1]);
    sessionStorage.setItem("a19", data.CurrentInstrumentalADL?.Changes);
    sessionStorage.setItem("b19", data.CurrentInstrumentalADL?.ChangesComm);
    sessionStorage.setItem("a20", data.Continence?.Bowel);
    sessionStorage.setItem("a21", data.Continence?.Bladder);
    sessionStorage.setItem("OTAdditionalInfo", data.AddInfo);
	
	 sessionStorage.setItem("cc1", data.Continence?.BowelComment)
	 sessionStorage.setItem("bc1", data.Continence?.BowelCurrent)
	 sessionStorage.setItem("ac1", data.Continence?.BowelPrevious)
	 sessionStorage.setItem("cc2", data.Continence?.BladderComment)
	 sessionStorage.setItem("bc2", data.Continence?.BladderCurrent)
	 sessionStorage.setItem("ac2", data.Continence?.BladderPrevious)
	 sessionStorage.setItem("a21", data.Driving)
	 sessionStorage.setItem("a20", data.Employment)
	 sessionStorage.setItem("a21l", data.LeisureActivity)
	

  };

  const setNurse = (data) => {
    sessionStorage.setItem("BarthelIndexAdmission", data.Admission);
    sessionStorage.setItem("BarthelIndexDischarge", data.Discharge);
    sessionStorage.setItem("BarthelIndex", data.BarthelDifference);
	 sessionStorage.setItem("BarthelIndexAdmissionArray", data.AdmissionA);
    sessionStorage.setItem("BarthelIndexDischargeArray", data.DischargeA);
    sessionStorage.setItem("BarthelIndexDifference", data.BarthelDifferenceA);
    sessionStorage.setItem("Patient-CalculatedHeight", data.CalculatedHeight);
	sessionStorage.setItem("Patient-Height", data.Height)
    sessionStorage.setItem("Patient-Weight", data.Weight);
    sessionStorage.setItem("Weight Lost?", data.WeightLoss);
    sessionStorage.setItem("Due to exercise?", data.ExerciseWeightLoss);
    sessionStorage.setItem("bmi", data.BMI);
    sessionStorage.setItem(
      "Nurse-SLP",
      data.OtherProfessionReferredSLP || null
    );
    sessionStorage.setItem(
      "Nurse-Podiatry",
      data.OtherProfessionReferredPodiatry || null
    );
    sessionStorage.setItem(
      "Nurse-NaD",
      data.OtherProfessionReferredNaD || null
    );
    sessionStorage.setItem(
      "Nurse-Psychology",
      data.OtherProfessionReferredPsychology || null
    );
    sessionStorage.setItem(
      "Nurse-Psychiatry",
      data.OtherProfessionReferredPsychiatry || null
    );
    sessionStorage.setItem(
      "Nurse-Other",
      data.OtherProfessionReferredOther || null
    );
    sessionStorage.setItem("NurseAdditionalInfo", data.AddInfo);
	
	sessionStorage.setItem("Appetite3", data.Appetite);
	sessionStorage.setItem("Feeding-Route3", data.FeedingRoute);
	sessionStorage.setItem("assistive-devices-required", data.AssistiveDevices);
	sessionStorage.setItem("wound-present", data.WoundPresent);
	sessionStorage.setItem("Sutures-Clips-Present", data.SuturesClipsPresent);
  };

  const setSW = (data) => {
    sessionStorage.setItem("Lives Alone?", data.HomeSupport.LivesAlone);
    // sessionStorage.setItem("Array");
    sessionStorage.setItem("Any Support?", data.HomeSupport.Support);
    sessionStorage.setItem("Stairs In Home:", data.StairstoHome.Stairs);
    sessionStorage.setItem("Amenities", data.StairstoHome.AmenitiesOnSameFloor);
    sessionStorage.setItem(
      "Location Of Amenities",
      data.StairstoHome.AmenitiesLocation
    );
	      sessionStorage.setItem("maritualStatusHS", data.MaritalStatus)
	  sessionStorage.setItem("mainCarerSW", data.MainCarer)
	  sessionStorage.setItem("neighboursSW", data.Neighbours)
	  sessionStorage.setItem("supportAtHomeSW", data.SupportAtHome)
	  sessionStorage.setItem("socialServiceComments", data.SocialServicesComments)
	  sessionStorage.setItem("religiousBelief", data.ReligiousBelief)
	  sessionStorage.setItem("liftPresent", data.LiftPresent)
	  sessionStorage.setItem("typeofhouse", data.TypeofHouse)
	  sessionStorage.setItem("aimrelative", data.AimRelative)
	  sessionStorage.setItem("aimPatient", data.AimPatient)
    sessionStorage.setItem(
      "Other Amenity Location",
      data.StairstoHome.OtherAmenities
    );
    sessionStorage.setItem(
      "Amendments Required:",
      data.HomeAmendmentsRequired.Required
    );
    sessionStorage.setItem(
      "Required Amendments",
      data.HomeAmendmentsRequired.AmendmentsRequired
    );
    sessionStorage.setItem("PatientPlans", data.PatientsPlans);
    sessionStorage.setItem("RelativePlans", data.RelativePlans);
    sessionStorage.setItem("Array", data?.SocialServices?.split(","));
    sessionStorage.setItem(
      "CommCare",
      data?.SocialServices?.indexOf("CommCare") != -1
    );
    sessionStorage.setItem(
      "Telecare",
      data?.SocialServices?.indexOf("Telecare") != -1
    );
    sessionStorage.setItem(
      "HomeHelp",
      data?.SocialServices?.indexOf("HomeHelp") != -1
    );
    sessionStorage.setItem(
      "Meals On Wheels",
      data?.SocialServices?.indexOf("Meals On Wheels") != -1
    );
    sessionStorage.setItem("Other Profession:", data.SocialServicesOther);
    sessionStorage.setItem(
      "CommunityApps:",
      data.CommLTCData.CommLTCApplication
    );
    sessionStorage.setItem("TimeSpan", data.CommLTCData?.TimeSpan);
    sessionStorage.setItem("Add Info", data.AddInfo || "");
  };

  const setGC = (data) => {
	  console.log(data)
	  sessionStorage.setItem("pattern", "0");
  sessionStorage.setItem("cube", "0");
  sessionStorage.setItem("clock1", "0");
  sessionStorage.setItem("clock2", "0");
  sessionStorage.setItem("clock3", "0");
  sessionStorage.setItem("camel", "0");
  sessionStorage.setItem("lion", "0");
  sessionStorage.setItem("rhino", "0");
  sessionStorage.setItem("front", "0");
  sessionStorage.setItem("back", "0");
  sessionStorage.setItem("letters", "0");
  sessionStorage.setItem("one", "0");
  sessionStorage.setItem("two", "0");
  sessionStorage.setItem("three", "0");
  sessionStorage.setItem("four", "0");
  sessionStorage.setItem("five", "0");
  sessionStorage.setItem("rep1", "0");
  sessionStorage.setItem("rep2", "0");
  sessionStorage.setItem("sec12", "0");
  sessionStorage.setItem("count", "0");
  sessionStorage.setItem("trans", "0");
  sessionStorage.setItem("meas", "0");
  sessionStorage.setItem("date", "0");
  sessionStorage.setItem("day", "0");
  sessionStorage.setItem("month", "0");
  sessionStorage.setItem("year", "0");
  sessionStorage.setItem("place", "0");
  sessionStorage.setItem("city", "0");
  sessionStorage.setItem("eduCheck", "0");
  sessionStorage.setItem("blindCheck", "0");
  sessionStorage.setItem("writeCheck", "0");
  sessionStorage.setItem("face", "0");
  sessionStorage.setItem("velvet", "0");
  sessionStorage.setItem("red", "0");
  sessionStorage.setItem("daisy", "0");
  sessionStorage.setItem("church", "0");
  sessionStorage.setItem("cubepic", "");
  sessionStorage.setItem("clockpic", "");
  sessionStorage.setItem("patternpic", "");
  sessionStorage.setItem("subtraction", "0");
  sessionStorage.setItem("orientation", "0");
  sessionStorage.setItem("delayedrecall", "0");
  sessionStorage.setItem("abstraction", "0");
  sessionStorage.setItem("language", "0");
  sessionStorage.setItem("attention", "0");
  sessionStorage.setItem("naming", "0");
  sessionStorage.setItem("visuo", "0");
  
    //   setDiagnosis(
    sessionStorage.setItem("q1", data.section1.diagnosis);
    // setCondition(
    sessionStorage.setItem("q2", data.section1.presentCondition);
    // setMedical(
    sessionStorage.setItem("q3", data.section1.medicalHistory);
    // setDrug(
    sessionStorage.setItem("q4", data.section1.drugHistory);
    // setSmoking(
    sessionStorage.setItem("q5", data.section1.smoking);
    // setAlcohol(
    sessionStorage.setItem("q6", data.section1.alcohol);
    // setEducation(
    sessionStorage.setItem("q7", data.section1.education);
    sessionStorage.setItem("qsurgical", data.section1.surgicalProcedure);
    // setCmi(parseInt(
    sessionStorage.setItem("mobility-index", data.section1.charlsonIndex);
    // setMocaScore(parseInt(
    sessionStorage.setItem("moca-score", data.moca.finalScore.split("/")[0]);
    // // setMocaMax(parseInt(
	const maxMoca = data.moca.finalScore.split("/")[1] && data.moca.finalScore.split("/")[1].split(" -")[0]
    sessionStorage.setItem("moca-max",maxMoca);
    // // setMocaStatus(
    sessionStorage.setItem("moca-status", data.moca.finalScore.split("-")[1]);
    sessionStorage.setItem("GCAdditionalInfo", data.AddInfo);
	    sessionStorage.setItem("mobility-index-checked", data.CharlsonIndexChecklist)
		sessionStorage.setItem("pattern", data.MocaTest?.visioPattern)
		sessionStorage.setItem("cube", data.MocaTest?.cube)
		sessionStorage.setItem("clock1", data.MocaTest?.clock1)
		sessionStorage.setItem("clock2", data.MocaTest?.clock2)
		sessionStorage.setItem("clock3", data.MocaTest?.clock3)
		sessionStorage.setItem("camel", data.MocaTest?.camel)
		sessionStorage.setItem("rhino", data.MocaTest?.rhino)
		sessionStorage.setItem("lion", data.MocaTest?.lion)
		sessionStorage.setItem("trail1", data.MocaTest?.trial1)
		sessionStorage.setItem("trail2", data.MocaTest?.trial2)
		sessionStorage.setItem("front", data.MocaTest?.front)
		sessionStorage.setItem("back", data.MocaTest?.back)
		sessionStorage.setItem("letters", data.MocaTest?.letters)
		sessionStorage.setItem("checkedV", data.MocaTest?.serial)
		sessionStorage.setItem("rep1", data.MocaTest?.rep1)
		sessionStorage.setItem("rep2", data.MocaTest?.rep2)
		
		 sessionStorage.setItem("TUGTimer", data.MocaTest?.tugTimer)
		sessionStorage.setItem("counter", data.MocaTest?.counter)
		sessionStorage.setItem("trans", data.MocaTest?.trans)
		sessionStorage.setItem("meas", data.MocaTest?.meas)
		sessionStorage.setItem("trail22", data.MocaTest?.trail22)
		sessionStorage.setItem("trail21", data.MocaTest?.trail11)
		sessionStorage.setItem("trail23", data.MocaTest?.trail33)
		sessionStorage.setItem("checkedv15", data.MocaTest?.mocaTestOrientation)
		sessionStorage.setItem("writeCheck", data.MocaTest?.writeCheck)
		sessionStorage.setItem("eduCheck", data.MocaTest?.eduCheck)
		sessionStorage.setItem("blindCheck", data.MocaTest?.blindCheck)
		    sessionStorage.setItem("MOCASec3Comment", data.MocaTest?.visualPatternComment);
		    sessionStorage.setItem("MOCASec4Comment", data.MocaTest?.cubeComment);
		    sessionStorage.setItem("MOCASec5Comment", data.MocaTest?.clockComment);

		  sessionStorage.setItem("attention", data.moca.attention)
		  sessionStorage.setItem("abstraction", data.moca.abstraction)
		  sessionStorage.setItem("language", data.moca.language)
		  sessionStorage.setItem("naming", data.moca.naming)
		  sessionStorage.setItem("orientation", data.moca.orientation)
		  sessionStorage.setItem("delayedrecall", data.moca.recall)
		  sessionStorage.setItem("visuo", data.moca.visual)
    

  };

  //Navigates to page depending on role
  const handleSummaryClick = (role) => {
    if (role == "nurse") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionE"
      ).then((data) => {
        if (!data) setDialogOpen(true);
        else {
          setNurse(data);
          navigation("/SummaryPageNurse", { state: { summary: true } });
        }
      });
    }

    if (role == "occupational") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionC"
      ).then((data) => {
        console.log(data, "uguk");
        if (!data) setDialogOpen(true);
        else {
          setOccupational(data);
          navigation("/Section6OT", { state: { summary: true } });
        }
      });
    }

    if (role == "other") {
      navigation("/PatientView-Other", { state: { summary: true } });
    }

    if (role == "physio") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionB"
      ).then((data) => {
        if (!data) setDialogOpen(true);
        else {
          setPhysio(data);
          navigation("/SummaryTeam2", { state: { summary: true } });
        }
      });
    }

    if (role == "social worker") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionD"
      ).then((data) => {
        console.log(data);
        if (!data) setDialogOpen(true);
        else {
          setSW(data);

          navigation("/Summary-Social-Worker", { state: { summary: true } });
        }
      });
    }
    if (role == "geriatric consultant") {
      getPatientSectionDetails(
        sessionStorage.getItem("patientId"),
        "SectionA"
      ).then((data) => {
        if (!data) setDialogOpen(true);
        else {
          setGC(data);
          navigation("/review", { state: { summary: true } });
        }
      });
    }
  };

  var typography =
    "Click on your profession to start assessment. \nClick on other professions to view summary of their assessments.";

  const detailroles = [
    {
      name: "nurse",
      item: "Nursing",
    },
    {
      name: "occupational",
      item: "Occupational Therapy",
    },
    {
      name: "physio",
      item: "Physiotherapy",
    },
    {
      name: "social worker",
      item: "Social Work",
    },
    {
      name: "geriatric consultant",
      item: "Geriatrics",
    },
  ];

  //Roles without selected
  const [roles1, setRoles] = useState(detailroles);

  //Removes selectedRole from the role array
  const removeSecond = () => {
    setRoles((current) =>
      current.filter((role) => {
        console.log({ selectedrole });
        return role.name !== selectedrole;
      })
    );
  };

  useEffect(() => {
    removeSecond();
    resetData();
    // sessionStorage.clear();
  }, []);

  return (
    <div className="screen">
      <Header
        typography={typography}
        history={"/PatientView"}
        name="View Forms"
      ></Header>
      <Grid
        container
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        rowGap={0}
        columns={{ xs: 2, sm: 2, md: 6 }}
      >
        <div display="flex" flex-direction="column" justifyContent="center">
          <h1>Case Managers</h1>
        </div>

        {/* Assessment box */}
        <div item className={"case-manager-container"} style={{ width: "60%" }}>
          <List>
            <ListItem
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h2>Assessment</h2>
            </ListItem>
            <ListItem
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{
                  fontSize: "22px",
                  borderRadius: "35px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "2em",
                  paddingRight: "2em",
                  margin: "15px 15px",
                  fontFamily: "Fenix",
                  textTransform: "capitalize",
                  minWidth: "100%",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                name={selectedrole}
				className={selectedrole == "social worker" ? "social_work": selectedrole == "nurse" ? "nurse" : selectedrole =="occupational" ? "occupational_therapy": selectedrole == "physio" ? "physiotherapy" : selectedrole=="geriatric consultant"?"generic":""}
                onClick={() => {
                  {
                    handleRoleClick(selectedrole);
                  }
                }}
              >
                {detailroles.find((r) => r.name == selectedrole).item}
              </Button>
            </ListItem>
          </List>
        </div>

        <div item className={"case-manager-container"} style={{ width: "60%" }}>
          {/* <div className="roles"> */}
          <List>
            <ListItem
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h2>Summary</h2>
            </ListItem>
            {/*Creates button for each role*/}
            {roles1.map((role, i) => (
              <ListItem
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{
                    fontSize: "22px",
                    borderRadius: "35px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "2em",
                    paddingRight: "2em",
                    margin: "15px 15px",
                    fontFamily: "Fenix",
                    textTransform: "capitalize",
                    minWidth: "100%",
                    whiteSpace: "nowrap",
                  }}
                  variant="contained"
                  key={i}
                  name={role.name}
				  className={role.name == "social worker" ? "social_work": role.name == "nurse" ? "nurse" : role.name =="occupational" ? "occupational_therapy": role.name == "physio" ? "physiotherapy" : role.name=="geriatric consultant"?"generic":""}
                  onClick={() => {
                    {
                      handleSummaryClick(role.name);
                    }
                  }}
                >
                  {role.item}
                </Button>
                {}
              </ListItem>
            ))}
          </List>
        </div>
        {/* </div> */}
      </Grid>
      <NoDataDialog open={dialogOpen} setOpen={() => setDialogOpen()} />
    </div>
  );
}
