import "../styles/CommonStyle.css";
import Fab from "@mui/material/Fab";
import HelpIcon from "@mui/icons-material/Help";
import { ArrowBack, Padding } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect, useRef } from "react";
import Timer from "react-compound-timer";
import TUGPhoto from "../assets/TUG_Test_Setup.png";
import { Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import Grid from "@mui/material/Grid";
import { AppBar, Button, fabClasses } from "@mui/material";
import Header from "../header";
import BottomNav from "../bottomnavg";
import { saveTeam2 } from "../firebase";

function ListOfEquipment() {
  var typography =
    "\nThis page is to be used as a Practice TUG Test. \nMove to next page to carry out the Timed TUG Test.\n \nInstructions: \n\n1.  Equipment: arm chair, tape measure, tape or marker. \n2.  Begin the test with the patient sitting correctly (hips all the way to the back of the seat) in a chair with arm rests. The chair should be stable and positioned such that it will not move when the patient moves from sit-to-stand. The patient is allowed to use the arm rests during the sit-to-stand and stand-to-sit movements. \n3.  Place a marker or tape on the floor, 3 metres away from the chair, so that it is easily seen by the patient. \n4.  Instructions: “On the word GO you will stand up, walk to the line on the floor, turn around and walk back to the chair and sit down. Walk at your regular pace. \n5.  Start timing on the word GO and stop timing when the patient is seated again correctly in the chair with their back resting on the back of the chair. \n6.  The patient wears their regular footwear, may use any gait aid that they normally use during ambulation, but may not be assisted by another person. There is no time limit. They may stop and rest (but not sit down) if they need to. ";

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const question1 = sessionStorage.getItem("TUGQuestion1");
  const question2 = sessionStorage.getItem("TUGQuestion2");
 // sessionStorage.setItem("TUGTimer", 0);
 // sessionStorage.setItem("TUGStatus", "");
 // sessionStorage.setItem("TUGTestCarriedOut", "");
 // sessionStorage.setItem("question1", "");
 // sessionStorage.setItem("question2", "");
 // sessionStorage.setItem("question3", "");
  //sessionStorage.setItem("question4", "");
  //sessionStorage.setItem("question5", "");


  const saveData = () =>{
	    let SessionLeftResult = sessionStorage.getItem("MaxLeftHandResult");
  let SessionRightResult = sessionStorage.getItem("MaxRightHandResult");

  console.log(SessionLeftResult + " Left")
  console.log(SessionRightResult + " Right")

  //let ActualObjectLeftResult = JSON.parse(SessionLeftResult);
 // let ActualObjectRightResult = JSON.parse(SessionRightResult);

  let ActualObjectLeftResult = null;
  try{
    ActualObjectLeftResult = JSON.parse(SessionLeftResult)
  } catch(e){
    ActualObjectLeftResult = SessionLeftResult
  }

  let ActualObjectRightResult = null;
  try{
    ActualObjectRightResult = JSON.parse(SessionRightResult)
  } catch(e){
    ActualObjectRightResult = SessionRightResult
  }
	    const dataArray = {
    date: null,
    lefthandrisk: ActualObjectLeftResult && ActualObjectLeftResult.Risk,
    lefthandresult: ActualObjectLeftResult && ActualObjectLeftResult.TestResult,
    righthandrisk: ActualObjectRightResult && ActualObjectRightResult.Risk,
    righthandresult:
      ActualObjectRightResult && ActualObjectRightResult.TestResult,
    question1: sessionStorage.getItem("question1"),
    question2: sessionStorage.getItem("question2"),
    question3: sessionStorage.getItem("question3"),
    question4: sessionStorage.getItem("question4"),
    question5: sessionStorage.getItem("question5"),
    currentmobility: sessionStorage.getItem("TUGQuestion2"),
    previousmobility: sessionStorage.getItem("TUGQuestion1"),
	 dcurrentmobility: sessionStorage.getItem("mobilityQuestion22"),
    dpreviousmobility: sessionStorage.getItem("mobilityQuestion11"),
	currentMobility :{
		ul:{
			right: sessionStorage.getItem("ulRight"),
			left: sessionStorage.getItem("ulLeft")
		},
		ll:{
			right: sessionStorage.getItem("llRight"),
			left: sessionStorage.getItem("llLeft")
		},
		bedMobility: sessionStorage.getItem("bedmobility"),
		transfers: sessionStorage.getItem("transfers") && sessionStorage.getItem("transfers").split(","),
		otherComment: sessionStorage.getItem("freeText")
	},
    riskoffallstatus: sessionStorage.getItem("TUGStatus"),
    tugtimetaken: sessionStorage.getItem("TUGTimer"),
    tugcarriedout: sessionStorage.getItem("TUGTestCarriedOut"),
    AddInfo: sessionStorage.getItem("PhysioAdditionalInfo"),
		draft: true

  };
  saveTeam2(sessionStorage.getItem("patientId") , null, dataArray, false)
  }
  
  function navToNextPage() {
    // sessionStorage.setItem("Instructions", true);
	saveData()
    navigate("/TimedTimer");
  }

  function navToPreviousPage() {
	  saveData()
    navigate("/CurrentMobility");
  }

  return (
    <div className="screen" style={{justifyContent:"space-between"}}>
      <div className="screen" style={{width:"100%", minHeight:"auto"}}>
      <Header
        typography={typography}
        history={"/CurrentMobility"}
        name="Timed Up and Go Test"
      />

      <h1>Timed Up and Go Test</h1>
      <div className="physio-equipment-container" style={{width:"70%"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={TUGPhoto} />
        </div>
        <div
          style={{
            display: "flex",
            textAlign: "start",
            paddingTop: "2em",
            paddingBottom: "1.5 em",
            fontSize: "1.2em",
            width:"100%"
          }}
        >
          <ul style={{ paddingLeft: "1em", width:"100%" }}>
            <li>
              On the word "GO" you will stand up, walk to the line on the floor,
              turn around and walk back to the chair and sit down.
            </li><br />
            <li>Walk at your regular pace.</li><br />
            <li>You are allowed one practice before the test.</li><br />
            <li>Move to the next page to start the timer.</li>
          </ul>
        </div>
        <div
          style={{ paddingTop: "20px", fontSize: "35px", fontFamily: "Fenix" }}
        ></div>
      </div>
      </div>
      <BottomNav
        prevNavigation={() => navToPreviousPage()}
        nextNavigation={() => navToNextPage()}
        currentPage={3}
        numPages={9}
      ></BottomNav>
    </div>
  );
}

export default ListOfEquipment;
